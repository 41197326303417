import { JwtHelperService } from "@auth0/angular-jwt"

export class User{
    constructor(
        // public Idsito: number,
        // public Role : string,
        // public userid : number,
        // public name : string,
        // private _exp: string,
        // private _token : string,

        public id: string,
        public roleName:string,
        public idUtente: number,
        public firstName:string,
        public lastName: string,
        public username: string,
        public expiresIn: string,
        public token:string,
    ){}

    jwtHelper : JwtHelperService;

    get data(){
        if(!this.expiresIn || new Date().toISOString() > this.expiresIn){
            return null
        }
        return this.data
    }
}