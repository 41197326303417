import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gruppo } from '../Models/Gruppo';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GruppoService {

  private apiUrl1 = environment.apiUrl;
  constructor(private http: HttpClient) { }

  GetGruppo(id: number): Observable<Gruppo[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/articoli/GetGruppi/?id=" + id}`;
    return this.http.get<Gruppo[]>(url, httpOptions);
  }

  InsGruppo(gruppo: Gruppo): Observable<Gruppo> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/UpdateGruppi";
    return this.http.put<Gruppo>(url, gruppo, httpOptions);
  }

  Updins_Gruppo(gruppoid : number, descrizione:string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/utenti/Updins_Gruppo?gruppoID=" + gruppoid + "&Descrizione=" + descrizione;
    return this.http.put<any>(url, httpOptions);
  }

  UpdateGruppiMenuMultimo(idgruppo: number, idmenu:string, abilitato:boolean): Observable<string> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/utenti/UpdateGruppiMenuMultimo?idgruppo=" + idgruppo + "&idmenu=" + idmenu + "&abilitato=" + abilitato;
    return this.http.put<string>(url, httpOptions);
  }

  Sel_IdLivello(link:string): Observable<number> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/utenti/Sel_IdLivello?link=" + link;
    return this.http.get<number>(url, httpOptions);
  }
}
