<form [formGroup]="formingresso" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>
            <button class="button-tab-delete" type="button" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button> -->

        </mat-card-actions>
        <b>Numero Ordine {{numeroOrdine}}</b><br>
        <b>Cliente {{cliente}}</b><br>
        <b>Destinatario {{destinatario}}</b><br>
        <b>Data Consegna {{dataconsegna}}</b><br>
        <b>Nota: {{note}}</b>
        <table>
            <tr>
                
                <td>
                    <mat-form-field class="full-width">
                        <mat-label>Data</mat-label>
                        <input type="date" matInput formControlName="data">
                    </mat-form-field>
                </td>
                <td>
                    <!-- <mat-form-field class="full-width">
                        <mat-label>Stato</mat-label>
                        <input matInput formControlName="Stato">
                    </mat-form-field> -->
                </td>
                <td>
                    <mat-form-field class="full-width">
                        <mat-label>Note</mat-label>
                        <textarea matInput name="note" formControlName="note"></textarea>
                    </mat-form-field>
                </td>
            </tr>
        
        </table>
        <div style="display:flex;flex-direction: row; justify-content: space-between;">
            <div>
                <!-- <button class="button-add-row" type="button" (click)="openDialogSearchArticolo()">Aggiungi
                    Riga</button> -->
                <!-- <button class="button-delete-row" type="button">Elimina
                    Riga</button> -->
            </div>
            <div>
                <button class="button-print" type="button" (click)="onPrint(1)">
                    Stampa</button>
                    <!--   -->
                    <!-- <button class="button-add-row" type="button" (click)="onPrint(2)" *ngIf="isProdottoFinito">  
                    Stampa Etichette</button> -->
                <!-- <button class="button-delete-row" type="button" title="Accetta" (click)="onAccetta()">
                    Accetta
                </button> -->
            </div>
        </div>
        <app-grid [columnDefs]="columnDefsArticoli"
            [defaultColDef]="defaultColDef" [rowData]="rowArticoli" (gridReady)="RefreshMissioni($event)" (onDblClick)="openDialog($event)" [getRowStyle]="getRowStyle"
            >
        </app-grid>
        

    </mat-card>
</form>