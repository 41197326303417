<form [formGroup]="formingresso" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <!-- <button class="button-tab-save" type="submit" title="Salva" *ngIf="btnsalva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button> -->
            
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>
            <button class="button-tab-delete" type="button" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button> -->

        </mat-card-actions>
        
        <table style="width: 100%;">
            <tr>
                <td style="border-right: 1px solid #c0c0c0;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr><td style="text-align: center;" colspan="3"><p class="text-grigio">Dati Generali</p></td></tr>
                        <tr>
                            <td>
                                <span>Cliente <b>{{RagioneSociale}}</b></span><br>
        
                                <span>Nota <b>{{note}}</b></span>
                            </td>
                                                      
                        </tr>
                        <!-- <tr>
                            <td>
                                <mat-checkbox type="checkbox" name="Evaso" color="primary" formControlName="Evaso">Evaso
                                </mat-checkbox>
                            </td>
                        </tr> -->
                    </table>
                </td>
                <td style="display: table;align-items: start;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr>
                            <td style="text-align: center;" colspan="3">
                                <p class="text-grigio">Intestatario</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Intestatario <b>{{intestatario}}</b></span><br>
                                <span>Indirizzo <b>{{indirizzo}}</b></span><br>
                                <span>CAP <b>{{cap}}</b></span><br>
                                <span>Località <b>{{localita}}</b></span><br>
                                <span>Provincia <b>{{provincia}}</b></span><br>
                                <span>Nazione <b>{{nazione}}</b></span><br>
                                <span>Targa <b>{{targa}}</b></span><br>
                            </td>
                        </tr>
                    </table>
                </td>
                </tr>
                
                </table>
                <div style="display:flex;flex-direction: row; justify-content: space-between;">
            <div>
                <button *ngIf="btnadd" class="button-add-row" type="button" (click)="openDialogSearchArticolo()">Aggiungi
                    Riga</button>
                
                <!-- <button class="button-delete-row" type="button">Elimina
                    Riga</button> -->
            </div>
            <!-- <div>
                <button class="button-confirm-row " *ngIf="btnsalva" type="button" title="Salva" (click)="onClickConferma()">
                    Conferma
                </button>
            </div> -->
        </div>
        <app-grid [columnDefs]="columnDefsArticoli"
            [defaultColDef]="defaultColDef" [rowData]="rowArticoli" (gridReady)="RefreshMissioni($event)"
            >
        </app-grid>
        

    </mat-card>
</form>