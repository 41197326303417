import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, throwError } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar,private router: Router, private http: HttpClient, private loginService: LoginService,private route: ActivatedRoute) { }
  credentials: any = {password:'', confirmPassword:'', token: '', userId: ''};
  hidebtn=true;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.credentials.token = params['token'];
      this.credentials.userId = params['userId'];
    });
    if((this.credentials?.token == '' || this.credentials?.token === undefined ) || (this.credentials?.userId == '' || this.credentials?.userId === undefined)){
      alert('Link non valido: il token e/o userId non sono presenti nell\'url')
      this.hidebtn=false;
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 3000
    });
  }
  reset = (form: NgForm) => {
    if (form.valid) {

      
      //console.log(this.credentials)
      if (this.credentials.password == this.credentials.confirmPassword) {
        this.loginService.ResetPassword(this.credentials).subscribe({
          next(response) {
            console.log('Password reset successful!', response);
            alert(response.message || 'Salvataggio avvenuto con successo')
          },
          error(err) {

            switch(err.status){
              case 400:
              case 500:
                alert(err.error.message || 'Server error');
                break;
              case 404:
                alert('User not found');
                break;
              default:
                alert('Something went wrong. Please try again later.');
                  break;
            }
            return null;
          }
        });

        // this.loginService.ResetPassword(this.credentials).pipe(
        //   map(response => {
        //     // Handle successful response (status code 200)
        //     console.log('Password reset successful!', response);
        //     return response;
        //   }),
        //   catchError(error => {
        //     // Handle errors (status codes 400, 404, 500, etc.)
        //     if (error.status === 404) {
        //       // Specific error handling for 404 (Not Found)
        //       console.error('User not found');
        //       this.openSnackBar('Utente non trovato.');
        //       return throwError(() => new Error('User not found'));
        //     } else if (error.status === 400) {
        //       // Extract error message from response body (if available)
        //       this.openSnackBar('Password e Conferma Password non corrispondono');
        //       return throwError(() => new Error(error.error.message || 'Server error'));
        //     } else {
        //       // Generic error handling for other status codes

        //       this.openSnackBar('Unexpected error, message:' + error.error);
        //       return throwError(() => new Error('Something went wrong. Please try again later.'));
        //     }
        //   })
        // );
      } else {
        this.openSnackBar('Password e Conferma Password non corrispondono');
        this.credentials.confirmPassword = '';
      }
    }
  }

}
