import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { Liste } from 'src/app/Models/Liste';
import { TipoDocumento } from 'src/app/Models/TipoDocumento';
import { ArticoloService } from 'src/app/services/articolo.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogIngressoArticoloComponent } from '../dialog-ingresso-articolo/dialog-ingresso-articolo.component';
import { DialogIngressoCercaArticoloComponent } from '../dialog-ingresso-cerca-articolo/dialog-ingresso-cerca-articolo.component';
import { Tab } from '../ingresso/ingresso.component';
//  import {pdf} from 'pdf.json'
import data from 'pdf.json';
import { Ingresso } from 'src/app/Models/Ingresso';
import { DialogMultisceltaComponent } from '../../dialog-multiscelta/dialog-multiscelta.component';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { GridUdcIconComponent } from '../../grid-udc-icon/grid-udc-icon.component';
import { UDC } from 'src/app/Models/UDC';
import { DialogIngressoUdcComponent } from '../../dialog-ingresso-udc/dialog-ingresso-udc.component';
import { retryWhen } from 'rxjs';


@Component({
  selector: 'app-ingresso-edit',
  templateUrl: './ingresso-edit.component.html',
  styleUrls: ['./ingresso-edit.component.scss']
})
export class IngressoEditComponent implements OnInit {

  formingresso: FormGroup = new FormGroup({
    idCliente: new FormControl(''),
    idIntenstatario: new FormControl(''),
    tipoDocID: new FormControl(''),
    idCausale: new FormControl(''),
    ragioneSociale: new FormControl(''),
    numDoc: new FormControl(''),
    dataDoc: new FormControl(''),
    // NumeroRif: new FormControl(''),
    dataRif: new FormControl(''),
    indirizzo: new FormControl(''),
    id_Valuta: new FormControl(''),
    nRif: new FormControl(''),
    valoreDoc: new FormControl(''),
    cap: new FormControl(''),
    localita: new FormControl(''),
    provincia: new FormControl(''),
    nazione: new FormControl(''),
    //Esportato: new FormControl(''),
    bloccato: new FormControl(''),
    documentoID: new FormControl(''),
    colli: new FormControl('0'),
    peso: new FormControl('0'),
    volume: new FormControl('0'),
    email: new FormControl(''),
    telefono: new FormControl(''),
    dataConsegna: new FormControl(''),
    contatto: new FormControl(''),
    contrattoVettoreId: new FormControl(''),
    naturaMerce: new FormControl(''),
    rifVettore: new FormControl(''),
    noteVettore: new FormControl(''),
    codVettore: new FormControl(''),
    pagamentoId: new FormControl(''),
    note: new FormControl(''),
  });

  esportato: string = "No";
  @Output() closeTab = new EventEmitter<number>();
  @Input() id: number = 0;

  @Input() tab: Tab = { id: 0, nome: "", numero: "" };

  public loading: string = 'none';
  selected = new FormControl(0);
  clienti: Cliente[] = [];
  intestatari: Cliente[] = [];
  tipodoc: TipoDocumento[] = [];
  vettori: Cliente[] = [];
  liste: Liste;
  causali: [{ idCausale: number, descrizione: string }];

  idInterno: number;

  Confermato: boolean = false;
  Accettato: boolean = false;
  isStocked: boolean = false;
  TipoConferma: number=1;

  constructor(private _snackBar: MatSnackBar,
    private documentiService: DocumentiService,
    private clientiService: CustomerService,
    public dialog: MatDialog,
    private commonService: CommonService) { }

  openSnackBar(message: string, time: number = 2000) {
    this._snackBar.open(message, 'Chiudi', {
      duration: time
    });
  }

  ngOnInit(): void {
    this.getListe();
    this.getDocumento(this.id);
    this.idInterno = this.id
  }

  onElimina(){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'Il documento sarà eliminato e con esso tutte le eventuali UDC già accettate, Continuare?',
        BtnSi: 'Elimina',
        BtnNo: 'Annulla',
        BtnInvertiti: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = 'block';
        this.documentiService.DelDocumentoDDT(this.idInterno).subscribe((data) => {
          this.loading = 'none'
          if (data[0].err == '0') {
            this.closeTab.emit(this.idInterno);
          } else {
            this.openSnackBar(data[0].msg);
          }
        });
      }
    });

    
  }

  getDocumento(id: number) {
    this.documentiService.GetDocumento(14, id).subscribe((res) => {
      this.formingresso = new FormGroup({
        idCliente: new FormControl(res[0]?.idCliente || 0, Validators.required),
        idIntenstatario: new FormControl(res[0]?.idIntenstatario || 0, Validators.required),
        tipoDocID: new FormControl(res[0]?.tipoDocID || 1, Validators.required),
        idCausale: new FormControl(res[0]?.idCausale || 1, Validators.required),
        ragioneSociale: new FormControl(res[0]?.ragioneSociale || ''),
        numDoc: new FormControl(res[0]?.numDoc || '', Validators.required),
        dataDoc: new FormControl(new Date(res[0]?.dataDoc).toLocaleDateString('af').replace(new RegExp('/', "g"), '-') || '', Validators.required),
        // NumeroRif: new FormControl(res[0]?.NumeroRif || '', Validators.required),
        dataRif: new FormControl({value: new Date(res[0]?.dataRif).toLocaleString('af').replace(new RegExp('/', "g"), '-') || '', disabled:true}),
        indirizzo: new FormControl(res[0]?.indirizzo || ''),
        id_Valuta: new FormControl(res[0]?.id_Valuta || 0),
        nRif: new FormControl(res[0]?.nRif || ''),
        valoreDoc: new FormControl(res[0]?.valoreDoc || '0'),
        cap: new FormControl(res[0]?.cap || ''),
        localita: new FormControl(res[0]?.localita || ''),
        provincia: new FormControl(res[0]?.provincia || ''),
        nazione: new FormControl(res[0]?.nazione || ''),
        //Esportato: new FormControl(res[0]?.Esportato || false),
        bloccato: new FormControl(res[0]?.bloccato || false),
        documentoID: new FormControl(res[0]?.documentoID || '0', Validators.required),
        colli: new FormControl(res[0]?.colli || '0'),
        peso: new FormControl(res[0]?.peso || '0'),
        volume: new FormControl(res[0]?.volume || '0'),
        email: new FormControl(res[0]?.email || ''),
        telefono: new FormControl(res[0]?.telefono || ''),
        dataConsegna: new FormControl(new Date(res[0]?.dataConsegna).toLocaleDateString('af').replace(new RegExp('/', "g"), '-') || ''),
        contatto: new FormControl(res[0]?.contatto || ''),
        contrattoVettoreId: new FormControl(res[0]?.contrattoVettoreId || 0),
        naturaMerce: new FormControl(res[0]?.naturaMerce || ''),
        rifVettore: new FormControl(res[0]?.rifVettore || ''),
        noteVettore: new FormControl(res[0]?.noteVettore || ''),
        codVettore: new FormControl(res[0]?.codVettore || 0),
        pagamentoId: new FormControl(res[0]?.pagamentoId || 0),
        note : new FormControl(res[0]?.note || ''),
      });
      res[0].esportato ? this.esportato = 'Si' : 'No';
      this.rowArticoli = res[0].dettagliArticoli;
      this.rowUDC = res[0].dettagli;
      this.clienti = res[0].enti;
      this.intestatari =res[0].intestatario;
      this.causali = res[0].causali;
      this.tipodoc = res[0].tipodocumenti;
      this.vettori = res[0].vettori;
      this.formingresso.get('idIntenstatario').disable()

      this.Confermato = res[0].confermato;
      this.Accettato = res[0].accettato;
      this.TipoConferma = res[0].tipoConferma;
      //this.isStocked = res[0].isStocked;
      this.onChangeCliente(res[0].idCliente)
      this.onChangeIntestatario(res[0].idIntenstatario)
    }
    );
  }

  RefreshArticoli(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.rowArticoli = this.rowArticoli;
  }
  RefreshUDC(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.rowUDC = this.rowUDC;
  }

  getListe() {
    this.commonService.getListe()
      .subscribe((data) => (this.liste = data));
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  printPDF() {
    if (this.idInterno > 0) {
      if (this.rowUDC.length > 0) {
        const dialogRef = this.dialog.open(DialogMultisceltaComponent, {
          data: {
            messaggio: 'Selezionare Tipo di Stampa',
            Btn1: 'Info Parziali',
            Btn2: 'Info Complete',
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          this.documentiService.stampaUDC(this.idInterno, result).subscribe((data) => {
            this.loading = 'none'
            if (data[0].err = '0') {
              var fileblob = this.b64toBlob(data[0].msg, 'application/pdf');
              var url = window.URL.createObjectURL(fileblob);

              window.open(url);
            } else {
              this.openSnackBar(data[0].msg, 5000);
            }
          });

          // if (result == 1) {
          //   var fileblob = this.b64toBlob(data.responsible_official.signature, 'application/pdf');
          //   var url = window.URL.createObjectURL(fileblob);

          //   window.open(url);
          // } else if (result == 2) {
          //   var fileblob = this.b64toBlob(data.responsible_official.signature, 'application/pdf');
          //   var url = window.URL.createObjectURL(fileblob);

          //   window.open(url);
          // }
        })
      } else {
        this.openSnackBar('Il documento non contiene UDC');
      }
    } else {
      this.openSnackBar('Salvare prima il Documento');
    }

  }

  private gridApi!: GridApi<any>;
  creaOrdine(){
    let selection = this.gridApi.getSelectedRows();
  }

  onSubmit() {
    if (!this.formingresso.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
      return false;
    } else {
      this.loading = 'block';
      let req: Ingresso = this.formingresso.value;
      //req.Note = '';
      req.dettagli = this?.rowArticoli || [];
      req.enti = [];
      req.log = [];
      req.vettori = [];
      req.causali = [{ idCausale: 0, descrizione: '' }];
      req.tipodocumenti = [];
      req.cAss=0;
      req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
      req.documentoID= this.idInterno == -1 ? 0 : this.idInterno;
      req.esportato = false
      //console.log(req)
      this.documentiService.InsDocumento(req).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err == '0') {
          this.idInterno = +data[0].msg;
          this.getDocumento(data[0].msg);
          this.openSnackBar('Documento salvato', 3000);
          return true
        } else {
          this.openSnackBar(data[0].msg, 5000);
          if(this.idInterno>0)
            this.getDocumento(this.idInterno);
          return false
        }
      });

      return true
    }
  }

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
  }

  enti: any;

  onChangeCliente(id: number) {
    if (id > 0) {
      this.formingresso.get('idIntenstatario').enable()

      this.clientiService.GetEnte(id).subscribe((data)=>{
        this.intestatari= data[0]?.entifornitori || [];
      })
    }
  }
  onChangeIntestatario(id: number) {
    if (id > 0) {
    this.clientiService.GetEnte(id).subscribe((data) => {
      this.formingresso.get('idIntenstatario').setValue(data[0].idCliente)
      this.formingresso.get('ragioneSociale').setValue(data[0].ragione_Sociale)
      this.formingresso.get('indirizzo').setValue(data[0].indirizzo_Legale)
      // this.formingresso.get('Localita').setValue(data[0].Localita)
      // this.formingresso.get('Provincia').setValue(data[0].Provincia)
      this.formingresso.get('cap').setValue(data[0].capL)
      // this.formingresso.get('Nazione').setValue(data[0].Nazione)
    })
  }
  }
  rowArticoli: any[] = [];
  rowUDC: IngressoArticolo[] = [];

  public columnDefsArticoli: (ColDef | ColGroupDef)[] = [
    //{ field: "NumUdc", headerName : "UDC",width:100, filter: false, cellRenderer: GridUdcIconComponent, checkboxSelection: true},
    // { field: 'Articolo_Id', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'partNumber', headerName: "PN", width: 200, filter: 'agTextColumnFilter' },
    { field: 'descrizione', headerName: "Des. Articolo", width: 200, filter: 'agTextColumnFilter' },
    //{ field: 'NumUdc', headerName: "NrUdc", type: 'numberColumn', width: 200 },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 200, filter: 'agNumberColumnFilter' },
    { field: 'qtaEvasa', headerName: "Qtà Accettata", type: 'numberColumn', width: 200, filter: 'agNumberColumnFilter' },
    { field: 'qtaMiss', headerName: "Qtà Riscontrata", type: 'numberColumn', width: 200, filter: 'agNumberColumnFilter' },
    { field: 'note', width: 250,filter: 'agTextColumnFilter'  },
  ];

  public columnDefsUDC: (ColDef | ColGroupDef)[] = [
    { field: "numUdc", headerName : "UDC",width:200, cellRenderer: GridUdcIconComponent, checkboxSelection: true, filter: 'agTextColumnFilter' },
    { field: 'udcalternativo', headerName: "UDC Alternativo", width: 200, filter: 'agTextColumnFilter'  },
    { field: 'descrizione', headerName: "Descrizione", width: 200,filter: 'agTextColumnFilter'  },
    { field: 'partNumber', headerName: "PartNumber", width: 200 ,filter: 'agTextColumnFilter'},
    { field: 'qta', headerName: "Qta Tot", type: 'numberColumn', width: 100, filter: 'agNumberColumnFilter' },
    { field: 'var1', headerName: "Variante 1", width: 100 ,filter: 'agTextColumnFilter' },
    { field: 'var2', headerName: "Variante 2", width: 100 ,filter: 'agTextColumnFilter'},
    { field: 'var3', headerName: "Variante 3", width: 100 ,filter: 'agTextColumnFilter'},
    { field: 'locazionesrg', headerName: "Ubicazione", width: 200,filter: 'agTextColumnFilter' },
  ];



  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
        return new Date(params.value).toISOString().replace(/T.*/, '').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  openUDCDialog(idarticolo: number = 0) {
    if(this.TipoConferma==1){
     let articolo = this.rowUDC.find((e) => e.gridIndex == idarticolo);
      console.log(articolo)
        const dialogRef = this.dialog.open(DialogIngressoUdcComponent, {
          width: '300px',
          //height: '500px',
          data: {
            QtaArticolo: articolo?.qta || '',
            gridIndex: articolo?.gridIndex || '',
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loading = 'block'
            this.documentiService.UpdUDCinDDT(result.gridIndex, result.Elimina, result.Qta).subscribe((data) => {
              this.loading = 'none'
              if (data[0].err == '0') {
                this.idInterno = +data[0].msg;
                this.getDocumento(data[0].msg);
                return true
              } else {
                this.openSnackBar(data[0].msg, 5000);
                if(this.idInterno>0)
                  this.getDocumento(this.idInterno);
                return false
              }
            });
          }
        });
      }
  }

  openArticoloDialog(idarticolo: number = 0) {
    //if (!this.Accettato) {
      let articolo = this.rowArticoli.find((e) => e.gridIndex == idarticolo);
      //if (articolo.IdAccettazione <= 0) {
        const dialogRef = this.dialog.open(DialogIngressoArticoloComponent, {
          width: '300px',
          //height: '500px',
          data: {
            DocumentoID: this.idInterno,
            Articolo_Id: articolo?.articolo_Id,
            Descrizione: articolo?.descrizione || '',
            Qta: articolo?.qta || '',
            QtaEvasa: articolo?.qtaEvasa || '',
            QtaMiss: articolo?.qtaMiss || '',
            Note: articolo?.note || '',
            Fase: articolo?.fase || '',
            MES: articolo?.mes || '',
            Colata: articolo?.colata || '',
            NumUdc: articolo?.numUdc || '',
            RigaId: +articolo?.gridIndex || 0,
            gridIndex: articolo?.gridIndex || '',
            PartNumber: articolo?.partNumber,
            IdAccettazione: 0
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.rowArticoli = [
              {
                articolo_Id: result.Articolo_Id,
                colata: result.Colata,
                descrizione: result.Descrizione,
                documentoID: result.DocumentoID,
                fase: result.Fase,
                mes: result.MES,
                note: result.Note,
                numUdc: result.NumUdc,
                partNumber: result.PartNumber,
                qta: result?.Qta || 0,
                qtaEvasa: result?.QtaEvasa || 0,
                qtaMiss: result?.qtaMiss || 0,
                rigaId: result.gridIndex,
                gridIndex: result.gridIndex,
                idAccettazione: result?.IdAccettazione || 0,
                elimina: result?.elimina || 0
              }
            ]
            this.onSubmit();
          }
        });
    //   }
    // }
  }

  openArticoloSearchDialog() {
    if (this.onSubmit()) {
      const dialogRef = this.dialog.open(DialogIngressoCercaArticoloComponent, {
        width: "80vw",
        height: window.innerHeight - 100 + "px",
        data: {
          DocumentoID: this.idInterno,
          CheckAusiliario:false,
          Articoli: [{
            Descrizione: '',
            PartNumber: '',
            Qta: ''
          }],
          clienteID: this.formingresso.get('idCliente').value
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.rowArticoli=[];
          for (var i = 0; i < result.Articoli.length; i++) {
            this.rowArticoli.push(
              {
                articolo_Id: result?.Articoli[i].articolo_Id || 0,
                colata: result?.Articoli[i].colata || '',
                descrizione: result?.Articoli[i].descrizione || '',
                documentoID: result?.Articoli[i].documentoID || 0,
                fase: result?.Articoli[i].fase || '',
                mes: result?.Articoli[i].mes || '',
                note: result?.Articoli[i].note || '',
                numUdc: result?.Articoli[i].numUdc || '',
                partNumber: result?.Articoli[i].partNumber || '',
                qta: result?.Articoli[i].qta || 0,
                qtaEvasa: result?.Articoli[i].qtaEvasa || 0,
                qtaMiss: result?.Articoli[i].qtaMiss || 0,
                rigaId: 0,
                gridIndex: result?.Articoli[i].gridIndex || '',
                idAccettazione: result?.Articoli[i].idAccettazione || 0,
                elimina : 0
              }
            )
          }
          //console.log(this.rowArticoli)
          this.onSubmit();
        }
      });
    }
  }


  onConfirm(type: number) {
    if (this.idInterno > 0) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Confermare il Documento?',
          BtnSi: 'Conferma',
          BtnNo: 'Annulla'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          this.documentiService.InsUDCDoc(this.idInterno, type).subscribe(data => {
            this.loading = 'none';
            if (!data.error) {
              this.getDocumento(this.idInterno);
              if (type == 1)
                this.openSnackBar('Articoli associati a UDC');
              else
                this.openSnackBar('Documento Confermato');
            }else{
              this.openSnackBar(data);
            }
          });
        }
      });
    } else {
      this.openSnackBar('Salvare prima il Documento');
    }

  }


  onAccetta() {
    if (this.idInterno > 0) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Accettare il documento?',
          BtnSi: 'Conferma',
          BtnNo: 'Annulla'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          this.documentiService.AccettaDocumento(this.idInterno).subscribe((data) => {
            this.loading = 'none'
            if (data[0].err == '0') {
              this.idInterno = +data[0].msg;
              this.getDocumento(data[0].msg);
              this.openSnackBar('Operazione Avvenuta con Successo!', 3000);
              return true
            } else {
              this.openSnackBar(data[0].msg, 5000);
              if(this.idInterno>0)
                this.getDocumento(this.idInterno);
              return false
            }
          });
        }
      });
    } else {
      this.openSnackBar('Salvare prima il Documento');
    }

  }

}
