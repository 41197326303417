<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Qta</mat-label>
        <input matInput [(ngModel)]="data.Qta" type="number">
    </mat-form-field>
    <br>
    <b *ngIf="isError" style="color:red; font-size: 10px;">La Quantità inserita è minore di quella ingressata, effettuare prima un riscontro.</b>
</div>
<div mat-dialog-actions>
    <button class="button-annulla2" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" (click)="onSave()" cdkFocusInitial>Salva</button>
    <button class="button-delete-row" (click)="onElimina()" cdkFocusInitial>Elimina</button>
</div>
