import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ColDef, ColGroupDef, ColumnApi } from 'ag-grid-community';
import { Giacenza } from 'src/app/Models/Giacenza';
import { Ordine } from 'src/app/Models/Ordine';
import { ArticoloService } from 'src/app/services/articolo.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogCercaOrdiniDettagliComponent } from '../dialog-cerca-ordini-dettagli/dialog-cerca-ordini-dettagli.component';

export interface DialogData{
  IDIntestatario: number
}

@Component({
  selector: 'app-dialog-cerca-ordini',
  templateUrl: './dialog-cerca-ordini.component.html',
  styleUrls: ['./dialog-cerca-ordini.component.scss']
})
export class DialogCercaOrdiniComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCercaOrdiniComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private articoliService: ArticoloService,
    private documentiService: DocumentiService,
    public dialog: MatDialog,
    private jwtHelper : JwtHelperService
  ) { }

  msg:string = '';
  articoloselezionato:string = '';

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.loadData()
  }

  rowArticoli: Ordine[] = [];
  
  public columnDefsArticoli:(ColDef | ColGroupDef)[] = [
    { field: 'cliente', headerName: "Cliente", width: 200, filter: 'agTextColumnFilter' },
    { field: 'intestatario', headerName: "Intestatario", width: 200, filter: 'agTextColumnFilter' },
    { field: 'data', headerName: "Data", width: 200, type: 'dateColumn' },
    // { field: 'RagioneSociale', headerName: "Ragione Sociale", width: 200 },
    { field: 'localita', headerName: "Località", width: 200, filter: 'agTextColumnFilter' },
    { field: 'indirizzo', headerName: "Indirizzo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'provincia', headerName: "Provincia", width: 100, filter: 'agTextColumnFilter' },
    { field: 'nazione', headerName: "Nazione", width: 200, filter: 'agTextColumnFilter' },
    { field: 'confermato', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },

  ];


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  loadData() {
    this.documentiService.GetOrdinexIntestatario(this.data.IDIntestatario,14).subscribe((data) => {
      this.rowArticoli = data
    });

  }

   

  ret : any;
  loadDataDettaglio(idordine) {
    // let articolo = this.rowArticoli.find((e) => e.gridIndex == idarticolo);
  
    // this.ret = {
    //   articolo,
    // }
    // console.log(this.ret)
    // this.articoloselezionato = 'Articolo: ' + articolo.Descrizione
    //this.dialogRef.close()
    const dialogRef = this.dialog.open(DialogCercaOrdiniDettagliComponent, {
      width: "80vw",
      data: {
        idordine
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.dialogRef.close(result)
      }
    });
   
  }

  onSave() {
    // if (this.data.Qta > 0) {
    //   if (this.data.Qta <= this.ret.articolo.Qta) {
    //     this.ret.articolo.Qta = this.data.Qta;
    //     this.ret.articolo.utente = this.jwtHelper.decodeToken(localStorage.getItem('jwt')).name;
    //     this.ret.articolo.stato = '';
    //     this.ret.articolo.rifdoc = '';
    //     this.ret.articolo.disabilitato = false;
    //     this.ret.articolo.locazionedst = '';
    //     this.ret.articolo.gridIndex = 0;

    //     if(!this.data.isUDC ){
    //       this.ret.articolo.NumUDC = '';
    //     }

    //     this.dialogRef.close(this.ret)
    //   } else {
    //     this.msg = 'La Quantità inserita è maggiore di quella disponibile ('+ this.ret.articolo.Qta +')';
    //     setTimeout(() => {
    //       this.msg = ''
    //     }, 2000);
    //   }
    // } else {
    //   this.msg = 'Inserisci una Quantità prima di continuare';
    //   setTimeout(() => {
    //     this.msg = ''
    //   }, 2000);
    // }
  }

}
