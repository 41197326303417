import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CellClickedEvent, ColDef, GridReadyEvent, ColumnApi, ColGroupDef,
   RowDoubleClickedEvent,GetContextMenuItemsParams, MenuItemDef,
   Column, ColumnPinnedType, SelectionChangedEvent, RowSelectedEvent, StatusPanelDef,
} from 'ag-grid-community';
import { GridLoadingCellComponent } from '../grid-loading-cell/grid-loading-cell.component';
import { SelectionChange } from '@angular/cdk/collections';

declare var AG_GRID_LOCALE_IT: {
  [key: string]: string;
};

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnChanges {


  constructor(private http: HttpClient) { }
  
  @Input() columnDefs: (ColDef | ColGroupDef)[];
  @Input() defaultColDef: ColDef;
  @Input() rowData: any;
  @Input() rowData$: any[];
  @Input() style: string = "height: 50vh";
  @Input() columnTypes: { [key: string]: ColDef };
  @Input() gridColumnApi!: ColumnApi;
  @Input() frameworkComponents : any = '';
  @Input() getRowStyle: any = null;
  @Input() rowSelection: any = 'multiple';
  @Input() groupSelectsFiltered: boolean = false;
  @Input() masterDetail: boolean = false;

  @Output() gridReady: EventEmitter<GridReadyEvent<any>> = new EventEmitter();
  @Output() onDblClick: EventEmitter<any> = new EventEmitter();
  @Output() rowDoubleClicked: EventEmitter<RowDoubleClickedEvent<any>> = new EventEmitter();
  @Output() onChangedCheckbox: EventEmitter<RowSelectedEvent<any>> = new EventEmitter();
  
  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
      //{ statusPanel: 'agTotalRowCountComponent', align: 'left' },
      // { statusPanel: 'agFilteredRowCountComponent' },
      // { statusPanel: 'agSelectedRowCountComponent' },
      // { statusPanel: 'agAggregationComponent' },
    ],
  };
  public loadingCellRenderer: any = GridLoadingCellComponent;
  public loadingCellRendererParams: any = {
    loadingMessage: 'Attendere prego...',
  };

  public localeText: {
    [key: string]: string;
  } = AG_GRID_LOCALE_IT;

  ngOnInit(): void {
    //this.load;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rowData$ = this.rowData;
  }

  load(params: GridReadyEvent){
    this.gridReady.emit(params);
  }


  dblClick(e: RowDoubleClickedEvent) {
    let res = e.data?.gridIndex;
    this.onDblClick.emit(res)
  }

  onRowSelected(e: RowSelectedEvent) {
    let res = e;
    this.onChangedCheckbox.emit(res)
  }

  

  // reloadData() {
  //   this.http
  //     .get<Articolo[]>("https://testapiall1.azurewebsites.net/api/articoli/get/?id=14")
  //     .subscribe((data) => {
  //       this.rowData$ = data
  //     });
  // }
  onCellClicked(e: CellClickedEvent): void {
    // console.log('cellClicked', e.data);
  }

  getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    var result: (string | MenuItemDef)[] = [
      {
        // custom item
        name: 'Alert ' + params.value,
        action: () => {
          window.alert('Alerting about ' + params.value);
        },
        cssClasses: ['redFont', 'bold'],
      },
      {
        // custom item
        name: 'Always Disabled',
        disabled: true,
        tooltip:
          'Very long tooltip, did I mention that I am very long, well I am! Long!  Very Long!',
      },
      {
        name: 'Person',
        subMenu: [
          {
            name: 'Niall',
            action: () => {
              console.log('Niall was pressed');
            },
          },
          {
            name: 'Sean',
            action: () => {
              console.log('Sean was pressed');
            },
          },
          {
            name: 'John',
            action: () => {
              console.log('John was pressed');
            },
          },
          {
            name: 'Alberto',
            action: () => {
              console.log('Alberto was pressed');
            },
          },
          {
            name: 'Tony',
            action: () => {
              console.log('Tony was pressed');
            },
          },
          {
            name: 'Andrew',
            action: () => {
              console.log('Andrew was pressed');
            },
          },
          {
            name: 'Kev',
            action: () => {
              console.log('Kev was pressed');
            },
          },
          {
            name: 'Will',
            action: () => {
              console.log('Will was pressed');
            },
          },
          {
            name: 'Armaan',
            action: () => {
              console.log('Armaan was pressed');
            },
          },
        ],
      },
      'separator',
      {
        // custom item
        name: 'Windows',
        shortcut: 'Alt + W',
        action: () => {
          console.log('Windows Item Selected');
        },
        icon:
          '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />',
      },
      {
        // custom item
        name: 'Mac',
        shortcut: 'Alt + M',
        action: () => {
          console.log('Mac Item Selected');
        },
        icon:
          '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      },
      'separator',
      {
        // custom item
        name: 'Checked',
        checked: true,
        action: () => {
          console.log('Checked Selected');
        },
        icon:
          '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      },
      'copy',
      'separator',
      'chartRange',
    ];
    return result;
  }


}
