import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Giacenza } from 'src/app/Models/Giacenza';
import { ODSDettagli } from 'src/app/Models/ODSDettagli';
import { Ordine } from 'src/app/Models/Ordine';
import { OrdineDettagli } from 'src/app/Models/OrdineDettagli';
import { ArticoloService } from 'src/app/services/articolo.service';
import { DocumentiService } from 'src/app/services/documenti.service';

export interface DialogData{
  idordine: number
}

@Component({
  selector: 'app-dialog-cerca-ordini-dettagli',
  templateUrl: './dialog-cerca-ordini-dettagli.component.html',
  styleUrls: ['./dialog-cerca-ordini-dettagli.component.scss']
})
export class DialogCercaOrdiniDettagliComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCercaOrdiniDettagliComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private articoliService: ArticoloService,
    private documentiService: DocumentiService,
    public dialog: MatDialog,
    private jwtHelper : JwtHelperService
  ) { }

  msg:string = '';
  articoloselezionato:string = '';

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.loadData()
  }

  rowArticoli: OrdineDettagli[] = [];
  
  public columnDefsArticoli:(ColDef | ColGroupDef)[];
  public columnDefsArticolinoUDC: (ColDef | ColGroupDef)[] = [
    //{ field: 'gridIndex', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'descrizione', width: 200, checkboxSelection: true, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
  ];
  public columnDefsArticoliUDC: (ColDef | ColGroupDef)[] = [
    { field: 'descrizione', width: 200, checkboxSelection: true, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'numUDC', headerName: "UDC", width: 150, filter: 'agTextColumnFilter' },
  ];


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  loadData() {
    this.documentiService.GetOrdineDettagli(this.data.idordine,0).subscribe((data) => {
      this.rowArticoli = data
      if (this.rowArticoli?.length > 0) {
        if (this.rowArticoli[0].numUDC != '') {
          // this.hasUDC = true;
          this.columnDefsArticoli = this.columnDefsArticoliUDC
        } else
        this.columnDefsArticoli = this.columnDefsArticolinoUDC
      } else
        this.columnDefsArticoli = this.columnDefsArticolinoUDC
    });

  }

  onGridReady(params: GridReadyEvent<any>){
    this.gridApi = params.api;
  }
   

  ret : any;
  loadDataDettaglio(idarticolo) {
    // let articolo = this.rowArticoli.find((e) => e.gridIndex == idarticolo);
  
    // this.ret = {
    //   articolo,
    // }
    // console.log(this.ret)
    // this.articoloselezionato = 'Articolo: ' + articolo.Descrizione
    // this.dialogRef.close()
    // const dialogRef = this.dialog.open(DialogCercaOrdiniDettagliComponent, {
    //   width: "80vw",
    //   data: {
    //     idarticolo
    //   },
    // });

    // dialogRef.afterClosed().subscribe(result => {
      
    // });
   
  }
  private gridApi!: GridApi<any>;
  onSave() {
    let selection = this.gridApi.getSelectedRows();
    let selectionODSD = [];
    for(var i = 0;i<selection.length; i++){
      selectionODSD.push({
        Descrizione : selection[i].Descrizione,
        PartNumber: selection[i].PartNumber,
        Qta: selection[i].Qta,
        Articolo_Id: selection[i].Articolo_Id,
        IDOrdineDettaglio: selection[i].IDOrdineDettaglio,
        IDODS: 0,
        IDODSDettaglio: 0,
        gridIndex: 0,
        disabilitato: false,
        NumUDC: selection[i].NumUDC
      })
    }
    this.dialogRef.close(selectionODSD)
  }

}
