import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'src/app/Models/Cliente';

@Component({
  selector: 'app-dialog-destinazioni',
  templateUrl: './dialog-destinazioni.component.html',
  styleUrls: ['./dialog-destinazioni.component.scss']
})
export class DialogDestinazioniComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDestinazioniComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Cliente,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.dialogRef.close(this.data.idCliente);
  }


  ngOnInit(): void {
  }

}
