import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { OrdineDettagli } from 'src/app/Models/OrdineDettagli';
@Component({
  selector: 'app-grid-udc-icon',
  templateUrl: './grid-udc-icon.component.html',
  styleUrls: ['./grid-udc-icon.component.scss']
})
export class GridUdcIconComponent {

  public cellValue!: any;
  constructor(public dialog: MatDialog){}
  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.data
  }
}
