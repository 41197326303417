import { Component, OnInit } from '@angular/core';
import { CellEditRequestEvent, ColDef, ColumnApi, ColumnState, FirstDataRenderedEvent, GetRowIdParams, GridApi,
   GridOptions, GridReadyEvent, IAggFuncParams, IDetailCellRendererParams, IServerSideDatasource, IServerSideGetRowsRequest, ITextFilterParams, ITooltipParams, RefreshCellsParams,
    RowModelType, RowSelectedEvent, RowStyle, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';

import {
  ICellEditorParams,
} from "@ag-grid-community/core";
import { query } from '@angular/animations';
import { tick } from '@angular/core/testing';
import { elementAt, retry } from 'rxjs';
import { ProdottofinitoService } from 'src/app/services/prodottofinito.service';
import { PianoConsegna } from '../../../Models/PianoConsegna';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GridButtonSpedizioneComponent } from '../grid-button-spedizione/grid-button-spedizione.component';
import { DataPresaPianoConsegnaCellComponent } from '../data-presa-piano-consegna-cell/data-presa-piano-consegna-cell.component';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { DialogPianoConsegneInsertContainerComponent } from '../dialog-piano-consegne-insert-container/dialog-piano-consegne-insert-container.component';
import { MatDialog } from '@angular/material/dialog';

export class ResultPianoConsegne{
  PartNumber: string;
  codiceClienteDestinatario:string;
  codiceIndirizzoSpedizioneDestinatario: string;
  dataPresa: Date;
  dataSpedizione: Date;
  UDC: {list: any[], Qta: number};
  Qta: number;
  id: number;
  status: number;
}

@Component({
  selector: 'app-piano-consegne-pf',
  templateUrl: './piano-consegne-pf.component.html',
  styleUrls: ['./piano-consegne-pf.component.scss']
})
export class PianoConsegnePfComponent implements OnInit {

  loading: string = 'none';
  public frameworkComponents;
  constructor(private _snackBar: MatSnackBar,private prodottofinitoservice: ProdottofinitoService,public dialog: MatDialog) {
    // this.getRowStyle = (params) => {
    //   if (params.data.Status == 1) {
    //     return { background: '#4caf50' };
    //   } else
    //     return false
    // };
    this.frameworkComponents = {
      addButtonGrid: GridButtonSpedizioneComponent,
    }
  }

  private gridApi!: GridApi<any>;
  public gridColumnApi!: ColumnApi;

  ngOnInit(): void {
  }

  onFirstDataRendered(event: FirstDataRenderedEvent){
    this.gridApi.getDisplayedRowAtIndex(0).setExpanded(true); //Espando il primo gruppo della lista
  }


  public rowModelType : RowModelType = 'serverSide';

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    sessionStorage.removeItem("dataIn")
    sessionStorage.removeItem("dataOut")

    this.reloadData();

    

    // let server = this.getServerData()
    // let dataSource = this.getServerSideDatasource(server);
    // this.gridOptions.serverSideDatasource = dataSource;
    // const column = this.gridColumnApi.getColumn('DataPresa');
    // column.addEventListener('filterActiveChanged', this.onfilterActiveChanged);


  }
  
  onfilterActiveChanged(event){
    console.log(event)
  }

  getServerSideDatasource(server: any): IServerSideDatasource {
    return {
      getRows: (params) => {
        console.log("[Datasource] - rows requested by grid: ", params.request);
        // get data for request from our fake server
        var response = server.getData(params.request);
          if (response.success) {
            // supply rows for requested block to grid
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          } else {
            params.fail();
          }
      },
    };
  }


 

  openSnackBar(message: string, time: number = 2000) {
    this._snackBar.open(message, 'Chiudi', {
      duration: time
    });
  }

  getServerData(dataFilter = null){
    let results = this.reloadData(dataFilter);
    return {
      getData: function (request) {
        console.log(request);
        return {
          success: true,
          rows: results,
          lastRow: request[request.length-1]
        };
      }
    };
  }


  getCurrentMonthDates(): { startOfMonth: string; endOfMonth: string } {
    const now = new Date();

    // Inizio del mese
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    
    // Fine del mese
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // Funzione per formattare la data
    function formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return {
        startOfMonth: formatDate(startOfMonth),
        endOfMonth: formatDate(endOfMonth)
    };
}


  isGridVisible:boolean = true;



  dataIn : any = "";
  dataOut: any = "";
  reloadData(data = null, dataTo = null) {
    this.loading = 'block';
    this.dataresult = []; //svuoto l'array di potenziali ordini
    
    this.isGridVisible = false;

    const { startOfMonth, endOfMonth } = this.getCurrentMonthDates();

    return this.prodottofinitoservice.GetPianoConsegne('2000-01-01', '2050-01-01').subscribe({
      next: (response: PianoConsegna[]) => {
        this.loading = 'none';
        this.isGridVisible = true;
        if (response) {

          //console.log('ok', response)
          
          let x= [];
          response.forEach((v)=>{
            x.push(this.PushChildrenPN(v))
            // let key = v.dataPresa.toString();

            // let index = x.findIndex((element)=>element.dataPresa == key);
            // // let indexSaved=-1;

            
            // if(index != -1){
              
            //   x[index].children.push(this.PushChildrenPN(v))
            // }else{
            //   x.push({
            //     dataPresa: v.dataPresa,
            //     status: 0,
            //     children: [this.PushChildrenPN(v)]
            //   })
            // }
          })
          
          // let indexRowDataSelected = x.findIndex((element) => +new Date(element.dataPresa) == +new Date(this.dataIn));
          // let rowsInDataSelected = x.filter((element) => +new Date(element.dataPresa) > +new Date(this.dataIn) && +new Date(element.dataPresa) <= +new Date(this.dataOut))

          // if(indexRowDataSelected > -1){
          //   rowsInDataSelected.forEach((element) => {
          //     x[indexRowDataSelected].children.push(...element.children);
          //   })
          
          //   let occurrencesMap = new Map();
          //   x[indexRowDataSelected].children.forEach((item, index) => {
          //     let key = `${item.codiceClienteDestinatario}_${item.descrizioneDest}_${item.codiceIndirizzoSpedizioneDestinatario}_${item.partNumber}`;

          //     if (occurrencesMap.has(key)) {
          //       item.status = -1;
          //     } else {
          //       occurrencesMap.set(key, index);
          //     }
          //   });
          // }

          // console.table(x[0].children)
          this.rowData = x;
          //return x

          
          

        } else {
          console.log('no ok',response)
          return null
        }
      },
      error: (err: HttpErrorResponse) => {
        this.loading = 'none';
        console.error(err)
        if(err.status == 500)
          this.openSnackBar(err.message);
        else
          this.openSnackBar('Si è verificato un errore');
        return null
      }
    });

  }

  PushChildrenPN(v){
    let udc= [];
    // v.dettaglioUDC.forEach((dett)=>{
    //   udc.push({
    //     udc:dett.udc,
    //     qta:dett.qta,
    //     status: dett.status,
    //     pesoNetto: dett.pesoNetto,
    //     pesoLordo: dett.pesoLordo,
    //     altezza: dett.altezza,
    //     lunghezza: dett.lunghezza,
    //     larghezza: dett.larghezza
    //   })
    // })
    let ret = {
      partNumber: v.partNumber,
      codiceClienteDestinatario:v.codiceClienteDestinatario,
      descrizioneDest:v.descrizioneDest,
      codiceIndirizzoSpedizioneDestinatario:v.codiceIndirizzoSpedizioneDestinatario,
      numOrdine:v.numOrdine,
      dataSpedizione:v.dataSpedizione,
      qtaRitardo:v.qtaRitardo,
      qtaOrdine:v.qtaOrdine,
      qtaTot:v.qtaTot,
      delta:v.delta,
      qtaDaSpedire:v.qtaDaSpedire,
      qtaDispPN:v.qtaDispPN < 0 ? 0 : v.qtaDispPN,
      futureSpedizioni:v.spedizioniFuture,
      dataPresa:v.dataPresa,
      children:udc,
      id: v.id,
      status: v.status,
      numOrdineStikstok: v.numOrdineStikstok
      }

      return ret
  }

  public rowData: any[] | null;

  public columnDefs: ColDef[] = [
    {
      field: "codiceClienteDestinatario", headerName: 'Codice Cliente', filter: "agSetColumnFilter", cellRenderer: "agGroupCellRenderer", resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      // tooltipField: "QtaDispPn",
      tooltipValueGetter: (p: ITooltipParams) =>{
        // let result = [];
        // p.data.futureSpedizioni.forEach(element => {
        //   result.push("Data Futura Spedizione: " + new Date(element.dataSpedizione).toLocaleDateString('it-IT') + " ~ Q.tà Spedizione: " + element.qta + " pz.")
        // });
        // return 'Codice Cliente/Destinatario ' + p.data.codiceClienteDestinatario + ";" + result.join(" ; ");
        return p.data.codiceClienteDestinatario;
      }
        
        //"Data Futura Spedizione: " + p.data.DataFuturaSpedizione + " ~ Q.tà Disp.: " + p.data.QtaDispPN + " pz.",
    },
    { field: "descrizioneDest", headerName: 'Descr.Cliente', filter: "agSetColumnFilter", resizable:true, 
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.descrizioneDest
      }
    },
    { field: "codiceIndirizzoSpedizioneDestinatario", headerName: 'Ind.Sped.', filter: "agSetColumnFilter", resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.codiceIndirizzoSpedizioneDestinatario
      }
    },
    {
      field: "partNumber", headerName: 'P/N', resizable:true,
      filter: "agSetColumnFilter",
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.partNumber
      }
    },
    // { field: "PartNumber",headerName:'PartNumber'},
    { field: "numOrdine", headerName: 'C.O.', filter: "agSetColumnFilter", resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.numOrdine
      }
    },
    { field: "numOrdineStikstok", headerName: 'Ord. StikStok', filter: "agSetColumnFilter", resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.numOrdineStikstok
      }
    },
    { field: "dataSpedizione", headerName: 'Data Spedizione', type: 'dateColumn', resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return new Date(p.data.dataSpedizione).toLocaleDateString('it-IT')
      }
    },
    { field: "qtaRitardo", headerName: 'Q.tà Ritardo', filter: 'agNumberColumnFilter', resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.qtaRitardo
      }
    },
    { field: "qtaOrdine", headerName: 'Q.tà Ordine', filter: 'agNumberColumnFilter', resizable:true, 
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.qtaOrdine
      }
    },
    { field: "qtaTot", headerName: 'Q.tà Tot.', filter: 'agNumberColumnFilter', resizable:true, 
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.qtaTot
      }
    },
    { field: "qtaDispPN", headerName: 'Q.tà Disp. Part Number', filter: 'agNumberColumnFilter', resizable:true, 
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.qtaDispPN
      }
    },
    { field: "delta", filter: 'agNumberColumnFilter' , resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.delta
      }
    },
    {
      field: "qtaDaSpedire", headerName: 'Q.tà da Spedire', filter:'agNumberColumnFilter', editable: (params)=>{
        if (params.data.status == 0)
          return true
        else
          return false
      }, resizable:true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.qtaDaSpedire
      },
      valueGetter: (params: ValueGetterParams) => {
        if(params.data.status == 0){
          if (!isNaN(params.data.qtaDaSpedire)) {
            //if(params.data.qtaDaSpedire == 0 && !this.manualEdit){

            let index = this.dataresult.findIndex((x) => x.PartNumber == params.data.partNumber && x.codiceClienteDestinatario == params.data.codiceClienteDestinatario && x.codiceIndirizzoSpedizioneDestinatario == params.data.codiceIndirizzoSpedizioneDestinatario && x.id == +params.data.id && x.dataPresa == params.data.dataPresa && x.dataSpedizione == params.node.data.dataSpedizione) 
            
            if (index == -1) {
              this.dataresult.push({
                PartNumber: params.data.partNumber,
                codiceClienteDestinatario: params.data.codiceClienteDestinatario,
                codiceIndirizzoSpedizioneDestinatario: params.data.codiceIndirizzoSpedizioneDestinatario,
                dataPresa: params.data.dataPresa,
                dataSpedizione: params.data.dataSpedizione,
                UDC: {
                  list: [],
                  Qta: 0
                },
                Qta: +params.data.qtaDaSpedire,
                id: params.data.id,
                status: params.data.status,
              })
            } else {
              //console.log(this.dataresult[index].Qta) // qta complessiva
              //console.log(diff)// quantità aggiunta a mano
              //console.log(this.dataresult[index])
              let qtaUDC = this.dataresult[index].UDC.Qta
              let qtaManuale;
              if (this.manualEdit) {
                qtaManuale = +params.data.qtaDaSpedire - this.dataresult[index].UDC.Qta;
                this.dataresult[index].Qta = qtaManuale;
              }
              else {
                qtaManuale = 0;
              }
              // if (this.dataresult[index].UDC.list.length > 0) {
              //   //evidenzia la riga data presa
              //   params.context.masterGrid.node.data.status = 1;
              // } else {
              //   params.context.masterGrid.node.data.status = 0;
              // }
              // params.context.masterGrid.node.updateData(params.context.masterGrid.node.data);

              
              if(this.udcChanged)
                this.dataresult[index].Qta = 0;
              else
                qtaManuale = this.dataresult[index].Qta

              this.udcChanged = false;
              let somma = qtaManuale + qtaUDC;

              return somma >= 0 ? somma : 0;

              /*
                prendere la quantità scritta a mano
                prendere la quantità dagli udc
                fare la somma come risultato
              */

            }
          
            //console.log(this.dataresult);
            return params.data.qtaDaSpedire;
          // }else
          //   return params.data.qtaDaSpedire;

          }
          else {
            return 0
          }
        }else{
          return params.data.qtaDaSpedire;
        }
        //}
      },

      valueSetter: (params: ValueSetterParams) => {
        this.manualEdit = true;
        params.data.qtaDaSpedire = params.newValue;
        return true
      }
    }
    // { field: "QtaDispPN",headerName:'Q.tà Disp.'},

  ];

 

  onCellValueChange(primaData: any, secondaData: any): void {
    primaData = primaData.split("/");
    this.dataIn = primaData[2] + "-" + primaData[1] + "-" + primaData[0] + "T00:00:00";
    this.dataOut = secondaData+ "T00:00:00";

    sessionStorage.setItem("dataIn", this.dataIn);
    sessionStorage.setItem("dataOut", this.dataOut);

    //this.gridApi.refreshCells({ force: true, columns: ['dataPresa'] });
    this.reloadData(this.filteredData, secondaData);
  }

  ApriSpedizione(x){
    // let dataClick =x.rowData.dataPresa;
    // let pnFind= this.dataresult.filter((a)=> a.dataPresa == dataClick)
    //this.creaOrdine(this.dataresult)

  }
  
  public defaultColDef: ColDef = {
    flex: 1,
        enableCellChangeFlash: true,
        filter: true,
        sortable: true,
        // enable floating filters by default
        floatingFilter: true,
    
  };

  filteredData = null;

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
    DataPresatype: {
      // cellRenderer: (params) => {
      //   console.log(params)
      //   return '<b>'+ params.value + '</b>';
      // },
      //cellRenderer: (params) => {return "agGroupCellRenderer" + 'asdasda'},
      // valueFormatter: (params) => {
      //   return 'PRESA DEL: ' + new Date(params.value).toLocaleDateString('it-IT')
      // },
      //cellStyle: { fontWeight: 'bold' },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }

  public gridOptions: GridOptions= {
    getRowStyle: params => {
      if (params.data.status == 1)
        return { background: '#EDB100' };
      else if (params.data.status == 2)
        return { background: '#80B73A' };
      else if (params.data.status == -1)
        return { background: '#cecece' };
      else
        return { background: 'transparent' };
    },
    postSortRows: (params: any) => {
        var defaultSortModel: ColumnState[] = [
          { colId: "dataSpedizione", sort: "asc", sortIndex: 1 },
          { colId: "codiceClienteDestinatario", sort: "asc", sortIndex: 2 },
          { colId: "codiceIndirizzoSpedizioneDestinatario", sort: "asc", sortIndex: 3 },
          { colId: "partNumber", sort: "asc", sortIndex: 4 },
        ];
        params.columnApi.applyColumnState({ state: defaultSortModel });
    },
    detailRowAutoHeight: true,
    tooltipMouseTrack: true,
    tooltipShowDelay: 1,
    enableCellChangeFlash: true,
    //masterDetail: true,
    columnTypes: this.columnTypes,
    // context: {
    //   masterGrid: {
    //     node: masterGridParams.node.parent,
    //     data: masterGridParams.data,
    //     GridApi: masterGridParams.api
    //   }
    // },
    onCellValueChanged: (event) =>{
      if(this.manualEdit)
        this.manualEdit = false;
    },
    defaultColDef: {
      flex: 1,
      enableCellChangeFlash: true,
      filter: true,
      sortable: true,
      // enable floating filters by default
      floatingFilter: true,
    },
    groupDefaultExpanded: 0,
    multiSortKey: "ctrl",
    detailRowHeight: 240,
    
  }
  public sum = 0;
  public qtyEdited = 0;

  // myAggFunc(params: any) {
  //   console.log(params)
  //   return params.values[0];

  // }

  


  public dataresult: ResultPianoConsegne[] = [];

  creaOrdine() {
    //let pnWQta = e.filter((x) => x.UDC.Qta > 0); //NON CONTROLLA PIU LA QUANTITA PER UDC MA QUELLA DELLA RIGA, ABILITANDO LA CREAZIONE D'ORDINE PER OGNI PN CON UNA QUANTITA DA SPEDIRE
    let pnWQta = this.dataresult.filter((x) => (x.Qta > 0 || x.UDC.Qta > 0) && x.status == 0);
    
    if (pnWQta.length > 0) {
      let sameCliente = pnWQta.every((x) => x.codiceClienteDestinatario == pnWQta[0].codiceClienteDestinatario)
      if(!sameCliente){
        this.openSnackBar('Codice Cliente Destinatario non corrisponde per i PN selezionati', 3500);
        return
      }
      let req: any[] = [];
      for (var i = 0; i < pnWQta.length; i++) {
        req.push({
          dataPresa: pnWQta[i].dataPresa,
          partNumber: pnWQta[i].PartNumber,
          codiceClienteDestinatario: pnWQta[i].codiceClienteDestinatario,
          descrizioneDest: '',
          codiceIndirizzoSpedizioneDestinatario: pnWQta[i].codiceIndirizzoSpedizioneDestinatario,
          numOrdine: '',
          dataSpedizione: pnWQta[i].dataSpedizione,
          qtaRitardo: 0,
          qtaOrdine: 0,
          qtaTot: 0,
          qtaDaSpedire: (pnWQta[i].Qta + pnWQta[i].UDC.Qta),
          qtaDispPN: 0,
          delta: 0,
          spedizioniFuture: [],
          dettaglioUDC: [],
          id: pnWQta[i].id
        });

        for(var j =0;j<pnWQta[i].UDC.list.length;j++)
          req[req.length-1].dettaglioUDC.push({
            udc: pnWQta[i].UDC.list[j],
            qta: pnWQta[i].UDC.Qta,
            status: 0
          })

      }
      let utente = JSON.parse(localStorage.getItem("r"))
      req[0].utente = utente.idUtente
      //console.log(req)
      //this.dataresult = [];
      this.isGridVisible = false;
      this.prodottofinitoservice.InsertOrdineFromPianoConsegne(req).subscribe({
        next: (response: any) => {
          if (response) {
            console.log('ok', response)
            this.openSnackBar('Ordine inserito'); 
            this.dataresult = [];
            this.reloadData(this.filteredData)
          } else {
            console.log('no ok', response)
            this.reloadData(this.filteredData)
          }
        },
        error: (err: HttpErrorResponse) => {
          console.error(err.error)
          this.openSnackBar(err.error);
          this.reloadData(this.filteredData)
        }
      })
    }else{
      this.openSnackBar('Con i Part Number selezionati non è possibile creare un nuovo Ordine');
    }
  }

  public rowSelected;
  public nodeSelected;


  public manualEdit = false;
  public udcChanged = false;

  public groupDefaultExpanded = 0;
  public detailCellRendererParams: any = (masterGridParams) => ({
    // level 2 grid options
      refreshStrategy: 'nothing',
      detailGridOptions: {
        detailRowAutoHeight : true,
        enableCellChangeFlash: true,
        context: {
          masterGrid: {
            node: masterGridParams.node.parent,
            data: masterGridParams.data
          }
        },
        rowSelection: 'multiple',
        onSelectionChanged: event => {
          if (event.context.masterGrid.node.data.status == 0) {
            let qtyTotRows = 0;
            let index = -1;
            if (this.dataresult) {
              index = this.dataresult.findIndex((x) => x.PartNumber == event.context.masterGrid.node.data.partNumber && x.codiceClienteDestinatario == event.context.masterGrid.node.data.codiceClienteDestinatario && x.codiceIndirizzoSpedizioneDestinatario == event.context.masterGrid.node.data.codiceIndirizzoSpedizioneDestinatario && x.id == +event.context.masterGrid.node.data.id && x.dataPresa == event.context.masterGrid.node.data.dataPresa && x.dataSpedizione == event.context.masterGrid.node.data.dataSpedizione); 
            }
            //console.log(this.dataresult[index])
            let listudc = [];
            event.api.getSelectedNodes().forEach(element => {
              qtyTotRows += element.data.qta;
              listudc.push(element.data.udc);
            });
            if (index != -1) {
              
              if (this.dataresult[index].UDC.list.length !== listudc.length) {
                this.udcChanged = true;
              } else {
                for (let i = 0; i < this.dataresult[index].UDC.list.length; i++) {
                  if (this.dataresult[index].UDC.list[i] !== listudc[i]) {
                    this.udcChanged = true;
                  }
                }
              }
              this.dataresult[index].UDC.list = listudc;
              this.dataresult[index].UDC.Qta = qtyTotRows;
            } else {
              this.dataresult.push({
                PartNumber: event.context.masterGrid.node.data.partNumber,
                codiceClienteDestinatario: event.context.masterGrid.node.data.codiceClienteDestinatario,
                codiceIndirizzoSpedizioneDestinatario: event.context.masterGrid.node.data.codiceIndirizzoSpedizioneDestinatario,
                dataPresa: event.context.masterGrid.node.data.dataPresa,
                dataSpedizione: event.context.masterGrid.node.data.dataSpedizione,
                UDC: {
                  list: listudc,
                  Qta: qtyTotRows
                },
                Qta: 0,
                id: +event.context.masterGrid.node.data.id,
                status: event.context.masterGrid.node.data.status,
              })
            }

            
            event.context.masterGrid.node.data.qtaDaSpedire = qtyTotRows;
            event.context.masterGrid.node.updateData(event.context.masterGrid.node.data);
          }
        },
        // getRowStyle: params => {
        //   if (params.data.status == 1)
        //     return { background: '#EDB100' };
        //   else if (params.data.status == 2)
        //     return { background: '#80B73A' };
        //   else
        //     return false;
        // },
        columnTypes: this.columnTypes,
        columnDefs: [
          { field: "udc",resizable:true , checkboxSelection: (params) => {
            if(params.context.masterGrid.data.status != -1)
              return true
            else 
              return false
          }, cellRenderer: (params) => {
            if (params.context.masterGrid.data.status == 0) {
              let findPN = this.dataresult.find((x) => x.PartNumber == params.context.masterGrid.data.partNumber && x.codiceClienteDestinatario == params.context.masterGrid.data.codiceClienteDestinatario && x.codiceIndirizzoSpedizioneDestinatario == params.context.masterGrid.data.codiceIndirizzoSpedizioneDestinatario && x.id == +params.context.masterGrid.data.id && x.dataPresa == params.context.masterGrid.data.dataPresa && x.dataSpedizione == params.context.masterGrid.data.dataSpedizione );
              let list = findPN.UDC.list
              if (list.length > 0) {
                let UDCSelectedIndex = list.findIndex((a) => a == params.node.data.udc)
                if (UDCSelectedIndex > -1)
                  params.node.setSelected(true);

              }
              return params.node.data.udc;
            }
          }, filter: 'agTextColumnFilter' },
          { field: "qta", headerName: 'Q.tà', filter:'agNumberColumnFilter',resizable:true },
          { field: "pesoNetto", headerName: 'Peso Netto', filter:'agNumberColumnFilter' ,resizable:true},
          { field: "pesoLordo", headerName: 'Peso Lordo', filter:'agNumberColumnFilter' ,resizable:true},
          { field: "altezza", headerName: 'Altezza', filter:'agNumberColumnFilter' ,resizable:true},
          { field: "larghezza", headerName: 'Larghezza', filter:'agNumberColumnFilter' ,resizable:true},
          { field: "lunghezza", headerName: 'Lunghezza', filter:'agNumberColumnFilter' ,resizable:true},
        ],
        multiSortKey: "ctrl",
        defaultColDef: {
          enableCellChangeFlash: true,
          flex: 1,
          filter: true,
          sortable: true,
          // enable floating filters by default
          floatingFilter: true,
        },
      },
      getDetailRowData: (params) => {
        this.loading = 'block';
        this.isGridVisible = false;
        this.prodottofinitoservice.GetPianoConsegnaDetailUDC(params.data.status, params.data.partNumber, params.data.numOrdine).subscribe({
          next: (response: any[]) => {
            this.loading = 'none';
            this.isGridVisible = true;
            if (response) {
              params.data.children = response
              params.successCallback(params.data.children);
            } else {
              console.log('no ok',response)
              return null
            }
          },
          error: (err: HttpErrorResponse) => {
            this.loading = 'none';
            console.error(err)
            if(err.status == 500)
              this.openSnackBar(err.message);
            else
              this.openSnackBar('Si è verificato un errore');
            return null
          }
        })

      },
    }) as unknown as IDetailCellRendererParams
    
    openNewContainer(){
      const dialogRef = this.dialog.open(DialogPianoConsegneInsertContainerComponent, {
        width: '50vw',
        maxWidth: '50vw',
        data: {
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log(result)
          
        }
      });
    }
}
