import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BodyComponent } from '../app/components/body/body.component';
import { SidenavComponent } from '../app/components/sidenav/sidenav.component';
import { DashboardComponent } from '../app/components/dashboard/dashboard.component';
import { ProductsComponent } from '../app/components/products/products.component';
import { StatisticsComponent } from '../app/components/statistics/statistics.component';
import { SettingsComponent } from '../app/components/settings/settings.component';

import { TabItemComponent } from './components/tab-item/tab-item.component';

import { MaterialModule } from '../material.module';

import { JwtModule } from "@auth0/angular-jwt";

import { AgGridModule } from 'ag-grid-angular';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { TabComponent } from './components/tab/tab.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductEditDirectiveDirective } from './directives/product-edit-directive.directive';
import { GridComponent } from './components/grid/grid.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ProductVarianteComponent } from './components/product-variante/product-variante.component';
import { ProductDialogVarianteComponent } from './components/product-dialog-variante/product-dialog-variante.component';
import { ProductDialogDescrizioneComponent } from './components/product-dialog-descrizione/product-dialog-descrizione.component';
import { ClientiComponent } from './components/clienti/clienti/clienti.component';
import { ClienteComponent } from './components/clienti/cliente/cliente.component';
import { MagazziniComponent } from './components/magazzini/magazzini/magazzini.component';
import { MagazzinoComponent } from './components/magazzini/magazzino/magazzino.component';
import { DialogNewMagazzinoComponent } from './components/magazzini/dialog-new-magazzino/dialog-new-magazzino.component';
import { DialogNewRigaMagazzinoComponent } from './components/magazzini/dialog-new-riga-magazzino/dialog-new-riga-magazzino.component';
import { IngressoComponent } from './components/documenti/ingresso/ingresso.component';
import { IngressoEditComponent } from './components/documenti/ingresso-edit/ingresso-edit.component';
import { DialogIngressoArticoloComponent } from './components/documenti/dialog-ingresso-articolo/dialog-ingresso-articolo.component';
import { DialogIngressoCercaArticoloComponent } from './components/documenti/dialog-ingresso-cerca-articolo/dialog-ingresso-cerca-articolo.component';
import 'ag-grid-enterprise';
import { UdcComponent } from './components/documenti/udc/udc.component';
import { UdcEditComponent } from './components/documenti/udc-edit/udc-edit.component';
import { UdcAbbinamentoComponent } from './components/documenti/udc-abbinamento/udc-abbinamento.component';
import { UdcAbbinamentoEditComponent } from './components/documenti/udc-abbinamento-edit/udc-abbinamento-edit.component';
import { DepositoComponent } from './components/documenti/deposito/deposito.component';
import { DepositoEditComponent } from './components/documenti/deposito-edit/deposito-edit.component';
import { SidenavAiComponent } from './components/sidenav-ai/sidenav-ai.component';
import { DialogVariantiComponent } from './components/clienti/dialog-varianti/dialog-varianti.component';
import { GruppoComponent } from './components/gruppi/gruppo/gruppo.component';
import { DialogGruppoComponent } from './components/gruppi/dialog-gruppo/dialog-gruppo.component';
import { FamigliaComponent } from './components/gruppi/famiglia/famiglia.component';
import { DialogFamigliaComponent } from './components/gruppi/dialog-famiglia/dialog-famiglia.component';
import { LoginComponent } from './components/login/login.component';
import { MissioniComponent } from './components/documenti/missioni/missioni.component';
import { MissioneComponent } from './components/documenti/missione/missione.component';
import { UscitaComponent } from './components/documenti/uscita/uscita.component';
import { UscitaEditComponent } from './components/documenti/uscita-edit/uscita-edit.component';
import { DialogcercaspedizioniComponent } from './components/documenti/dialogcercaspedizioni/dialogcercaspedizioni.component';
import { AuthGuard } from './guards/auth.guard';
import { ProductDialogArticoloComponent } from './components/product-dialog-articolo/product-dialog-articolo.component';
import { ProductDialogEditArticoloComponent } from './components/product-dialog-edit-articolo/product-dialog-edit-articolo.component';
import { GruppoUtentiComponent } from './components/accesso/gruppo-utenti/gruppo-utenti.component';
import { GruppoUtentiEditComponent } from './components/accesso/gruppo-utenti-edit/gruppo-utenti-edit.component';
import { DialogAssociaGruppoutenteMenuComponent } from './components/accesso/dialog-associa-gruppoutente-menu/dialog-associa-gruppoutente-menu.component';
import { BtnRinominaRepartoComponent } from './components/accesso/btn-rinomina-reparto/btn-rinomina-reparto.component';
import { DialogRinominaRepartoComponent } from './components/accesso/dialog-rinomina-reparto/dialog-rinomina-reparto.component';
import { UtentiComponent } from './components/utenti/utenti/utenti.component';
import { UtenteComponent } from './components/utenti/utente/utente.component';
import { SitiComponent } from './components/siti/siti/siti.component';
import { DialogSitoComponent } from './components/siti/dialog-sito/dialog-sito.component';
import { DialogUscitaArticoloComponent } from './components/documenti/dialog-uscita-articolo/dialog-uscita-articolo.component';
import { DialogUscitaCercaArticoloComponent } from './components/documenti/dialog-uscita-cerca-articolo/dialog-uscita-cerca-articolo.component';
import { OrdineComponent } from './components/documenti/ordine/ordine.component';
import { OrdineEditComponent } from './components/documenti/ordine-edit/ordine-edit.component';
import { DialogOrdineCercaArticoloComponent } from './components/documenti/dialog-ordine-cerca-articolo/dialog-ordine-cerca-articolo.component';
import { DialogDestinazioniComponent } from './components/clienti/cliente/dialog-destinazioni/dialog-destinazioni.component';
import { DialogFornitoriComponent } from './components/clienti/cliente/dialog-fornitori/dialog-fornitori.component';
import { GridApi } from 'ag-grid-enterprise';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { OdsComponent } from './components/documenti/ods/ods.component';
import { OdsEditComponent } from './components/documenti/ods-edit/ods-edit.component';
import { DialogCercaOrdiniComponent } from './components/documenti/dialog-cerca-ordini/dialog-cerca-ordini.component';
import { DialogCercaOrdiniDettagliComponent } from './components/documenti/dialog-cerca-ordini-dettagli/dialog-cerca-ordini-dettagli.component';
import { GridWarnIconComponent } from './components/grid-warn-icon/grid-warn-icon.component';
import { DialogMultisceltaComponent } from './components/dialog-multiscelta/dialog-multiscelta.component';
import { GridAddButtonComponent } from './components/grid-add-button/grid-add-button.component';
import { GridUdcIconComponent } from './components/grid-udc-icon/grid-udc-icon.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { WorkflowDialogComponent } from './components/workflow-dialog/workflow-dialog.component';
import { GiacenzaUdcComponent } from './components/giacenza-udc/giacenza-udc.component';
import { GiacenzaUdcEditComponent } from './components/giacenza-udc-edit/giacenza-udc-edit.component';
import { CreaudcComponent } from './components/creaudc/creaudc.component';
import { DialogDocumentoComponent } from './components/dialog-documento/dialog-documento.component';
import { DialogStampaUdcComponent } from './components/dialog-stampa-udc/dialog-stampa-udc.component';
import { StoricoUdcComponent } from './components/storico-udc/storico-udc.component';
import { PnComponent } from './components/pn/pn.component';
import { PnEditComponent } from './components/pn-edit/pn-edit.component';
import { AuthServiceService } from './services/auth-service.service';
import { User } from './Models/user.model';
import { DialogSpostaUDCComponent } from './components/dialog-sposta-udc/dialog-sposta-udc.component';
import { DialogEditVarianteComponent } from './components/dialog-edit-variante/dialog-edit-variante.component';
import { DialogRettificaUdcComponent } from './components/dialog-rettifica-udc/dialog-rettifica-udc.component';
import { AssemblyComponent } from './components/assembly/assembly.component';
import { AssemblyEditComponent } from './components/assembly-edit/assembly-edit.component';
import { GruppoOrdiniComponent } from './components/gruppo-ordini/gruppo-ordini.component';
import { GridDelButtonComponent } from './components/grid-del-button/grid-del-button.component';
import { GruppoOrdiniEditComponent } from './components/gruppo-ordini-edit/gruppo-ordini-edit.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { InventarioEditComponent } from './components/inventario-edit/inventario-edit.component';
import { GridLoadingCellComponent } from './components/grid-loading-cell/grid-loading-cell.component';
import { GridUrgenzaIconComponent } from './components/grid-urgenza-icon/grid-urgenza-icon.component';
import { DialogIngressoUdcComponent } from './components/dialog-ingresso-udc/dialog-ingresso-udc.component';
import { DialogOrdiniDdtemessiComponent } from './components/documenti/dialog-ordini-ddtemessi/dialog-ordini-ddtemessi.component';
import { DialogSelezioneArticoloInventarioComponent } from './components/dialog-selezione-articolo-inventario/dialog-selezione-articolo-inventario.component';
import { MagazziniTableComponent } from './components/magazzini/magazzini-table/magazzini-table.component';
import { DialogUtenteSitoComponent } from './components/dialog-utente-sito/dialog-utente-sito.component';
import { GridLavaggioIconComponent } from './components/grid-lavaggio-icon/grid-lavaggio-icon.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PianoConsegneComponent } from './components/prodottofinito/piano-consegne/piano-consegne.component';
import { GridButtonSpedizioneComponent } from './components/prodottofinito/grid-button-spedizione/grid-button-spedizione.component';
import { DataPresaPianoConsegnaCellComponent } from './components/prodottofinito/data-presa-piano-consegna-cell/data-presa-piano-consegna-cell.component';
import { PrenotazionePresaComponent } from './components/prodottofinito/prenotazione-presa/prenotazione-presa.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { PianoConsegnePfComponent } from './components/prodottofinito/piano-consegne-pf/piano-consegne-pf.component';
import { DialogPianoConsegneInsertContainerComponent } from './components/prodottofinito/dialog-piano-consegne-insert-container/dialog-piano-consegne-insert-container.component';

// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// import { ModuleRegistry } from '@ag-grid-community/core';
// import { GridChartsModule } from '@ag-grid-enterprise/charts';
// import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
// import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
// import { MenuModule } from '@ag-grid-enterprise/menu';
// import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';

export function tokenGetter() { 
  return localStorage.getItem("jwt"); 
}

@NgModule({
  declarations: [
    AppComponent,
    BodyComponent,
    SidenavComponent,
    DashboardComponent,
    ProductsComponent,
    StatisticsComponent,
    SettingsComponent,
    TabItemComponent,
    ProductEditComponent,
    TabComponent,
    ProductEditDirectiveDirective,
    GridComponent,
    SpinnerComponent,
    SnackbarComponent,
    ProductVarianteComponent,
    ProductDialogVarianteComponent,
    ProductDialogDescrizioneComponent,
    ClientiComponent,
    ClienteComponent,
    MagazziniComponent,
    MagazzinoComponent,
    DialogNewMagazzinoComponent,
    DialogNewRigaMagazzinoComponent,
    IngressoComponent,
    IngressoEditComponent,
    DialogIngressoArticoloComponent,
    DialogIngressoCercaArticoloComponent,
    UdcComponent,
    UdcEditComponent,
    UdcAbbinamentoComponent,
    UdcAbbinamentoEditComponent,
    DepositoComponent,
    DepositoEditComponent,
    SidenavAiComponent,
    DialogVariantiComponent,
    GruppoComponent,
    DialogGruppoComponent,
    FamigliaComponent,
    DialogFamigliaComponent,
    LoginComponent,
    MissioniComponent,
    MissioneComponent,
    UscitaComponent,
    UscitaEditComponent,
    DialogcercaspedizioniComponent,
    ProductDialogArticoloComponent,
    ProductDialogEditArticoloComponent,
    GruppoUtentiComponent,
    GruppoUtentiEditComponent,
    DialogAssociaGruppoutenteMenuComponent,
    BtnRinominaRepartoComponent,
    DialogRinominaRepartoComponent,
    UtentiComponent,
    UtenteComponent,
    SitiComponent,
    DialogSitoComponent,
    DialogUscitaArticoloComponent,
    DialogUscitaCercaArticoloComponent,
    OrdineComponent,
    OrdineEditComponent,
    DialogOrdineCercaArticoloComponent,
    DialogDestinazioniComponent,
    DialogFornitoriComponent,
    DialogConfirmComponent,
    OdsComponent,
    OdsEditComponent,
    DialogCercaOrdiniComponent,
    DialogCercaOrdiniDettagliComponent,
    GridWarnIconComponent,
    DialogMultisceltaComponent,
    GridAddButtonComponent,
    GridUdcIconComponent,
    WorkflowComponent,
    WorkflowDialogComponent,
    GiacenzaUdcComponent,
    GiacenzaUdcEditComponent,
    CreaudcComponent,
    DialogDocumentoComponent,
    DialogStampaUdcComponent,
    StoricoUdcComponent,
    PnComponent,
    PnEditComponent,
    DialogSpostaUDCComponent,
    DialogEditVarianteComponent,
    DialogRettificaUdcComponent,
    AssemblyComponent,
    AssemblyEditComponent,
    GruppoOrdiniComponent,
    GridDelButtonComponent,
    GruppoOrdiniEditComponent,
    InventarioComponent,
    InventarioEditComponent,
    GridLoadingCellComponent,
    GridUrgenzaIconComponent,
    DialogIngressoUdcComponent,
    DialogOrdiniDdtemessiComponent,
    DialogSelezioneArticoloInventarioComponent,
    MagazziniTableComponent,
    DialogUtenteSitoComponent,
    GridLavaggioIconComponent,
    ResetPasswordComponent,
    PianoConsegneComponent,
    GridButtonSpedizioneComponent,
    DataPresaPianoConsegnaCellComponent,
    PrenotazionePresaComponent,
    PianoConsegnePfComponent,
    DialogPianoConsegneInsertContainerComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AgGridModule,
    MaterialModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:5001"],
        disallowedRoutes: []
      }
    })
  ],
  providers: [AuthGuard,GridApi,AuthServiceService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ProductEditComponent],
})
export class AppModule { }
