import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogData{
  messaggio: string;
  Btn1: string;
  Btn2: string;
}

@Component({
  selector: 'app-dialog-multiscelta',
  templateUrl: './dialog-multiscelta.component.html',
  styleUrls: ['./dialog-multiscelta.component.scss']
})
export class DialogMultisceltaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogMultisceltaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }



  ngOnInit(): void {
  }

}
