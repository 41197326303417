import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Livello } from 'src/app/Models/Livello';
import { RepartoMenu } from 'src/app/Models/RepartoMenu';
import { MenuService } from 'src/app/services/menu.service';


@Component({
  selector: 'app-dialog-associa-gruppoutente-menu',
  templateUrl: './dialog-associa-gruppoutente-menu.component.html',
  styleUrls: ['./dialog-associa-gruppoutente-menu.component.scss']
})
export class DialogAssociaGruppoutenteMenuComponent implements OnInit {

  livelli : Livello[]=[
    {
      idLivello: 1,
      nome: "Admin"
    },
    {
      idLivello: 2,
      nome: "User"
    },
    {
      idLivello: 3,
      nome: "Cliente"
    }
  ]

  constructor(
    public dialogRef: MatDialogRef<DialogAssociaGruppoutenteMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RepartoMenu,
    private menuService: MenuService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
descrizione:string='';
  ngOnInit(): void {
    this.menuService.GetDescrizioneVoceMenu(this.data.gridIndex).subscribe((res)=>{
      this.descrizione=res;
    })
  }

}
