import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Movimento } from 'src/app/Models/Movimento';
import { DocumentiService } from 'src/app/services/documenti.service';
import { Tab } from '../ingresso/ingresso.component';


@Component({
  selector: 'app-deposito-edit',
  templateUrl: './deposito-edit.component.html',
  styleUrls: ['./deposito-edit.component.scss']
})
export class DepositoEditComponent implements OnInit {
  form: FormGroup = new FormGroup({
    barcodelocazione: new FormControl(''),
    udc: new FormControl(''),
  })
  @Input() id: number = 0;

  @Input() tab: Tab = { id: 0, nome: "", numero: '' };

  public loading: string = 'none';
  constructor(private _snackBar: MatSnackBar,
  private documentiService: DocumentiService,) { }
  selected = new FormControl(0);

  idInterno: number;

  ngOnInit(): void {
    this.idInterno=this.id
  }

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  onSubmit() {
      if (!this.form.valid) {
        this.openSnackBar('Compila i campi obbligatori prima di continuare');
      } else {
        this.loading = 'block';
        let req: Movimento = this.form.value;
        req.utente= '';
        req.segno= '1';
        req.idMissione=this.idInterno;
        //req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
        this.documentiService.InsMovimentazione(req).subscribe((data) => {
          this.loading = 'none';
          switch (data[0].err) {
            case '1':
              this.openSnackBar(data[0].msg);
              break;
            case '0':
              this.openSnackBar('Salvataggio avvenuto con successo');
              break;
          }
        });
      }
    }

}
