import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { Liste } from 'src/app/Models/Liste';
import { TipoDocumento } from 'src/app/Models/TipoDocumento';
import { ArticoloService } from 'src/app/services/articolo.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogIngressoArticoloComponent } from '../dialog-ingresso-articolo/dialog-ingresso-articolo.component';
import { DialogIngressoCercaArticoloComponent } from '../dialog-ingresso-cerca-articolo/dialog-ingresso-cerca-articolo.component';
import { Tab } from '../ingresso/ingresso.component';
import { Prelievo } from 'src/app/Models/Prelievo';
import { PrelievoDettagli } from 'src/app/Models/PrelievoDettagli';
import { DialogUscitaArticoloComponent } from '../dialog-uscita-articolo/dialog-uscita-articolo.component';
import { DialogUscitaCercaArticoloComponent } from '../dialog-uscita-cerca-articolo/dialog-uscita-cerca-articolo.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OrdineDettagli } from 'src/app/Models/OrdineDettagli';
import { Ordine } from 'src/app/Models/Ordine';
import { DialogOrdineCercaArticoloComponent } from '../dialog-ordine-cerca-articolo/dialog-ordine-cerca-articolo.component';
import { DestinazioniClienti } from 'src/app/Models/DestinazioniClienti';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { ODSDettagli } from 'src/app/Models/ODSDettagli';
import { ODS } from 'src/app/Models/ODS';
import { DialogCercaOrdiniComponent } from '../dialog-cerca-ordini/dialog-cerca-ordini.component';



@Component({
  selector: 'app-ods-edit',
  templateUrl: './ods-edit.component.html',
  styleUrls: ['./ods-edit.component.scss']
})
export class OdsEditComponent implements OnInit {

  formingresso: FormGroup = new FormGroup({
    Note : new FormControl(''),
    IdCliente: new FormControl(0),
    IDIntestatario: new FormControl(0),
    IdSito: new FormControl(0),
    //Codice_Dest: new FormControl(0),
    //Evaso: new FormControl(false),
    Indirizzo: new FormControl(''),
    Localita: new FormControl(''),
    Provincia: new FormControl(''),
    Nazione: new FormControl(''),
    CAP: new FormControl(''),
    Targa : new FormControl('')
  });
  @Input() id: number = 0;

  @Input() tab: Tab = { id: 0, nome: "", numero: "" };

  public loading: string = 'none';
  selected = new FormControl(0);
  clienti : Cliente[] = [];
  idInterno: number;

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  
  constructor(private _snackBar: MatSnackBar, 
    private documentiService: DocumentiService,
    private clientiService: CustomerService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private jwtHelper: JwtHelperService,
    private gridApi: GridApi) { 
      


    }

  hasUDC: boolean = false;
  hasList : boolean = false;

  RagioneSociale = '';
  note = '';
  intestatario = '';
  indirizzo= '';
  cap= '';
  localita= '';
  nazione= '';
  targa= '';
  provincia= '';
  getOrdine(id: number) {
    this.documentiService.GetODS(id, 14).subscribe((res) => {
      this.formingresso = new FormGroup({

        //Data: new FormControl(new Date(res[0]?.Data).toLocaleDateString('af').replace(new RegExp('/', "g"), '-') || '', Validators.required),
        Note: new FormControl(res[0]?.note || ''),
        IdCliente: new FormControl(res[0]?.idCliente || 0),
        IDIntestatario: new FormControl(res[0]?.iDIntestatario || 0),
        //Codice_Dest: new FormControl(res[0]?.Codice_Dest || 0),
        //Evaso: new FormControl(res[0]?.Evaso || false),
        Indirizzo: new FormControl(res[0]?.indirizzo || ''),
        Localita: new FormControl(res[0]?.localita || ''),
        Provincia: new FormControl(res[0]?.provincia || ''),
        Nazione: new FormControl(res[0]?.nazione || ''),
        CAP: new FormControl(res[0]?.cap || ''),
         Targa: new FormControl(res[0]?.targa || ''),
      });

      this.RagioneSociale = res[0].cliente;
      this.note = res[0].note;
      this.intestatario = res[0].intestatario;
      this.indirizzo = res[0].indirizzo;
      this.cap = res[0].cap;
      this.localita = res[0].localita;
      this.nazione = res[0].nazione;
      this.targa = res[0].targa;
      this.provincia = res[0].provincia;

      //this.clienti = res[0].enti;
      this.rowArticoli = res[0].dettagliODS;
      this.listarticoli = res[0].dettagliODS;
      if (this.listarticoli?.length > 0) {
        this.hasList = true;
        if (this.listarticoli[0].numUDC != '') {
          // this.hasUDC = true;
          this.columnDefsArticoli = this.columnDefsArticoliUDC
        } else
        this.columnDefsArticoli = this.columnDefsArticolinoUDC
      } else
        this.columnDefsArticoli = this.columnDefsArticolinoUDC

        //this.formingresso.get('IDIntestatario').disable()
        //this.formingresso.get('IDIntestatario').disable()
        //this.formingresso.get('Codice_Dest').disable()

        // if(res[0].Confermato){
        //   this.btnadd = false;
        //   this.btnsalva = false;
        // }
        this.onChangeCliente(res[0].idCliente)
        this.onChangeIntestatario(res[0].iDIntestatario)
    }
    );
  }

  destinazioni : DestinazioniClienti[]= [];
  enti;
  btnadd = false;
  btnsalva= true;

  clienteselezionato : any;
  intestatarioselezionato :any;

  onChangeCliente(id:number){
    if(id != 0){
      this.clienteselezionato = id;
      this.intestatarioselezionato=0;
      this.formingresso.get('IDIntestatario').enable()
      this.clientiService.GetEntexCliente(id).subscribe((data)=>{
        this.enti= data;
      })
    }
  }

  onChangeIntestatario(id:number){
    if (id != 0) {
      this.intestatarioselezionato = id;
      this.clientiService.GetEnte(id).subscribe((data) => {
        this.formingresso.get('Indirizzo').setValue(data[0].Indirizzo_Legale)
        this.formingresso.get('Localita').setValue(data[0].CittaL)
        this.formingresso.get('Provincia').setValue(data[0].ProvL)
        this.formingresso.get('CAP').setValue(data[0].CAPL)
        this.formingresso.get('Nazione').setValue('Italia');

        //this.formingresso.get('Codice_Dest').enable()
        // if(this.btnsalva)
        //   this.btnadd = true

        // this.clientiService.GetDestinazioniClienti(id).subscribe((data) => {
        //   this.destinazioni = data;
        // })
        if(this.clienteselezionato != 0 && this.intestatarioselezionato != 0 && !this.hasList ){
          //let articolo = this.rowArticoli.find((val) => val.gridIndex == +e);
          const dialogRef = this.dialog.open(DialogCercaOrdiniComponent, {
            width: "80vw",
            data: {
              IDIntestatario: this.formingresso.get('IDIntestatario').value
            },
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if(result){
              this.listarticoli = result;
              this.onSubmit()
            }
          });
        }
      })
    }

  }

  onChangeDestinazione(id:number){
    
    if(id!=0 && this.clienteselezionato != 0 && this.intestatarioselezionato != 0 ){
      //let articolo = this.rowArticoli.find((val) => val.gridIndex == +e);
      const dialogRef = this.dialog.open(DialogCercaOrdiniComponent, {
        width: "80vw",
        data: {
          IDIntestatario: this.formingresso.get('IDIntestatario').value
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.listarticoli = result;
          this.onSubmit()
        }
      });
    }
  }

  RefreshMissioni(params : GridReadyEvent){
    this.gridColumnApi = params.columnApi;
    this.rowArticoli= this.rowArticoli;
  }

  onSubmit() {
    if (!this.formingresso.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
      return false;
    } else {
      this.loading = 'block';
      let req: ODS = this.formingresso.value;
      req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
      req.idOrdine= this.idInterno == -1 ? 0 : this.idInterno;
      req.dettagliODS = this.listarticoli;
      req.evaso = 0
      
      //console.log(req);
      this.documentiService.InsODS(req).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err == '0') {
          this.idInterno = +data[0].msg;
          this.getOrdine(data[0].msg);
          this.openSnackBar('Salvataggio avvenuto con successo');
          return true
        } else {
          this.openSnackBar(data[0].msg);
          return false
        }
      });
      
      return true
    }
  }

  public listarticoli: ODSDettagli[] = [];
  rowArticoli: ODSDettagli[] = [];

  public columnDefsArticoli:(ColDef | ColGroupDef)[];
  public columnDefsArticolinoUDC: (ColDef | ColGroupDef)[] = [
    //{ field: 'gridIndex', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
  ];
  public columnDefsArticoliUDC: (ColDef | ColGroupDef)[] = [
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'numUDC', headerName: "UDC", width: 150, filter: 'agTextColumnFilter' },
  ];


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
    }

  ngOnInit(): void {
    this.getOrdine(this.id)
    this.idInterno = this.id
  }

  openDialog(e: RowDoubleClickedEvent){
    if(this.btnsalva){
    let articolo = this.rowArticoli.find((val) => val.gridIndex == +e);
    const dialogRef = this.dialog.open(DialogUscitaArticoloComponent, {
      data: {
        articolo,
        note: articolo.note
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.articolo.utente = this.jwtHelper.decodeToken(localStorage.getItem('jwt')).name;
        result.articolo.note = result.note;
        this.listarticoli = [result.articolo];
        this.onSubmit()
      }
    });
  }
  }

  openDialogSearchArticolo(){
    let IdCliente = this.formingresso.get('IdCliente').value;

    let hasUDC = false;
    let isUDC = false;

    if (this.listarticoli == null) {
       hasUDC= true;
       
    }else
      if(this.listarticoli[0].numUDC != ''){
        isUDC = true;
    }


    const dialogRef = this.dialog.open(DialogOrdineCercaArticoloComponent, {
      width: "80vw",
      data: {
        IdCliente,
        hasUDC,
        isUDC
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.listarticoli = [result.articolo];
        this.onSubmit()
      }
    });
  }

  onClickConferma(){
    if (!this.formingresso.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
    } else {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'Una volta confermato non sarà possibile modificare l\'ordine',
        BtnSi: 'Conferma',
        BtnNo: 'Annulla'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //alert()
        //this.onSubmit()
      }
    });
  }
  }

}
