import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean;

  display = "none";

  constructor() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.isLoading)
      this.display = 'block';
    else
      this.display = 'none';
  }


  ngOnInit(): void {
  }

}
