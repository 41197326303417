<div mat-dialog-content>
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <div style="display:flex;flex-direction:row; width: 100%;">
            <div style="width: 80%;padding: 4px;">
                <h1>Seleziona Articolo</h1>
                <app-grid style="height: 350px" [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef" [rowData]="rowArticoli"
                    [columnTypes]="columnTypes" (onDblClick)="loadDataDettaglio($event)" (onChangedCheckbox)="onChange($event)" [rowSelection]="'multiple'" (gridReady)="GridReady($event)">
                </app-grid>

            </div>
            <div style="width: 20%;padding: 4px;">
                <h1>Inserisci Articolo</h1>
                <table>
                    <tr>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Descrizione</mat-label>
                                <input matInput [(ngModel)]="data.Articoli[0].descrizione" [disabled]="true">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Part Number</mat-label>
                                <input matInput [(ngModel)]="data.Articoli[0].partNumber" [disabled]="true">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Qta</mat-label>
                                <input matInput [(ngModel)]="data.Articoli[0].qta" type="number" [cdkTrapFocusAutoCapture]="show" [cdkTrapFocus]="show" [disabled]="multipleSelection">
                            </mat-form-field>
                        </td>
                    </tr>
                       <!-- <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Qta Evasa</mat-label>
                                <input matInput [(ngModel)]="data.QtaEvasa" type="number">
                            </mat-form-field>
                        </td>
                     <tr>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Qta Miss.</mat-label>
                                <input matInput [(ngModel)]="data.QtaMiss" type="number">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Fase</mat-label>
                                <input matInput [(ngModel)]="data.Fase">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>MES</mat-label>
                                <input matInput [(ngModel)]="data.MES">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Colata</mat-label>
                                <input matInput [(ngModel)]="data.Colata">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>Note</mat-label>
                                <input matInput [(ngModel)]="data.Note">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="fill">
                                <mat-label>NrUdc</mat-label>
                                <input matInput [(ngModel)]="data.NumUdc" type="number">
                            </mat-form-field>
                        </td>
                    </tr> -->
                </table>
            </div>
    </div>
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
</div>