import { HttpClient } from '@angular/common/http';
import { Component, OnInit, DoCheck, ViewChild, Input, EventEmitter, ContentChild, TemplateRef, ViewChildren, QueryList, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellClickedEvent, ColDef, GridReadyEvent, ColumnApi, ColGroupDef, RowDoubleClickedEvent,
} from 'ag-grid-community';

import { FormControl } from '@angular/forms';
import { Customer } from 'src/app/Models/Customer';
import { ArticoloService } from 'src/app/services/articolo.service';
import { Articolo } from 'src/app/Models/Articolo';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ProductEditDirectiveDirective } from 'src/app/directives/product-edit-directive.directive';
import { ProductEditComponent } from '../product-edit/product-edit.component';
import { Liste } from 'src/app/Models/Liste';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GridLavaggioIconComponent } from '../grid-lavaggio-icon/grid-lavaggio-icon.component';

declare var window: any;

export class Tab {
  id: number | null = 0;
  nome: string = "";
  partnumber: string = ""
}
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, DoCheck {
  //@ViewChild(ProductEditDirectiveDirective, { static: true }) dynamicChild!: ProductEditDirectiveDirective;
  //@ViewChild(ProductEditDirectiveDirective) dynamicChild!: ProductEditDirectiveDirective;
  @ViewChild('parent', { read: ViewContainerRef }) target!: ViewContainerRef;

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];

  @Input() label: string = "Lista";
  public data: Articolo = {
    idSito: 0,
    articolo_Id: 0,
    descrizione: '',
    partNumber: '',
    tipo: 0,
    idCategoria: 0,
    id_Valuta: 0,
    id_Misura: 0,
    note: '',
    pl: 0,
    pn: 0,
    larghezza: 0,
    altezza: 0,
    profondita: 0,
    volume: 0,
    pzConfezione: 0,
    scortamin: 0,
    scortamax: 0,
    idCategoriaABC: 0,
    idCriterio: 0,
    lottoRiordino: 0,
    colata: false,
    mes: false,
    fase: false,
    ausiliario: false,
    categoria: '',
    valuta: '',
    um: '',
    categoriaABC: '',
    criterio: '',
    variante: [],
    altredescrizioni: [],
    log: [],
    idCliente: 0,
    utente: '',
    gridIndex: 0,
    cliente: '',
    enti: [],
    gruppi: [],
    giacenze: [],
    kit: 0,
    lavaggio: false,
    prodottoFinito: false,
    bloccoQualita: false
  };
  prodottoID: number = 0;

  loading: string = 'none';



  ngOnInit(): void {

  }

  ngDoCheck(): void {
  }


  selected = new FormControl(0);

  tabs: Tab[] = [];

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo Prodotto',
      partnumber: ''
      
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.prodottoID = -1;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    if (!this.tabs.some((x) => x.id == +e)) {
      let obj = this.rowData$.find((value) => value.gridIndex == +e);
      let row = {
        id: obj.Articolo_Id,
        nome: obj.Descrizione,
        partnumber: obj.PartNumber
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = obj.Articolo_Id || 0;
      // this.data.Descrizione = obj.Descrizione;
      this.prodottoID = obj.Articolo_Id;
// console.log(e)
//       let row = {
//           id: e.data?.Articolo_Id,
//           nome: e.data?.Descrizione,
//           partnumber: e.data?.PartNumber
//         }
//         this.tabs.push(row);
//         // this.data.Articolo_Id = e.data?.Articolo_Id || 0;
//         // this.data.Descrizione = e.data?.Descrizione;
//         this.prodottoID = e.data?.Articolo_Id;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == +e) + 1);

  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }

  refreshGrid() {
    this.reloadData()
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'Articolo_Id', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'Cliente', width: 320, filter: 'agTextColumnFilter' },
    { field: 'Descrizione', width: 320, filter: 'agTextColumnFilter' },
    { field: 'PartNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'Kit', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },
    { field: 'Lavaggio', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    }, filter: true, cellRenderer: GridLavaggioIconComponent },
    { field: 'CategoriaABC', headerName: "Categoria ABC", width: 200, filter: 'agTextColumnFilter' },
    { field: 'Criterio', width: 200, filter: 'agTextColumnFilter' },
    { field: 'Valuta', width: 200, filter: 'agTextColumnFilter' },
    { field: 'UM', headerName: "UM", width: 200, filter: 'agTextColumnFilter' },
    {
      headerName: 'Dimensioni',
      groupId: 'dimensionGroup',
      children: [
        // using medal column type
        { headerName: 'Altezza', field: 'Altezza', type: 'dimensionColumn', filter: 'agNumberColumnFilter' },
        { headerName: 'Larghezza', field: 'Larghezza', type: 'dimensionColumn', filter: 'agNumberColumnFilter' },
        { headerName: 'Profondità', field: 'Profondita', type: 'dimensionColumn', filter: 'agNumberColumnFilter' },
        {
          headerName: '',
          valueGetter:
            function sumField(params) {
              return params.data.Altezza + " x " + params.data.Larghezza + " x " + params.data.Profondita
            },
          width: 150,
          type: 'dimensionColumn',
          columnGroupShow: 'closed',
        },
      ],
    },
  ];


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public defaultColGroupDef: Partial<ColGroupDef> = {
    marryChildren: true,
  };

  // Data that gets displayed in the grid
  public rowData$!: any[];

  private gridColumnApi!: ColumnApi;



  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(private http: HttpClient, private articoloService: ArticoloService, private componentFactoryResolver: ComponentFactoryResolver) { 
    // console.log(environment.apiUrl)
  }


  // // Example load data from sever
  // onGridReady(params: GridReadyEvent) {
  //   this.rowData$ = this.http
  //     .get<any[]>('https://www.ag-grid.com/example-assets/row-data.json');
  // }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    // console.log('cellClicked', e.data);
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }


  saveState() {
    const sortModel = this.gridColumnApi.getColumnState();
    console.log(sortModel);
  }

  restoreState() {
    if (!window.colState) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }
    this.gridColumnApi.applyColumnState({
      state: window.colState,
      applyOrder: true,
    });
    console.log('column state restored');
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.reloadData()

  }

  reloadData() {
    this.loading = 'block';
    // this.http
    //   .get<Articolo[]>("https://testapiall1.azurewebsites.net/api/articoli/get/?id=14")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });

    this.articoloService.ElencoArticoli().subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }



}
