import { Component, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { Menu } from 'src/app/Models/Menu';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {

  menuList: Observable<Menu[]> | undefined;
  preferitiList: Observable<Menu[]> | undefined;
  menuService: MenuService;


  ngOnInit(): void {
    this.initializeMenu();
  }

  // menuItem: Menu[] = [];

  constructor(menuService: MenuService) {
    this.menuService = menuService;
  }

  initializeMenu() {
    this.menuList = this.menuService!.getMenu();
    this.preferitiList = this.menuService!.getPreferiti();
  }


  togglePreferiti(menu: Menu) {
    menu.favorite = !(menu.favorite);
    this.menuService!.togglePreferiti(menu).subscribe(r => this.checkMenuTogglePreferiti(r));
  }

  checkMenuTogglePreferiti(r) {
    if (r == 'ok')
      this.initializeMenu()

  }

}
