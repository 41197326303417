<div mat-dialog-content align="center">
    <b>Sposta UDC</b><br>
    <!-- <mat-form-field class="full-width">
        <mat-label>Locazione</mat-label>
        <mat-select name="locazionesrg" [(ngModel)]="data.locazionesrg">
            <mat-option *ngFor="let locazione of locazioni" [value]="locazione.Nome">
                {{locazione.Nome}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
    <ag-grid-angular style="height: 50vh" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="locazioni" [animateRows]="true"
                (gridReady)="onGridReady($event)" [columnTypes]="columnTypes" (rowDoubleClicked)="onSposta($event)">
            </ag-grid-angular>
            <!-- <app-grid [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowData]="locazioni" (gridReady)="onGridReady($event)" (onDblClick)="onSposta($event)" style='height: 50vh'
            >
        </app-grid> -->
 </div>
 <div mat-dialog-actions align="center">
     <button class="button-annulla" (click)="onNoClick()">Annulla</button>
 </div>