<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>
            <button class="button-tab-delete" type="button" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button> -->

        </mat-card-actions>
        <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" (selectedTabChange)="gridload($event)">
            <mat-tab label="Generali">
                <table class="full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Nome</mat-label>
                                <input matInput formControlName="nome">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Cognome</mat-label>
                                <input matInput formControlName="cognome">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>User</mat-label>
                                <input matInput formControlName="altriFlagUtente"/>
                            </mat-form-field>
                        </td>
                        <!-- <td>
                            <mat-form-field class="full-width">
                                <mat-label>Password</mat-label>
                                <input matInput formControlName="psw" type="password"/>
                            </mat-form-field>
                        </td> -->
                    </tr>
                    <tr>
                        <td>
                        <mat-form-field class="full-width">
                            <mat-label>Gruppo</mat-label>
                            <mat-select name="IdGruppo" formControlName="idGruppo">
                                <mat-option *ngFor="let gruppo of gruppi" [value]="gruppo.idGruppo">
                                    {{gruppo.nome}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Azienda</mat-label>
                            <mat-select name="IdAzienda" formControlName="idAzienda">
                                <mat-option *ngFor="let azienda of aziende" [value]="azienda.idCliente">
                                    {{azienda.ragione_Sociale}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    </tr>
                </table>

            </mat-tab>
            <mat-tab label="Siti">
                <app-grid (onDblClick)="toggleSito($event)" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                [rowData]="rowDataSiti" (gridReady)="onGridReady($event)"></app-grid>
            </mat-tab>
            <mat-tab label="Clienti">
                <app-grid (onDblClick)="toggleCliente($event)" [columnDefs]="columnDefsClienti" [defaultColDef]="defaultColDef"
                [rowData]="rowDataClienti" (gridReady)="onGridReady($event)"></app-grid>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</form>