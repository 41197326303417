import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-giacenza-udc-edit',
  templateUrl: './giacenza-udc-edit.component.html',
  styleUrls: ['./giacenza-udc-edit.component.scss']
})
export class GiacenzaUdcEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
