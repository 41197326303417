import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-button-spedizione',
  templateUrl: './grid-button-spedizione.component.html',
  styleUrls: ['./grid-button-spedizione.component.scss']
})
export class GridButtonSpedizioneComponent {

  params;

  agInit(params): void {
    this.params = params;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
  getValueToDisplay(params: ICellRendererParams) {
    return params.data
  }
}
