<div mat-dialog-content>
    <!-- <mat-form-field appearance="fill">
        <mat-label>Descrizione</mat-label>
        <input matInput [(ngModel)]="data.Descrizione">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Part Number</mat-label>
        <input matInput [(ngModel)]="data.PartNumber">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Qta</mat-label>
        <input matInput [(ngModel)]="data.Qta" type="number">
    </mat-form-field> -->
     <mat-form-field appearance="fill">
        <mat-label>Qta Riscontrata</mat-label>
        <input matInput [(ngModel)]="data.qtaMiss" type="number">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Note</mat-label>
        <input matInput [(ngModel)]="data.note">
    </mat-form-field>
    <!--<mat-form-field appearance="fill">
        <mat-label>Qta Miss.</mat-label>
        <input matInput [(ngModel)]="data.QtaMiss" type="number">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Fase</mat-label>
        <input matInput [(ngModel)]="data.Fase">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>MES</mat-label>
        <input matInput [(ngModel)]="data.MES">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Colata</mat-label>
        <input matInput [(ngModel)]="data.Colata">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>NrUdc</mat-label>
        <input matInput [(ngModel)]="data.NumUdc" type="number">
    </mat-form-field> -->
    <br>
    <b *ngIf="isError" style="color:red; font-size: 10px;">Inserire Nota e Quantità prima di continuare</b>
</div>
<div mat-dialog-actions>
    <button class="button-annulla2" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" (click)="onSave()" cdkFocusInitial>Salva</button>
    <button class="button-delete-row" (click)="onElimina()" cdkFocusInitial>Elimina</button>
</div>