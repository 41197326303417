<form [formGroup]="forminventario" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start" *ngIf="StatoInventarioID < 2">
            <button class="button-tab-save" type="submit" title="Salva">
                            <mat-icon style="font-size:20px">save</mat-icon>
                        </button>
                        <button class="button-tab-delete" type="button" title="Elimina">
                            <mat-icon style="font-size:20px">delete</mat-icon>
                        </button> 
            
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>-->

        </mat-card-actions>
        <span *ngIf="idInterno > 0">ID Inventario: <b>{{idInterno}}</b></span>
        <table class="full-width" cellspacing="0">
            <tr>
                <td>
                    <mat-form-field class="full-width">
                        <mat-label>Cliente</mat-label>
                        <mat-select [disabled]="disablefields" name="IdCliente" formControlName="idCliente">
                            <mat-option *ngFor="let cliente of clienti" [value]="cliente.idCliente">
                                {{cliente.ragione_Sociale}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Tipologia Inventario</mat-label>
                            <mat-select [disabled]="disablefields" formControlName="tipoInvetarioID">
                                <mat-option [value]="1">
                                    Fiscale
                                </mat-option>
                                <mat-option [value]="2">
                                    Rotativo
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Inventario</mat-label>
                            <!-- [disabled]="elencoArticoli" -->
                            <mat-select formControlName="opzione" [disabled]="disablefields">
                                <mat-option [value]="1">
                                    Totale
                                </mat-option>
                                <mat-option [value]="2">
                                    Per Part Number
                                </mat-option>
                                <mat-option [value]="3">
                                    Per Ubicazione
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Squadre</mat-label>
                                <mat-select formControlName="numSquadre" [disabled]="disablefields">
                                    <mat-option [value]="1">
                                        1 Squadra
                                    </mat-option>
                                    <mat-option [value]="2">
                                        2 Squadre
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width" >
                                <mat-label>DAL</mat-label>
                                <input matInput type="date" [disabled]="disablefields" name="datacreazione" formControlName="dal">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Al</mat-label>
                                <input matInput type="date"  [disabled]="disablefields" name="datacreazione" formControlName="al">
                            </mat-form-field>
                        </td>
                    </tr>
            </table>
            <!-- <div style="display: flex;flex-direction: row;justify-content: space-between;"> -->
                
                <button class="button-confirm-row" type="button" *ngIf="StatoInventarioID == 1 && !Avviato" (click)="onAvvio()">Avvia Inventario</button>
                <button class="button-confirm-row" type="button" *ngIf="StatoInventarioID == 2 && !rimuoviTermina" (click)="onTermina()">Termina Inventario</button>
            <!-- </div> -->
            <mat-tab-group backgroundColor="primary" (selectedTabChange)="gridload($event)">
                <mat-tab label="Informazioni">
                    <button class="button-add-row" type="button" *ngIf="(idInterno != -1 && StatoInventarioID < 2)" (click)="openDialogArticoli()">Aggiungi
                        {{opzionelabel}}</button>
                        <button class="button-delete-row" type="button" *ngIf="idInterno != -1 && !isTotale " (click)="deleteRighe()">Elimina
                            {{opzionelabel}}</button>
                        <app-grid  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData" [columnTypes]="columnTypes"
                        (gridReady)="onGridReady($event)">
                    </app-grid>
                </mat-tab>
                <mat-tab label="Dati Inventario" *ngIf="Avviato || StatoInventarioID == 3 || isTotale">
                    <button type="button" class="button-tab-undo" (click)="ElencoInventarioSlave()" title="Ricarica">
                        <mat-icon style="font-size:20px">refresh</mat-icon>
                    </button>
                    <app-grid (gridReady)="onGridReadyDati($event)" [getRowStyle]="getRowStyle" [columnDefs]="columnDefsAvviamento" [defaultColDef]="defaultColDef" [rowData]="rowAvviamento" [columnTypes]="columnTypes">
                    </app-grid>
                </mat-tab>
                <mat-tab label="Movimenti" *ngIf="StatoInventarioID >= 1">
                    <app-grid (gridReady)="onGridReadySlave($event)" [getRowStyle]="getRowStyle" [columnDefs]="columnDefsMov" [defaultColDef]="defaultColDef" [rowData]="rowMov" [columnTypes]="columnTypes">
                    </app-grid>
                </mat-tab>
            </mat-tab-group>
        

    </mat-card>
</form>