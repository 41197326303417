<div class="spinner" [style]="'display:' + loading + ';z-index:20;position: absolute; top:50%; left:50% '">
    <mat-spinner></mat-spinner>
</div>
<div mat-dialog-content>
    <ng-container *ngIf="data.tipo == 2; else secondoComponente">
        <div style="display:flex;flex-direction:row; width: 100%;">
            <h3>Seleziona Articoli</h3>
        </div>
        <app-grid [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef" [rowData]="rowArticoli"
            [columnTypes]="columnTypes" (gridReady)="GridReady($event)">
        </app-grid>
    </ng-container>
    <ng-template #secondoComponente>
        <app-magazzini-table [pageMagazzini]="false" [selectedLocation]="selectedLocation" ></app-magazzini-table>
    </ng-template>

</div>
<div mat-dialog-actions>
    <button class="button-annulla2" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" (click)="onSave()" cdkFocusInitial>Aggiungi</button>
</div>