<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
</div>
<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)"
    (selectedTabChange)="changeTab($event)">
    <mat-tab [label]=label>
        
        <div class="card">
            <mat-card-actions align="start">
                <mat-form-field>
                                            
                    <mat-label>Q.tà Nuovi UDC</mat-label>
                    <input matInput name="qta" min="0" [(ngModel)]="qta">
                </mat-form-field>
                <button class="button-add-row" (click)="newUdc()" title="Aggiungi">
                        Crea
                    </button>
                <button class="button-add-row" (click)="newUdcPrint()" title="Aggiungi">
                        Crea e Stampa
                    </button>
                <button class="button-print-icon" type="button" (click)="onPrint()" title="Stampa">
                    <mat-icon style="font-size:20px">print</mat-icon></button>
                <button class="button-tab-undo" (click)="refreshGrid()" title="Ricarica">
                    <mat-icon style="font-size:20px">refresh</mat-icon>
                </button>
                <button class="button-tab-delete" (click)="eliminaUdc()" title="Elimina">
                    <mat-icon style="font-size:20px">delete</mat-icon>
                </button>
            </mat-card-actions>
            <ag-grid-angular style="width: 100%; height: 80vh" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData$" [rowSelection]="'multiple'" [animateRows]="true"
                (gridReady)="onGridReady($event)" [columnTypes]="columnTypes">
            </ag-grid-angular>
            <!-- <app-grid [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowData]="rowData$" (gridReady)="onGridReady($event)" style='width: 100%; height: 80vh'
            >
        </app-grid> -->
        </div>
    </mat-tab>
    
</mat-tab-group>