import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent } from 'ag-grid-community';
import { DocumentiService } from 'src/app/services/documenti.service';

@Component({
  selector: 'app-gruppo-ordini-edit',
  templateUrl: './gruppo-ordini-edit.component.html',
  styleUrls: ['./gruppo-ordini-edit.component.scss']
})
export class GruppoOrdiniEditComponent implements OnInit {

  @Input() id: number[] = [];
  loading: string = 'none';
  public rowData: any[];

  constructor(private documentiService: DocumentiService, private _snackBar: MatSnackBar,public dialog: MatDialog) { }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'descrizione', headerName: "Descrizione", width: 300, filter: 'agTextColumnFilter', 
    },
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'numeroOrdine', headerName: "Numero Ordine", width: 200, filter: 'agNumberColumnFilter' },
    { field: 'numUDC', headerName: "UDC", width: 200, filter: 'agTextColumnFilter' },
    { field: 'udcalternativo', headerName: "UDC Alternativo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Q.tà", width: 200, filter: 'agNumberColumnFilter' },
  ];


  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.reloadData()

  }

  reloadData() {
    this.loading = 'block';
    this.documentiService.GetOrdineDettagliProntoxCarico(this.id[0], this.id[1]).subscribe((data) => {
      this.rowData = data
      this.loading = 'none';
    });

  }

  ngOnInit(): void {
  }

}
