import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, ExcelStyle, GetContextMenuItemsParams, GridApi, GridReadyEvent, MenuItemDef, RowDoubleClickedEvent, StatusPanelDef } from 'ag-grid-community';
import { UDC } from 'src/app/Models/UDC';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogMultisceltaComponent } from '../../dialog-multiscelta/dialog-multiscelta.component';
import { DialogSpostaUDCComponent } from '../../dialog-sposta-udc/dialog-sposta-udc.component';
import { GruppoService } from 'src/app/services/gruppo.service';
import { Route, Router } from '@angular/router';
import { trigger } from '@angular/animations';


export class Tab {
  id: number | null = 0;
  nome: string = "";
}

@Component({
  selector: 'app-udc',
  templateUrl: './udc.component.html',
  styleUrls: ['./udc.component.scss']
})
export class UdcComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: UDC[];
  label = "Lista"
  tabs: Tab[] = [];
  idUDC: number = 0;
  ndoc: string='';

  livelloID:number=-1;

  idtipo:number=1;
  onChangeTipo(e : number){
    this.idtipo=e;
    this.reloadData(this.idtipo)
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.reloadData(this.idtipo)

  }

  refreshGrid() {
    this.reloadData(this.idtipo)
  }
  reloadData(id : number = 1) {
    this.loading = 'block';
    // this.http
    //   .get<Ingresso[]>("https://testapiall1.azurewebsites.net/api/documenti/geti/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
    this.documentiService.GetUDC(id, 1, 14).subscribe((data) => {
      this.rowData$ = data;
      this.loading = 'none';
    });

  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo UDC'
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.idUDC = 0;
    this.ndoc='-1';
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    //if(this.livelloID == 1){
    if (!this.tabs.some((x) => x.id == e.data.idUDC)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.idUDC,
        nome: e.data.nomeUDC
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.idUDC = e.data.idUDC;
      this.ndoc= e.data.numDoc;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.idUDC) + 1);
  //}
  }

  private gridApi!: GridApi<any>;
  onPrint(){
    let selection = this.gridApi.getSelectedRows();
    if(selection.length > 0){

      const dialogRef = this.dialog.open(DialogMultisceltaComponent, {
        data: {
          messaggio: 'Selezionare Tipo di Stampa',
          Btn1: 'UDC',
          Btn2: 'UDC Alternativo',
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        selection = selection.map((x) => x['idUDC']);
        this.documentiService.StampaMultiUDC(selection.join(), result).subscribe((res) => { //1 udc, 2 udc alt
          if (res[0].err == '0') {
            this.printPDF(res[0].msg);
          } else
            this.openSnackBar(res[0].msg);
        })
      })

      
    }else{
      this.openSnackBar('Seleziona almeno un UDC');
    }

  }

  printPDF(pdf : any){
    var fileblob = this.b64toBlob(pdf, 'application/pdf');
    var url = window.URL.createObjectURL(fileblob); 

    window.open(url);

  }

  b64toBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  onSpostaUDC() {
    
    let selection = this.gridApi.getSelectedRows();
if(selection.length > 0){
    if (selection.every((e) => e.Idcliente == selection[0].Idcliente)) {

      let selection = this.gridApi.getSelectedRows();
      let iddoc = selection[0].idUDC
      selection = selection.map((ele)=> ele.id_Giacenza)
      //console.log(selection.join())
      const dialogRef = this.dialog.open(DialogSpostaUDCComponent, {
        data: {
          UDCs: selection.join(),
          iddoc,
          idlocazione: 0,
        },
        width:'500px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.documentiService.SpostaUDC(selection.join(), result.idlocazione).subscribe((data)=>{
            if(data[0].err == '0'){
              this.refreshGrid()
            }else{
              this.openSnackBar(data[0].msg)
            }
          })
        }
      })
    }else{
      this.openSnackBar('Il cliente delle righe selezionate non corrisponde')
    }
  }else
    this.openSnackBar('Selezionare almeno una riga')
  }

  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
      //{ statusPanel: 'agTotalRowCountComponent', align: 'left' },
      // { statusPanel: 'agFilteredRowCountComponent' },
      // { statusPanel: 'agSelectedRowCountComponent' },
        // { statusPanel: 'agAggregationComponent' },
      ],
    };

  dataOggiExcel() {
    const oggi = new Date();

    const giorno = oggi.getDate().toString().padStart(2, '0');
    const mese = (oggi.getMonth() + 1).toString().padStart(2, '0');  // Mese è zero-based, quindi aggiungo 1
    const anno = oggi.getFullYear().toString().slice(-2);


    return `${giorno}_${mese}_${anno}`;
  }

  export() {
    let par = {
      columnKeys: ['nomeUDC', 'data', 'numDoc', 'partNumber', 'descrizione', 'locazionesrg', 'var1','var2','var3', 'qta'],
      sheetName: this.dataOggiExcel()
    }
    this.gridApi.exportDataAsExcel(par);
  //console.log(this.gridApi.getFilterModel());
  //   {
  //     "RagioneSociale": {
  //         "filterType": "text",
  //         "type": "contains",
  //         "filter": "skf"
  //     },
  //     "locazionesrg": {
  //         "filterType": "text",
  //         "type": "contains",
  //         "filter": "3805"
  //     }
  // }
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'idUDC', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'ragioneSociale', headerName: "Ragione Sociale", width: 200, checkboxSelection: true, filter: 'agTextColumnFilter' },
    { field: 'nomeUDC', headerName: "UDC", width: 200, filter: 'agTextColumnFilter' },
    { field: 'udcalternativo', headerName: "UDC Alternativo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'numDoc', headerName: "NumDoc", width: 150, filter: 'agTextColumnFilter' },
    { field: 'data', headerName: "DataDoc",type : 'datetimeColumn', width: 200, cellClass: 'dateIT' },
    { field: 'locazionesrg', headerName: "Ubicazione", width: 150, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta',headerName: "Qta TOT", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'qtaDisponibile',headerName: "Qta Disponibile", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'qtaImpegnata',headerName: "Qta Impegnata", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'var1', width: 150, filter: 'agTextColumnFilter' },
    { field: 'var2', width: 150, filter: 'agTextColumnFilter' },
    { field: 'var3', width: 150, filter: 'agTextColumnFilter' },
    { field: 'pesoNetto',headerName: "Peso Netto", width: 150, type:'numberColumn', filter: 'agNumberColumnFilter' },
    { field: 'pesoLordo',headerName: "Peso Lordo", width: 150, type:'numberColumn', filter: 'agNumberColumnFilter' },
    {
      headerName: 'Dimensioni',
      groupId: 'dimensionGroup',
      children: [
        // using medal column type
        { headerName: 'Altezza', field: 'altezza', type: 'dimensionColumn', filter: 'agNumberColumnFilter' },
        { headerName: 'Larghezza', field: 'larghezza', type: 'dimensionColumn', filter: 'agNumberColumnFilter' },
        { headerName: 'Lunghezza', field: 'lunghezza', type: 'dimensionColumn', filter: 'agNumberColumnFilter' },
        {
          headerName: '',
          valueGetter:
            function sumField(params) {
              return params.data.altezza + " x " + params.data.larghezza + " x " + params.data.lunghezza
            },
          width: 150,
          type: 'dimensionColumn',
          columnGroupShow: 'closed',
        },
      ],
    },
    // { field: 'NomeUDM', headerName: "Nome UDM", width: 200, filter: 'agTextColumnFilter' },

  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      //valueFormatter: (params) =>{return ''},
    },
    datetimeColumn: {
      valueFormatter: (params) => {
        return new Date(params.value + 'Z').toISOString().replace(/T.*/,'').split('-').reverse().join('/')
        //return new Date(params.value).toLocaleString('it-IT').replace(new RegExp('-', "g"), '/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          //debugger
          const dateParts = cellValue.split('T');
          const date = dateParts[0].split('-');
          const day = Number(date[2]);
          const month = Number(date[1]) - 1;
          const year = Number(date[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }

  public excelStyles: ExcelStyle[] = [
    {
      id: 'dateIT',
      dataType: 'DateTime',
      numberFormat: {
        format: 'dd/mm/yyyy',
      },
    },
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true,
    
  };

  public gridColumnApi!: ColumnApi;
  public getRowStyle;
  constructor(private http: HttpClient, private documentiService: DocumentiService,private _snackBar: MatSnackBar, public dialog: MatDialog, private gruppoService: GruppoService, private router: Router ) {
    this.getRowStyle = (params) => {
      if (params.data.Qta>0) {
          return { background: '#4caf50' };
      }else
      return false
  };
   }

  ngOnInit(): void {
    
    this.gruppoService.Sel_IdLivello(this.router.url.substring(6)).subscribe((data)=>{
      this.livelloID=data;
    })
  }

}
