import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  Articolo_Id: number,
  IdVarianti: number,
  name: string;
  valore: string;
  note: string;
}
@Component({
  selector: 'app-product-dialog-variante',
  templateUrl: './product-dialog-variante.component.html',
  styleUrls: ['./product-dialog-variante.component.scss']
})
export class ProductDialogVarianteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProductDialogVarianteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
