<mat-nav-list class="side-navnar">
    <div style="padding:5px">
        <b style="color:#828282">Preferiti</b>
    </div>
    <ng-container *ngFor="let preferiti of preferitiList | async; index as i;">
        <ng-container [ngTemplateOutlet]="parentMenu" [ngTemplateOutletContext]="{menu:preferiti}">
        </ng-container>
    </ng-container>

    <mat-divider></mat-divider>
    <div style="padding:5px">
        <b style="color:#828282">Tutte le app</b>
    </div>
    <ng-container *ngFor="let menu of menuList | async; index as i;">
        <ng-container [ngTemplateOutlet]="menu.children  ?  childMenu : parentMenu"
            [ngTemplateOutletContext]="{menu:menu}">
        </ng-container>
    </ng-container>
</mat-nav-list>

<ng-template #parentMenu let-menu="menu">
    <mat-list-item routerLinkActive="is-active" [routerLinkActiveOptions]="{exact:
        true}">
        <a matLine class="riga" [routerLink]="menu.RouterLink">
            <mat-icon>{{menu.Icon}}</mat-icon> {{menu.Label}}
        </a>
        <button mat-icon-button (click)="togglePreferiti(menu)">
            <mat-icon class="favorite">{{menu.favorite ? 'star' : 'star_border'}}</mat-icon>
        </button>
    </mat-list-item>
</ng-template>

<ng-template #childMenu let-menu="menu">
    <mat-expansion-panel [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
            <mat-icon mat-list-icon>{{menu.Icon}}</mat-icon>{{menu.Label}}
        </mat-expansion-panel-header>
        <mat-nav-list>
            <mat-list-item *ngFor="let submenu of menu.children" routerLinkActive="is-active"
                [routerLinkActiveOptions]="{exact:true}">

                <a matLine class="riga" [routerLink]="submenu.RouterLink">
                    <mat-icon>{{submenu.Icon}}</mat-icon> {{submenu.Label}}
                </a>
                <button mat-icon-button (click)="togglePreferiti(submenu)">
                    <mat-icon class="favorite">{{submenu.favorite ? 'star' : 'star_border'}}</mat-icon>
                </button>
            </mat-list-item>
        </mat-nav-list>
    </mat-expansion-panel>
</ng-template>