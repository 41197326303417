<div mat-dialog-content>
    <h2>Modifica Password</h2>
    <b style="color:red;font-size: 12px;">{{msg}}</b>
    <mat-form-field appearance="fill">
        <mat-label>Vecchia Password</mat-label>
        <input matInput [(ngModel)]="data.pswold">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Nuova Password</mat-label>
        <input matInput [(ngModel)]="data.psw">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Conferma Password</mat-label>
        <input matInput [(ngModel)]="data.pswconfirm">
    </mat-form-field>
    
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button (click)="onSave()" cdkFocusInitial>Salva</button>
</div>