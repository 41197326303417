<!-- <app-spinner [isLoading]="loading"></app-spinner> -->
<form [formGroup]="formprodotto" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button> -->
            <button class="button-tab-delete" type="button" (click)="onElimina()" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button>

        </mat-card-actions>
        <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
            <mat-tab label="Generali">
                <table class="full-width" cellspacing="0">
                    <tr>
                        <td style="text-align: center;" colspan="4">
                            <p class="text-grigio">Generali</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Cliente</mat-label>
                                <mat-select name="cliente" formControlName="idCliente">
                                    <mat-option *ngFor="let cliente of listaClienti" [value]="cliente.idCliente">
                                        {{cliente.ragione_Sociale}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Part Number</mat-label>
                                <input matInput name="partnumber" formControlName="partNumber">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Descrizione</mat-label>
                                <!-- <input matInput [value]="data.Descrizione" #descrizione> -->
                                <input matInput name="descrizione" formControlName="descrizione">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Valuta</mat-label>
                                <mat-select name="Valuta" formControlName="id_Valuta">
                                    <mat-option *ngFor="let valuta of liste.valuta" [value]="valuta.id">
                                        {{valuta.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;" colspan="4">
                            <p class="text-grigio">Origine</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Gruppo</mat-label>
                                <mat-select name="gruppo" formControlName="idGruppo"
                                    (valueChange)="onChangeGruppo($event)">
                                    <mat-option *ngFor="let gruppo of listaGruppi" [value]="gruppo.idGruppo">
                                        {{gruppo.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Famiglia</mat-label>
                                <mat-select name="famiglia" formControlName="idFamiglia">
                                    <mat-option *ngFor="let famiglia of listaFamiglie" [value]="famiglia.idFamiglia">
                                        {{famiglia.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Pz. Confezione</mat-label>
                                <!-- <input type="number" matInput [value]="data.PzConfezione" #pzconfezione> -->
                                <input type="number" matInput name="pzconfezione" formControlName="pzConfezione">
                            </mat-form-field>
                        </td>

                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Categoria</mat-label>
                                <mat-select name="categoria" formControlName="idCategoria">
                                    <mat-option *ngFor="let categoria of liste.categorie" [value]="categoria.id">
                                        {{categoria.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;" colspan="4">
                            <p class="text-grigio">Dati Magazzino</p>
                        </td>
                    </tr>
                    <tr>


                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Categoria ABC</mat-label>
                                <mat-select name="categoriaABC" formControlName="idCategoriaABC">
                                    <mat-option *ngFor="let categoriaABC of liste.categorieABC"
                                        [value]="categoriaABC.id">
                                        {{categoriaABC.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>

                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Criterio</mat-label>
                                <mat-select name="criterio" formControlName="idCriterio">
                                    <mat-option *ngFor="let criterio of liste.criterio" [value]="criterio.id">
                                        {{criterio.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Scorta Min</mat-label>
                                <input type="number" matInput name="scortamin" formControlName="scortamin">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Scorta Max</mat-label>
                                <input matInput name="scortamax" formControlName="scortamax">
                            </mat-form-field>
                        </td>

                    </tr>
                    <tr>
                        <td style="text-align: center;" colspan="4">
                            <p class="text-grigio">Altri Dati</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Q.tà Std</mat-label>
                                <!-- <input type="number" matInput [value]="data.PzConfezione" #pzconfezione> -->
                                <input type="number" matInput name="pzconfezione" formControlName="pzConfezione">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-checkbox type="checkbox" name="ausiliario" color="primary"
                                formControlName="ausiliario">Ausiliario</mat-checkbox>
                        </td>
                        <td>
                            <mat-checkbox type="checkbox" name="ausiliario" color="primary"
                                formControlName="ausiliario">Eredita Udc Cliente</mat-checkbox>
                        </td>
                        <td colspan="2">
                            <mat-form-field class="full-width">
                                <mat-label>Note</mat-label>
                                <textarea matInput name="note" formControlName="note"></textarea>
                            </mat-form-field>
                        </td>
                        
                    </tr>
                    <tr>
                        <td>
                            <mat-checkbox type="checkbox" name="lavaggio" color="primary"
                                formControlName="lavaggio">Lavaggio</mat-checkbox>
                        </td>
                        <td *ngIf="prodottoFinito">
                            <mat-checkbox type="checkbox" name="bloccoQualita" color="primary" (change)="changeBloccoQualita()"
                                formControlName="bloccoQualita">Blocco Qualità</mat-checkbox>
                        </td>
                    </tr>
                </table>

            </mat-tab>
            <mat-tab label="Altri Dati">
                <table>
                    <tr>
                        <td><b>Dimensioni</b></td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Altezza</mat-label>
                                <input type="number" matInput name="altezza" formControlName="altezza">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Larghezza</mat-label>
                                <input type="number" matInput name="larghezza" formControlName="larghezza">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Profondità</mat-label>
                                <input type="number" matInput name="profondita" formControlName="profondita">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Volume</mat-label>
                                <input type="number" matInput name="volume" formControlName="volume">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Um</mat-label>
                                <mat-select name="misura" formControlName="id_Misura">
                                    <mat-option *ngFor="let um of liste.um" [value]="um.id">
                                        {{um.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Peso</b></td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>PN</mat-label>
                                <input type="number" matInput name="pn" formControlName="pn">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>PL</mat-label>
                                <input type="number" matInput name="pl" formControlName="pl">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Um</mat-label>
                                <mat-select name="misura" formControlName="id_Misura">
                                    <mat-option *ngFor="let um of liste.um" [value]="um.id">
                                        {{um.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td><b>Lotto</b></td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Lotto Riordino</mat-label>
                                <input type="number" matInput name="lottoriordino" formControlName="lottoRiordino">
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
            </mat-tab>
            <mat-tab label="Kit">
                <mat-card>
                    <mat-card-actions>
                        <button mat-button type="button" color="primary" (click)="openArticoloDialog()">
                            Aggiungi Articolo
                        </button>
                    </mat-card-actions>
                    <div class="card">
                        <app-grid style="width: 100%; height: 80vh" (gridReady)="RefreshKit($event)"
                            [rowData]="rowDataKit" [columnDefs]="columnDefKit" [defaultColDef]="defaultColDef"
                            (onDblClick)="openArticoloEditDialog($event)"></app-grid>
                    </div>

                </mat-card>
            </mat-tab>
            <mat-tab label="Varianti">
                <mat-card>
                    <mat-card-actions>
                        <button mat-button type="button" color="primary" (click)="openVarianteDialog()">
                            Aggiungi Variante
                        </button>
                    </mat-card-actions>
                    <div class="card">
                        <app-grid style="width: 100%; height: 80vh" (gridReady)="RefreshVariantiDescrizioni($event)"
                            [rowData]="rowDataVarianti" [columnDefs]="columnDefsVarianti" [defaultColDef]="defaultColDef"
                            (onDblClick)="openVarianteDialog($event)"></app-grid>
                    </div>

                </mat-card>
            </mat-tab>
            <mat-tab label="Altre Descrizioni">
                <mat-card>
                    <mat-card-actions>
                        <button mat-button type="button" color="primary" (click)="openDescrizioneDialog()">
                            Aggiungi Descrizione
                        </button>
                    </mat-card-actions>
                    <div class="card">
                        <app-grid style="width: 100%; height: 80vh" (gridReady)="RefreshVariantiDescrizioni($event)"
                            [rowData]="rowDataDescrizioni" [columnDefs]="columnDefsDescrizioni" [defaultColDef]="defaultColDef"
                            (onDblClick)="openDescrizioneDialog($event)"></app-grid>

                    </div>
                    <!-- <mat-card-content>
                        <tr>
                            <td>
                                <mat-label>Sigla Lingua</mat-label>
                                <mat-select name="sigla" formControlName="Sigla">
                                </mat-select>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Descrizione</mat-label>
                                    <input matInput name="altraDescrizione" formControlName="altraDescrizione">
                                </mat-form-field>
                            </td>
                        </tr>
                    </mat-card-content> -->
                </mat-card>
            </mat-tab>
            <mat-tab label="Giacenze">
                <mat-card>
                    <table style="width:100%" mat-table [dataSource]="dataSourceGiacenze" class="mat-elevation-z8">

                        <ng-container matColumnDef="udc">
                            <th mat-header-cell *matHeaderCellDef>UDC</th>
                            <td mat-cell *matCellDef="let element"> {{element.numUDC}} </td>
                        </ng-container>

                        <ng-container matColumnDef="locazione">
                            <th mat-header-cell *matHeaderCellDef>Ubicazione </th>
                            <td mat-cell *matCellDef="let element"> {{element.locazionesrg}} </td>
                        </ng-container>

                        <ng-container matColumnDef="qta">
                            <th mat-header-cell *matHeaderCellDef>Q.tà</th>
                            <td mat-cell *matCellDef="let element"> {{element.qta }} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsGiacenza"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsGiacenza;"></tr>
                    </table>
                </mat-card>
            </mat-tab>
            <mat-tab label="Log">
                <mat-card>
                    <table style="width:100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8">


                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> ID </th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef>Tipo </th>
                            <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
                        </ng-container>

                        <ng-container matColumnDef="utente">
                            <th mat-header-cell *matHeaderCellDef> Utente </th>
                            <td mat-cell *matCellDef="let element"> {{element.utente}} </td>
                        </ng-container>

                        <ng-container matColumnDef="data">
                            <th mat-header-cell *matHeaderCellDef> Data </th>
                            <td mat-cell *matCellDef="let element"> {{element.data | date : 'dd/MM/yyyy HH:mm' }} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</form>