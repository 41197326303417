import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';


@Component({
  selector: 'app-dialog-ingresso-articolo',
  templateUrl: './dialog-ingresso-articolo.component.html',
  styleUrls: ['./dialog-ingresso-articolo.component.scss']
})
export class DialogIngressoArticoloComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogIngressoArticoloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IngressoArticolo,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }
  isError=false;
  onSave(){
    if(this.data.qtaMiss.toString() !='' && this.data.note != ''){
      this.dialogRef.close(this.data)
      this.isError=false;
    }else{
      this.isError=true;
    }
  }

  onElimina(){
    this.data.elimina = 1;
    this.dialogRef.close(this.data)
    
  }

}
