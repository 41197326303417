import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, ColGroupDef, ColumnApi, RowDoubleClickedEvent } from 'ag-grid-community';
import { Articolo } from 'src/app/Models/Articolo';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { Kit } from 'src/app/Models/Kit';
import { ArticoloService } from 'src/app/services/articolo.service';


@Component({
  selector: 'app-product-dialog-articolo',
  templateUrl: './product-dialog-articolo.component.html',
  styleUrls: ['./product-dialog-articolo.component.scss']
})
export class ProductDialogArticoloComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProductDialogArticoloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Kit,
    private articoliService: ArticoloService,
    public dialog: MatDialog
  ) { }

  isProductSelected = false;
  isQtyInsert = false;
  product = '';

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.loadData()
  }

  rowArticoli: Articolo[] = [];
  
  public columnDefsArticoli: (ColDef | ColGroupDef)[] = [
    // { field: 'Articolo_Id', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'descrizione', headerName: "Des. Articolo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 200 , filter: 'agTextColumnFilter'},
    { field: 'qta', headerName: "Qtà", width: 150, type: 'numberColumn', filter: 'agNumberColumnFilter' },
  ];
  


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  loadData() {
    this.articoliService.ElencoArticoli().subscribe((data) => {
      this.rowArticoli = data;
    });

  }

  addArticolo(e: RowDoubleClickedEvent) {
    let articolo = this.rowArticoli.find((value) => value.gridIndex == +e);
    this.data.articolochild_Id = articolo?.articolo_Id || 0;
    this.data.gridIndex = 0;
    this.data.partNumber = articolo?.partNumber || '';
    //this.data.Note = articolo?.Note || '';
    this.data.utente = '';
    this.data.disabilitato = false;

    this.isProductSelected = true;
    this.product = 'Articolo selezionato: ' + articolo?.partNumber;
  }

  onSave(){
    if(this.data.qta > 0){
      this.data.note = this.data?.note || '';
      this.dialogRef.close(this.data);
    }
  }

}
