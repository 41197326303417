<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>
            <button class="button-tab-delete" type="button" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button> -->

        </mat-card-actions>
        <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
            <mat-tab label="Generali">
                <table class="full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>UDC</mat-label>
                                <input matInput name="udc" formControlName="udc">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Locazione Movimento</mat-label>
                                <input matInput formControlName="barcodelocazione">
                            </mat-form-field>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>
                            <button class="button-tab-save" type="submit" title="Salva">
                                Salva
                            </button>
                        </td>
                    </tr> -->
                </table>
        
            </mat-tab>
        </mat-tab-group>
        </mat-card>
        </form>