<div mat-dialog-content align="center">
    <b>Rettifica UDC</b><br>
    <mat-form-field class="full-width">
        <mat-label>Causale</mat-label>
        <mat-select [(ngModel)]="data.causale">
            <mat-option value="0">
                Seleziona
            </mat-option>
            <mat-option *ngFor="let option of causali" [value]="option.idCausale">
                {{option.descrizione}}
            </mat-option>
            <!-- <mat-option value="1">
                RETTIFICA INVENTARIALE
            </mat-option>
            <mat-option value="2">
                RETTIFICA INTERNA
            </mat-option> -->
        </mat-select>
    </mat-form-field>
    <!-- <mat-form-field class="full-width">
        <mat-label>Part Number</mat-label>
        <input type="text" matInput name="pn" [(ngModel)]="data.partnumber"/>
    </mat-form-field> -->
    <mat-form-field class="full-width">
        <mat-label>Part Number</mat-label>
        <input type="text"
           aria-label="Number"
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto" [(ngModel)]="data.partnumber">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
      </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>Q.tà</mat-label>
        <input type="number" matInput name="rettifica" [(ngModel)]="data.num"/>
    </mat-form-field>
    <mat-form-field class="full-width">
        <mat-label>Note</mat-label>
        <textarea rows="4" matInput name="pn" [(ngModel)]="data.Note"></textarea>
    </mat-form-field>
 </div>
 <div mat-dialog-actions align="center">
     <button class="button-annulla" (click)="onNoClick()">Annulla</button>
     <button class="button-add-row" (click)="onSalva()" cdkFocusInitial>Salva</button>
 </div>