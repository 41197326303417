import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';

@Component({
  selector: 'app-dialog-ordini-ddtemessi',
  templateUrl: './dialog-ordini-ddtemessi.component.html',
  styleUrls: ['./dialog-ordini-ddtemessi.component.scss']
})
export class DialogOrdiniDdtemessiComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogOrdiniDdtemessiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private jwtHelper : JwtHelperService
  ) { }

  ngOnInit(): void {
  }

}
