
<div mat-dialog-content>
    <b style="color:red">{{labelerror}}</b>
    <div style="display: flex; flex-direction: row;justify-content: flex-end;">
        <a href="assets/layout-file/layoutCNT.xlsx" download="layoutCNT.xlsx">Scarica Layout</a>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-around;">
        <mat-form-field appearance="fill">
            <mat-label>Numero Programma (12 caratteri)</mat-label>
            <input matInput [(ngModel)]="data.numpro">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Codice Cliente (5 caratteri)</mat-label>
            <input matInput [(ngModel)]="data.codcliente">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Codice Spedizione (4 caratteri)</mat-label>
            <input matInput [(ngModel)]="data.codspedizione">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Ordine Oerlikon (6 caratteri)</mat-label>
            <input matInput [(ngModel)]="data.ordoer">
        </mat-form-field>
    </div>
    <div style="display:flex; justify-content:center; width:100%">
        
        <button class="button-add-row" (click)="addClipboardNote()">Recupera UDC da Appunti</button>
    </div>
    <div *ngIf="concatenatedNotes != ''">
        <b>{{countUDC}} UDC Trovati: </b>{{concatenatedNotes}}
    </div>
    
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" (click)="Salva()">Salva</button>
    
</div>