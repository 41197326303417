import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MenuService } from 'src/app/services/menu.service';

export class DialogData{
  valore: string;
  id:number;
}

@Component({
  selector: 'app-dialog-edit-variante',
  templateUrl: './dialog-edit-variante.component.html',
  styleUrls: ['./dialog-edit-variante.component.scss']
})
export class DialogEditVarianteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogEditVarianteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
    
  }

}
