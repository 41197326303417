import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-data-presa-piano-consegna-cell',
  templateUrl: './data-presa-piano-consegna-cell.component.html',
  styleUrls: ['./data-presa-piano-consegna-cell.component.scss']
})
export class DataPresaPianoConsegnaCellComponent implements ICellRendererAngularComp  {

  public params!: any;
  public data: string;
  public value: any;
  public minDate: any;

  public dataIn: any;
  public dataOut: any;

  agInit(params: any): void {
    this.params = params;
    this.minDate = params.value.split("T")[0];
    this.data = new Date(params.value).toLocaleDateString('it-IT');

    this.dataIn = sessionStorage.getItem("dataIn");
    if(this.dataIn){
      let dataInSplitted = this.dataIn.split("T")[0];

      this.dataOut = sessionStorage.getItem("dataOut");
      let dataOutSplitted = this.dataOut.split("T")[0];
    
      if(+new Date(this.dataIn) == +new Date(params.value)){
        this.value= dataOutSplitted;
      }
    }

  }


  refresh(params: any): boolean {
    this.params = params;
    this.value = this.params.value;
    
    // this.dataIn = params.dataIn;
    // this.dataOut = params.dataOut;
    return true;
  }

  onInputChange(event: any): void {
    this.value = event.target.value;
    //this.params.data[this.params.colDef.field] = this.value;
    if (this.params.onChange) {
      this.params.onChange(this.data, this.value);
    }
  }

  

  isValidDate(dateString: string): boolean {
    // Controlla il formato della data (YYYY-MM-DD)
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regex)) {
      return false;
    }
  
    // Prova a creare un oggetto Date
    const date = new Date(dateString);
  
    // Controlla se la data è valida
    const timestamp = date.getTime();
    if (isNaN(timestamp)) {
      return false;
    }
  
    // Controlla se i componenti della data corrispondono alla stringa originale
    const [year, month, day] = dateString.split('-').map(Number);
    if (date.getUTCFullYear() !== year || date.getUTCMonth() + 1 !== month || date.getUTCDate() !== day) {
      return false;
    }
  
    return true;
  }

}
