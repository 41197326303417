import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { ODS } from 'src/app/Models/ODS';
import { DocumentiService } from 'src/app/services/documenti.service';
import { GridAddButtonComponent } from '../../grid-add-button/grid-add-button.component';
import { GruppoService } from 'src/app/services/gruppo.service';
import { Router } from '@angular/router';

export class Tab {
  id: number | null = 0;
  nome: string = "";
  numero: string ="";
  isOds : boolean = false
}

@Component({
  selector: 'app-ods',
  templateUrl: './ods.component.html',
  styleUrls: ['./ods.component.scss']
})
export class OdsComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: ODS[];
  label = "Lista"
  tabs: Tab[] = [];
  IDODS: number = 0;
  DocumentoID: number = 0;

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.reloadData()

  }

  refreshGrid() {
    this.reloadData()
  }
  reloadData() {
    this.loading = 'block';
    // this.http
    //   .get<Ingresso[]>("https://testapiall1.azurewebsites.net/api/documenti/geti/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
    this.documentiService.GetODS(0,14).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo ODS',
      numero: '',
      isOds: true
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.IDODS = -1;
    this.selected.setValue(this.tabs.length);

  }

  numOrdine = "";

  addTab(e: RowDoubleClickedEvent) {
    if (!this.tabs.some((x) => x.id == e.data.idODS)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.idODS,
        nome: e.data.cliente,
        numero: '',
        isOds : true
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.IDODS = e.data.idODS;

    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.idODS) + 1);

  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
    }
    
  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'numeroOrdine', headerName: "Numero Ordine", width: 200, checkboxSelection: (e) => {
        if (e.data.NumDoc)
          return false;
        else
          return true
      }
    },
    { field: 'idGruppodiCarico', headerName: "Gruppo", width: 130, filter: 'agNumberColumnFilter' },
    { field: 'intestatario', headerName: "Destinatario", width: 200, filter: 'agTextColumnFilter' },
    {
      field: 'cliente', headerName: "Cliente", width: 200, filter: 'agTextColumnFilter'
    },
    {
      field: 'numDoc', headerName: "Documento", width: 200, filter: false,
      cellRenderer: 'addButtonGrid',
      cellRendererParams: {
        onClick: this.ApriDocumento.bind(this),
      }
    },
    { field: 'targa', headerName: "Targa", width: 200, filter: 'agTextColumnFilter' },
    { field: 'fornitore', headerName: "Intestatario", width: 200, filter: 'agTextColumnFilter' },
    { field: 'data', headerName: "Data", width: 200, type: 'dateColumn' },
    // { field: 'RagioneSociale', headerName: "Ragione Sociale", width: 200 },
    { field: 'localita', headerName: "Località", width: 200, filter: 'agTextColumnFilter' },
    { field: 'indirizzo', headerName: "Indirizzo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'provincia', headerName: "Provincia", width: 100, filter: 'agTextColumnFilter' },
    { field: 'nazione', headerName: "Nazione", width: 200, filter: 'agTextColumnFilter' },
    { field: 'targa', headerName: "Targa", width: 200, filter: 'agTextColumnFilter' },
    {
      field: 'evaso', headerName: "Completo", valueFormatter: (param) => {
        return param.value ? 'Si' : 'No'
      }
    },

  ];

  ApriDocumento(x){
    let id = x.rowData.documentoID;
    let row = {
      id: parseInt(id),
      nome: 'Documento ' + x.rowData.numDoc,
      numero: '',
      isOds: false
    }
    this.tabs.push(row);

    this.DocumentoID =  parseInt(id);
    this.numOrdine = x.rowData.numeroOrdine;
    this.selected.setValue(this.tabs.length);

  }

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
         return new Date(params.value).toLocaleDateString('it-IT')
       //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;
  public getRowStyle;
  public frameworkComponents;
  constructor(private http: HttpClient, private documentiService: DocumentiService, private _snackBar: MatSnackBar,private gruppoService: GruppoService, private router: Router) {
    this.getRowStyle = (params) => {
      if (params.data.evaso == 2) {
        return { background: '#4caf50' };
      } else if (params.data.evaso == 1) {
        return { background: '#cbcbcb' };
      } else
        return false
    };
    this.frameworkComponents = {
      addButtonGrid: GridAddButtonComponent,
    }
  }

  livelloID : number = 0

  ngOnInit(): void {
    this.gruppoService.Sel_IdLivello(this.router.url.substring(6)).subscribe((data)=>{
      this.livelloID=data;
    })
  }

  private gridApi!: GridApi<any>;
  newDDT(){
    let selection = this.gridApi.getSelectedRows();
    if(selection.length > 0){
    if(selection.every((e) => e.codice_Dest == selection[0].codice_Dest) && selection.every((e) => e.cliente == selection[0].cliente) && selection.every((e) => e.targa == selection[0].targa) && selection.every((e) => e.fornitoreId == selection[0].fornitoreId)){

      selection = selection.map((x) => x['idODS'])

      this.documentiService.InsDocumentoODS(selection.join()).subscribe((res)=>{
        if (res[0].err == '0') {
          let row = {
            id: parseInt(res[0].msg),
            nome: 'Nuovo DDT',
            numero: '',
            isOds: false
          }
          this.tabs.push(row);

          this.DocumentoID =  parseInt(res[0].msg);
          this.selected.setValue(this.tabs.length);
        }else
          this.openSnackBar('Si è verificato un errore con la creazione del DDT');
      })


      
    }else{
      this.openSnackBar('Clienti, Destinazioni, Targa o Intestatario non corrispondono, verificare gli ordini');
    }
  }else{
    this.openSnackBar('Selezionare almeno un ordine');
  }

  }

  

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 5000
    });
  }

}
