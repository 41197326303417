import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { Customer } from 'src/app/Models/Customer';
import { DestinazioniClienti } from 'src/app/Models/DestinazioniClienti';
import { VariantiClienti } from 'src/app/Models/VariantiClienti';
import { CustomerService } from 'src/app/services/customer.service';
import { ClientiComponent, Tab } from '../clienti/clienti.component';
import { DialogVariantiComponent } from '../dialog-varianti/dialog-varianti.component';
import { DialogDestinazioniComponent } from './dialog-destinazioni/dialog-destinazioni.component';
import { DialogFornitoriComponent } from './dialog-fornitori/dialog-fornitori.component';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
})
export class ClienteComponent implements OnInit {
  formcliente: FormGroup = new FormGroup({
    idCliente: new FormControl(''),
    ragione_Sociale: new FormControl(''),
    piva: new FormControl(''),
    cf: new FormControl(''),
    indirizzo_Legale: new FormControl(''),
    cittaL: new FormControl(''),
    provL: new FormControl(''),
    capl: new FormControl(''),
    telefoniL: new FormControl(''),
    cellulari: new FormControl(''),
    email1: new FormControl(''),
    email2: new FormControl(''),
    note: new FormControl(''),
    codVettore: new FormControl(''),
    data_Creazione: new FormControl(''),
    linguaDOC: new FormControl(''),
    nazione: new FormControl(),
    orario: new FormControl(''),
    gGprima: new FormControl(0),
    gGdopo: new FormControl(0),
    ggUrgenza: new FormControl(0),
    idSito: new FormControl(''),
    //    Ente: new FormControl(''),
  });

  @Input() id: number = 0;

  @Input() tab: Tab = { id: 0, nome: "" };
  @Input() isEnte: boolean;

  public loading: string = 'none';
  selected = new FormControl(0);
  nazioni = [];
  siti = [];

  idInterno : number;

  @Output() onClose = new EventEmitter<number>(); // messo nuovo


  constructor(private _snackBar: MatSnackBar, private customerService: CustomerService, public dialog: MatDialog,private http: HttpClient) {}

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  ngOnInit(): void {
    this.getCliente(this.id);
    this.idInterno = this.id;
  }

  getCliente(id: number) {
if(!this.isEnte){
    this.customerService.GetEnte(id).subscribe((res) => {
      this.formcliente = new FormGroup({
        idCliente: new FormControl(res[0]?.idCliente || 0, Validators.required),
        ragione_Sociale: new FormControl(res[0]?.ragione_Sociale || '', Validators.required),
        piva: new FormControl(res[0]?.piva || '', Validators.required),
        cf: new FormControl(res[0]?.cf || '', Validators.required),
        indirizzo_Legale: new FormControl(res[0]?.indirizzo_Legale || '', Validators.required),
        cittaL: new FormControl(res[0]?.cittaL || '', Validators.required),
        provL: new FormControl(res[0]?.provL || '', Validators.required),
        capl: new FormControl(res[0]?.capl || '', Validators.required),
        telefoniL: new FormControl(res[0]?.telefoniL || '', Validators.required),
        cellulari: new FormControl(res[0]?.cellulari || '', Validators.required),
        email1: new FormControl(res[0]?.email1 || '', Validators.required),
        email2: new FormControl(res[0]?.email2 || '', Validators.required),
        note: new FormControl(res[0]?.note || ''),
        codVettore: new FormControl(res[0]?.codVettore || 0),
        data_Creazione: new FormControl( new Date(res[0]?.data_Creazione).toLocaleDateString('af').replace(new RegExp('/', "g"),'-')  || ''),
        linguaDOC: new FormControl(res[0]?.linguaDOC || ''),
        nazione: new FormControl(res[0]?.nazione || ''),
        orario: new FormControl(res[0]?.orario || ''),
        gGprima: new FormControl(res[0]?.gGprima || 0),
        gGdopo: new FormControl(res[0]?.gGdopo || 0),
        ggUrgenza: new FormControl(res[0]?.ggUrgenza || 0),
        idSito: new FormControl(res[0]?.idSito || 0),
      });
      this.nazioni = res[0]?.nazioni || [];
      this.siti = res[0]?.siti || [];
      this.rowDataVarianti = res[0]?.varianticlienti || [];
      this.rowDataDestinazioni = res[0]?.entidestinatari || [];
      this.rowDataFornitori = res[0]?.entifornitori || [];
    }
    );
  }
  }

  onSubmit() {
    if (!this.formcliente.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
    } else {
      this.loading = 'block';
      let req: Cliente = this.formcliente.value;
      req.capl = req.capl.toString();
      if(req.orario == "")
        req.orario= "00:00"
      req.ente = false;
      req.idClientePadre = 0;
      req.fornitore = false;
      req.destinatario = false;

      let token = JSON.parse(localStorage.getItem('r'));

      req.utente = token.firstName + " " + token.lastName; //dal token prelevare il nome utente


      this.customerService.InsEnte(req).subscribe(data => {
        this.loading = 'none';
        if(data){
          this.openSnackBar(req.idCliente == 0 ? 'Cliente salvato' : 'Cliente modificato');
        }else{
          this.openSnackBar(data.msg); 
        }
      });
    }
  }


  onElimina(){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'Il cliente sarà eliminato, Continuare?',
        BtnSi: 'Elimina',
        BtnNo: 'Annulla',
        BtnInvertiti: true
      },
    });
 
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = 'block';
        this.customerService.DelCliente(this.idInterno).subscribe((data) => {
          this.loading = 'none'
          if (data > 0) {
            this.onClose.emit(this.idInterno);   // da doc era closeTab
          } else {
            this.openSnackBar(data);
          }
        });
      }
    });
  };


  onUndo() {
    if (this.idInterno > 0) {
      this.getCliente(this.idInterno);
    } else {
      this.getCliente(-1);
    }
  }

  rowDataVarianti: VariantiClienti[];
  rowDataDestinazioni: Cliente[];
  rowDataFornitori : Cliente[];

  openVarianteDialog(varianteid: number = 0): void {
    if (this.idInterno > 0) {
      let variante = this.rowDataVarianti.find((e) => e.gridIndex == varianteid);
      const dialogRef = this.dialog.open(DialogVariantiComponent, {
        width: '250px',
        data: {
          Alias: variante?.alias || '',
          Tipo: variante?.tipo || '',
          IdVariante: variante?.idVariante || 0
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let variante: VariantiClienti = {
            alias: result.Alias,
            tipo: result.Tipo,
            disabilitato: false,
            idCliente: this.id,
            idVariante: result?.IdVariante,
            gridIndex: result?.gridIndex
          }
          this.customerService.InsVariante(variante).subscribe(() => this.RefreshVariantiDescrizioni());;
        }
      });
    } else
      this.openSnackBar('Salvare il Cliente prima di continuare');
  }

  openDestinazioneDialog(destinazioneid: number = 0): void {
    if (this.idInterno > 0) {
      let destinatario = this.rowDataDestinazioni.find((e) => e.idCliente == destinazioneid);
      const dialogRef = this.dialog.open(DialogDestinazioniComponent, {
        width: (window.innerWidth - 300) + "px",
        height: (window.innerHeight - 200) + "px",
        data: destinatario ? destinatario : {
          Destinatario: true
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && isNaN(result)) {
          
          if (result.ragione_Sociale) {
            this.loading = 'block';
            let req: Cliente = result;
            req.idCliente = result?.idCliente || 0;
            req.utente = result?.utente || ''; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
            req.idClientePadre = this.idInterno;
            req.ente = true;
            req.note = result?.note || "";
            req.codVettore = result?.codVettore || 0;
            req.linguaDOC = result?.linguaDOC || "";
            req.piva = result?.piva || "";
            req.cf = result?.cf || "";
            req.indirizzo_Legale = result?.indirizzo_Legale || "";
            req.provL = result?.provL || "";
            req.cittaL = result?.cittaL || "";
            req.capl = result?.capl.toString() || '';
            req.telefoniL = result?.telefoniL || '';
            req.cellulari = result?.cellulari || '';
            req.email1 = result?.email1 || '';
            req.email2 = result?.email2 || '';
            req.nazione = result?.nazione || '';
            //req = result;
            req.gGprima = 0;
            req.gGdopo = 0;
            req.gGUrgenza = 0;
            req.orario= '00:00:00'
            req.fornitore = result?.fornitore || false;
            req.destinatario = result?.destinatario || false;
            req.idSito = 0;

            this.customerService.InsEnte(req).subscribe(data => {
              this.loading = 'none';
              if (data.err == '0') {
                this.RefreshDestinazioniDescrizioni()
                this.RefreshFornitoriDescrizione()
                this.openSnackBar('Destinatario salvato');
                return true
              } else {
                this.openSnackBar(data.msg);
                return false
              }
            });
          } else {
            this.openSnackBar('Inserire una Ragione Sociale');
          }
          
        }else if(!isNaN(result)){
          let res={
            ClienteId : this.idInterno,
            ClienteEnteId : result,
            Tipo: 1
          }
          this.customerService.DelEntexCliente(res).subscribe(data => {
            this.openSnackBar('Destinatario Rimosso');
            this.RefreshDestinazioniDescrizioni()
          });
        }
      });
    }
    else
      this.openSnackBar('Salvare il Cliente prima di continuare');
  }

  openFornitoreDialog(fornitoreid: number = 0): void {
    
    if (this.idInterno > 0) {
      let fornitore = this.rowDataFornitori.find((e) => e.idCliente == fornitoreid);
      const dialogRef = this.dialog.open(DialogFornitoriComponent, {
        width: (window.innerWidth - 300) + "px",
        height: (window.innerHeight - 200) + "px",
        data: fornitore ? fornitore : {
          Fornitore: true
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && isNaN(result)) {
          if (result.ragione_Sociale) {
            this.loading = 'block';
            let req: Cliente = result;
            req.idCliente = result?.idCliente || 0;
            req.utente = result?.utente || ''; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
            req.idClientePadre = this.idInterno;
            req.ente = true;
            req.note = result?.note || "";
            req.codVettore = result?.codVettore || 0;
            req.linguaDOC = result?.linguaDOC || "";
            req.piva = result?.piva || "";
            req.cf = result?.cf || "";
            req.indirizzo_Legale = result?.indirizzo_Legale || "";
            req.provL = result?.provL || "";
            req.cittaL = result?.cittaL || "";
            req.capl = result?.capl.toString() || '';
            req.telefoniL = result?.telefoniL || '';
            req.cellulari = result?.cellulari || '';
            req.email1 = result?.email1 || '';
            req.email2 = result?.email2 || '';
            req.nazione = result?.nazione || '';
            req.gGprima = 0;
            req.gGdopo = 0;
            req.gGUrgenza = 0;
            req.orario= '00:00:00'
            req.fornitore = result?.fornitore || false;
            req.destinatario = result?.destinatario || false;
            req.idSito = 0;

            this.customerService.InsEnte(req).subscribe(data => {
              this.loading = 'none';
             
              if (data.err == '0') {
                this.RefreshDestinazioniDescrizioni()
                this.RefreshFornitoriDescrizione()
                this.openSnackBar('Fornitore salvato');
                return true
              } else {
                this.openSnackBar(data.msg);
                return false
              }
            });
          } else {
            this.openSnackBar('Inserire una Ragione Sociale');
          }
        }else if(!isNaN(result)){
          let res={
            ClienteId : this.idInterno,
            ClienteEnteId : result,
            Tipo: 2
          }
          this.customerService.DelEntexCliente(res).subscribe(data => {
            this.openSnackBar('Fornitore Rimosso');
            this.RefreshFornitoriDescrizione()
          });
        }
      });
    } else
      this.openSnackBar('Salvare il Cliente prima di continuare');



  }

  RefreshFornitoriDescrizione() {
    this.customerService.GetEnte(this.idInterno).subscribe((res) => {
      this.rowDataFornitori = res[0]?.entifornitori || [];
    });
  }

  RefreshFornitori(params : GridReadyEvent){
    this.gridColumnApi = params.columnApi;
    this.RefreshFornitoriDescrizione();
  }

  RefreshVariantiDescrizioni() {
    this.customerService.GetEnte(this.idInterno).subscribe((res) => {
      this.rowDataVarianti = res[0]?.varianticlienti || [];
    });
  }

  RefreshVarianti(params : GridReadyEvent){
    this.gridColumnApi = params.columnApi;
    this.RefreshVariantiDescrizioni();
  }

  RefreshDestinazioni(params : GridReadyEvent){
    this.gridColumnApi = params.columnApi;
    this.RefreshDestinazioniDescrizioni();
  }

  RefreshDestinazioniDescrizioni() {
    this.customerService.GetEnte(this.idInterno).subscribe((res) => {
      this.rowDataDestinazioni = res[0]?.entidestinatari || [];
    });
  }

  public columnDefsVarianti: (ColDef | ColGroupDef)[] = [
    // { field: 'IdVariante', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'alias', width: 320,filter: 'agTextColumnFilter' },
    { field: 'tipo', width: 320,filter: 'agTextColumnFilter' },
  ];
  public columnDefsDestinazioni: (ColDef | ColGroupDef)[] = [
    { field: 'ragione_Sociale',headerName:'Ragione Sociale', width: 320,filter: 'agTextColumnFilter' },
    { field: 'indirizzo_Legale',headerName:"Indirizzo Legale", width: 320,filter: 'agTextColumnFilter' },
  ];
  public columnDefsFornitori: (ColDef | ColGroupDef)[] = [
    { field: 'ragione_Sociale',headerName:'Ragione Sociale', width: 320,filter: 'agTextColumnFilter' },
    { field: 'indirizzo_Legale',headerName:"Indirizzo Legale", width: 320,filter: 'agTextColumnFilter' },
  ];
  
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

}
