<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.nome">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
</div>