import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { Liste } from 'src/app/Models/Liste';
import { TipoDocumento } from 'src/app/Models/TipoDocumento';
import { ArticoloService } from 'src/app/services/articolo.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogIngressoArticoloComponent } from '../dialog-ingresso-articolo/dialog-ingresso-articolo.component';
import { DialogIngressoCercaArticoloComponent } from '../dialog-ingresso-cerca-articolo/dialog-ingresso-cerca-articolo.component';
import { Tab } from '../ingresso/ingresso.component';
import { Prelievo } from 'src/app/Models/Prelievo';
import { PrelievoDettagli } from 'src/app/Models/PrelievoDettagli';
import { DialogUscitaArticoloComponent } from '../dialog-uscita-articolo/dialog-uscita-articolo.component';
import { DialogUscitaCercaArticoloComponent } from '../dialog-uscita-cerca-articolo/dialog-uscita-cerca-articolo.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-missione',
  templateUrl: './missione.component.html',
  styleUrls: ['./missione.component.scss']
})
export class MissioneComponent implements OnInit {

  formingresso: FormGroup = new FormGroup({
    data: new FormControl(''),
    note: new FormControl(''),
    //IdCliente: new FormControl(''),
    // Stato: new FormControl(''),
  });
  @Input() id: number = 0;

  @Input() tab: Tab = { id: 0, nome: "", numero: "" };

  public loading: string = 'none';
  selected = new FormControl(0);
  clienti: Cliente[] = [];

  idInterno: number;

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  public getRowStyle
  constructor(private _snackBar: MatSnackBar,
    private documentiService: DocumentiService,
    private clientiService: CustomerService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private jwtHelper: JwtHelperService) {
    this.getRowStyle = (params) => {
      if (params.data.idpadre == 0) {
        return { background: '#4caf50' };
      } else
        return false
    };
  }



  numeroOrdine = '';
  cliente = '';
  destinatario = '';
  dataconsegna = '';
  note = '';
  idCliente = 0;

  isProdottoFinito = false;

  getPrelievo(id: number) {
    this.documentiService.GetPrelievi(id, 14).subscribe((res) => {
      this.formingresso = new FormGroup({

        data: new FormControl(new Date(res[0]?.data).toLocaleDateString('af').replace(new RegExp('/', "g"), '-') || '', Validators.required),
        note: new FormControl(res[0]?.note || ''),
        //IdCliente: new FormControl(res[0]?.IdCliente || 0, Validators.required),
        //Stato: new FormControl(res[0]?.Stato || 0, Validators.required),

      });
      this.cliente = res[0].ragioneSociale;
      this.idCliente = res[0].idCliente;
      this.destinatario = res[0].intestatario;
      this.numeroOrdine = res[0].numeroOrdine;
      this.note = res[0].note;
      this.dataconsegna = new Date(res[0].dataConsegna).toLocaleDateString('it-IT');
      this.clienti = res[0].enti;
      this.rowArticoli = res[0].dettagliprelievo;
      this.listarticoli = res[0].dettagliprelievo;
      this.isProdottoFinito =res[0].fromPianoConsegne;

    }
    );
  }

  RefreshMissioni(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.rowArticoli = this.rowArticoli;
  }

  onSubmit() {
    if (!this.formingresso.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
      return false;
    } else {
      this.loading = 'block';
      let req: Prelievo = this.formingresso.value;
      req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
      req.idPrelievo = this.idInterno == -1 ? 0 : this.idInterno;
      this.listarticoli.forEach(a => a.disabilitato = false);
      req.dettagliprelievo = this.listarticoli;
      req.idCliente = this.idCliente;

      //console.log(req);
      this.documentiService.InsPrelievo(req).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err == '0') {
          this.idInterno = +data[0].msg;
          this.getPrelievo(data[0].msg);
          return true
        } else {
          this.openSnackBar(data[0].msg);
          return false
        }
      });

      return true
    }
  }

  listarticoli: PrelievoDettagli[] = [];
  rowArticoli: PrelievoDettagli[] = [];

  public columnDefsArticoli: (ColDef | ColGroupDef)[] = [
    //{ field: 'gridIndex', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    // { field: 'stato', headerName: "Stato", width: 150, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'descrizione', headerName: "Descrizione", width: 250, filter: 'agTextColumnFilter' },
    { field: 'numUDC', headerName: "Udc", type: 'numberColumn', width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'qtaEvasa', headerName: "Qtà Evasa", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'qtaDaEvadere', headerName: "Qtà da Ev.", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'locazionesrgDESC', headerName: "Ub. Sorg.", type: 'numberColumn', width: 200, filter: 'agTextColumnFilter' },
    { field: 'locazionedstDESC', headerName: "Ub. Dest", type: 'numberColumn', width: 200, filter: 'agTextColumnFilter' },
    { field: 'ndoc', headerName: "Num Doc.", width: 200, filter: 'agTextColumnFilter' },
    { field: 'rifdoc', headerName: "Doc. Rif", width: 200, filter: 'agTextColumnFilter' },
  ];



  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
        //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
  }

  ngOnInit(): void {
    this.getPrelievo(this.id)
    this.idInterno = this.id;
  }

  openDialog(e: RowDoubleClickedEvent) {
    // let articolo = this.rowArticoli.find((val) => val.gridIndex == +e);
    // const dialogRef = this.dialog.open(DialogUscitaArticoloComponent, {
    //   data: {
    //     articolo
    //   },
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     result.articolo.utente = this.jwtHelper.decodeToken(localStorage.getItem('jwt')).name
    //     this.listarticoli = [result.articolo];
    //     this.onSubmit()
    //   }
    // });
  }

  openDialogSearchArticolo() {
    let IdCliente = this.formingresso.get('IdCliente').value;
    const dialogRef = this.dialog.open(DialogUscitaCercaArticoloComponent, {
      width: "80vw",
      data: {
        IdCliente
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.listarticoli = [result.articolo];
        this.onSubmit()
      }
    });
  }

  onAccetta() {
    if (this.idInterno > 0) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Accettare il Picking?',
          BtnSi: 'Conferma',
          BtnNo: 'Annulla'
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          // this.documentiService.InsUDCDoc(this.idInterno, type).subscribe(data => {
          //   this.loading = 'none';
          //   this.getDocumento(this.idInterno);
          //   if (type == 1)
          //     this.openSnackBar('Articoli associati a UDC');
          //   else
          //     this.openSnackBar('Documento Confermato');
          // });
        }
      });
    } else {
      this.openSnackBar('Salvare prima il Documento');
    }

  }

  onPrint(type) {
    if (this.idInterno > 0) {
      if (type == 1) {
        this.documentiService.StampaPicking(this.idInterno).subscribe((data) => {
          this.loading = 'none'
          if (data[0].err == '0') {
            this.printPDF(data[0].msg);
          } else {
            this.openSnackBar(data[0].msg);
          }
        });
      } else if (type == 2) {
        this.documentiService.StampaEtichetta(this.numeroOrdine).subscribe((data) => {
          this.loading = 'none'
          // if (data[0].err == '0') {
          //   this.printPDF(data[0].msg);
          // } else {
          //   this.openSnackBar(data[0].msg);
          // }
          for (var i = 0; i< data.length; i++)
            this.downloadPDF(data[i].contentBase64, data[i].fileName);
        });
      }
    } else {
      this.openSnackBar('Salvare prima il Documento');
    }
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  printPDF(pdf: any) {
    var fileblob = this.b64toBlob(pdf, 'application/pdf');
    var url = window.URL.createObjectURL(fileblob);

    window.open(url);

  }

  downloadPDF(pdf: any, name: string){
    var fileblob = this.b64toBlob(pdf, 'application/pdf');
    var url = window.URL.createObjectURL(fileblob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name + '.pdf';
    link.click();

    // Libera l'URL oggetto
    URL.revokeObjectURL(url);
  }

}
