<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Gruppo</mat-label>
        <mat-select [(ngModel)]="data.idGruppo">
            <mat-option *ngFor="let gruppo of data.gruppi" [value]="gruppo.idGruppo">
                {{gruppo.descrizione}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Famiglia</mat-label>
        <input matInput [(ngModel)]="data.descrizione">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
</div>