import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
import { LicenseManager } from '@ag-grid-enterprise/core'

LicenseManager.setLicenseKey("CompanyName=AllinONE SRL,LicensedApplication=Allinone,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-035429,SupportServicesEnd=1_December_2023_[v2]_MTcwMTM4ODgwMDAwMA==fe560556d173d19757c8bc74206f9b1f")


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
