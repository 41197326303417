<form [formGroup]="formingresso" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button> -->
            <button *ngIf="!this.fromDDT" class="button-tab-delete" type="button" title="Elimina" (click)="onDelete()">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button>
            <button class="button-print" type="button" (click)="onAnteprimaPrint(0)" *ngIf="!fromDDT">
                Anteprima di Stampa</button>
            <button class="button-confirm-row" type="button" (click)="onPrint(1)" *ngIf="!fromDDT">
                Stampa e Conferma</button>
            <button class="button-print" type="button" (click)="onAnteprimaPrint(0)" *ngIf="fromDDT">
                Ristampa</button>
            <button class="button-add-row" type="button" *ngIf="isProdottoFinito" (click)="creaTxt()">
                    Crea Tracciato DDT
                </button>
            <button class="button-add-row" type="button" (click)="stampaEtichette()" *ngIf="isProdottoFinito">  
                    Stampa Etichette</button>
            <button class="button-add-row" type="button" (click)="stampaFattura()" *ngIf="hasFattura">  
                    Scarica Fattura</button>

        </mat-card-actions>
        <table>
            <tr>
                <td style="border-right: 1px solid #c0c0c0;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr><td style="text-align: center;" colspan="3"><p class="text-grigio">Dati Generali</p></td></tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Cliente</mat-label>
                                    <mat-select name="IdCliente" formControlName="idCliente"
                                        (valueChange)="onChangeCliente($event)">
                                        <mat-option *ngFor="let cliente of clienti" [value]="cliente.idCliente">
                                            {{cliente.ragione_Sociale}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Tipo Doc.</mat-label>
                                    <mat-select name="TipoDocID" formControlName="tipoDocID">
                                        <mat-option *ngFor="let tipodocumento of tipodoc"
                                            [value]="tipodocumento.idtipologiadocumento">
                                            {{tipodocumento.descrizione}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Causale Doc.</mat-label>
                                    <mat-select name="IdCausale" formControlName="idCausale">
                                        <mat-option *ngFor="let causale of causali" [value]="causale.idCausale">
                                            {{causale.descrizione}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Numero Doc.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="NumDoc" formControlName="numDoc" [disabled]="true">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Numero Rif</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="nrif" formControlName="nRif">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Data Doc.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input type="date" matInput name="datadoc" formControlName="dataDoc">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Data Rif.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input type="date" matInput name="DataRif" formControlName="dataRif">
                                </mat-form-field>
                            </td>
                            
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>C/Ass.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput type="number" name="CAss" formControlName="cAss">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Valore Doc.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput type="number" name="ValoreDoc" formControlName="valoreDoc">
                                </mat-form-field>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width ">
                                    <mat-label>Valuta</mat-label>
                                    <mat-select name="id_Valuta" formControlName="id_Valuta">
                                        <mat-option *ngFor="let valuta of liste.valuta" [value]="valuta.id">
                                            {{valuta.descrizione}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            
                            <td>
                                <mat-checkbox type="checkbox" name="Esportato" color="primary" aria-readonly="true" formControlName="esportato">Esportato
                                </mat-checkbox>
                            </td>
                            <td>
                                <mat-checkbox type="checkbox" name="Bloccato" color="primary" formControlName="bloccato">Bloccato
                                </mat-checkbox>
                            </td>
                        </tr>
                    </table>
                </td>
                <td style="display: table;align-items: start;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr><td style="text-align: center;" colspan="3"><p class="text-grigio">Destinatario</p></td></tr>
                        <tr>
                            <td colspan="2">
                                <mat-form-field class="full-width">
                                    <mat-label>Destinatario</mat-label>
                                    <mat-select name="IdIntenstatario" formControlName="idIntenstatario"
                                        (valueChange)="onChangeIntestatario($event)">
                                        <mat-option *ngFor="let intestatario of intestatari" [value]="intestatario.idCliente">
                                            {{intestatario.ragione_Sociale}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Ragione Sociale</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="RagioneSociale" formControlName="ragioneSociale">
                                </mat-form-field>
                            </td>
                            <td>
                            <mat-form-field class="full-width">
                                <mat-label>Indirizzo</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="Indirizzo" formControlName="indirizzo">
                            </mat-form-field>
                        </td>
                    </tr>
                        <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>CAP</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="cap" formControlName="cap">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Località</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="Localita" formControlName="localita">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Provincia</mat-label>
                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                            <input matInput name="Provincia" formControlName="provincia">
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Nazione</mat-label>
                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                            <input matInput name="Nazione" formControlName="nazione">
                        </mat-form-field>
                    </td>
                </tr>
                    </table>
                </td>
            </tr>
        </table>
        <mat-tab-group backgroundColor="primary" [selectedIndex]="selected.value"
            (selectedIndexChange)="selected.setValue($event)">
            <mat-tab label="Articoli">
                <div>
                    <!-- <button class="button-add-row" type="button" (click)="openArticoloSearchDialog()">Aggiungi
                        Riga</button>
                    <button class="button-delete-row" type="button">Elimina
                        Riga</button> -->
                    
                </div>
        
                <!-- <button class="button-add-row" type="button" (click)="onConfirm()">
                                        Conferma UDC</button> -->
                <app-grid (onDblClick)="openArticoloDialog($event)" [columnDefs]="columnDefsArticoli"
                    [defaultColDef]="defaultColDef" [rowData]="rowArticoli"
                    (gridReady)="RefreshArticoli($event)">
                </app-grid>
            </mat-tab>
            <mat-tab label="Info Spedizione">
                <table class="full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Targa</mat-label>
                                <input matInput name="Targa" formControlName="targa">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Porto</mat-label>
                                <mat-select name="porto" formControlName="porto">
                                    <mat-option value="Franco">
                                        Franco
                                    </mat-option>
                                    <mat-option value="Assegnato">
                                        Assegnato
                                    </mat-option>
                                    <mat-option value="Franco Destino">
                                        Franco Destino
                                    </mat-option>
                                    <mat-option value="Franco Frontiera IT">
                                        Franco Frontiera IT
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Colli</mat-label>
                                <input matInput type="number" formControlName="colli">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Peso (kg)</mat-label>
                                <input matInput type="number" formControlName="peso">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Volume (m^3)</mat-label>
                                <input matInput type="number" formControlName="volume">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Telefono</mat-label>
                                <input matInput formControlName="telefono">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Data Cons.</mat-label>
                                <input type="date" matInput [disabled]="true" formControlName="dataConsegna">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Contatto</mat-label>
                                <input matInput formControlName="contatto">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Vettore</mat-label>
                                <mat-select name="CodVettore" formControlName="codVettore">
                                    <mat-option *ngFor="let vettore of vettori" [value]="vettore.codVettore">
                                        {{vettore.ragione_Sociale}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Contratto Vettore</mat-label>
                                <mat-select formControlName="contrattoVettoreId">
                                    <!-- <mat-option *ngFor="let nazione of nazioni" [value]="nazione.sigla">
                                        {{nazione.descrizione}}
                                    </mat-option> -->
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Incasso C/Ass</mat-label>
                                <mat-select formControlName="pagamentoId">
                                    <mat-option *ngFor="let pagamento of liste.pagamenti" [value]="pagamento.id">
                                        {{pagamento.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Natura Merce</mat-label>
                                <input matInput formControlName="naturaMerce">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Rif. Vettore</mat-label>
                                <input matInput formControlName="rifVettore">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <mat-form-field class="full-width">
                                <mat-label>Note</mat-label>
                                <textarea formControlName="note" matInput rows="3"></textarea>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>

            </mat-tab>
            <mat-tab label="Ordini">
                <div mat-subheader>Lista di Ordini nel Documento</div>
                <mat-list role="list" *ngFor="let ordine of ordini">
                        <mat-list-item role="listitem"> - {{ordine}}</mat-list-item>
                  </mat-list>
            </mat-tab>
        </mat-tab-group>

    </mat-card>
</form> 