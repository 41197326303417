import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowClickedEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Ingresso } from 'src/app/Models/Ingresso';
import { UDC } from 'src/app/Models/UDC';
import { DocumentiService } from 'src/app/services/documenti.service';
import { Tab } from '../ingresso/ingresso.component';
import { DialogDocumentoComponent } from '../../dialog-documento/dialog-documento.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogEditVarianteComponent } from '../../dialog-edit-variante/dialog-edit-variante.component';
import { DialogRettificaUdcComponent } from '../../dialog-rettifica-udc/dialog-rettifica-udc.component';
import { DialogSpostaUDCComponent } from '../../dialog-sposta-udc/dialog-sposta-udc.component';
import { MagazzinoService } from 'src/app/services/magazzino.service';


@Component({
  selector: 'app-udc-edit',
  templateUrl: './udc-edit.component.html',
  styleUrls: ['./udc-edit.component.scss']
})
export class UdcEditComponent implements OnInit {

  form: FormGroup = new FormGroup({
    idUDC: new FormControl(''),
    NomeUDC: new FormControl(''),
    data: new FormControl(''),
  })

  @Input() id: number = 0;
  @Input() ndoc: string = '';
  @Input() livelloID: number = -1;
  @Input() tab: Tab = { id: 0, nome: "", numero: '' };

  public loading: string = 'none';

  constructor(private _snackBar: MatSnackBar,
  private documentiService: DocumentiService,
  public dialog: MatDialog,) { }

  selected = new FormControl(0);
  public rowData$!: any[];
  public rowDataGia$!: any[];
  public rowDataMov$!: any[];
  public rowDataVar$!: any[];
  idDoc: number = 0;
  isAbbinato: boolean= false;

  public columnDefsUDC: (ColDef | ColGroupDef)[] = [
    // { field: 'DocumentoID', headerName: "ID", width: 100, type: 'numberColumn'},
    { field: 'numDoc', headerName: "Numero Documento", width: 320, filter: 'agTextColumnFilter' },
    { field: 'dataDoc', headerName: "Data Documento", width: 320, type: 'dateColumn' },
    { field: 'causale', headerName: "Tipo Documento", width: 200, filter: 'agTextColumnFilter' },
    { field: 'ragioneSociale', headerName: "Ragione Sociale", width: 320, filter: 'agTextColumnFilter' },
    // { field: 'Localita', headerName: "Località", width: 200 },
    // { field: 'Indirizzo', headerName: "Indirizzo", width: 200 },
    // { field: 'Provincia', headerName: "Provincia", width: 200 },
    // { field: 'Nazione', headerName: "Nazione", width: 200 },
    // { field: 'cap', headerName: "CAP", width: 100 },
    // { field: 'DataConsegna', headerName: "Data Consegna", width: 200, type: 'dateColumn' },
    // { field: 'Peso', headerName: "Peso", width: 200, type: 'numberColumn' },
    // { field: 'Volume', headerName: "Volume", width: 200, type: 'numberColumn' },
    // { field: 'Email', headerName: "Email", width: 200 },
    // { field: 'Telefono', headerName: "Telefono", width: 200 },
  ];

  public columnDefsUDM: (ColDef | ColGroupDef)[] = [
    // { field: 'idUDC', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true},
    { field: 'nomeUDC', headerName: "Nome UDC", width: 320, filter: 'agTextColumnFilter' },
    { field: 'data', headerName: "Data", width: 320, type: 'dateColumn' },
  ];

  public columnDefsGia: (ColDef | ColGroupDef)[] = [
    // { field: 'Id_giacenza', headerName: "ID", width: 100, type: 'numberColumn'},
    
    { field: 'locazionesrg', headerName: "Ubicazione", width: 320, filter: 'agTextColumnFilter',checkboxSelection:true },
    { field: 'partNumber', headerName: "Part Number", width: 320, filter: 'agTextColumnFilter'},
    { field: 'descrizione', width: 320, filter: 'agTextColumnFilter'},
    { field: 'qta',headerName: "Qta TOT", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'qtaDisponibile',headerName: "Qta Disponibile", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'qtaImpegnata',headerName: "Qta Impegnata", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'note', headerName: "Note", width: 320, filter: 'agTextColumnFilter'},
  ];

  public columnDefsMov: (ColDef | ColGroupDef)[] = [
    { field: 'data', width: 200, type: 'dateColumn'},
    { field: 'barcodelocazione', headerName: "Ubicazione", width: 320, filter: 'agTextColumnFilter' },
    { field: 'causale', headerName: "Causale", width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'numDoc', headerName: "Numero Documento", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta',headerName: "Qta TOT", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'utente', headerName: "Utente", width: 200, filter: 'agTextColumnFilter'},
    { field: 'note', headerName: "Note", width: 320, filter: 'agTextColumnFilter'},
  ];

  public columnDefsVar: (ColDef | ColGroupDef)[] = [
    { field: 'nomeVariante', headerName: "Nome Variante", width: 200, filter: 'agTextColumnFilter' },
    { field: 'variante', headerName: "Variante", width: 200, filter: 'agTextColumnFilter' },
  ];
  public columnDefs : (ColDef | ColGroupDef)[] = this.columnDefsUDC;
  idtipo:number=1;

  public label : string= 'UDC';


  onChangeTipo(e : number){
    this.idtipo=e;
    if(this.idtipo == 1){
      this.columnDefs = this.columnDefsUDC;
      this.label = 'UDC';
      this.reloadUDCData();
    }else if(this.idtipo == 0){
      this.label = 'UDM';
      this.columnDefs = this.columnDefsUDM;
      this.reloadUDMData();
    }
    this.idDoc = 0;
    (document.querySelector('#selectedRows') as any).innerHTML = ''
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
  }

  isAdmin: boolean= false;

  ngOnInit(): void {

    if(this.ndoc != '-1'){
      this.isAbbinato= true;
    }
    this.idDoc=this.id;

    if(this.livelloID == 1)
      this.isAdmin=true;
  

  }
  onEditVariante(idVariante:number=0){
    let element = this.rowDataVar$.find((e) => e.gridIndex == idVariante);

    const dialogRef = this.dialog.open(DialogEditVarianteComponent, {
        data: {
          valore: element.Valore,
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.documentiService.Upd_VariantiXUDC(this.idDoc, element.note, result.valore).subscribe((res) => {
            if (res[0].err == '0') {
              this.reloadVarianti();
              this.openSnackBar(res[0].msg);
            } else {
              this.openSnackBar(res[0].msg);
            }
          });

        }
      });
  }

  // getDocumento(id: number) {
  //   this.documentiService.GetUDC(id).subscribe((res) => {
  //     this.form = new FormGroup({
  //       idUDC: new FormControl(res[0].idUDC),
  //       NomeUDC: new FormControl(res[0].NomeUDC, Validators.required),
  //       data: new FormControl(res[0].data, Validators.required),
  //     })
  //   })

  // }

  // onSubmit() {
  //   if (!this.form.valid) {
  //     this.openSnackBar('Compila i campi obbligatori prima di continuare');
  //   } else {
  //     this.loading = 'block';
  //     let req: UDC = this.form.value;
  //     //req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
  //     this.documentiService.InsUDC(req).subscribe(data => this.loading = 'none');
  //     this.openSnackBar(req.idUDC == 0 ? 'Documento salvato' : 'Documento modificato');
  //   }
  // }

  onSave() {
    this.loading = 'block';
    if (this.idDoc == 0) {
      this.documentiService.InsUDC(1).subscribe(data => this.loading = 'none');
    } else {
      this.documentiService.InsUDCDoc(this.idDoc, 0).subscribe(data => this.loading = 'none');
    }
    this.openSnackBar('UDC salvato');
  }

  OpenDoc(e: RowDoubleClickedEvent) {
    //if(this.isAdmin){
    const dialogRef = this.dialog.open(DialogDocumentoComponent, {
      width: "80vw",
      height: window.innerHeight - 10 + "px",
      data: {
        id: e.data.documentoID,
        tipo: e.data.tipoDocID
      },
    });

    dialogRef.afterClosed().subscribe(result => {

    })
  //}
  }
  
  

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('it-IT')
       //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;
  onGridReady(params: GridReadyEvent) {
    if (!this.gridApi) {
      this.gridColumnApi = params.columnApi;
      this.gridApi = params.api;
      if (this.isAbbinato) {
        if (this.livelloID == 1)
          this.reloadGiacenza()
        else {
          this.reloadMovimento()
        }
      } else {
        this.reloadUDCData();
      }
    }
    // if(!this.gridsLoaded)
    //   this.reloadGrids()

  }

  onSpostaUDC(){
    let selection = this.gridApi.getSelectedRows();
    if (selection.length > 0) {
      selection = selection.map((ele) => ele.id_giacenza)
      const dialogRef = this.dialog.open(DialogSpostaUDCComponent, {
        data: {
          UDCs: selection.join(),
          iddoc: this.idDoc,
          idlocazione: 0,
        },
        width: '500px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.documentiService.SpostaUDC(selection.join(), result.idlocazione).subscribe((data) => {
            if (data[0].err == '0') {
              this.reloadGiacenza()
            } else {
              this.openSnackBar(data[0].msg)
            }
          })
        }
      })
    } else if (selection.length == 0) {
      this.openSnackBar('Selezionare una riga prima di continuare')
    } 
  }

  private gridApi!: GridApi<any>;

  openRettifica() {
    //let row = e.data;
    let selection = this.gridApi.getSelectedRows();
    console.log(selection)
    //selection = selection.map((ele)=> ele.Id_giacenza)
    if(selection.length==1){
    const dialogRef = this.dialog.open(DialogRettificaUdcComponent, {
      data: {
        id:this.idDoc,
        num: 0,
        partnumber: selection[0].PartNumber,
        Note: '',
        causale: 0,
      },
      width: "300px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        selection[0].Qta = result.num;
        selection[0].PartNumber = result.partnumber;
        selection[0].Note= result.Note;
        selection[0].NumUDC = '';
        selection[0].Variante = '';
        selection[0].Varianti = [];
        selection[0].ndoc = '';

        //console.log(row)
        this.documentiService.RettificaUDC(selection[0], result.causale).subscribe((data)=>{
          this.reloadGiacenza()
          if(data[0].err != '0'){
            this.openSnackBar(data[0].msg)
          }
        })
      }
    });
  }else if(selection.length==0){
    this.openSnackBar('Selezionare una riga prima di continuare')
  }else if(selection.length>1){
    this.openSnackBar('Seleziona una singola riga')
  }
}

  gridload(e: any){
    //console.log(e.tab.textLabel);
    switch (e.tab.textLabel) {
      case 'Giacenze':
        this.reloadGiacenza();
        break;
      case 'Varianti':
        this.reloadVarianti();
        break;
      case 'Movimenti':
        this.reloadMovimento();
        break;
      case 'Documenti':
        this.reloadUDCData();
        break;
        default: break
    }


  }

  gridsLoaded: boolean=false;
  reloadGrids(){
    this.reloadUDCData()
    this.reloadGiacenza()
    this.reloadMovimento()
    this.reloadVarianti()
    this.gridsLoaded = true;
  }

  reloadUDCData() {
    this.loading = 'block';
    this.documentiService.GetNoUDC(this.idDoc,14,0).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  reloadUDMData() {
    this.loading = 'block';
    this.documentiService.GetUDCnoUDM(14).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  reloadGiacenza() {
    this.loading = 'block';
    this.documentiService.GetGiacenze(this.idDoc).subscribe((data) => {
      this.rowDataGia$ = data
      this.loading = 'none';
    });

  }

  reloadMovimento() {
    this.loading = 'block';
    this.documentiService.GetMovimenti(this.idDoc,0).subscribe((data) => {
      this.rowDataMov$ = data
      this.loading = 'none';
    });

  }
  reloadVarianti() {
    this.loading = 'block';
    this.documentiService.GetVarianti(this.idDoc).subscribe((data) => {
      this.rowDataVar$ = data
      this.loading = 'none';
    });

  }
}
