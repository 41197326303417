import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-ingresso-udc',
  templateUrl: './dialog-ingresso-udc.component.html',
  styleUrls: ['./dialog-ingresso-udc.component.scss']
})
export class DialogIngressoUdcComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogIngressoUdcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }
  isError=false;
  onSave(){
      this.data.Elimina = 0;
      this.dialogRef.close(this.data)
  }

  onElimina(){
    this.data.Qta = 0;
    this.data.Elimina = 1;
    this.dialogRef.close(this.data)
    
  }

}
