import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  tabs = ['Clienti'];
  selected = new FormControl(0);
  constructor() { }
  loading: string = 'none';
  ngOnInit(): void {
  }

  addTab() {
    this.tabs.push('New');

    //if (selectAfterAdding) {
    this.selected.setValue(this.tabs.length - 1);
    //}
  }

}
