import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, ColGroupDef, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { MagazzinoService } from 'src/app/services/magazzino.service';

export class DialogData{
  idlocazione: number=0;
  UDCs: string='';
  iddoc: number = 0;
}

@Component({
  selector: 'app-dialog-sposta-udc',
  templateUrl: './dialog-sposta-udc.component.html',
  styleUrls: ['./dialog-sposta-udc.component.scss']
})
export class DialogSpostaUDCComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSpostaUDCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private magazzinoService: MagazzinoService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  locazioni: any= [];

  ngOnInit(): void {
    
  }

  onGridReady(params: GridReadyEvent) {
    this.magazzinoService.GetLocazioniSpostamento(this.data.iddoc).subscribe((data)=>{
      this.locazioni = data;
      //console.log('locazioni prese da GetLocazioniSpostamento', this.locazioni)
    })
  }

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('it-IT')
       //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'nome',headerName: "Ubicazione", filter: 'agTextColumnFilter', width:400},

  ];
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  onSposta(e: RowDoubleClickedEvent){
    this.data.idlocazione= e.data.id_Locazione;
    this.dialogRef.close(this.data);
  }

}
