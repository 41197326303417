<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
</div>
<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)"
    (selectedTabChange)="changeTab($event)">
    <mat-tab [label]=label>
        <div class="card">
            <mat-card-actions align="start">
                <button class="button-tab-save" (click)="openMagazzinoDialog()" title="Aggiungi">
                    <mat-icon style="font-size:20px">add</mat-icon>
                </button>
                <button class="button-tab-undo" (click)="refreshGrid()" title="Ricarica">
                    <mat-icon style="font-size:20px">refresh</mat-icon>
                </button>
            </mat-card-actions>
            <div id="div_list_table">
                <div style="width:20%">
                    <div id="div_list">
                        <div id="header_div_list">
                            <b>Seleziona un Magazzino</b>
                        </div>
                        <!-- <mat-nav-list>
                            <mat-list-item class="" *ngFor="let magazzino of listMagazzini" >
                                <a matLine (click)="reloadData(magazzino.Id_Magazzino)">{{magazzino.Descrizione}}
                                </a>
                                <button type="button" (click)="openMagazzinoDialog(magazzino.Id_Magazzino)" mat-icon-button>
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-nav-list> -->
                        <ng-template #iconTemplate let-node="node" let-class="class">
                            <mat-icon [ngSwitch]="treeControl.getLevel(node)" class="mat-icon-rtl-mirror" [ngClass]="class">
                                <ng-template [ngSwitchCase]="0">
                                    warehouse
                                </ng-template>
                                <ng-template [ngSwitchCase]="1">
                                  shelves
                                </ng-template>
                                <ng-template [ngSwitchCase]="2">
                                    pallet
                                </ng-template>
                                
                                <ng-template ngSwitchDefault>
                                 
                                </ng-template>
                              </mat-icon>
                            </ng-template>
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                            
                            <mat-tree-node *matTreeNodeDef="let node" style="padding:0" >
                              
                              <ng-container *ngTemplateOutlet="iconTemplate; context: { node: node, class:'tree-icon-empty' }"></ng-container>
                              {{node.name}}
                            </mat-tree-node>
                            
                            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" class="voce" matTreeNodePadding>
                              <a matTreeNodeToggle
                                      [attr.aria-label]="'Toggle ' + node.name">
                                <ng-container *ngTemplateOutlet="iconTemplate; context: { node: node, class:'tree-icon-fill' }"></ng-container>
                               


                                {{node.name}}
                            </a>
                            </mat-tree-node>
                          </mat-tree>
                    </div>
                </div>
                <div style="width:80%">
                    <button class="button-add-row" type="button" (click)="openRigaMagazzinoDialog()">Aggiungi
                        Riga</button>
                    <ag-grid-angular style="width: 100%; height: 80vh" class="ag-theme-alpine"
                        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData$"
                        [rowSelection]="'multiple'" [animateRows]="true" (gridReady)="onGridReady($event)"
                        [columnTypes]="columnTypes" (rowDoubleClicked)="
                                                            openRigaMagazzinoDialog($event)">
                    </ag-grid-angular>
                    <!-- <app-grid [columnDefs]="columnDefs" 
            [defaultColDef]="defaultColDef" [rowData]="rowData$" (gridReady)="onGridReady($event)" (onDblClick)="openRigaMagazzinoDialog($event)" style='width: 100%; height: 80vh'
            >
        </app-grid> -->
                </div>
            </div>
        </div>
    </mat-tab>
    <!-- <ng-container *ngFor="let tab of tabs; let index = index;">
        <mat-tab *ngIf="searchTab(tab.id!)">
            <ng-template mat-tab-label>
                {{tab.id + ' ' + tab.nome}}
                <button mat-icon-button (click)="closeTab(tab.id!)">
                    <mat-icon>close</mat-icon>
                </button>
            </ng-template>
           

        </mat-tab>
    </ng-container> -->
</mat-tab-group>