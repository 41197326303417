import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { CustomerService } from 'src/app/services/customer.service';
export class Tab {
  id: number | null = 0;
  nome: string = "";
}
@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.scss']
})
export class ClientiComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: any;
  label = "Lista"
  tabs: Tab[] = [];
  clienteID: number = 0;

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.reloadData()
  }

  refreshGrid() {
    this.reloadData()
  }
  reloadData() {
    this.loading = 'block';

    this.customerService.GetEnte(0).subscribe((data)=>{
        this.rowData$ = data
        this.loading = 'none';
    })

    // this.http
    //   .get<Cliente[]>("https://testapiall1.azurewebsites.net/api/enti/get/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo Cliente'
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.clienteID = -1;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    console.log(e)
    // if (!this.tabs.some((x) => x.id == +e)) {
    //   let obj = this.rowData$.find((value) => value.IdCliente == +e);
    //   let row = {
    //     id: obj.IdCliente,
    //     nome: obj.Ragione_Sociale
    //   }
    //   this.tabs.push(row);
    //   // this.data.Articolo_Id = obj.Articolo_Id || 0;
    //   // this.data.Descrizione = obj.Descrizione;
    //   this.clienteID = obj.IdCliente;
    // }

    // this.selected.setValue(this.tabs.findIndex((x) => x.id == +e) + 1);

    if (!this.tabs.some((x) => x.id == e.data.IdCliente)) {
        
        let row = {
          id: e.data.IdCliente,
          nome: e.data.Ragione_Sociale // Ragione_Sociale ? ragione_Sociale
        }
        this.tabs.push(row);
        // this.data.Articolo_Id = e.data.Articolo_Id || 0;
        // this.data.Descrizione = e.data.Descrizione;
        this.clienteID = e.data.IdCliente;
      }
  
      this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.IdCliente) + 1);

  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'idCliente', headerName: "ID Cliente", hide: true }, // Colonna invisibile per tenere traccia dell'ID
    //{ field: 'IdCliente', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'Ragione_Sociale', headerName: "Ragione Sociale", width: 320,filter: 'agTextColumnFilter' },
    { field: 'Piva', headerName: "P.IVA", width: 200,filter: 'agTextColumnFilter' },
  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  constructor(private http: HttpClient, private customerService: CustomerService) { }

  ngOnInit(): void {
  }

}
