<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Reparto</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.reparto">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Settore</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.settore">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Scaffale</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.scaffale">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Montante</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.montante">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Lato</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.lato">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Ripiano</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.ripiano">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Cella</mat-label>
        <!-- <input matInput [(ngModel)]="data.Descrizione"> -->
        <input matInput [(ngModel)]="data.cella">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
</div>