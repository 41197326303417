import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PianoConsegna } from '../Models/PianoConsegna';
import { PrenotazionePresa } from '../Models/PrenotazionePresa';

@Injectable({
  providedIn: 'root'
})
export class ProdottofinitoService {

  private apiUrl1 = environment.apiUrl;
  constructor(private http: HttpClient) { }

  GetPianoConsegne(data = null, dateTo = null): Observable<PianoConsegna[]> {
    //data yyyy-mm-dd
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    let dateToStr = dateTo ? (data ? "&" : "?") + 'dateTo=' + dateTo : '';

    const url = this.apiUrl1 + `${"/PianoConsegne/GetList" + (data ? "?date="+data + dateToStr : dateToStr)}` ;
    return this.http.get<PianoConsegna[]>(url, httpOptions);
  }

  GetPianoConsegnaDetailUDC(status: number, partNumber: string, numOrdine: string): Observable<any[]> {
    //data yyyy-mm-dd
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };

    const url = this.apiUrl1 + `${"/PianoConsegne/GetPianoConsegnaDetailUDC?partNumber=" + partNumber + "&status=" + status + "&numOrdine=" + numOrdine}` ;
    return this.http.get<any[]>(url, httpOptions);
  }

  GetPrenotazionePresa(data: Date = null): Observable<PrenotazionePresa[]> {
    //data yyyy-mm-dd
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/PrenotazionePrese/GetList"+ (data ? "?date="+data : '')}` ;
    return this.http.get<PrenotazionePresa[]>(url, httpOptions);
  }


  InsertOrdineFromPianoConsegne(items : any[]): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/PianoConsegne/InsertOrdineFromPianoConsegne";
    return this.http.post<any>(url, items, httpOptions);
  }

  UpdateConferma(ids : any[]): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/PrenotazionePrese/UpdateConferma";
    return this.http.put<any>(url, ids, httpOptions);
  }

  InsCNT(numpro: string, codcli: string, codspe: string, ordoer:string, udc: string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/PianoConsegne/InsCNT?NUM_PRO=" + numpro + "&COD_CLI=" + codcli + "&COD_SPE=" + codspe + "&ORD_OER=" + ordoer + "&UDC=" + udc;
    return this.http.post<any>(url, httpOptions);
  }

}
