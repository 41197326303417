import { Injectable } from '@angular/core';  
import {HttpClient, HttpErrorResponse} from '@angular/common/http';  
import {HttpHeaders} from '@angular/common/http';  
import { interval, take, shareReplay, Observable } from 'rxjs'
import { Register } from '../Models/Register';
import { AuthenticatedResponse } from '../Models/AuthenticatedResponse';
import { LoginModel } from '../Models/LoginModel';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Reparto } from '../Models/Reparto';
import { environment } from 'src/environments/environment';
import { Gruppo } from '../Models/Gruppo';



@Injectable({  
  providedIn: 'root'  
})  
export class LoginService {  
  private apiUrl1 = environment.apiUrl;
  Url :string;  
  token : string;  
  header : any;  
  
  constructor(private http : HttpClient,private router: Router, private jwtHelper: JwtHelperService) {   
    
    this.Url = 'https://testapiall1.azurewebsites.net/api/login';  

    const headerSettings: {[name: string]: string | string[]; } = {};  
    this.header = new HttpHeaders(headerSettings); 
  }  

  login(credentials: LoginModel): Observable<AuthenticatedResponse> {
    //const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
      })
    };
    const url = this.apiUrl1 + "/Users/authenticate";
    return this.http.post<AuthenticatedResponse>(url, credentials,httpOptions);
  } 
  
  refreshToken(token: string): Observable<AuthenticatedResponse>{
    return this.http.get<AuthenticatedResponse>(this.apiUrl1 + "/login/RefreshToken/?id=" + token, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    })
  }

  GetReparto(): Observable<Reparto[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/utenti/getreparti";
    return this.http.get<Reparto[]>(url, httpOptions);
  }

  ResetPassword(credentials: any): Observable<any>{
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      }),
    };
    const url = this.apiUrl1 + "/Users/ResetPassword";
    return this.http.post<Reparto[]>(url, credentials,httpOptions);
  }

  
  
}  