<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Area</mat-label>
        <mat-select [(ngModel)]="data.Id_Locazione">
            <mat-option *ngFor="let area of data.aree" [value]="area.Id_Locazione">
                {{area.Reparto}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>Task</mat-label>
        <input matInput type="number" [(ngModel)]="data.Task">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
</div>