import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, tap } from 'rxjs';
import { LoginModel } from '../Models/LoginModel';
import { environment } from 'src/environments/environment';
import { User } from '../Models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  user: User;
  isLogged : boolean = false;

  constructor(private http: HttpClient) { }


  // createUser(Idsito:number,Role:string,userid:number,name:string, _exp:string,_token:string ){
  //   localStorage.setItem("jwt", _token);
  //   this.user = new User(Idsito,Role,userid,name,_exp,_token);
  //   this.isLogged = true
  // }

  createUserAlt(id:string,roleName:string,idUtente:number,firstName:string,lastName:string,username:string, expiresIn:string,token:string ){
    localStorage.setItem("jwt", token);
    this.user = new User(id,roleName,idUtente,firstName,lastName,username,expiresIn,token);
    this.isLogged = true
  }

  get User(){
    return this.user
  }

  logout(){
    this.isLogged=false;
    this.user = null;
    localStorage.removeItem('jwt');
  }

  // login(): Observable<any> {
    
  // }

  // logout(): any {
  //   this._currentUser.next(null)
  // }

  // authenticate(): Observable<any> {
  //   return this.http.get(`/user/verify`).pipe(
  //     tap((user: any) => this._currentUser.next(user)),
  //     catchError(() => {
  //       this._currentUser.next(null);
  //       return [null];
  //     })
  //   );
  // }
}
