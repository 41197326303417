import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogData{
  qtaudc: string;
}

@Component({
  selector: 'app-dialog-stampa-udc',
  templateUrl: './dialog-stampa-udc.component.html',
  styleUrls: ['./dialog-stampa-udc.component.scss']
})
export class DialogStampaUdcComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogStampaUdcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }

}
