import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Famiglia } from '../Models/Famiglia';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class FamigliaService {

  private apiUrl1 = environment.apiUrl;
  constructor(private http: HttpClient) { }

  GetFamiglia(id: number): Observable<Famiglia[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/articoli/GetFamiglie/?id=" + id}`;
    return this.http.get<Famiglia[]>(url, httpOptions);
  }

  InsFamiglia(famiglia: Famiglia): Observable<Famiglia> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/Updins_Famiglie";
    return this.http.put<Famiglia>(url, famiglia, httpOptions);
  }
}
