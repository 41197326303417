<tr>
    <td>
        <mat-form-field class="full-width">
            <mat-label>Variante</mat-label>
            <input matInput name="variante" formControlName="variante">
        </mat-form-field>
    </td>
    <td>
        <mat-form-field class="full-width">
            <mat-label>Valore</mat-label>
            <input type="number" matInput name="valore" formControlName="valore">
        </mat-form-field>
    </td>
    <td style="width:100%">
        <mat-form-field class="full-width">
            <mat-label>Note</mat-label>
            <textarea matInput name="noteVariante" formControlName="noteVariante"></textarea>
        </mat-form-field>
    </td>
</tr>