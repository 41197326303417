<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
</div>
<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)"
    (selectedTabChange)="changeTab($event)">
    <mat-tab [label]=label>
        <div class="card">
            <mat-card-actions align="start">
                <button class="button-tab-save" (click)="openReparto()" title="Aggiungi">
                    <mat-icon style="font-size:20px">add</mat-icon>
                </button>
                <button class="button-tab-undo" (click)="refreshGrid()" title="Ricarica">
                    <mat-icon style="font-size:20px">refresh</mat-icon>
                </button>
            </mat-card-actions>
            <!-- (onDblClick)="openReparto($event)" -->
            <app-grid [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowData]="rowData$" (gridReady)="onGridReady($event)"
            ></app-grid>

        </div>
    </mat-tab>
    <!-- <ng-container *ngFor="let tab of tabs; let index = index;">
        <mat-tab *ngIf="searchTab(tab.id!)">
            <ng-template mat-tab-label>
                {{tab.nome}}
                <button mat-icon-button (click)="closeTab(tab.id!)">
                    <mat-icon>close</mat-icon>
                </button>
            </ng-template>
            <app-gruppo-utenti-edit [id]="IdGruppo"></app-gruppo-utenti-edit>

        </mat-tab>
    </ng-container> -->
</mat-tab-group>