import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { DialogSitoComponent } from '../dialog-sito/dialog-sito.component';
import { MenuService } from 'src/app/services/menu.service';

export class Tab {
  id: number | null = 0;
  nome: string = "";
}
@Component({
  selector: 'app-siti',
  templateUrl: './siti.component.html',
  styleUrls: ['./siti.component.scss']
})
export class SitiComponent implements OnInit {

  loading: string = 'none';
  selected = new FormControl(0);
  public gridColumnApi!: ColumnApi;
  label = "Lista";
  public rowData$!: any[];
  tabs: Tab[] = [];
  IdGruppo : number;
  public closedTabs: Array<any> = [];

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;

  constructor(private http: HttpClient, public dialog: MatDialog, private menuService: MenuService) {
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData(0)
    }
  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  newTab(){
    let row = {
      id: 0,
      nome: 'Nuovo Reparto'
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.IdGruppo = -1;
    this.selected.setValue(this.tabs.length);
  }

  addTab(e: RowDoubleClickedEvent){
    if (!this.tabs.some((x) => x.id == +e)) {
      let obj = this.rowData$.find((value) => value.idGruppo == +e);
      let row = {
        id: obj.idGruppo,
        nome: obj.nome
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = obj.Articolo_Id || 0;
      // this.data.Descrizione = obj.Descrizione;
      this.IdGruppo = obj.idGruppo;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == +e) + 1);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.reloadData(0)
  }

  refreshGrid() {
    this.reloadData(0)
  }
  reloadData(id : number) {
    this.loading = 'block';
    this.Elenco();
  }

  Elenco(){
    this.menuService.GetSito().subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });
  }



  openReparto(e: RowDoubleClickedEvent = null){
    
    const dialogRef = this.dialog.open(DialogSitoComponent, {
      width: '250px',
      data: {
        IdSito: 0,
        Nome: '',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'IdGruppo', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'nome', headerName: "Nome", width: 200, filter: 'agTextColumnFilter' },
    
  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };


  ngOnInit(): void {
    this.Elenco();
  }
}
