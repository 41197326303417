import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Articolo } from 'src/app/Models/Articolo';
import { Giacenza } from 'src/app/Models/Giacenza';
import { PrelievoDettagli } from 'src/app/Models/PrelievoDettagli';

export class DialogData{
  isUDC:boolean;
  articolo: PrelievoDettagli;
  note: string
}

@Component({
  selector: 'app-dialog-uscita-articolo',
  templateUrl: './dialog-uscita-articolo.component.html',
  styleUrls: ['./dialog-uscita-articolo.component.scss']
})
export class DialogUscitaArticoloComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogUscitaArticoloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.data.articolo.disabilitato = false;
  }

  onElimina(){
    this.data.articolo.disabilitato = true;
    this.dialogRef.close(this.data)
  }

}
