import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { Gruppo } from 'src/app/Models/Gruppo';
import { DialogGruppoComponent } from '../dialog-gruppo/dialog-gruppo.component';
import { GruppoService } from 'src/app/services/gruppo.service';

@Component({
  selector: 'app-gruppo',
  templateUrl: './gruppo.component.html',
  styleUrls: ['./gruppo.component.scss']
})
export class GruppoComponent implements OnInit {
  loading: string = 'none';
  selected = new FormControl(0);
  public gridColumnApi!: ColumnApi;
  label = "Lista";
  public rowData$!: any[];

  constructor(private http: HttpClient, public dialog: MatDialog, private gruppoService: GruppoService) {
  }

  openGruppoDialog(e : RowDoubleClickedEvent = undefined): void {
    let res = this.rowData$.find((value) => value.gridIndex == +e);
    const dialogRef = this.dialog.open(DialogGruppoComponent, {
      width: '250px',
      data: {
        IdGruppo: res?.IdGruppo || 0,
        Descrizione: res?.Descrizione || '',
        gridIndex: res?.gridIndex || 0
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let obj: Gruppo = {
          idSito: 14,
          idGruppo: result.IdGruppo,
          descrizione: result.Descrizione,
          disabilitato: false,
          gridIndex : result.IdGruppo
        }
        this.gruppoService.InsGruppo(obj).subscribe(() => this.refreshGrid());
      }
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.reloadData(0)

  }

  refreshGrid() {
    this.reloadData(0)
  }
  reloadData(id : number) {
    this.loading = 'block';
    // this.magazzinoService.GetLocazioni(id).subscribe((data) => {
    //   this.rowData$ = data
    //   this.loading = 'none';
    // });
    this.Elenco();
  }

  listGruppi: Gruppo[] = [];

  Elenco(){
    this.gruppoService.GetGruppo(0).subscribe((data) => {
        this.rowData$ = data
        this.loading = 'none';
      });
  }


  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'IdGruppo', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'descrizione', headerName: "Gruppo", width: 320 , filter: 'agTextColumnFilter'},
  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };


  ngOnInit(): void {
    this.Elenco();
  }


}
