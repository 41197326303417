import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import { DocumentiService } from 'src/app/services/documenti.service';
import { Cliente } from 'src/app/Models/Cliente';
import { InventarioMaster } from 'src/app/Models/Inventario';
import { DialogSelezioneArticoloInventarioComponent } from '../dialog-selezione-articolo-inventario/dialog-selezione-articolo-inventario.component';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogMultisceltaComponent } from '../dialog-multiscelta/dialog-multiscelta.component';
import { getMultipleValuesInSingleSelectionError } from '@angular/cdk/collections';

@Component({
  selector: 'app-inventario-edit',
  templateUrl: './inventario-edit.component.html',
  styleUrls: ['./inventario-edit.component.scss']
})
export class InventarioEditComponent implements OnInit {

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  forminventario: FormGroup = new FormGroup({
    idCliente: new FormControl('0'),
    opzione: new FormControl('0'),
    tipoInvetarioID: new FormControl('0'),
    numSquadre: new FormControl('0'),
    dal: new FormControl(''),
    al: new FormControl(''),
  })
  
  clienti : any;
  @Input() id: number = 0;
  loading: string = 'none';
  public rowData: any[];
  public rowAvviamento: any[];
  public rowMov: any[];
  idInterno : number = -1;
  disablefields: boolean = false;
  Avviato : boolean = false;
  StatoInventarioID: number = 0;
  nsquadre = 2;
  isTotale = false;

  public getRowStyle;
  constructor(private documentiService: DocumentiService, private clientiService: CustomerService, private _snackBar: MatSnackBar,public dialog: MatDialog) {
    this.getRowStyle = (params) => {
      if (params.data.alert == 1) {
        return { background: '#d95055' };
      } else
        return false
    };
   }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {headerName:'', width:30, checkboxSelection:true},
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'locazione', headerName: "Ubicazione", width: 200, filter: 'agTextColumnFilter' },
  ];

  public columnDefsAvviamento: (ColDef | ColGroupDef)[] = [
    { field: 'alert', headerName: "Anomalia", width: 100, filter: 'agTextColumnFilter', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    }},
    { field: 'causaAnomalia', headerName: "Causa Anomalia", width: 150, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 150, filter: 'agTextColumnFilter' },
    { field: 'nomeudc', headerName: "Udc", width: 150, filter: 'agTextColumnFilter' },
    {headerName: 'Varianti' ,children: [
      {headerName: '', width: 150 , columnGroupShow: 'closed', valueFormatter: (e)=>{
        let str=e.data.var1 + ', ' + e.data.var2 + ', ' + e.data.var3;
        return str
      }},
      { field: 'var1', columnGroupShow: 'open', headerName: "Variante 1", width: 150, filter: 'agTextColumnFilter' },
      { field: 'var2', columnGroupShow: 'open', headerName: "Variante 2", width: 150, filter: 'agTextColumnFilter' },
      { field: 'var3', columnGroupShow: 'open', headerName: "Variante 3", width: 150, filter: 'agTextColumnFilter' },
    ]},
    // { field: 'var4', headerName: "Variante 4", width: 200, filter: 'agTextColumnFilter' },
    // { field: 'var5', headerName: "Variante 5", width: 200, filter: 'agTextColumnFilter' },
    { field: 'quantita', headerName: "Q.tà", width: 100, filter: 'agNumberColumnFilter' },
    { field: 'locazione', headerName: "Ubicazione", width: 200, filter: 'agTextColumnFilter' },
    {headerName: 'Squadra 1', children:[
      { field: 'inventariataS1', headerName: "Inventariata", width: 100, filter: 'agTextColumnFilter' },
      { field: 'variante1NewS1',columnGroupShow: 'open', headerName: "Variante 1", width: 150, filter: 'agTextColumnFilter' },
      { field: 'variante2NewS1',columnGroupShow: 'open', headerName: "Variante 2", width: 150, filter: 'agTextColumnFilter' },
      { field: 'variante3NewS1',columnGroupShow: 'open', headerName: "Variante 3", width: 150, filter: 'agTextColumnFilter' },
      { field: 'locazioneS1', headerName: "Ubicazione", width: 150, filter: 'agTextColumnFilter' },
      // { field: 'Variante4NewS1', headerName: "Variante 4", width: 200, filter: 'agTextColumnFilter' },
      // { field: 'Variante5NewS1', headerName: "Variante 5", width: 200, filter: 'agTextColumnFilter' },
      { field: 'dataInventario1', headerName: "Data", width: 150, filter: 'agTextColumnFilter', type:'dateColumn' },
      { field: 'utente1', headerName: "Utente", width: 200, filter: 'agTextColumnFilter' },
    ]},
    {headerName: 'Squadra 2' ,children: [
      { field: 'inventariataS2', headerName: "Inventariata", width: 100, filter: 'agTextColumnFilter' },
      { field: 'variante1NewS2',columnGroupShow: 'open', headerName: "Variante 1", width: 150, filter: 'agTextColumnFilter' },
      { field: 'variante2NewS2',columnGroupShow: 'open', headerName: "Variante 2", width: 150, filter: 'agTextColumnFilter' },
      { field: 'variante3NewS2',columnGroupShow: 'open', headerName: "Variante 3", width: 150, filter: 'agTextColumnFilter' },
      { field: 'locazioneS2', headerName: "Ubicazione", width: 150, filter: 'agTextColumnFilter' },
      // { field: 'Variante4NewS2', headerName: "Variante 4", width: 200, filter: 'agTextColumnFilter' },
      // { field: 'Variante5NewS2', headerName: "Variante 5", width: 200, filter: 'agTextColumnFilter' },
      { field: 'dataInventario2', headerName: "Data", width: 150, filter: 'agTextColumnFilter', type:'dateColumn' },
      { field: 'utente2', headerName: "Utente", width: 200, filter: 'agTextColumnFilter' },
    ]},
  ];

  public columnDefsMov: (ColDef | ColGroupDef)[] = [
    { field: 'data', width: 200, type: 'dateColumn'},
    { field: 'barcodelocazione', headerName: "Ubicazione", width: 320, filter: 'agTextColumnFilter' },
    { field: 'causale', headerName: "Causale", width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'numDoc', headerName: "Numero Documento", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta',headerName: "Qta TOT", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'utente', headerName: "Utente", width: 200, filter: 'agTextColumnFilter'},
    { field: 'note', headerName: "Note", width: 320, filter: 'agTextColumnFilter'},
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;
  public gridColumnApiSlave!: ColumnApi;

  private gridApi!: GridApi<any>;
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.ElencoArticoloInventario()
    this.gridApi = params.api;
  }
  
  
  
  
  onGridReadyDati(params: GridReadyEvent) {
  }
  onGridReadySlave(params: GridReadyEvent) {
    this.gridColumnApiSlave = params.columnApi;
  }

  ElencoArticoloInventario() {
    this.loading = 'block';
    this.documentiService.ElencoArticoloInventario(this.idInterno).subscribe((data) => {
      this.rowData = data;
      if(this.rowData.length > 0)
          this.disablefields = true
      this.loading = 'none';
    });
  }

  ElencoInventarioSlave(){
    this.rowAvviamento = []
    this.loading = 'block';
    this.documentiService.ElencoInventarioSlave(this.idInterno).subscribe((data) => {
      this.rowAvviamento = data;
      if(this.rowAvviamento.length > 0)
          this.disablefields = true

      if(this.nsquadre == 1)
        this.gridColumnApiSlave.setColumnsVisible(['inventariataS2', 'variante1NewS2', 'variante2NewS2', 'variante3NewS2', 'locazioneS2', 'dataInventario2', 'utente2' ],false);
      this.loading = 'none';
    });
  }

  ElencoInventarioMovimenti(){
    this.rowAvviamento = []
    this.loading = 'block';
    this.documentiService.GetMovimentiInventario(this.idInterno).subscribe((data) => {
      this.rowMov = data;
      this.loading = 'none';
    });
  }

  opzionelabel = 'Articoli';
  
  getInventario(id){
    if(id != -1){
    this.documentiService.GetInventarioMaster(id).subscribe((res) => {
      //this.rowData = data;
      this.forminventario = new FormGroup({
        tipoInvetarioID: new FormControl(res[0]?.tipoInvetarioID || 0, Validators.required),
        idCliente: new FormControl(res[0]?.idCliente || 0, Validators.required),
        opzione: new FormControl(res[0]?.opzione || 0, Validators.required),
        numSquadre: new FormControl(res[0]?.numSquadre || 0, Validators.required),
        dal: new FormControl( new Date(res[0]?.dal).toLocaleDateString('af').replace(new RegExp('/', "g"),'-')  || '', Validators.required),
        al: new FormControl( new Date(res[0]?.al).toLocaleDateString('af').replace(new RegExp('/', "g"),'-')  || '', Validators.required),
      })
      this.Avviato = res[0]?.statoInventarioID == 2 ? true : false;
      this.loading = 'none';
      this.StatoInventarioID = res[0]?.statoInventarioID

      this.opzionelabel = res[0]?.opzione == 3 ? 'Ubicazioni' : 'Articoli';

      this.nsquadre = res[0]?.numSquadre || 2;

      this.isTotale = (res[0]?.tipoInvetarioID || 0) == 1;

      if(this.Avviato){ //oppure totale
        this.ElencoInventarioSlave()
      }


      //console.log(this.StatoInventarioID == 1 && !this.Avviato)

    });
  }
  }

  gridload(e: any){
    //console.log(e.tab.textLabel);
    switch (e.tab.textLabel) {
      case 'Informazioni':
        this.ElencoArticoloInventario();
        break;
      case 'Dati Inventario':
        this.ElencoInventarioSlave();
        break;
      case 'Movimenti':
        this.ElencoInventarioMovimenti();
        break;
        default: break
    }


  }


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('it-IT')
       //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  ngOnInit(): void {
    this.clientiService.GetEnte(0).subscribe((data)=>{
      this.clienti= data || [];
    })
    this.getInventario(this.id)
    this.idInterno = this.id;
  }

  
  onSubmit(){
    if (!this.forminventario.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
      return false;
    } else {
      this.loading = 'block';
      let req: InventarioMaster = this.forminventario.value;
      req.nota = '';
      req.inventarioMasterID=this.idInterno == -1 ? 0 :this.idInterno;
      req.statoInventarioID = this.StatoInventarioID;
      req.statoInventario = '';
      req.cliente = '';
      req.tipoInventario = '';
      this.documentiService.InsInventarioMaster(req).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err == '0') {
          this.idInterno = +data[0].msg;
          this.getInventario(data[0].msg); //nell'update mi da vuoto
          this.openSnackBar('Salvataggio avvenuto con successo');
          //this.disablefields = false
          return true
        } else {
          this.openSnackBar(data[0].msg);
          return false
        }
      });
      
      return true
    }
  }

  onAvvio(){
    const dialogRef = this.dialog.open(DialogMultisceltaComponent, {
      data: {
        Btn2: 'Avvia',
        Btn1: 'Annulla',
        messaggio:'Avviare l\'Inventario?'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 2){
        this.documentiService.AvviaInventario(this.idInterno).subscribe((data) => {
          this.loading = 'none'
          if (data[0].err == '0') {
            //this.idInterno = +data[0].msg;
            this.getInventario(this.idInterno); //nell'update mi da vuoto
            //this.ElencoInventarioSlave();
            this.openSnackBar('Inventario Avviato');
            return true
          } else {
            this.openSnackBar(data[0].msg);
            return false
          }
        });
      }
    });
  }
rimuoviTermina = false
  onTermina(){
    const dialogRef = this.dialog.open(DialogMultisceltaComponent, {
      data: {
        Btn2: 'Termina',
        Btn1: 'Annulla',
        messaggio:'Terminare l\'Inventario?'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 2){
        this.documentiService.TerminaInventario(this.idInterno).subscribe((data) => {
          this.loading = 'none'
          if (data[0].err == '0') {
            this.openSnackBar('Inventario Terminato');
            this.rimuoviTermina=true;
            return true
          } else {
            this.openSnackBar(data[0].msg);
            return false
          }
        });
      }
    });
  }

  openDialogArticoli() {
    if (this.forminventario.get('idCliente').value != 0) {
      //console.log(this.forminventario.get('Opzione').value)
      const dialogRef = this.dialog.open(DialogSelezioneArticoloInventarioComponent, {
        width: '100vw',
        maxWidth: '100vw',
        data: {
          IdCliente: this.forminventario.get('idCliente').value,
          tipo : this.forminventario.get('opzione').value,
          id: this.idInterno
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          //this.listarticoli = result.articoli;

          // console.log(result);
          result = {
            dettagliArticoloInventario: result
          }
          this.loading = 'block'
          //this.onSubmit()
          this.documentiService.InsArticoloInventario(result).subscribe((data) => {
            this.loading = 'none'
            if (data[0].err == '0') {
              //this.idInterno = +data[0].msg;
              //this.getInventario(data[0].msg); //nell'update mi da vuoto
              this.ElencoArticoloInventario();
              this.openSnackBar('Salvataggio avvenuto con successo');
              return true
            } else {
              this.openSnackBar(data[0].msg);
              return false
            }
          });
        }
      });
    }
  }

  deleteRighe() {
    let rows = this.gridApi.getSelectedRows();
    if (rows.length > 0) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Eliminare le righe selezionate?',
          BtnSi: 'Elimina',
          BtnNo: 'Annulla',
          BtnInvertiti: true
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let ids = rows.map(x => x.articoloInventarioID).join()
          this.documentiService.EliminaArticoloInventario(ids).subscribe((data) => {
            this.loading = 'none'
            if (data[0].err == '0') {
              this.ElencoArticoloInventario();
              return true
            } else {
              this.openSnackBar(data[0].msg);
              return false
            }
          });
        }
      });

    }else{
      this.openSnackBar('Selezionare almeno una riga prima di continuare');
    }
  }

}
