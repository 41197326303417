<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
    <mat-tab [label]=label>
        <div class="card">
            <ag-grid-angular style="width: 100%; height: 80vh" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData$" [rowSelection]="'multiple'" [animateRows]="true"
                (gridReady)="onGridReady($event)" (cellClicked)="onCellClicked($event)"
                (gridReady)="onGridReady($event)" [columnTypes]="columnTypes" (rowDoubleClicked)="addTab($event)">
            </ag-grid-angular>
        </div>
    </mat-tab>
    <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tabs[index].nome">
        <!-- <app-product-edit nome={{tab.nome}}></app-product-edit> -->
        <!-- <ng-template [ngTemplateOutlet]=templateRef [ngTemplateOutletContext]="{$implicit: tab}"></ng-template> -->

        <!-- <ng-container [ngTemplateOutlet]="tab.component"></ng-container> -->
    </mat-tab>
</mat-tab-group>