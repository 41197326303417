import { Component, ElementRef, EmbeddedViewRef, OnInit, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  //   template: `
  //   <div class="ad-banner-example">
  //     <h3>Advertisements</h3>
  //     <ng-template ></ng-template>
  //   </div>
  // `,
  styleUrls: ['./tab-item.component.scss']
})
export class TabItemComponent implements OnInit {

  //@ViewChild('tab') tab: TemplateRef<any>;
  //@ViewChild("component", { read: TemplateRef }) component: TemplateRef<any> | undefined;

  @ViewChild('vc', { read: ViewContainerRef }) vc: ViewContainerRef | undefined;

  constructor() {
    //elementRef: ElementRef;
    //createEmbeddedView()
  }

  ngOnInit(): void {
    //this.vc.createEmbeddedView(this._template, {fromContext: 'John'});
  }

}

