import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { Liste } from 'src/app/Models/Liste';
import { TipoDocumento } from 'src/app/Models/TipoDocumento';
import { ArticoloService } from 'src/app/services/articolo.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogIngressoArticoloComponent } from '../dialog-ingresso-articolo/dialog-ingresso-articolo.component';
import { DialogIngressoCercaArticoloComponent } from '../dialog-ingresso-cerca-articolo/dialog-ingresso-cerca-articolo.component';
import { Tab } from '../ingresso/ingresso.component';
//  import {pdf} from 'pdf.json'
import data from 'pdf.json';
import { Ingresso } from 'src/app/Models/Ingresso';
import { DialogcercaspedizioniComponent } from '../dialogcercaspedizioni/dialogcercaspedizioni.component';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { DialogMultisceltaComponent } from '../../dialog-multiscelta/dialog-multiscelta.component';
import { from } from 'rxjs';
import { DialogOrdiniDdtemessiComponent } from '../dialog-ordini-ddtemessi/dialog-ordini-ddtemessi.component';
import { GruppoService } from 'src/app/services/gruppo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-uscita-edit',
  templateUrl: './uscita-edit.component.html',
  styleUrls: ['./uscita-edit.component.scss']
})
export class UscitaEditComponent implements OnInit {

  formingresso: FormGroup = new FormGroup({
    idCliente : new FormControl(''),
    idIntenstatario : new FormControl(''),
    tipoDocID: new FormControl(''),
    idCausale: new FormControl(''),
    ragioneSociale: new FormControl(''),
    numDoc : new FormControl(''),
    dataDoc: new FormControl(''),
    // NumeroRif: new FormControl(''),
    dataRif:new FormControl(''),
    indirizzo: new FormControl(''),
    id_Valuta:new FormControl(''),
    cAss:new FormControl(''),
    valoreDoc: new FormControl(''),
    cap: new FormControl(''),
    localita: new FormControl(''),
    provincia: new FormControl(''),
    nazione: new FormControl(''),
    esportato: new FormControl(''),
    bloccato: new FormControl(''),
    documentoID : new FormControl(''),
    colli: new FormControl(''),
    peso: new FormControl(''),
    volume: new FormControl(''),
    email: new FormControl(''),
    telefono: new FormControl(''),
    dataConsegna:new FormControl(''),
    contatto:new FormControl(''),
    contrattoVettoreId:new FormControl(''),
    naturaMerce:new FormControl(''),
    rifVettore:new FormControl(''),
    noteVettore:new FormControl(''),
    codVettore : new FormControl(''),
    pagamentoId: new FormControl(''),
    targa: new FormControl(''),
    porto: new FormControl('Franco'),
    note : new FormControl(''),
    nRif: new FormControl(''),
  });

  @Input() id: number = 0;

  @Input() livelloID: number = 0;

  @Input() numOrdine: string = "";

  @Input() tab: Tab = { id: 0, nome: "", numero: "" };
  @Input() fromDDT: boolean;
  @Output() closeTab = new EventEmitter<number>();

  idDocumento : number=0;
 
  isProdottoFinito = false;

  public loading: string = 'none';
  selected = new FormControl(0);
  clienti : Cliente[] = [];
  intestatari: Cliente[] = [];
  tipodoc : TipoDocumento[] = [];
  vettori : Cliente[] = [];
  liste : Liste;
  causali: [{idCausale: number,descrizione: string}];
  ordini: string[];

  constructor(private _snackBar: MatSnackBar, 
    private documentiService: DocumentiService,
    private clientiService: CustomerService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private gruppoService: GruppoService, private router: Router) { }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }


  ngOnInit(): void {
    

    if(this.numOrdine.substring(0,2) == 'PF')
        this.isProdottoFinito = true;

    this.getListe();
    this.getDocumento(this.id);
    this.idDocumento = this.id;
  }

  hasFattura : boolean = false;
  fatturab64 : string = "";
  nomeFatturab64 : string = "";

  getDocumento(id: number) {
    this.documentiService.GetDocumentoUscita(14, id, +this.fromDDT ).subscribe((res) => {
      this.formingresso = new FormGroup({
        idCliente : new FormControl(res[0]?.idCliente || 0, Validators.required),
        idIntenstatario : new FormControl(res[0]?.idIntenstatario || 0, Validators.required),
        tipoDocID: new FormControl(res[0]?.tipoDocID || 5, Validators.required),
        idCausale: new FormControl(res[0]?.idCausale || 2, Validators.required),
        ragioneSociale: new FormControl(res[0]?.ragioneSociale || '', Validators.required),
        numDoc : new FormControl({value: res[0]?.numDoc || '', disabled:true}),
        dataDoc: new FormControl(new Date(res[0]?.dataDoc).toLocaleDateString('af').replace(new RegExp('/', "g"),'-')  || '', Validators.required),
        // NumeroRif: new FormControl(res[0]?.NumeroRif || '', Validators.required),
        dataRif:new FormControl(new Date(res[0]?.dataRif).toLocaleDateString('af').replace(new RegExp('/', "g"),'-')  || '', Validators.required),
        indirizzo: new FormControl(res[0]?.indirizzo || '', Validators.required),
        id_Valuta:new FormControl(res[0]?.id_Valuta || 0),
        cAss:new FormControl(res[0]?.cAss || 0),
        valoreDoc: new FormControl(res[0]?.valoreDoc || '0'),
        cap: new FormControl(res[0]?.cap || '', Validators.required),
        localita: new FormControl(res[0]?.localita || '', Validators.required),
        provincia: new FormControl(res[0]?.provincia || '', Validators.required),
        nazione: new FormControl(res[0]?.nazione || '', Validators.required),
        esportato: new FormControl(res[0]?.esportato || false),
        bloccato: new FormControl(res[0]?.bloccato || false),
        documentoID : new FormControl(res[0]?.documentoID || '0', Validators.required),
        colli: new FormControl(res[0]?.colli || 0),
        peso: new FormControl(res[0]?.peso || 0),
        volume: new FormControl(res[0]?.volume || 0),
        email: new FormControl(res[0]?.email || ''),
        telefono: new FormControl(res[0]?.telefono || ''),
        dataConsegna:new FormControl(new Date(res[0]?.dataConsegna).toLocaleDateString('af').replace(new RegExp('/', "g"),'-')  || ''),
        contatto:new FormControl(res[0]?.contatto || ''),
        contrattoVettoreId:new FormControl(res[0]?.contrattoVettoreId || 0),
        naturaMerce:new FormControl(res[0]?.naturaMerce || ''),
        rifVettore:new FormControl(res[0]?.rifVettore || ''),
        noteVettore:new FormControl(res[0]?.noteVettore || ''),
        codVettore : new FormControl(res[0]?.codVettore || 0),
        pagamentoId: new FormControl(res[0]?.pagamentoId || 0),   
        targa: new FormControl(res[0]?.targa || ''),   
        porto: new FormControl(res[0]?.porto || 'Franco'),  
        note : new FormControl(res[0]?.note || ''), 
        nRif: new FormControl(res[0]?.nRif || '0'), 
      });
      this.rowArticoli = res[0].dettagli;
      this.clienti = res[0].enti;
      this.intestatari =res[0].intestatario;
      this.causali = res[0].causali;
      this.tipodoc= res[0].tipodocumenti;
      this.vettori= res[0].vettori;
      this.formingresso.get('idIntenstatario').disable()
      this.onChangeCliente(res[0].idCliente)
      this.onChangeIntestatario(res[0].idIntenstatario)

      this.ordini= res[0].ordini.split('#*');

      this.numOrdine = res[0].numeroOrdine;

      if(this.numOrdine.substring(0,2) == 'PF')
        this.isProdottoFinito = true;

      if(res[0].fatturab64){
        this.hasFattura = true;
        this.fatturab64 = res[0].fatturab64.split("|")[1];
        this.nomeFatturab64 = res[0].fatturab64.split("|")[0];
      }
    }
    );
  }

  RefreshArticoli(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.rowArticoli= this.rowArticoli;
  }

  getListe() {
    this.commonService.getListe()
      .subscribe((data) => (this.liste = data));
  }

  b64toBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  printPDF(pdf : any){
    var fileblob = this.b64toBlob(pdf, 'application/pdf');
    var url = window.URL.createObjectURL(fileblob); 

    window.open(url);

  }

  onSubmit() {
    if (!this.formingresso.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
      return false;
    } else {
      this.loading = 'block';
      let req: Ingresso = this.formingresso.value;
      //req.Note = '';
      // req.dettagli = this?.rowArticoli || [];
      req.dettagli = [];
      req.enti = [];
      req.log = [];
      req.vettori = [];
      req.causali = [{ idCausale: 0, descrizione: '' }];
      req.tipodocumenti = [];
      req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
      req.documentoID= this.idDocumento == -1 ? 0 : this.idDocumento;
      //req.NRif = '';
      req.numDoc = this.formingresso.getRawValue().numDoc
      
      this.documentiService.InsDocumento(req).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err == '0') {
          this.id = +data[0].msg;
          this.getDocumento(data[0].msg);
          this.openSnackBar('Salvataggio avvenuto con successo');
          return true
        } else {
          this.openSnackBar(data[0].msg);
          return false
        }
      });
      
      return true
    }
  }

  onUndo() {
  //   if (this.id > 0) {
  //     this.getCliente(this.id);
  //   } else {
  //     this.getCliente(-1);
  //   }
  }

  onChangeCliente(id:number){
    if(id > 0){
      this.formingresso.get('idIntenstatario').enable()
    }
  }
  onChangeIntestatario(id:number){
    this.clientiService.GetEnte(id).subscribe((data) => {
      this.formingresso.get('idIntenstatario').setValue(data[0].idCliente)
      // this.formingresso.get('RagioneSociale').setValue(data[0].Ragione_Sociale)
      // this.formingresso.get('Indirizzo').setValue(data[0].Indirizzo_Legale)
      // this.formingresso.get('Localita').setValue(data[0].CittaL)
      // this.formingresso.get('Provincia').setValue(data[0].ProvL)
      // this.formingresso.get('cap').setValue(data[0].CAPL)
      // this.formingresso.get('Nazione').setValue('Italia')
    })
    
  }
  rowArticoli: IngressoArticolo[] = [];

  public columnDefsArticoli: (ColDef | ColGroupDef)[] = [
    //{ field: 'Articolo_Id', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'partNumber', headerName: "PN", width: 200, filter: 'agTextColumnFilter' },
    { field: 'descrizione', headerName: "Des. Articolo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 200, filter: 'agNumberColumnFilter' },
    { field: 'qtaEvasa', headerName: "Qtà Accettata", type: 'numberColumn', width: 200, filter: 'agNumberColumnFilter' },
    { field: 'qtaMiss', headerName: "Qtà Riscontrata", type: 'numberColumn', width: 200, filter: 'agNumberColumnFilter' },
    { field: 'note', width: 250, filter: 'agTextColumnFilter' },
    { field: 'numUdc', headerName: "NrUdc", type: 'numberColumn', width: 200, filter: 'agTextColumnFilter' },
  ];
  


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  openArticoloDialog(idarticolo : number = 0){
    // let articolo = this.rowArticoli.find((e) => e.gridIndex == idarticolo);
    // const dialogRef = this.dialog.open(DialogcercaspedizioniComponent, {
    //   width: '300px',
    //   //height: '500px',
    //   data: {
    //     DocumentoID: this.id,
    //     Articolo_Id: 7,
    //     Descrizione: articolo?.Descrizione || '',
    //     Qta: articolo?.Qta || '',
    //     QtaEvasa: articolo?.QtaEvasa || '',
    //     QtaMiss: articolo?.QtaMiss || '',
    //     Note: articolo?.Note || '',
    //     Fase: articolo?.Fase || '',
    //     MES: articolo?.MES || '',
    //     Colata: articolo?.Colata || '',
    //     NumUdc: articolo?.NumUdc || '',
    //     RigaId: +articolo?.gridIndex || 0,
    //     gridIndex: articolo?.gridIndex || '',
    //     PartNumber: articolo?.PartNumber
    //   },
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.rowArticoli = [
    //       {
    //         Articolo_Id: result.Articolo_Id,
    //         Colata: result.Colata,
    //         Descrizione: result.Descrizione,
    //         DocumentoID: result.DocumentoID,
    //         Fase: result.Fase,
    //         MES: result.MES,
    //         Note: result.Note,
    //         NumUdc: result.NumUdc,
    //         PartNumber: result.PartNumber,
    //         Qta: result.Qta,
    //         QtaEvasa: result.QtaEvasa,
    //         QtaMiss: result.QtaMiss,
    //         RigaId: result.RigaId,
    //         gridIndex: result.gridIndex,
    //       }
    //     ]
    //     this.onSubmit();
    //   }
    // });
  }

  onDelete(){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'Eliminare il DDT Corrente?',
        BtnSi: 'Conferma',
        BtnNo: 'Annulla'
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.documentiService.DelDocumentoODS(this.idDocumento).subscribe((res) => {
          if (res[0].err == '0')
            this.closeTab.emit(this.idDocumento);
        })
      }
    });
  }

  

  onPrint(tipo:number){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'Una volta stampato non sarà possibile modificare il documento e verrà scaricata la giacenza, Continuare?',
        BtnSi: 'Continua',
        BtnNo: 'Annulla'
      },
    });
    
    dialogRef.afterClosed().subscribe(result => {
      
      if (result) {
        this.documentiService.StampaDoc(this.idDocumento,tipo).subscribe((res) => {
            if(res[0].err == '0'){
              this.printPDF(res[0].msg);
              this.closeTab.emit(this.idDocumento);

            }else{
              this.openSnackBar(res[0].msg);
            }
        })
      }
      
    });
  }

  stampaEtichette(){
    this.documentiService.StampaEtichetta(this.numOrdine).subscribe((data) => {
      this.loading = 'none'
      for (var i = 0; i< data.length; i++)
        this.downloadPDF(data[i].contentBase64, data[i].fileName);
    });
  }

  stampaFattura(){
    this.downloadPDF(this.fatturab64, this.nomeFatturab64);
  }

  downloadPDF(pdf: any, name: string){
    var fileblob = this.b64toBlob(pdf, 'application/pdf');
    var url = window.URL.createObjectURL(fileblob);
    const link = document.createElement('a');
    link.href = url;
    link.download = name + '.pdf';
    link.click();

    // Libera l'URL oggetto
    URL.revokeObjectURL(url);
  }

  onAnteprimaPrint(tipo:number){
    this.documentiService.StampaDoc(this.idDocumento,tipo).subscribe((res) => {
        if(res[0].err == '0'){
          this.printPDF(res[0].msg)
        }else{
          this.openSnackBar(res[0].msg);
        }
    })
  }

  openArticoloSearchDialog() {
    if (this.onSubmit()) {
      const dialogRef = this.dialog.open(DialogIngressoCercaArticoloComponent, {
        width: "80vw",
        height: window.innerHeight - 100 + "px",
        data: {
          DocumentoID: this.idDocumento,
          CheckAusiliario: true,
          Articolo: {
            Descrizione: '',
            PartNumber: '',
            Qta: ''
          }
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.rowArticoli = [
            {
              articolo_Id: result?.Articolo_Id || 0,
              colata: result?.Colata || '',
              descrizione: result?.Descrizione || '',
              documentoID: result?.DocumentoID || 0,
              fase: result?.Fase || '',
              mes: result?.MES || '',
              note: result?.Note || '',
              numUdc: result?.NumUdc || '',
              partNumber: result?.PartNumber || '',
              qta: result?.Qta || 0,
              qtaEvasa: result?.QtaEvasa || 0,
              qtaMiss: result?.QtaMiss || 0,
              rigaId: result?.RigaId || 0,
              gridIndex: result?.gridIndex || '',
              idAccettazione : result?.IdAccettazione  || 0,
              elimina : 0
            }
          ]
          this.onSubmit();
        }
      });
    }
  }

  openDialogOrdini(){
   this.dialog.open(DialogOrdiniDdtemessiComponent, {
      data: {
        ordini: []
        }
    });
  }

  creaTxt(){
    this.documentiService.GenerateTracciatoDDT(this.idDocumento).subscribe((res) => {
      if(res[0].err == '0')
        this.openSnackBar('Tracciato DDT generato');
      else
        this.openSnackBar('Errore durante la generazione del Tracciato DDT');
    })
  }

  // openArticoloSearchDialog(){
    
  //   if(this.onSubmit()){
  //     //this.documentiService.GetSpedizioni(this.formingresso.get('IdCliente').value).subscribe()
  //   const dialogRef = this.dialog.open(DialogcercaspedizioniComponent, {
  //     width: "50vw",
  //     //height: window.innerHeight - 100 + "px",
  //     data: {
  //       ClienteID: this.formingresso.get('IdCliente').value,
  //       UDC : '',
  //       Descrizione: '',
  //       PartNumber: '',
  //       Qta: 0,
  //       QtaEvasa: 0
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result){
  //     this.rowArticoli = [
  //       {
  //         Articolo_Id: 7,
  //         Colata: result?.Colata || '',
  //         Descrizione: result?.Descrizione || '',
  //         DocumentoID: result?.DocumentoID || 0,
  //         Fase: result?.Fase || '',
  //         MES: result?.MES || '',
  //         Note: result?.Note || '',
  //         NumUdc: result?.NumUdc || '',
  //         PartNumber: result?.PartNumber || '',
  //         Qta: result?.Qta || 0,
  //         QtaEvasa: result?.QtaEvasa || 0,
  //         QtaMiss: result?.QtaMiss || 0,
  //         RigaId: result?.RigaId || 0,
  //         gridIndex: result?.gridIndex || '',
  //         IdAccettazione : 0
  //       }
  //     ]
  //     this.onSubmit();
  //   }
  //   });
  // }
  // }


  // onConfirm(){
  //   if(this.id > 0){
  //     this.documentiService.InsUDCDoc(this.id).subscribe(data => {this.loading = 'none';
  //     this.getDocumento(this.id);
  //     this.openSnackBar('Articoli associati a UDC');
  //     });
  //   }else{
  //     this.openSnackBar('Salvare prima il Documento');
  //   }
  // }

}
