import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTreeModule, MatTreeFlatDataSource, MatTreeFlattener, MatTree } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Menu } from 'src/app/Models/Menu';
import { MenuService } from 'src/app/services/menu.service';
import { Title } from '@angular/platform-browser';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { SettingsComponent } from '../settings/settings.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { User } from 'src/app/Models/user.model';


interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  RouterLink: string;
  id: number;
  favorite: boolean
}

@Component({
  selector: 'app-sidenav-ai',
  templateUrl: './sidenav-ai.component.html',
  styleUrls: ['./sidenav-ai.component.scss']
})
export class SidenavAiComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  title: string = '';

  transformer = (node: Menu, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.label,
      level: level,
      RouterLink: node.routerLink,
      id: node.id,
      favorite: node.favorite
    };
  }

  username: string='';

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  dataSourcePreferiti = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  sidenavStyle = {
    height : "100vh",
    backgroundColor: "red",
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  // setTitle(title: string) {
  //   this.titleService.setTitle(title);
  //   window.document.title = title;
  //   this.title= title;
  // }

  nodeClick(node: any) {
    if(this.router.url != "/"+ [node.RouterLink]){
      this.router.navigate([node.RouterLink], {relativeTo:this.route}).then(()=>{
        this.title = this.titleService.getTitle();
      });
    }
  }

  // currentUser = this.authService.currentUser$;
  menuList: Observable<Menu[]> | undefined;
  preferitiList: Observable<Menu[]> | undefined;
  menuService: MenuService;


  constructor(menuService: MenuService,private router: Router,private titleService: Title, private route: ActivatedRoute , public dialog : MatDialog, private jwtHelper: JwtHelperService, private _router: Router, private authService: AuthServiceService) {
    this.menuService = menuService;
  }

  ngOnInit(): void {
    this.title = this.titleService.getTitle();
    let user = this.authService.User;
    this.username = user.username;
    this.initializeMenu();
  }

  onExit(){
    this.authService.logout();
    this._router.navigate([''])
  }

  initializeMenu() {
    this.menuService!.getMenu().subscribe((data) => {this.dataSource.data = data});
    this.menuService!.getPreferiti().subscribe((data) => {this.dataSourcePreferiti.data = data});
    

  }

  SidenavOpened : boolean = true;
  openSidenav() {
      this.sidenav.open();
      this.SidenavOpened = true;
  }
  closeSidenav(){
    this.sidenav.close();
    this.SidenavOpened = false;
  }

  apriFinestra(node: any){
    if(this.router.url != "/"+ [node.RouterLink]){
      // this.router.navigate([node.RouterLink], {relativeTo:this.route}).then(()=>{
      //   this.title = this.titleService.getTitle();
      // });
      window.open('/main/'+node.RouterLink);
    }
  }

  togglePreferiti(menu: any) {
    this.menuService!.togglePreferiti(menu).subscribe(r => this.checkMenuTogglePreferiti(r));
  }

  checkMenuTogglePreferiti(r) {
    if (r == 'ok')
      this.initializeMenu()

  }


  openChangePsw(){

    const dialogRef = this.dialog.open(SettingsComponent, {
      width: '250px',
      data: {
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}


