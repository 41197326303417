import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sito } from 'src/app/Models/Sito';

@Component({
  selector: 'app-dialog-sito',
  templateUrl: './dialog-sito.component.html',
  styleUrls: ['./dialog-sito.component.scss']
})
export class DialogSitoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSitoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Sito,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
