<div mat-dialog-content>
    <div style="display:flex;flex-direction:row; width: 100%;">
        <div style="width: 70%;padding: 4px;">
            <h1>Seleziona Articolo</h1>
            <b class="articoloselezionato">{{articoloselezionato}}</b>
            <app-grid [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef" [rowData]="rowArticoli"
                [columnTypes]="columnTypes" (onDblClick)="loadDataDettaglio($event)">
            </app-grid>
        </div>
        <div style="width: 30%;padding: 4px; margin-top: 10px;">
            <br>
            <mat-form-field appearance="fill">
                <mat-label>Qta</mat-label>
                <input matInput [(ngModel)]="data.Qta" type="number">
            </mat-form-field>
            <br>
            <b style="color:red">{{msg}}</b>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" (click)="onSave()" cdkFocusInitial>Salva</button>
</div>