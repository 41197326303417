import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-del-button',
  templateUrl: './grid-del-button.component.html',
  styleUrls: ['./grid-del-button.component.scss']
})
export class GridDelButtonComponent {

  params;
  label: number;

  agInit(params): void {
    this.params = params;
    this.label = parseInt( this.getValueToDisplay(params).idGruppodiCarico);
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
  getValueToDisplay(params: ICellRendererParams) {
    return params.data
  }

}
