<div mat-dialog-content>
    <div>
        <div style="padding: 4px;">
            <h1>Seleziona Ordine</h1>
            <b class="articoloselezionato">{{articoloselezionato}}</b>
            <app-grid [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef" [rowData]="rowArticoli"
                [columnTypes]="columnTypes" (onDblClick)="loadDataDettaglio($event)">
            </app-grid>
        </div>
        <!-- <div style="width: 30%;padding: 4px; margin-top: 10px;">
            <b style="color:red">{{msg}}</b>
        </div> -->
    </div>
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <!-- <button class="button-add-row" (click)="onSave()" cdkFocusInitial>Salva</button> -->
</div>