import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ImpostazioniUtente } from 'src/app/Models/ImpostazioniUtente';
import { MenuService } from 'src/app/services/menu.service';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  msg : string ='';

  constructor(
    public dialogRef: MatDialogRef<SettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImpostazioniUtente,
    private menuService: MenuService,
    private jwtHelper: JwtHelperService,
    private router : Router
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave(){
    if(this.data.psw == this.data.pswconfirm){
      this.data.gridIndex = this.jwtHelper.decodeToken(localStorage.getItem('jwt')).userid
      this.menuService.changePassword(this.data).subscribe((res)=>{
        if(res == 'ok'){

          this.msg= '';
          localStorage.removeItem('jwt');
          this.dialogRef.close();
          this.router.navigate(['/'])
        }else{
          this.msg= res;
          setTimeout(() => {
            this.msg='';
          }, 2000);
        }
      })
    } else {
      this.msg = 'I campi Nuova Password e Conferma Password non corrispondono'
      setTimeout(() => {
        this.msg = '';
      }, 2000);
    }
  }

  ngOnInit(): void {
  }

}
