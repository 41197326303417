import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Input, EventEmitter, ContentChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellClickedEvent, ColDef, GridReadyEvent, ColumnApi, ColGroupDef, RowDoubleClickedEvent,
} from 'ag-grid-community';

import { FormControl } from '@angular/forms';

import { ProductEditComponent } from '../product-edit/product-edit.component';
import { TabItemComponent } from '../tab-item/tab-item.component';


declare var window: any;

export class Tab {
  id: number | null = 0;
  nome: string = "";
  component: TabItemComponent = new TabItemComponent;
  //component2: TemplateRef<TabItemComponent> = TemplateRef<new (TabItemComponent) => TabItemComponent>
}
@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  @Input() label: string = "Lista";
  public editVar: string = "";
  public data: Tab[] = [{ id: 0, nome: "", component: new TabItemComponent }];
  //@ViewChild('component', { static: true }) component: TemplateRef<TabItemComponent>;

  myModel = {
  };

  selected = new FormControl(0);
  productEditComponent = ProductEditComponent

  tabs: Tab[] = [];


  addTab(e: RowDoubleClickedEvent) {
    console.log(e);
    if (!this.tabs.some((x) => x.id == e.rowIndex)) {
      let row = {
        id: e.rowIndex,
        nome: e.data.athlete,
        component: new TabItemComponent
      }
      this.tabs.push(row);
      this.editVar = e.data.athlete;
      this.data.push(row);
    }

    //this.selected.setValue(this.tabs.findIndex((x) => x.id == e.rowIndex) + 1);

  }

  removeTab(index) { }

  ngOnInit(): void {

  }


  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'athlete', width: 220 },
    { field: 'age', width: 100, type: 'numberColumn' },
    { field: 'sport', width: 220 },
    { field: 'date', type: ['dateColumn', 'nonEditableColumn'], width: 220 },
    {
      headerName: 'Medals',
      groupId: 'medalsGroup',
      children: [
        // using medal column type
        { headerName: 'Gold', field: 'gold', type: 'medalColumn' },
        { headerName: 'Silver', field: 'silver', type: 'medalColumn' },
        { headerName: 'Bronze', field: 'bronze', type: 'medalColumn' },
        {
          headerName: 'Total',
          field: 'total',
          type: 'medalColumn',
          columnGroupShow: 'closed',
        },
      ],
    },
  ];


  public columnTypes: { [key: string]: ColDef } = {
    medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public defaultColGroupDef: Partial<ColGroupDef> = {
    marryChildren: true,
  };

  // Data that gets displayed in the grid
  public rowData$!: any[];

  private gridColumnApi!: ColumnApi;



  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(private http: HttpClient) { }

  // // Example load data from sever
  // onGridReady(params: GridReadyEvent) {
  //   this.rowData$ = this.http
  //     .get<any[]>('https://www.ag-grid.com/example-assets/row-data.json');
  // }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    // console.log('cellClicked', e.data);
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }


  saveState() {
    window.colState = this.gridColumnApi.getColumnState();
    console.log('column state saved');
    console.log(window.colState)
  }

  restoreState() {
    if (!window.colState) {
      console.log('no columns state to restore by, you must save state first');
      return;
    }
    this.gridColumnApi.applyColumnState({
      state: window.colState,
      applyOrder: true,
    });
    console.log('column state restored');
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.http
      .get<any[]>('https://www.ag-grid.com/example-assets/olympic-winners.json')
      .subscribe((data) => (this.rowData$ = data));
  }

}
