<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
  </div>
  <button class="button-add-row" (click)="openNewContainer()">Inserisci Container</button>
  <button class="button-add-row" (click)="creaOrdine()"> Crea Ordine</button>
  <div [ngClass]="{'disabilita-griglia': !isGridVisible}">
    <ag-grid-angular 
    style="width: 100%; height: 85vh;"
      [defaultColDef]="defaultColDef"
      [groupDefaultExpanded]="groupDefaultExpanded"
      [masterDetail]="true"
      [detailCellRendererParams]="detailCellRendererParams"
      [detailRowAutoHeight]="true"
      class="ag-theme-alpine"
      (gridReady)="onGridReady($event)"
      [columnDefs]="columnDefs"
      [undoRedoCellEditing]="true"
      [columnTypes]="columnTypes"
      [detailRowAutoHeight]="true"
      [gridOptions]="gridOptions"
      (firstDataRendered)="onFirstDataRendered($event)"
      [frameworkComponents]="frameworkComponents"
      [rowData]="rowData"
      ></ag-grid-angular>
    </div>
    