import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColumnApi, ColumnState, CsvCell, CsvExportParams, ExcelCell, ExcelExportParams, ExcelRow, ExcelStyle, FirstDataRenderedEvent, GridApi, GridOptions,
   GridReadyEvent, IDetailCellRendererParams, ITooltipParams, ProcessRowGroupForExportParams, ValueGetterParams, ValueSetterParams } from 'ag-grid-community';
import { PrenotazionePresa } from 'src/app/Models/PrenotazionePresa';
import { ProdottofinitoService } from 'src/app/services/prodottofinito.service';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-prenotazione-presa',
  templateUrl: './prenotazione-presa.component.html',
  styleUrls: ['./prenotazione-presa.component.scss']
})
export class PrenotazionePresaComponent implements OnInit {

  loading: string = 'none';
  public frameworkComponents;
  public getRowStyle;
  constructor(private _snackBar: MatSnackBar,private prodottofinitoservice: ProdottofinitoService, public dialog: MatDialog) {
    this.getRowStyle = (params) => {
      if (params.data.ColoreStatus == 1) {
        return { background: '#EDB100' };
      }  else if(params.data.ColoreStatus == 2){
        return { background: '#4caf50' };
      }else
        return false
    };
  }

  private gridApi!: GridApi<any>;
  public gridColumnApi!: ColumnApi;

  ngOnInit(): void {
  }

  onFirstDataRendered(event: FirstDataRenderedEvent){
    this.gridApi.getDisplayedRowAtIndex(0).setExpanded(true); //Espando il primo gruppo della lista
  }
  

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    this.reloadData();
  }
  openSnackBar(message: string, time: number = 2000) {
    this._snackBar.open(message, 'Chiudi', {
      duration: time
    });
  }
  reloadData(data = null) {
    this.loading = 'block';
    return this.prodottofinitoservice.GetPrenotazionePresa(data).subscribe({
      next: (response: PrenotazionePresa[]) => {
        this.loading = 'none';
        if (response) {
          let x= [];

          response.forEach((v)=>{
            x.push(this.PushChildrenPN(v));
          })
          

          var defaultSortModel: ColumnState[] = [
            { colId: "numeroOrdine", sort: "asc", sortIndex: 0 },
            { colId: "descrizioneDestinatario", sort: "asc", sortIndex: 1 },
            { colId: "indirizzoSpedizione", sort: "asc", sortIndex: 2 },
          ];
          this.gridColumnApi.applyColumnState({ state: defaultSortModel });

          this.rowData = x;
        } else {
          console.log('no ok',response)
          return null
        }
      },
      error: (err: HttpErrorResponse) => {
        this.loading = 'none';
        console.error(err)
        if(err.status == 500)
          this.openSnackBar(err.message);
        else
          this.openSnackBar('Si è verificato un errore');
        return null
      }
    });

  }

  sumX = 0; sumY= 0; sumZ= 0;

  PushChildrenPN(v){
    let udc= [];
    
    v.dettaglioUDC.forEach((dett)=>{
       let dimensioni = dett.larghezza + 'm / ' +  dett.altezza + 'm / ' +  dett.profondita + "m";
      // this.sumX += dett.larghezza;
      // this.sumY += dett.altezza;
      // this.sumZ += dett.profondita;
      udc.push({
        partNumber:dett.partNumber,
        qtaOrdine:dett.qta,
        numeroColli: dett.pzConfezione,
        dimensioniSingoloUDC: dimensioni,
        pesoSingoloUDC: dett.peso,
        udc: dett.udc,
      })

      /**
       * { field: "partNumber", headerName: 'PartNumber', filter: 'agTextColumnFilter', },
        { field: "qtaOrdine", headerName: 'Qta Ordine', filter: 'agNumberColumnFilter' },
        { field: "numeroColli", headerName: 'N° Colli', filter: 'agTextColumnFilter' },
        { field: "dimensioniSingoloUDC", headerName: 'Dimensioni Singolo UDC', filter: 'agTextColumnFilter' },
        { field: "pesoSingoloUDC", headerName: 'Peso Singolo UDC', filter: 'agTextColumnFilter' },
       */
    })
    // console.log(v.dettaglioUDC)
    // console.log(udc)
    // udc.push({
    //   partNumber: '',
    //   qtaOrdine: this.sumUDCElements(udc, 'qtaOrdine'),
    //   numeroColli: this.sumUDCElements(udc, 'qtaOrdine'),
    //   dimensioniSingoloUDC: '',
    //   pesoSingoloUDC: this.sumUDCElements(udc, 'pesoSingoloUDC'),
    //   udc: '',
    // })
    
    let ret = {
      id: v.id,
      codiceClienteDestinatario:v.codiceClienteDestinatario,
      descrizioneDestinatario:v.descrizioneDestinatario,
      codiceIndirizzoSpedizione:v.codiceIndirizzoSpedizione,
      dataPresa:v.dataPresa,
      dataSpedizione:v.dataSpedizione,
      // codiceVettore:v.vettore.codiceVettore,
      descrizioneVettore:v.vettore.descrizioneVettore,
      infoPrenotazione:v.infoPrenotazione,
      ColoreStatus: v.coloreStatus,
      indirizzoSpedizione: v.indirizzoSpedizione,
      numeroOrdine: v.numeroOrdine,
      utente: v.utente,
      partNumber: v.partNumber,
      children:udc
      }

      return ret
  }

  pinnedRows: Map<string, any[]> = new Map();

  sumUDCElements(arr, key) {
    return arr.reduce((accumulatore, oggetto) => {
      //if (key != 'dimensioniSingoloUDC') {
        if (oggetto.hasOwnProperty(key) && typeof oggetto[key] === 'number') {
          return accumulatore + oggetto[key];
        }
        return accumulatore;
      // } else {
      //   let dimensioni = oggetto.dimensioniSingoloUDC.split("/");
      //   let dimensioniAcc = accumulatore.dimensioniSingoloUDC.split("/");
      //   dimensioni[0] = dimensioni[0] + dimensioniAcc[0];
      //   dimensioni[1] = dimensioni[1] + dimensioniAcc[1];
      //   dimensioni[2] = dimensioni[2] + dimensioniAcc[2];

      //   return dimensioni.join(" / ")
      // }
    }, 0);
  }

  public rowData: any[] | null;
  

  public columnDefs: ColDef[] = [
    {field: "codiceClienteDestinatario", headerName: 'Codice Cliente Destinatario', filter: "agSetColumnFilter", cellRenderer: 'agGroupCellRenderer', sortable: true, checkboxSelection:(param)=>{
      if(param.data.ColoreStatus != 2)
        return true
      else
        return false
    },
    tooltipComponentParams: { color: "#55AA77" },
    tooltipValueGetter: (p: ITooltipParams) =>{
      return p.data.codiceClienteDestinatario
    }
  },
    {field: "descrizioneDestinatario", headerName: 'Descrizione Destinatario', filter: "agSetColumnFilter", resizable:true, sortable: true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.descrizioneDestinatario
      }
    },
    // {field: "partNumber", headerName: 'Part Number', filter: "agSetColumnFilter", resizable:true,
    //   tooltipComponentParams: { color: "#55AA77" },
    //   tooltipValueGetter: (p: ITooltipParams) =>{
    //     return p.data.partNumber
    //   }
    // },
    // {field: "codiceIndirizzoSpedizione", headerName: 'Codice Indirizzo Spedizione', filter: "agSetColumnFilter", resizable:true,
    //   tooltipComponentParams: { color: "#55AA77" },
    //   tooltipValueGetter: (p: ITooltipParams) =>{
    //     return p.data.codiceIndirizzoSpedizione
    //   }
    // },
    {field: "indirizzoSpedizione", headerName: 'Indirizzo Spedizione', filter: "agSetColumnFilter", resizable:true, sortable: true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.indirizzoSpedizione
      }
    },
    {field: "dataPresa", headerName: 'Data Presa', type: 'dateColumn',cellClass: "dateUK", resizable:true, sortable: true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return new Date(p.data.dataPresa).toLocaleDateString('it-IT')
      }
    },
    {field: "dataSpedizione", headerName: 'Data Spedizione', type: 'dateColumn' ,cellClass: "dateUK", resizable:true, sortable: true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return new Date(p.data.dataSpedizione).toLocaleDateString('it-IT')
      }
    },
    //{field: "codiceVettore", headerName: 'Codice Vettore', filter: "agSetColumnFilter", resizable:true, },
    {field: "descrizioneVettore", headerName: 'Descrizione Vettore', filter: "agSetColumnFilter", resizable:true,  sortable: true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.descrizioneVettore
      }
    },
    {field: "infoPrenotazione", headerName: 'Info Prenotazione', filter: "agSetColumnFilter", resizable:true, tooltipComponentParams: { color: "#55AA77" }, sortable: true,
    tooltipValueGetter: (p: ITooltipParams) =>{
      return p.data.infoPrenotazione
    }},
    {field: "numeroOrdine", headerName: 'Num. Ordine', filter: "agSetColumnFilter", resizable:true, tooltipComponentParams: { color: "#55AA77" }, sortable: true,
    tooltipValueGetter: (p: ITooltipParams) =>{
      return p.data.numeroOrdine
    }},
    {field: "utente", headerName: 'Utente', filter: "agSetColumnFilter", resizable:true,  sortable: true,
      tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.utente
      }
    },
  ];

  
  
  public defaultColDef: ColDef = {
    flex: 1,
    enableCellChangeFlash: true,
    filter: true,
    // enable floating filters by default
    floatingFilter: true,
    
  };

  filterDate: any = null;

  public gridOptions: GridOptions= {
    // getRowStyle: (params) => {
    //   if (params.data.Status == 1) {
    //     return { background: 'red' };
    //   } else
    //     return false
    // }
    
    onFilterChanged: (event)=> {
      if (event.columns[0].getColId() == 'dataPresa') { 
        if (this.gridApi.getFilterModel()['dataPresa'])
          this.filterDate = this.gridApi.getFilterModel()['dataPresa']?.dateFrom.split(' ')[0];
        else
          this.filterDate = null
        this.reloadData(this.filterDate)
      }

      
    },
    getRowStyle(params) {
      if (params.data.Status == 1) {
            return { background: '#ACD8FB' };
          } else
            return { background: 'transparent'}
    },
    
  }
  public sum = 0;
  public qtyEdited = 0;

  // myAggFunc(params: any) {
  //   console.log(params)
  //   return params.values[0];

  // }

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
    DataPresatype: {
      // cellRenderer: (params) => {
      //   console.log(params)
      //   return '<b>'+ params.value + '</b>';
      // },
      //cellRenderer: (params) => {return "agGroupCellRenderer" + 'asdasda'},
      // valueFormatter: (params) => {
      //   return 'PRESA DEL: ' + new Date(params.value).toLocaleDateString('it-IT')
      // },
      //cellStyle: { fontWeight: 'bold' },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }


  public dataresult: any[] = [];

  confermaPrenotazione() {
    let req: any[] = [];

    let rows = this.gridApi.getSelectedRows()
    
    let ids = rows.map(el => el.id);

    if (rows.length > 0) {
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Confermare l\'ordine per le righe selezionate?',
          BtnSi: 'Conferma',
          BtnNo: 'Annulla'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.prodottofinitoservice.UpdateConferma(ids).subscribe({
            next: (response: any) => {
              this.openSnackBar('Ordine Confermato');
              this.reloadData(this.filterDate);
              if (response) {
                console.log('ok', response)
              } else {
                console.log('no ok', response)
              }
            },
            error: (err: HttpErrorResponse) => {
              console.error(err.error)
              if(err.status == 500 || err.status == 400)
                this.openSnackBar(err.error != '' ? err.error : err.error.text);
              else
                this.openSnackBar(err.error.text, 3000);

              this.reloadData(this.filterDate);
            }
          })
        }
      }
      );
    }else{
      this.openSnackBar('Selezionare almeno una riga prima di confermare');
    }
  }

  public excelStyles: ExcelStyle[] = [
    {
      id: "dateUK",
      dataType: "DateTime",
      numberFormat: {
        format: "dd/mm/yyyy",
      },
    },
    {
      id: "header",
      interior: {
        color: "#aaaaaa",
        pattern: "Solid",
      },
    },
    {
      id: "body",
      interior: {
        color: "#dddddd",
        pattern: "Solid",
      },
    },
    {
      id: "footer",
      interior: {
        color: "#A9D08E",
        pattern: "Solid",
      },
    },
];

  onBtExport() {
    // var spreadsheets = [];
    // const mainSheet = this.gridApi.getSheetDataForExcel();
    // if (mainSheet) {
    //   spreadsheets.push(mainSheet);
    // }
    // this.gridApi.forEachDetailGridInfo(function (node) {
    //   const sheet = node.api!.getSheetDataForExcel({
    //     sheetName: node.id.replace("detail_", ""),
    //   });
    //   if (sheet) {
    //     spreadsheets.push(sheet);
    //   }
    // });
    // this.gridApi.exportMultipleSheetsAsExcel({
    //   data: spreadsheets,
    //   fileName: "PrenotazionePrese.xlsx",
    // });
    this.gridApi.exportDataAsExcel();
  }

  public rowSelected;
  public nodeSelected;


  public manualEdit = false;


  public defaultCsvExportParams: CsvExportParams = {
    getCustomContentBelowRow: (params) => {
      const rows = this.getRows(params);
      return rows.map((row) => row.cells) as CsvCell[][];
    },
  };

  public defaultExcelExportParams: ExcelExportParams = {
    getCustomContentBelowRow: (params) => this.getRows(params) as ExcelRow[],
    columnWidth: 120,
    fileName: "ag-grid.xlsx",
  };


  addPinnedBottomRow(params) {
    const detailGridApi = params.api;
    const detailData = params.context.masterGrid.data.children;

    const pinnedrow = [{
      partNumber: '',
      qtaOrdine: '',
      numeroColli: detailData.reduce((partialSum, a) => partialSum + a.numeroColli, 0),
      dimensioniSingoloUDC: '',
      pesoSingoloUDC: detailData.reduce((partialSum, a) => partialSum + a.pesoSingoloUDC, 0),
      udc: '',
    }]
    detailGridApi.setPinnedBottomRowData(pinnedrow)

    const gridId = params.context.masterGrid.data.id;
    this.pinnedRows.set(gridId, pinnedrow);

  }

  public groupTotalRow = 'bottom';

  public groupDefaultExpanded = 0;
  public detailCellRendererParams: any = (masterGridParams) => ({
    // level 2 grid options
    refreshStrategy: 'rows',
    detailGridOptions: {
      //pinnedBottomRowData: this.pinnedBottomRowData,
      detailRowAutoHeight: true,
      tooltipMouseTrack: true,
      tooltipShowDelay: 1,
      enableCellChangeFlash: true,
      masterDetail: true,
      columnTypes: this.columnTypes,
      context: {
        masterGrid: {
          node: masterGridParams.node.parent,
          data: masterGridParams.data,
          GridApi: masterGridParams.api
        }
      },
      onCellValueChanged: (event) => {
        if (this.manualEdit)
          this.manualEdit = false;
      },
      columnDefs: [
        { field: "partNumber", headerName: 'PartNumber', filter: 'agTextColumnFilter', tooltipComponentParams: { color: "#55AA77" },
        tooltipValueGetter: (p: ITooltipParams) =>{
          return p.data.partNumber
        }},
        { field: "udc", headerName: 'UDC', filter: 'agTextColumnFilter',
          tooltipComponentParams: { color: "#55AA77" },
          tooltipValueGetter: (p: ITooltipParams) =>{
            return p.data.udc
          }
         },
        { field: "qtaOrdine", headerName: 'Qta Ordine', filter: 'agNumberColumnFilter',
          tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.qtaOrdine
      }
        },
        { field: "numeroColli", headerName: 'N° Colli', filter: 'agTextColumnFilter',
          tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.numeroColli
      }
        },
        {
          field: "dimensioniSingoloUDC", headerName: 'Dimensioni Singolo UDC', filter: 'agTextColumnFilter', tooltipComponentParams: { color: "#55AA77" },
          // tooltipField: "QtaDispPn",
          tooltipValueGetter: (p: ITooltipParams) => {
            
            return p.data.dimensioniSingoloUDC;
          }
        },
        { field: "pesoSingoloUDC", headerName: 'Peso Singolo UDC', filter: 'agTextColumnFilter',
          tooltipComponentParams: { color: "#55AA77" },
      tooltipValueGetter: (p: ITooltipParams) =>{
        return p.data.pesoSingoloUDC
      }
        },
      ],
      onGridReady: (params) => {
        this.addPinnedBottomRow(params);
      },
      defaultColDef: {
        flex: 1,
        enableCellChangeFlash: true,
        filter: true,
        // enable floating filters by default
        floatingFilter: true,
      },
      groupDefaultExpanded: 0,

      detailRowHeight: 240,
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.children);
    },
  }) as IDetailCellRendererParams;

  // preCalculatePinnedBottomRows() {
  //   const rowData = this.gridOptions.api.getRowNode();
  //   rowData.forEach(node => {
  //     const detailData = node.data.detailData || [];
  //     const total = detailData.reduce((sum, current) => sum + (current.field2 || 0), 0);
  //     const pinnedBottomRowData = [{ field1: 'Total', field2: total }];
  //     this.pinnedBottomRowDataMap.set(node.data.id, pinnedBottomRowData);
  //   });
  // }

  getRows = (params: ProcessRowGroupForExportParams) => {
    const gridId = params.node.data.id;
    const rows = [
      {
        outlineLevel: 1,
        cells: [
          this.cell(""),
          this.cell("PartNumber", "header"),
          this.cell("Qta Ordine", "header"),
          this.cell("N° Colli", "header"),
          this.cell("Dimensioni Singolo UDC", "header"),
          this.cell("Peso Singolo UDC", "header"),
        ],
      },
    ].concat(
      ...params.node.data.children.map((record: any) => [
        {
          outlineLevel: 1,
          cells: [
            this.cell(""),
            this.cell(record.partNumber, "body"),
            this.cell(record.qtaOrdine, "body"),
            this.cell(record.numeroColli, "body"),
            this.cell(record.dimensioniSingoloUDC, "body"),
            this.cell(record.pesoSingoloUDC, "body"),
          ],
        },
      ]),
    );

    //rows.push(...children);

    // Aggiungi le righe fissate in basso
    const pinnedBottomRowData = this.pinnedRows.get(gridId);
    if (pinnedBottomRowData && pinnedBottomRowData.length) {
      const pinnedRows = pinnedBottomRowData.map((record: any) => ({
        outlineLevel: 1,
        cells: [
          this.cell(""),
          this.cell(record.partNumber, "footer"),
          this.cell(record.qtaOrdine, "footer"),
          this.cell(record.numeroColli, "footer"),
          this.cell(record.dimensioniSingoloUDC, "footer"),
          this.cell(record.pesoSingoloUDC, "footer"),
        ],
      }));
      rows.push(...pinnedRows);
    }

    return rows;
  };
  cell(text: string, styleId?: string): ExcelCell {
    return {
      styleId: styleId,
      data: {
        type: /^\d+$/.test(text) ? "Number" : "String",
        value: String(text),
      },
    };
  }

}
