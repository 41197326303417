import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { UDC } from 'src/app/Models/UDC';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { DialogStampaUdcComponent } from '../dialog-stampa-udc/dialog-stampa-udc.component';

export class Tab {
  id: number | null = 0;
  nome: string = "";
}

@Component({
  selector: 'app-creaudc',
  templateUrl: './creaudc.component.html',
  styleUrls: ['./creaudc.component.scss']
})
export class CreaudcComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: UDC[];
  label = "Lista"
  tabs: Tab[] = [];
  idUDC: number = 0;
  ndoc: string='';


  idtipo:number=1;
  onChangeTipo(e : number){
    this.idtipo=e;
    this.reloadData(this.idtipo)
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.reloadData(this.idtipo)

  }

  refreshGrid() {
    this.reloadData(this.idtipo)
  }
  reloadData(id : number = 1) {
    this.loading = 'block';
    // this.http
    //   .get<Ingresso[]>("https://testapiall1.azurewebsites.net/api/documenti/geti/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
    this.documentiService.GetUDCV(id).subscribe((data) => {
      this.rowData$ = data;
      this.loading = 'none';
    });

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  qta = 0;
  newUdc(){
    if(this.qta != 0){
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Verranno create '+ this.qta + ' nuove UDC, Continuare?',
          BtnSi: 'Continua',
          BtnNo: 'Annulla'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.documentiService.stampaUDCV(this.qta,1).subscribe(data => {this.loading = 'none'
            this.openSnackBar('UDC creati');
            this.refreshGrid();
            this.qta=0;
          }
          );
        }
      });
    }else{
      this.openSnackBar('Inserire un valore maggiore di 0');
    }

  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onPrint(){
    const dialogRef = this.dialog.open(DialogStampaUdcComponent, {
      data: {
       qtaudc:1
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result)
        this.documentiService.stampaUDCV(result.qtaudc, 2).subscribe((data) => {
          this.loading = 'none'
          if (data[0].err = '0') {
            var fileblob = this.b64toBlob(data[0].msg, 'application/pdf');
            var url = window.URL.createObjectURL(fileblob);
            this.refreshGrid()
            window.open(url);
          } else {
            this.openSnackBar(data[0].msg);
          }
        });
      }
    });
  }


  newUdcPrint(){
    if(this.qta != 0){
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Verranno create '+ this.qta + ' nuove UDC e poi stampate, Continuare?',
          BtnSi: 'Continua',
          BtnNo: 'Annulla'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.documentiService.stampaUDCV(this.qta, 0).subscribe((data) => {
            this.loading = 'none'
            if (data[0].err = '0') {
              this.openSnackBar('UDC creati');
              this.refreshGrid()
              var fileblob = this.b64toBlob(data[0].msg, 'application/pdf');
              var url = window.URL.createObjectURL(fileblob);
    
              window.open(url);
              this.qta=0;
            } else {
              this.openSnackBar(data[0].msg);
            }
          });
        }
      });
    }else{
      this.openSnackBar('Inserire un valore maggiore di 0');
    }
  }

  private gridApi!: GridApi<any>;
  eliminaUdc(){
    let selection = this.gridApi.getSelectedRows();
    console.log(selection)
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: {
          messaggio: 'Verranno eliminate '+ selection.length +' UDC selezionate, Continuare?',
          BtnSi: 'Continua',
          BtnNo: 'Annulla'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          
          

        }
      });
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    
    { field: 'nomeUDC', headerName: "Nome UDC", width: 200, filter: 'agTextColumnFilter' },
    { field: 'data',headerName:'Data', width: 200, type: 'dateColumn' },
    

  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
       //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  constructor(private http: HttpClient, private documentiService: DocumentiService,private _snackBar: MatSnackBar,public dialog: MatDialog,) { }

  ngOnInit(): void {
  }

}
