import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent } from 'ag-grid-community';
import { Articolo } from 'src/app/Models/Articolo';
import { Ingresso } from 'src/app/Models/Ingresso';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { Prelievo } from 'src/app/Models/Prelievo';
import { PrelievoDettagli } from 'src/app/Models/PrelievoDettagli';
import { ArticoloService } from 'src/app/services/articolo.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogIngressoArticoloComponent } from '../dialog-ingresso-articolo/dialog-ingresso-articolo.component';


@Component({
  selector: 'app-dialogcercaspedizioni',
  templateUrl: './dialogcercaspedizioni.component.html',
  styleUrls: ['./dialogcercaspedizioni.component.scss']
})
export class DialogcercaspedizioniComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogcercaspedizioniComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      ClienteID: number,
      NumUdc: string,
      Descrizione: string,
      PartNumber: string,
      Qta: number,
      QtaEvasa: number
    },
    private articoliService: ArticoloService,
    private documentiService: DocumentiService,
    public dialog: MatDialog
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.loadData()
  }

  rowArticoli: PrelievoDettagli[] = [];
  
  public columnDefsArticoli: (ColDef | ColGroupDef)[] = [
    { field: 'udc', headerName: "Udc", type: 'numberColumn', width: 150 , filter: 'agTextColumnFilter'},
    { field: 'descrizione', headerName: "Descrizione", width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 150, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 90, filter: 'agNumberColumnFilter' },
    { field: 'qtaEvasa', headerName: "Evasa", type: 'numberColumn', width: 90, filter: 'agNumberColumnFilter' },

  ];
  


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  loadData() {
    this.documentiService.GetSpedizioni(this.data.ClienteID).subscribe((data) => {
      this.rowArticoli = data;
    });

  }
  
  RefreshSpedizioni(params : GridReadyEvent){
    this.gridColumnApi = params.columnApi;
    this.loadData();
  }

  loadDataDettaglio(idarticolo: number = 0) {

    let articolo = this.rowArticoli.find((e) => e.gridIndex == idarticolo);

    this.data.NumUdc = articolo?.numUdc || '';
    this.data.Descrizione = articolo?.descrizione || '';
    this.data.Qta = articolo?.qta || 0;
    this.data.QtaEvasa = articolo?.qta || 0;
    this.data.PartNumber = articolo?.partNumber;

    this.dialogRef.close(this.data)

  }

}
