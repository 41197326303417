<form>
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <!-- <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button> -->
            
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>
            <button class="button-tab-delete" type="button" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button> -->

        </mat-card-actions>
        
        <app-grid [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowData]="rowData" (gridReady)="onGridReady($event)"
            >
        </app-grid>
        

    </mat-card>
</form>