import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticatedResponse } from 'src/app/Models/AuthenticatedResponse';
import { LoginModel } from 'src/app/Models/LoginModel';
import { User } from 'src/app/Models/user.model';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // private currentUserSubject: BehaviorSubject<any | null>;
  // public currentUser$: Observable<any | null>;


  invalidLogin: boolean;
  credentials: LoginModel = {username:'', password:''};
  constructor(private _snackBar: MatSnackBar,private router: Router, private http: HttpClient, private loginService: LoginService,private jwtHelper: JwtHelperService,private authService: AuthServiceService) { 

    // let storageUser: any | null = null;
    // const storageUserAsStr = localStorage.getItem('currentUser');
    // if (storageUserAsStr) {
    //   storageUser = JSON.parse(storageUserAsStr);
    // }
    // this.currentUserSubject = new BehaviorSubject<any | null>(storageUser);
    // this.currentUser$ = this.currentUserSubject.asObservable();

  }
  ngOnInit(): void {
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }
  login = ( form: NgForm) => {
    if (form.valid) {
      this.loginService.login(this.credentials).subscribe({
        next: (response: AuthenticatedResponse) => {
          if (response) {
            //const token = response.token;
            const decodedToken = this.jwtHelper.decodeToken(response.token);
            
            // this.authService.createUser(decodedToken.Idsito,decodedToken.Role, decodedToken.userid, decodedToken.name, decodedToken._exp, token)
            localStorage.setItem("r", JSON.stringify(response));
            this.authService.createUserAlt(response.id, response.roleName, response.idUtente, response.firstName, response.lastName, response.username, response.expiresIn, response.token)

            this.invalidLogin = false;
            this.router.navigate(["/main"])

          } else {
            this.openSnackBar('Credenziali Errate');
            this.credentials.password = '';
            this.credentials.username = '';
          }
        },
        error: (err: HttpErrorResponse) => {
          this.invalidLogin = false;
          console.error(err)
          if(err.status == 500 || err.status == 400)
            this.openSnackBar(err.error.message != '' ? err.error.message : err.message);
          else
            this.openSnackBar('Si è verificato un errore');

        }
      })
    }
  }
}
