<mat-sidenav-container style="height:100vh" autosize>
    <mat-sidenav #sidenav mode="side" opened>
        <div class="w3-padding" id="sidenav-head">
        <div>
            <img src="../../../assets/images/logo.png" class="logo"/>
            
        </div>
        <div class="close-sidenav">
            <a routerLink="/main/dashboard" style="text-decoration: none;color:white"><mat-icon>home</mat-icon></a>
            <mat-icon (click)="closeSidenav()" >keyboard_double_arrow_left</mat-icon>
        </div>
    </div>
        <div class="row-menu"></div>
        <mat-nav-list>
            <b class="titolo-voci">PREFERITI</b>
            <mat-tree [dataSource]="dataSourcePreferiti" [treeControl]="treeControl">
                <!-- <mat-tree-node *matTreeNodeDef="let node; when: hasChild" class="voce">
                    {{node.name}}
                    <button mat-icon-button matTreeNodeToggle *ngIf="treeControl.isExpandable(node)" >
                        <mat-icon>
                            <span style="font-size:24px" *ngIf="treeControl.isExpanded(node); else open">keyboard_arrow_right</span>
                            <ng-template #open>keyboard_arrow_down</ng-template>
                        </mat-icon>
                    </button>
                </mat-tree-node> -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding class="tree-child voce" routerLinkActive="attivo">
                    <a routerLink="{{'/main/' + node.RouterLink}}" (click)="nodeClick(node)"  >
                        {{node.name}} 
                    </a>
                        <div>
                        <mat-icon (click)="apriFinestra(node)" class="linkfinestra" style="font-size: 18px;line-height: 1.5;">open_in_new</mat-icon>
                        <mat-icon (click)="togglePreferiti(node)" class="stella">star</mat-icon>
                    </div>
                </mat-tree-node>
            </mat-tree>
        </mat-nav-list>
        <div class="row-menu"></div>
        <mat-nav-list>
            <b class="titolo-voci">MENU</b>
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" class="voce" matTreeNodeToggle >
                        {{node.name}} 
                    <button mat-icon-button *ngIf="treeControl.isExpandable(node)">
                        <mat-icon>
                            <span style="font-size:24px" *ngIf="treeControl.isExpanded(node); else open">keyboard_arrow_right</span>
                            <ng-template #open>keyboard_arrow_down</ng-template>
                        </mat-icon>
                    </button>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node"  matTreeNodePadding class="tree-child voce" routerLinkActive="attivo">
                    <a routerLink="{{'/main/' + node.RouterLink}}" (click)="nodeClick(node)">
                        {{node.name}} 
                    </a>
                    <div>
                        <mat-icon (click)="apriFinestra(node)" class="linkfinestra" style="font-size: 18px;line-height: 1.5;z-index: 100;">open_in_new</mat-icon>
                        <mat-icon (click)="togglePreferiti(node)" class="stella" *ngIf="!node.favorite; else favorite">star_outline</mat-icon>
                    <!-- <button class="icon-button" style="padding:3px" (click)="togglePreferiti(node)" >
                        <img
                        class="icon"
                        src="../../../assets/icons/stella_STROKE.png"
                        style="width: 18px; height: 18px"
                        />
                    </button> -->
                          <ng-template #favorite><mat-icon (click)="togglePreferiti(node)" class="stella" >star</mat-icon></ng-template>
                    </div>
                </mat-tree-node>
            </mat-tree>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class=" router-container">
        <mat-toolbar class="toolbar">
            <button mat-icon-button class="open-menu-button" (click)="openSidenav()"
            *ngIf="!SidenavOpened"><mat-icon>menu</mat-icon></button>
            <p class="title-page">{{title}}</p>
            <div style="display:flex;">
            <img src="../../../assets/images/stikstok.png" width="120" style="margin-right: 20px;"/>
            <div class="div-menu-user">
                <h2><mat-icon>person</mat-icon>{{username}}</h2>
                <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>keyboard_arrow_down</mat-icon></button>
                <mat-menu #menu="matMenu">
                    <div style="padding:8px;display: flex; flex-direction: column;">
                        <a class="menu-user" (click)="openChangePsw()"><mat-icon>settings</mat-icon> Impostazioni</a>
                        <a class="exit" (click)="onExit()"><mat-icon>logout</mat-icon> Esci</a>
                    </div>
                </mat-menu>
            </div>
        </div>
          </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>