import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Accettazione } from '../Models/Accettazione';
import { Giacenza } from '../Models/Giacenza';
import { Ingresso } from '../Models/Ingresso';
import { Missione } from '../Models/Missione';
import { Prelievo } from '../Models/Prelievo';
import { Movimento } from '../Models/Movimento';
import { UDC } from '../Models/UDC';
import { PrelievoDettagli } from '../Models/PrelievoDettagli';
import { Ordine } from '../Models/Ordine';
import { OrdineDettagli } from '../Models/OrdineDettagli';
import { ODS } from '../Models/ODS';
import { ODSDettagli } from '../Models/ODSDettagli';
import { environment } from 'src/environments/environment';
import { ArticoloInventario, InventarioMaster } from '../Models/Inventario';


@Injectable({
  providedIn: 'root'
})
export class DocumentiService {

  private apiUrl1 = environment.apiUrl;
  constructor(private http: HttpClient) { }

  GetDocumento(idSito: number ,id: number): Observable<Ingresso[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/geti/?idSito="+idSito+"&id=" + id}`;
    return this.http.get<Ingresso[]>(url, httpOptions);
  }
  GetDocumentoUscita(idSito: number ,id: number, confermato : number): Observable<Ingresso[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/getu/?idSito="+idSito+"&id=" + id+"&id2=" + confermato}`;
    return this.http.get<Ingresso[]>(url, httpOptions);
  }

  InsDocumento(ingresso: Ingresso): Observable<Ingresso> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/UpdIns_Documenti";
    return this.http.put<Ingresso>(url, ingresso, httpOptions);
  }

  InsInventarioMaster(inventario: InventarioMaster): Observable<InventarioMaster> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/inventario/InsInventarioMaster";
    return this.http.put<InventarioMaster>(url, inventario, httpOptions);
  }

  InsArticoloInventario(articoli: ArticoloInventario): Observable<ArticoloInventario> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/inventario/InsArticoloInventario";
    return this.http.put<ArticoloInventario>(url, articoli, httpOptions);
  }

  EliminaArticoloInventario(ids: string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/inventario/EliminaArticoloInventario";
    return this.http.put<any>(url, ids, httpOptions);
  }

  AvviaInventario(id: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/inventario/AvvioInventario?id=" + id;
    return this.http.put<any>(url, httpOptions);
  }

  TerminaInventario(id: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/inventario/TerminaInventario?id=" + id;
    return this.http.put<any>(url, httpOptions);
  }

  UpdUDCinDDT(gridIndex: number, Elimina: number, Qta: number): Observable<Ingresso> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/UpdUDCinDDT?id=" + gridIndex + "&id2=" + Elimina + "&id3=" + Qta;
    return this.http.put<Ingresso>(url, httpOptions);
  }

  GetUDC(id: number, id2: number, idSito: number): Observable<UDC[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetUdc/?id=" + id + "&id2=" + id2 + "&idsito=" + idSito}`;
    return this.http.get<UDC[]>(url, httpOptions);
  }

  GetPN(id: number, id2: number, idSito: number): Observable<UDC[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetPN/?id=" + id + "&id2=" + id2 + "&idsito=" + idSito}`;
    return this.http.get<UDC[]>(url, httpOptions);
  }

  GetUDCV(id: number): Observable<UDC[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetUdcV/?id=" + id}`;
    return this.http.get<UDC[]>(url, httpOptions);
  }

  GetNoUDC(id: number, id2:number, id3:number): Observable<Ingresso[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetNoUDC/?id=" + id+'&id2=' + id2+'&id3=' + id3}`;
    return this.http.get<Ingresso[]>(url, httpOptions);
  }

  GetUDCnoUDM(id: number): Observable<UDC[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetUdcnoUDM/?id=" + id}`;
    return this.http.get<UDC[]>(url, httpOptions);
  }

  InsUDC(type:number = 1, qta:number = 1 ): Observable<UDC> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/CreaUDC?id="+type + "&qta="+ qta;
    return this.http.get<UDC>(url, httpOptions);
  }

  InsUDCDoc(idDoc: number, type : number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/Create_UDCxDocumento?id="+idDoc +'&id2=' + type;
    return this.http.get<any>(url, httpOptions);
  }

  AccettaDocumento(idDoc: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/AccettaDocumento?id="+idDoc;
    return this.http.put<string>(url, httpOptions);
  }

  Upd_VariantiXUDC(idDoc: number, nota : string, valore: string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/Upd_VariantiXUDC?IdUdc="+idDoc +'&TipoVariante=' + nota + '&Valore=' + valore;
    return this.http.put<any>(url, httpOptions);
  }

  GetUDCAccettazione(id: number): Observable<UDC[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetUDCAccettazione/?id=" + id}`;
    return this.http.get<UDC[]>(url, httpOptions);
  }

  InsAccettazione(accettazione:Accettazione): Observable<Accettazione> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/Crea_Accettazione";
    return this.http.put<Accettazione>(url, accettazione , httpOptions);
  }

  GetMissioni(id: number): Observable<Missione[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetMissioni/?id=" + id}`;
    return this.http.get<Missione[]>(url, httpOptions);
  }

  InsMovimentazione(obj:Movimento): Observable<Movimento> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/Crea_Movimento";
    return this.http.put<Movimento>(url, obj , httpOptions);
  }

  GetMovimenti(id: number, id2: number): Observable<Movimento[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetMovimenti/?id=" + id+"&id2=" + id2}`;
    return this.http.get<Movimento[]>(url, httpOptions);
  }

  GetMovimentiInventario(id: number): Observable<Movimento[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetMovimentiInventario/?id=" + id}`;
    return this.http.get<Movimento[]>(url, httpOptions);
  }

  GetVarianti(id: number): Observable<Movimento[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetVariantixUDC/?id=" + id}`;
    return this.http.get<Movimento[]>(url, httpOptions);
  }

  GetGiacenze(id: number): Observable<Giacenza[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetGiacenze/?id=" + id}`;
    return this.http.get<Giacenza[]>(url, httpOptions);
  }



  GetCausaliRettifica(): Observable<Giacenza[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetCausaliRettifica"}`;
    return this.http.get<Giacenza[]>(url, httpOptions);
  }
  /*
  idudc
  valore
   */
  RettificaUDC(giacenza: any, IdCausale: number): Observable<any[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/RettificaUDC?idcausale=" + IdCausale}`;
    return this.http.put<any[]>(url,giacenza ,httpOptions);
  }

/*
  join di idudc
  idlocazione
 */
  SpostaUDC(udcs: string, locazione: number): Observable<any[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/SpostaUDC?idgiacenza=" + udcs + "&locazioneid=" + locazione}`;
    return this.http.put<any[]>(url, httpOptions);
  }

  GetGiacenzexArticoloId(id: number): Observable<Giacenza[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/documenti/GetGiacenzexArticoloId/?id=" + id}`;
    return this.http.get<Giacenza[]>(url, httpOptions);
  }


  GetAssembly(id: number, idSito: number): Observable<Prelievo[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetAssembly/?id2="+idSito+"&id=" + id}`;
    return this.http.get<Prelievo[]>(url, httpOptions);
  }

  GetInventarioMaster(id: number): Observable<InventarioMaster[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/inventario/GetInventarioMaster/?id="+id}`;
    return this.http.get<InventarioMaster[]>(url, httpOptions);
  }

  ElencoArticoloInventario(id: number): Observable<ArticoloInventario[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/inventario/ElencoArticoloInventario/?id="+id}`;
    return this.http.get<ArticoloInventario[]>(url, httpOptions);
  }

  ElencoInventarioSlave(id: number): Observable<any[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/inventario/ElencoInventarioSlave/?id="+id}`;
    return this.http.get<any[]>(url, httpOptions);
  }

  GetPrelievi(id: number, idSito: number): Observable<Prelievo[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/Get/?id2="+idSito+"&id=" + id}`;
    return this.http.get<Prelievo[]>(url, httpOptions);
  }

  GetOrdine(id: number, idSito: number): Observable<Ordine[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetOrdine/?id="+id+"&id2=" + idSito}`;
    return this.http.get<Ordine[]>(url, httpOptions);
  }

  GetPrelievoSpedizioniHEAD(id: number): Observable<Ordine[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetPrelievoSpedizioniHEAD/?id="+id+"&id2=" + -1}`;
    return this.http.get<Ordine[]>(url, httpOptions);
  }
  GetOrdineDettagliProntoxCarico(id: number, id2: number): Observable<Ordine[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetOrdineDettagliProntoxCarico/?id="+id+"&id2=" + id2}`;
    return this.http.get<Ordine[]>(url, httpOptions);
  }

  GetOrdinexIntestatario(id: number, idSito: number): Observable<Ordine[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/jsonjson',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetOrdinexIntestatario/?id="+id+"&id2=" + idSito}`;
    return this.http.get<Ordine[]>(url, httpOptions);
  }

  GetOrdineDettagli(idordine: number, IDOrdineDettaglio: number): Observable<OrdineDettagli[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetOrdineDettagli/?id="+idordine+"&id2=" + IDOrdineDettaglio}`;
    return this.http.get<OrdineDettagli[]>(url, httpOptions);
  }

  GetODS(id: number, idSito: number): Observable<ODS[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetODS/?id="+id+"&IdSito=" + idSito}`;
    return this.http.get<ODS[]>(url, httpOptions);
  }

  GetODSDettagli(IDODS: number, IDODSDettaglio: number): Observable<ODSDettagli[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/Prelievo/GetODSDettagli/?id="+IDODS+"&id2=" + IDODSDettaglio}`;
    return this.http.get<ODSDettagli[]>(url, httpOptions);
  }

  InsPrelievo(missione:Prelievo): Observable<Prelievo> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/prelievo/UpdIns_Prelievo";
    return this.http.put<Prelievo>(url, missione , httpOptions);
  }

  InsDocumentoODS(ids:string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/CreaDocumentoDaODSList/?id=" + ids;
    return this.http.put<any>(url, httpOptions);
  }

  DelDocumentoODS(id:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/Del_ODSinDocumentoUScita/?id=" + id;
    return this.http.put<any>(url, httpOptions);
  }

  DelDocumentoDDT(id:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/DelDocumentoDDT/?id=" + id;
    return this.http.put<any>(url, httpOptions);
  }

  Del_GruppodiCarico(id:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/Del_GruppodiCarico/?id=" + id;
    return this.http.put<any>(url, httpOptions);
  }

  Del_Ordine(id:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      }),      
    };

    const url = this.apiUrl1 + "/prelievo/del_Ordine?id=" + id;
    return this.http.put<any>(url, httpOptions);
  }

  InsOrdine(ordine:Ordine): Observable<Ordine> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/prelievo/UpdIns_Ordine";
    return this.http.post<Ordine>(url, ordine , httpOptions);
  }

  InsODS(ods:ODS): Observable<ODS> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/prelievo/UpdIns_ODS";
    return this.http.put<ODS>(url, ods , httpOptions);
  }

  GetSpedizioni(id: number): Observable<PrelievoDettagli[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/prelievo/GetSpedizioni/?id=" + id}`;
    return this.http.get<PrelievoDettagli[]>(url, httpOptions);
  }

  GenerateTracciatoDDT(id: Number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/GenerateTracciatoDDT/?DocumentoID="+id;
    return this.http.post<any>(url, id , httpOptions);
  }

  StampaDoc(id: number, id2:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/Stampadoc/?id="+id + "&id2="+ id2;
    return this.http.put<any>(url, id , httpOptions);
  }

  stampaUDC(documentoID: number, Tipo:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/stampaUDC/?documentoID="+documentoID + "&Tipo="+ Tipo;
    return this.http.get<any>(url , httpOptions);
  }

  StampaPicking(documentoID: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/StampaPicking/?documentoID="+documentoID+"&IdSito=" + 14;
    return this.http.get<any>(url , httpOptions);
  }

  StampaEtichetta(numOrdine: string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/Stampa/StampaEtichetta?numOrdine="+numOrdine + "&isTest=false";
    return this.http.get<any>(url , httpOptions);
  }

  StampaOrdine(id: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/StampaOrdine/?id="+id;
    return this.http.get<any>(url , httpOptions);
  }

  StampaOrdineDdt(id: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/StampaOrdineDdt/?id="+id;
    return this.http.get<any>(url , httpOptions);
  }

  stampaUDCV(nr: number, tipo:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/stampaUDCV/?nr="+nr + "&tipo="+ tipo;
    return this.http.get<any>(url , httpOptions);
  }

  StampaMultiUDC(udc: string, Tipo:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/StampaMultiUDC/?udc="+udc + "&Tipo="+ Tipo;
    return this.http.get<any>(url , httpOptions);
  }

  CreaGruppoOrdini(ids:string): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/documenti/CreaGruppoOrdini/?id=" + ids;
    return this.http.put<any>(url, httpOptions);
  }

}
