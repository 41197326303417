<form [formGroup]="form">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
           
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>
            <button class="button-tab-delete" type="button" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button> -->
            <div *ngIf="!isAbbinato">
                <button class="button-tab-save" type="button" title="Salva" (click)="onSave()">
                    <mat-icon style="font-size:20px">save</mat-icon>
                </button>
            </div>
            </mat-card-actions>
            <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)" (selectedTabChange)="gridload($event)">
                <mat-tab label="Giacenze" *ngIf="isAbbinato && isAdmin">
                    <h3>Giacenze</h3>
                    <label>
                        <input type="checkbox" (change)="onGroupingCheckboxChanged($event)" /> Raggruppa per Ubicazione
                      </label>
                    <ag-grid-angular style="width: 100%; height: 60vh" class="ag-theme-alpine" [columnDefs]="columnDefsGia"
                        [defaultColDef]="defaultColDef" [rowData]="rowDataGia$" [animateRows]="true" [groupIncludeFooter]="isGroupingEnabled"
                        [groupIncludeTotalFooter]="isGroupingEnabled" [autoGroupColumnDef]="autoGroupColumnDef" (gridReady)="onGridReady($event)"
                        [columnTypes]="columnTypes">
                    </ag-grid-angular>
                        <!-- <app-grid [columnDefs]="columnDefsGia"
            [defaultColDef]="defaultColDef" [rowData]="rowDataGia$" (gridReady)="onGridReady($event)" style='width: 100%; height: 60vh'
            >
        </app-grid> -->
                </mat-tab>
                <mat-tab label="Movimenti" *ngIf="isAbbinato">
                    <h3>Movimentazione</h3>
                        <ag-grid-angular style="width: 100%; height: 60vh" class="ag-theme-alpine" [columnDefs]="columnDefsMov"
                            [defaultColDef]="defaultColDef" [rowData]="rowDataMov$" [rowSelection]="'single'" [animateRows]="true"
                             [columnTypes]="columnTypes" (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                        <!-- <app-grid [columnDefs]="columnDefsMov"
            [defaultColDef]="defaultColDef" [rowData]="rowDataMov$" (gridReady)="onGridReady($event)" style='width: 100%; height: 60vh'
            >
        </app-grid> -->
                </mat-tab>
                <mat-tab label="Documenti" *ngIf="isAdmin">
                    <h3>Documenti</h3>
                    <div *ngIf="!isAbbinato">
                        <mat-form-field style="padding: 16px;">
                            <mat-label>Tipo</mat-label>
                            <mat-select matNativeControl value="1" (valueChange)="onChangeTipo($event)">
                                <mat-option value="1">
                                    UDC
                                </mat-option>
                                <mat-option value="0">
                                    UDM
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            <b>
                                Selezione:
                                <span id="selectedRows"></span>
                            </b>
                        </div>
                    </div>
                    <ag-grid-angular style="width: 100%; height: 60vh" class="ag-theme-alpine" [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef"  [masterDetail]="true" [rowData]="rowData$" [rowSelection]="'single'" [animateRows]="true"
                        [columnTypes]="columnTypes" (rowDoubleClicked)="OpenDoc($event)" (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </mat-tab>
        </mat-tab-group>
    </mat-card>
</form>