import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, map, startWith } from 'rxjs';
import { ArticoloService } from 'src/app/services/articolo.service';
import { DocumentiService } from 'src/app/services/documenti.service';


export class DialogData {
  id: number = 0;
  num: number = 0;
  partnumber: string = '';
  Note: string = '';
  causale: number = 0;
}


@Component({
  selector: 'app-dialog-rettifica-udc',
  templateUrl: './dialog-rettifica-udc.component.html',
  styleUrls: ['./dialog-rettifica-udc.component.scss']
})
export class DialogRettificaUdcComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogRettificaUdcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    private documentiService: DocumentiService,
    private articoliService: ArticoloService
  ) { }

  causali: any=[];
  pn: any=[];

  myControl = new FormControl<string | any>('');;
  filteredOptions: Observable<string[]>;

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSalva(){
    if(this.data.Note && this.data.Note != '' && this.data.causale != 0 && !isNaN(this.data.num) && this.data.partnumber != '')
      this.dialogRef.close(this.data);
    else
      this.openSnackBar('Compilare tutti i campi prima di salvare')
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  ngOnInit(): void {
    this.documentiService.GetCausaliRettifica().subscribe((data)=>{
      this.causali = data
    })
    this.articoliService.GetPartNumberxUDC(this.data.id).subscribe((data)=>{
      this.pn=data.map((a)=> a.PartNumber);
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
      //console.log(this.pn)
    })
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.pn.filter(option => option.toLowerCase().includes(filterValue));
  }

}
