import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { Liste } from '../Models/Liste';
import { Stampa } from '../Models/Stampa';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private apiUrl1 = environment.apiUrl
  constructor(private http: HttpClient) { }

  getListe(): Observable<Liste> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/common/listeComuni";
    return this.http.get<Liste>( url, httpOptions);
  }

  getStampa(): Observable<Stampa>{
    return this.http.get<Stampa>('https://dgv.azurewebsites.net/api/getfile/31');
  }
  
}
