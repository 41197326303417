<div mat-dialog-content>
    <div style="display:flex;flex-direction:column; width: 100%;">
            <div style="padding: 4px;">
                <b>Doppio click sull'articolo da aggiungere</b>
                <app-grid [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef" [rowData]="rowArticoli"
                    (onDblClick)="loadDataDettaglio($event)" (gridReady)="RefreshSpedizioni($event)">
                </app-grid>
            </div>
    </div>
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <!-- <button class="button-add-row" [mat-dialog-close]="data" cdkFocusInitial>Salva</button> -->
</div>