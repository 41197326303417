import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData{
nome:string;
}


@Component({
  selector: 'app-dialog-new-magazzino',
  templateUrl: './dialog-new-magazzino.component.html',
  styleUrls: ['./dialog-new-magazzino.component.scss']
})
export class DialogNewMagazzinoComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<DialogNewMagazzinoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }

}
