import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-variante',
  templateUrl: './product-variante.component.html',
  styleUrls: ['./product-variante.component.scss']
})
export class ProductVarianteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
