import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { Menu } from '../Models/Menu';
import { Articolo } from '../Models/Articolo';
import { Liste } from '../Models/Liste';
import { Data } from '@angular/router';
import { VarianteArticolo } from '../Models/VarianteArticolo';
import { DescrizioneArticolo } from '../Models/DescrizioneArticolo';
import { Giacenza } from '../Models/Giacenza';
import { Kit } from '../Models/Kit';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ArticoloService {

  private apiUrl1 = environment.apiUrl;
  constructor(private http: HttpClient) { }

  ElencoArticoliOLD(): Observable<Articolo[]> {

    return this.http
      .get<Articolo[]>(this.apiUrl1 + "/articoli/getid", {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': this.apiUrl1,
          'Authorization': 'Bearer ' + localStorage.getItem('jwt')
        }),
        observe:'body',
        params: {
          id2: 0,
          id: 14
        }
      })
  }


  ElencoArticoli(): Observable<Articolo[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/articoli/getid?id=" + 0 + "&id2=" + 14}`;
    return this.http.get<Articolo[]>(url, httpOptions);
  }

  
  ElencoArticoliAusiliari(): Observable<Articolo[]> {
    const url = this.apiUrl1 + `${"/articoli/getidAusiliario"}`;
    return this.http.get<Articolo[]>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': this.apiUrl1,
        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
      }),
      observe:'body',
      params: {
        id2: 0,
        id: 14
      }
    });
  }

/*
  GetArticolo(id: number): Observable<Articolo> {
    const url = this.apiUrl1 + `${"/articoli/getid"}`;
    // const url = this.apiUrl1 + `${"/articoli/getid/?id=" + encodeURIComponent( JSON.stringify([{Articolo_Id:id} , {idSito:14}]))}`;
    return this.http.get<Articolo>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': this.apiUrl1,
        'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
      }),
      observe:'body',
      params: {
        id2: id,
        id: 14
      }
    });
  }*/ 
  
  GetArticolo(id: number): Observable<Articolo> {
  const token = localStorage.getItem('jwt');
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': environment.apiUrl,
      'Authorization': 'Bearer ' + token
    })
  };
  const url = this.apiUrl1 + `${"/articoli/GetId?id=" + id}`;
  return this.http.get<Articolo>(url, httpOptions);
}

  GetKit(id: number): Observable<Kit[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/articoli/GetKits/?id=" + id}`;
    return this.http.get<Kit[]>(url, httpOptions);
  }

  ElencoArticolixCliente(id: number, id2 : boolean): Observable<any[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/articoli/GetxIdClienteDt/?id=" + id +'&id2=' + id2}`;
    return this.http.get<any[]>(url, httpOptions);
  }

  ElencoArticoliIngressoxCliente(id: number, id2 : number): Observable<any[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/articoli/GetIdxClienteID/?id=" + id +'&id2=' + id2}`;
    return this.http.get<any[]>(url, httpOptions);
  }


  InsKit(param: Kit): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/UpdIns_Kits";
    return this.http.put<any>(url, param, httpOptions);

  }
  UpdateBloccoQualita(params: any): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/UpdateBloccoQualita";
    return this.http.post<any>(url, params, httpOptions);

  }

  InsArticolo(articolo: Articolo): Observable<Articolo> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/UpdIns_Articoli";
    return this.http.put<Articolo>(url, articolo, httpOptions);

  }

  InsVariante(variante: VarianteArticolo): Observable<VarianteArticolo> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/UpdIns_tbArticolixVarianti";
    return this.http.put<VarianteArticolo>(url, variante, httpOptions);

  }
  InsDescrizione(descrizione: DescrizioneArticolo): Observable<DescrizioneArticolo> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/UpdIns_tbArticolixAltreDescrizioni";
    return this.http.put<DescrizioneArticolo>(url, descrizione, httpOptions);

  }

  // GetGiacenze(id: number): Observable<Giacenza[]> {
  //   const url = this.apiUrl1 + `${"/api/documenti/GetGiacenze/?id=" + id}`;
  //   return this.http.get<Giacenza[]>(url, httpOptions);
  // }

  GetPartNumberxUDC(id: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/articoli/GetPartNumberxUDC/?id=" + id}`;
    return this.http.get<any>(url, httpOptions);
  }

  DelArticolo(id: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/articoli/DelArticolo?id=" + id;
    return this.http.delete<any>(url, httpOptions);
  }

}
