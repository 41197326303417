import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cliente } from '../Models/Cliente';
import { Customer } from '../Models/Customer';
import { DestinazioniClienti } from '../Models/DestinazioniClienti';
import { VariantiClienti } from '../Models/VariantiClienti';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private apiUrl1 = environment.apiUrl;
  constructor(private http: HttpClient) { }

  GetEnte(id: number): Observable<Cliente> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/enti/get/?id=" + id}`;
    return this.http.get<Cliente>(url, httpOptions);
  }

  GetEntexCliente(id: number, id2: number = 1): Observable<Cliente[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/enti/getentixclienti/?id=" + id+ "&id2=" + id2}`;
    return this.http.get<Cliente[]>(url, httpOptions);
  }

  GetDestinazioniClienti(id: number): Observable<DestinazioniClienti[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/enti/getdestinazioniclienti/?id=" + id }`;
    return this.http.get<DestinazioniClienti[]>(url, httpOptions);
  }

  InsEnte(data: Cliente): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/enti/UpdIns_Enti";
    return this.http.put<any>(url, data, httpOptions);

  }

  InsEntexCliente(data: any): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/enti/Ins_EntixClienti";
    return this.http.put<any>(url, data, httpOptions);

  }

  DelEntexCliente(data: any): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/enti/Del_EntixClienti";
    return this.http.put<any>(url,data, httpOptions);

  }

  DelCliente(id:number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/enti/DelCliente?id=" + id;
    return this.http.delete<any>(url, httpOptions);

  }

  InsVariante(variante: VariantiClienti): Observable<VariantiClienti> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/enti/UpdIns_VariantiClienti";
    return this.http.put<VariantiClienti>(url, variante, httpOptions);
  }
  InsDestinazione(destinazione: DestinazioniClienti): Observable<DestinazioniClienti> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/enti/UpdIns_DestinazioniClienti";
    return this.http.put<DestinazioniClienti>(url, destinazione, httpOptions);
  }


}
