import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { Magazzino } from 'src/app/Models/Magazzino';
import { LocazioneMagazzino } from 'src/app/Models/LocazioneMagazzino';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DialogNewMagazzinoComponent } from '../dialog-new-magazzino/dialog-new-magazzino.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewRigaMagazzinoComponent } from '../dialog-new-riga-magazzino/dialog-new-riga-magazzino.component';
import { MagazzinoService } from 'src/app/services/magazzino.service';


/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
 interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Magazzino 1',
    children: [{name: 'Scaffale 1'}, {name: 'Scaffale 2'}, {name: 'Scaffale 3'}],
  },
  {
    name: 'Magazzino 2',
    children: [
      {
        name: 'Scaffale 1',
        children: [{name: 'Cella 1'}, {name: 'Cella 2'}],
      },
      {
        name: 'Scaffale 2',
        children: [{name: 'Cella 1'}, {name: 'Cella 2'}],
      },
    ],
  },
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

export class Tab {
  id: number | null = 0;
  nome: string = "";
}
@Component({
  selector: 'app-magazzino',
  templateUrl: './magazzino.component.html',
  styleUrls: ['./magazzino.component.scss']
})
export class MagazzinoComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: any[];
  label = "Lista"
  tabs: Tab[] = [];
  magazzinoID: number = 0;


  constructor(private http: HttpClient,public dialog: MatDialog, private magazzinoService : MagazzinoService) {
    this.dataSource.data = TREE_DATA;
   }


  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  openMagazzinoDialog(magazzinoID: number = 0): void {
    
    const dialogRef = this.dialog.open(DialogNewMagazzinoComponent, {
      width: '250px',
      data: {
        
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  openRigaMagazzinoDialog(e : RowDoubleClickedEvent = undefined): void {
    
    const dialogRef = this.dialog.open(DialogNewRigaMagazzinoComponent, {
      width: '300px',
      //height: '500px',
      data: {
        
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData(0)
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.reloadData(0)

  }

  refreshGrid() {
    this.reloadData(0)
  }
  reloadData(id : number) {
    this.loading = 'block';
    this.magazzinoService.GetLocazioni(id).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });
  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo Magazzino'
    }

    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.magazzinoID = 0;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    if (!this.tabs.some((x) => x.id == e.data.idCliente)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.idCliente,
        nome: e.data.ragione_Sociale
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.magazzinoID = e.data.idCliente;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.idCliente) + 1);

  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'id_Locazione', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true, filter: 'agNumberColumnFilter' },
    { field: 'reparto', headerName: "Reparto", width: 320, filter: 'agTextColumnFilter' },
    { field: 'settore', headerName: "Settore", width: 200, filter: 'agTextColumnFilter' },
    { field: 'scaffale', headerName: "Scaffale", width: 200 , filter: 'agTextColumnFilter'},
  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  listMagazzini: Magazzino[] = [];

  ElencoMagazzini(){
    this.magazzinoService.GetMagazzino(0).subscribe((data)=> this.listMagazzini = data);
  }

  ngOnInit(): void {
    this.ElencoMagazzini();
  }

  myalert(){
    alert()
  }

}
