import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Accettazione } from 'src/app/Models/Accettazione';
import { Utente } from 'src/app/Models/Utente';
import { DocumentiService } from 'src/app/services/documenti.service';
import { MenuService } from 'src/app/services/menu.service';
import { Tab } from '../utenti/utenti.component';
import { Reparto } from 'src/app/Models/Reparto';
import { CustomerService } from 'src/app/services/customer.service';
import { Cliente } from 'src/app/Models/Cliente';
import { Sito } from 'src/app/Models/Sito';
import { MatDialog } from '@angular/material/dialog';
import { DialogUtenteSitoComponent } from '../../dialog-utente-sito/dialog-utente-sito.component';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-utente',
  templateUrl: './utente.component.html',
  styleUrls: ['./utente.component.scss']
})
export class UtenteComponent implements OnInit {
  form: FormGroup = new FormGroup({
    nome: new FormControl(''),
    cognome: new FormControl(''),
    email: new FormControl(''),
    //psw: new FormControl(''),
    idGruppo:new FormControl(0),
    idAzienda:new FormControl(0),
    altriFlagUtente:new FormControl(''),
  })

  @Input() id: number = 0;
  @Input() tab: Tab = { id: 0, nome: "" };

  @Output() closeTab = new EventEmitter<number>();

  public loading: string = 'none';
  constructor(private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private menuService: MenuService, private clientiService: CustomerService) { }
  selected = new FormControl(0);
  utente : Utente;

    idInterno:number;
  gruppi: Reparto[];
  aziende: Cliente[];
  getUtente(id: number) {
    this.menuService.getUtenti(id).subscribe((res) => {
      this.form = new FormGroup({
        nome: new FormControl(res[0]?.nome || '', Validators.required),
        cognome: new FormControl(res[0]?.cognome || '', Validators.required),
        email: new FormControl(res[0]?.email || '', Validators.required),
        //psw: new FormControl(res[0]?.psw || '', Validators.required),
        idGruppo: new FormControl(res[0]?.idGruppo || '', Validators.required),
        idAzienda: new FormControl(res[0]?.idAzienda, Validators.required),
        altriFlagUtente:new FormControl(res[0]?.altriFlagUtente || '', Validators.required),
      });
      if(this.idInterno > 0)
        this.form.get('altriFlagUtente').disable();
      this.utente = res[0];
      this.gruppi= res[0].gruppi;
      this.clientiService.GetEnte(0).subscribe((data)=>{
        // console.log([data]);
        this.aziende= [data].flat();
      })
    }
    );
  }

  ngOnInit(): void {
    this.getUtente(this.id)
    this.idInterno = this.id
  }
  onUndo() {
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
    } else {
      this.loading = 'block';
      let req: Utente = this.form.value;
      //req.IdGruppo = this.utente?.IdGruppo || 0;
      req.gridIndex = this.utente?.gridIndex || 0;
      req.disabilitato = false;
      req.sito = [{
        idSito: 0,
        nome: '',
        abilitato : false, 
        cap: '', 
        capSoc: 0, 
        citta: '', 
        default: false, 
        gridIndex:0 , 
        indirizzo: '', 
        naz: '', 
        piva: '', 
        prov: '',
        tel:''
      }],
      
      req.gruppi = [];
      req.gruppo = '';
      req.azienda = '';
      req.pswold = '';
      if (this.idInterno < 0) {
        this.menuService.InsUtente(req)
          .subscribe((data) => {
            if(!data.error){
              this.loading = 'none';
              this.closeTab.emit(this.idInterno);
            }else{
              this.loading = 'none';
              console.error(data.message);
              this.openSnackBar(data.message);
            }
          })
      } else {
        req.altriFlagUtente = this.form.get('altriFlagUtente').value;
        this.menuService.UpdUtente(req)
          .subscribe((data) => {
            if(!data.error){
              this.loading = 'none';
              this.closeTab.emit(this.idInterno);
            }else{
              this.loading = 'none';
              console.error(data.message);
              this.openSnackBar(data.message);
            }
        })
       }
     }
  }

  gridload(e: any){
    //console.log(e.tab.textLabel);
    switch (e.tab.textLabel) {
      case 'Generali':
        this.getUtente(this.id);
        break;
      case 'Siti':
        this.getSitiUtente(this.id)
        break;
      case 'Clienti':
        this.getUtentexCliente(this.id);
        break;
        default: break
    }


  }

  public gridColumnApi!: ColumnApi;
    public rowDataSiti!: any[];
    public rowDataClienti!: any[];

    onGridReady(params: GridReadyEvent) {
      this.gridColumnApi = params.columnApi;
      //this.reloadData(this.idInterno)
    }

    reloadData(id : number) {
      this.loading = 'block';
    }

    getUtentexCliente(id:number){
      this.menuService.GetUtentexCliente(id).subscribe((data)=>{
        this.rowDataClienti = data;
        this.loading = 'none';
      });
    }

    getSitiUtente(id:number){
      this.menuService.GetSitiUtente(id).subscribe((data)=>{
        this.rowDataSiti = data;
        this.loading = 'none';
      });
    }

    public columnDefs: (ColDef | ColGroupDef)[] = [
      // { field: 'IdGruppo', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
      { field: 'nome', headerName: "Nome", width: 200, filter: 'agTextColumnFilter' },
      { field: 'abilitato', headerName:'Abilitato', width: 150, valueFormatter: (param)=>{
        return param.value ? 'Si' : 'No'
      } },
      { field: 'default', headerName:'Default', width: 150, valueFormatter: (param)=>{
        return param.value ? 'Si' : 'No'
      } },
    ];
    public columnDefsClienti: (ColDef | ColGroupDef)[] = [
      // { field: 'IdGruppo', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
      { field: 'ragione_Sociale', headerName: "Nome", width: 200, filter: 'agTextColumnFilter' },
      { field: 'abilitato', headerName:'Abilitato', width: 200, valueFormatter: (param)=>{
        return param.value ? 'Si' : 'No'
      } },
    ];
    

    public defaultColDef: ColDef = {
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
      width: 100,
      // enable floating filters by default
      floatingFilter: true
  };

  toggleSito(e: RowDoubleClickedEvent) {
    console.log(e)
    if (this.idInterno > 0) {
      let sito = this.rowDataSiti.find((x) => x.gridIndex == e);
      const dialogRef = this.dialog.open(DialogUtenteSitoComponent, {
         width: '250px',
        data: {
          isAbilitato: sito?.abilitato || false,
          Default: sito?.Default || false,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loading = 'block'
          this.menuService.toggleSitodaUtente(this.idInterno, +e, result.Default).subscribe((data) => {
            if (data == 'ok') {
              this.openSnackBar('Modifica all\'associazione effettuata')
              this.getSitiUtente(this.idInterno)
              //this.reloadData(this.idInterno)
            }
          })
        } 
      });

    }
  }

  toggleCliente(e: RowDoubleClickedEvent) {
    if (this.idInterno > 0) {
      this.menuService.toggleUtentexCliente(this.idInterno, +e).subscribe((data) => {
        if(data[0].err == '0'){
          this.openSnackBar('Modifica all\'associazione effettuata')
          // this.reloadData(this.idInterno)
          this.getUtentexCliente(this.idInterno)
        }else{
          this.openSnackBar(data[0].msg)
        }
      })
    } else {
      this.openSnackBar('Salva un Utente prima di continuare')
    }
  }


 

}
