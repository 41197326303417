import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Gruppo } from 'src/app/Models/Gruppo';

@Component({
  selector: 'app-dialog-gruppo',
  templateUrl: './dialog-gruppo.component.html',
  styleUrls: ['./dialog-gruppo.component.scss']
})
export class DialogGruppoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogGruppoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Gruppo,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }

}
