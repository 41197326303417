<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Qta</mat-label>
        <input matInput type="number" [(ngModel)]="data.qta">
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>Note</mat-label>
        <textarea matInput type="number" [(ngModel)]="data.note"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
    <button class="button-elimina" (click)="onDelete()" cdkFocusInitial>Elimina</button>
</div>