import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../app/components/dashboard/dashboard.component';
import { ProductsComponent } from '../app/components/products/products.component';
import { ClientiComponent } from './components/clienti/clienti/clienti.component';
import { SettingsComponent } from '../app/components/settings/settings.component';
import { StatisticsComponent } from '../app/components/statistics/statistics.component';
import { MagazziniComponent } from './components/magazzini/magazzini/magazzini.component';
import { IngressoComponent } from './components/documenti/ingresso/ingresso.component';
import { UdcComponent } from './components/documenti/udc/udc.component';
import { UdcAbbinamentoComponent } from './components/documenti/udc-abbinamento/udc-abbinamento.component';
import { DepositoComponent } from './components/documenti/deposito/deposito.component';
import { GruppoComponent } from './components/gruppi/gruppo/gruppo.component';
import { FamigliaComponent } from './components/gruppi/famiglia/famiglia.component';
import { LoginComponent } from './components/login/login.component';
import { SidenavAiComponent } from './components/sidenav-ai/sidenav-ai.component';
import { MissioniComponent } from './components/documenti/missioni/missioni.component';
import { UscitaComponent } from './components/documenti/uscita/uscita.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuardService } from './guards/auth.guard.child';
import { Role } from './Models/Role';
import { GruppoUtentiEditComponent } from './components/accesso/gruppo-utenti-edit/gruppo-utenti-edit.component';
import { GruppoUtentiComponent } from './components/accesso/gruppo-utenti/gruppo-utenti.component';
import { UtentiComponent } from './components/utenti/utenti/utenti.component';
import { SitiComponent } from './components/siti/siti/siti.component';
import { OrdineComponent } from './components/documenti/ordine/ordine.component';
import { OdsComponent } from './components/documenti/ods/ods.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { GiacenzaUdcComponent } from './components/giacenza-udc/giacenza-udc.component';
import { CreaudcComponent } from './components/creaudc/creaudc.component';
import { StoricoUdcComponent } from './components/storico-udc/storico-udc.component';
import { PnComponent } from './components/pn/pn.component';
import { MagazzinoComponent } from './components/magazzini/magazzino/magazzino.component';
import { AssemblyComponent } from './components/assembly/assembly.component';
import { GruppoOrdiniComponent } from './components/gruppo-ordini/gruppo-ordini.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PianoConsegneComponent } from './components/prodottofinito/piano-consegne/piano-consegne.component';
import { PrenotazionePresaComponent } from './components/prodottofinito/prenotazione-presa/prenotazione-presa.component';
import { PianoConsegnePfComponent } from './components/prodottofinito/piano-consegne-pf/piano-consegne-pf.component';

const routes: Routes = [
  { path: '', title: "StikStok - Login", component: LoginComponent }, 
  { path: 'ResetPassword', title: "StikStok - Password Reset", component: ResetPasswordComponent },
  { path: 'main', component: SidenavAiComponent, title:"StikStok" , canActivate: [AuthGuard] , children:[
    { path: 'products', component: ProductsComponent, title:"Articoli" , canActivate: [AuthGuard] , data: { roles: [Role.Admin] }},
    { path: 'statistics', component: StatisticsComponent, title:"Dashboard" , canActivate: [AuthGuard]},
    { path: 'customers', component: ClientiComponent, title:"Clienti", canActivate: [AuthGuard] },
    { path: 'settings', component: SettingsComponent, title:"Dashboard", canActivate: [AuthGuard] },
    { path: 'stores', component: MagazziniComponent, title:"Magazzini", canActivate: [AuthGuard] },
    { path: 'magazzinotest', component: MagazzinoComponent, title:"Magazzino TreeView", canActivate: [AuthGuard] },
    { path: 'ingresso', component:IngressoComponent, title:"DDT", canActivate: [AuthGuard]},
    { path: 'udc', component:StoricoUdcComponent, title:"Storico UDC", canActivate: [AuthGuard]},
    { path: 'abbinamento-udc', component:UdcAbbinamentoComponent, title:"Abbinamento UDC", canActivate: [AuthGuard]},
    { path: 'deposito', component:DepositoComponent, title:"Stocking", canActivate: [AuthGuard]},
    { path: 'gruppo', component:GruppoComponent, title:"Gruppo", canActivate: [AuthGuard]},
    { path: 'famiglia', component:FamigliaComponent, title:"Famiglia", canActivate: [AuthGuard]},
    { path: 'prelievo', component:MissioniComponent, title:"Picking", canActivate: [AuthGuard]},
    { path: 'ddtuscita', component:UscitaComponent, title:"Emissione DDT", canActivate: [AuthGuard]},
    { path: 'ruoli', component:GruppoUtentiComponent, title:"Reparto", canActivate: [AuthGuard]},
    { path: 'utenti', component:UtentiComponent, title:"Utenti", canActivate: [AuthGuard]},
    { path: 'siti', component:SitiComponent, title:"Siti", canActivate: [AuthGuard]},
    { path: 'ordine', component:OrdineComponent, title:"Ordine", canActivate: [AuthGuard]},
    { path: 'dashboard', component:DashboardComponent, title:"Dashboard", canActivate: [AuthGuard]},
    { path: 'ods', component:OdsComponent, title:"Ordine di Spedizione", canActivate: [AuthGuard]},
    { path: 'workflow', component:WorkflowComponent, title:"Work Flow", canActivate: [AuthGuard]},
    { path: 'giacenza-udc', component:UdcComponent, title:"Giacenza UDC", canActivate: [AuthGuard]},
    { path: 'creaudc', component:CreaudcComponent, title:"UDC Vergini", canActivate: [AuthGuard]},
    { path: 'giacenza-pn', component:PnComponent, title:"Giacenza Part Number", canActivate: [AuthGuard]},
    { path: 'assembly', component:AssemblyComponent, title:"Assemblaggio", canActivate: [AuthGuard]},
    { path: 'gruppo-ordini', component:GruppoOrdiniComponent, title:"Carichi", canActivate: [AuthGuard]},
    { path: 'inventario', component:InventarioComponent, title:"Inventari", canActivate: [AuthGuard]},
    { path: 'piano-consegne', component:PianoConsegneComponent, title:"Piano Consegne", canActivate: [AuthGuard]}, 
    { path: 'prenotazione-presa', component:PrenotazionePresaComponent, title:"Prenotazione Presa", canActivate: [AuthGuard]}, 
    { path: 'piano-consegne-pf', component:PianoConsegnePfComponent, title:"Piano Consegne Prodotto Finito", canActivate: [AuthGuard]}, 
    //{ path: 'gruppiutenti', component:GruppoUtentiComponent, title:"Gruppi Utenti", canActivate: [AuthGuard]},
  ]},
  { path: 'test', title: "TEST", component: GruppoUtentiComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
