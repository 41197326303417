<div mat-dialog-content align="center">
    <b>Inserisci la quantità di UDC da stampare</b><br>
    <mat-form-field appearance="fill">
        <mat-label>Numero di UDC</mat-label>
        <input matInput [(ngModel)]="data.qtaudc" type="number" min="1">
    </mat-form-field>
 </div>
 <div mat-dialog-actions align="center">
     <button class="button-annulla" (click)="onNoClick()">Annulla</button>
     <button class="button-add-row" [mat-dialog-close]="data" cdkFocusInitial>Stampa</button>
 </div>