import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Articolo } from 'src/app/Models/Articolo';
import { Ingresso } from 'src/app/Models/Ingresso';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { ArticoloService } from 'src/app/services/articolo.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogIngressoArticoloComponent } from '../dialog-ingresso-articolo/dialog-ingresso-articolo.component';
import { A11yModule } from '@angular/cdk/a11y'
import { empty } from 'rxjs';


export class DialogData{
  DocumentoID: number;
  CheckAusiliario: boolean;
  Articoli: any[];
  clienteID: number;

}

@Component({
  selector: 'app-dialog-ingresso-cerca-articolo',
  templateUrl: './dialog-ingresso-cerca-articolo.component.html',
  styleUrls: ['./dialog-ingresso-cerca-articolo.component.scss']
})
export class DialogIngressoCercaArticoloComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogIngressoCercaArticoloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private articoliService: ArticoloService,
    private documentiService: DocumentiService,
    public dialog: MatDialog
  ) { }


  loading: string;
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.loadData()
  }

  rowArticoli: any[] = [];
  
  public columnDefsArticoli: (ColDef | ColGroupDef)[] = [
    //{ field: 'Articolo_Id', headerName: "Part Number", width: 100, type: 'numberColumn' },
    { field: 'descrizione', headerName: "Des. Articolo", width: 320, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 320 , filter: 'agTextColumnFilter'},
  ];
  
  multipleSelection : boolean = false;
  rowSelection: any[] = [];

  GridReady(params: GridReadyEvent){
    this.gridApi = params.api;
  }


  private gridApi!: GridApi<any>;


  onChange(e: any){
    this.rowSelection = this.gridApi.getSelectedRows();
    if(this.rowSelection.length > 0){
      this.multipleSelection = true;
      this.data.Articoli= this.rowSelection;
    } else {
      this.multipleSelection = false;
    }
  }

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  loadData() {
    this.loading = 'block';
    if (!this.data.CheckAusiliario) {
      this.articoliService.ElencoArticoliIngressoxCliente(14, this.data.clienteID).subscribe((data) => {
        this.loading = 'none';
        this.rowArticoli = data;
      });
    }else{
      this.articoliService.ElencoArticoliAusiliari().subscribe((data) => {
        this.loading = 'none';
        this.rowArticoli = data;
      });
    }

  }
  show: boolean = false;
  loadDataDettaglio(idarticolo: number = 0) {
    //if (this.rowSelection.length == 0) {
      let articolo = this.rowArticoli.find((e) => e.gridIndex == idarticolo);
      this.data.Articoli[0].articolo_Id = articolo?.articolo_Id || 0;
      this.data.Articoli[0].descrizione = articolo?.descrizione || '';
      this.data.Articoli[0].note = articolo?.note || '';
      this.data.Articoli[0].fase = '';
      this.data.Articoli[0].mes = '';
      this.data.Articoli[0].colata = '';
      this.data.Articoli[0].gridIndex = articolo?.gridIndex || '';
      this.data.Articoli[0].partNumber = articolo?.partNumber;
      //this.show = true;
    //}else{
      this.show = true;
    //}
  }


  
}
