import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData{
  id: number,
  tipo:number
}
@Component({
  selector: 'app-dialog-documento',
  templateUrl: './dialog-documento.component.html',
  styleUrls: ['./dialog-documento.component.scss']
})
export class DialogDocumentoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDocumentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
