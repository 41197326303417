import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocazioneMagazzino } from '../Models/LocazioneMagazzino';
import { Magazzino } from '../Models/Magazzino';
import { WorkFlow } from '../Models/WorkFlow';
import { environment } from 'src/environments/environment';




@Injectable({
  providedIn: 'root'
})
export class MagazzinoService {

  private apiUrl1 = environment.apiUrl;
  constructor(private http: HttpClient) { }

  GetMagazzino(id: number): Observable<Magazzino[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/magazzini/get/?id=" + id}`;
    return this.http.get<Magazzino[]>(url, httpOptions);
  }

  GetLocazioni(id: number): Observable<LocazioneMagazzino[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/magazzini/GetLocazioni/?id=" + id}`;
    return this.http.get<LocazioneMagazzino[]>(url, httpOptions);
  }

  GetAree(): Observable<LocazioneMagazzino[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/magazzini/GetAree"}`;
    return this.http.get<LocazioneMagazzino[]>(url, httpOptions);
  }

  GetWorkFlow(WorkflowID: number, isKit:boolean, IdCliente: number): Observable<WorkFlow[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/magazzini/GetWorkFlow/?id=" + WorkflowID + "&id2=" + isKit + "&id3=" + IdCliente}`;
    return this.http.get<WorkFlow[]>(url, httpOptions);
  }

  StampaMagazzini(id: string) : Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + "/magazzini/stampaMagazzini/?id="+id.toString();
    return this.http.get<any>(url, httpOptions);
  }

  GetLocazioniSpostamento(id: number): Observable<LocazioneMagazzino[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl1 + `${"/magazzini/GetLocazioniSpostamento/?id=" + id}`;
    return this.http.get<LocazioneMagazzino[]>(url, httpOptions);
  }

}
