import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { Gruppo } from 'src/app/Models/Gruppo';
import { DialogAssociaGruppoutenteMenuComponent } from '../dialog-associa-gruppoutente-menu/dialog-associa-gruppoutente-menu.component';
import { MenuService } from 'src/app/services/menu.service';
import { Tab } from '../gruppo-utenti/gruppo-utenti.component';
import { Livello } from 'src/app/Models/Livello';
import { RepartoMenu } from 'src/app/Models/RepartoMenu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GruppoService } from 'src/app/services/gruppo.service';

@Component({
  selector: 'app-gruppo-utenti-edit',
  templateUrl: './gruppo-utenti-edit.component.html',
  styleUrls: ['./gruppo-utenti-edit.component.scss']
})
export class GruppoUtentiEditComponent implements OnInit {

  loading: string = 'none';
  selected = new FormControl(0);
  public gridColumnApi!: ColumnApi;
  label = "Lista";
  public rowData$!: any[];
  
  @Input() id: number = 0;
  @Input() tab: Tab = { id: 0, nome: "" };

  constructor(private http: HttpClient, public dialog: MatDialog, private menuService: MenuService,private _snackBar: MatSnackBar, private gruppoService: GruppoService) {
  }

  
  openGruppoDialog(e : RowDoubleClickedEvent): void {
    let res = this.rowData$.find((value) => value.gridIndex == +e);
    const dialogRef = this.dialog.open(DialogAssociaGruppoutenteMenuComponent, {
      width: '250px',
      data: {
        Id: this.id || 0,
        gridIndex : res.gridIndex || 0,
        Label: res.Label || '',
        livello: res.livello || 0,
        livelli: this.listLivelli || [],
        abilitato: res.abilitato || false
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.abilitato = result.abilitato ? true : false;
        result.categoria = '';
        result.livellodescrizione = '';
        this.menuService.updateGruppiMenu(result).subscribe((data)=>{
          if(data){
            this.Elenco(this.id);
          }
        })
      }
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.reloadData(this.id)

  }

  refreshGrid() {
    this.reloadData(this.id)
  }

  reloadData(id : number) {
    this.loading = 'block';
    // this.magazzinoService.GetLocazioni(id).subscribe((data) => {
    //   this.rowData$ = data
    //   this.loading = 'none';
    // });
    this.Elenco(id);
  }

  listLivelli: Livello[] = [];

  Elenco(id: number){
    this.menuService.getMenuReparti(id).subscribe((data) => {
        this.rowData$ = data;
        this.loading = 'none';
        this.listLivelli = data[0].livelli;
      });
  }


  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'categoria', headerName:'Categoria', width: 200, filter: 'agTextColumnFilter', checkboxSelection:true, headerCheckboxSelection: true},
    { field: 'label', headerName:'Voce Menu', width: 200, filter: 'agTextColumnFilter' },
    { field: 'abilitato', headerName:'Abilitato', width: 200, valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },
    { field: 'livellodescrizione', headerName:'Livello', width: 150, filter: 'agTextColumnFilter' },
  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  private gridApi!: GridApi<any>;
  onAbilita(abilitato: boolean){
    let selection = this.gridApi.getSelectedRows();

    if (selection.length > 0) {
      selection = selection.map((x) => x['gridIndex'])
      console.log(selection)
      this.gruppoService.UpdateGruppiMenuMultimo(this.id, selection.join(), abilitato).subscribe((res) => {
        this.refreshGrid();
        if (res != 'ok')
          this.openSnackBar('Si è verificato un errore');
      })
    }
  }

  
  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  ngOnInit(): void {
    //this.Elenco();
  }

}
