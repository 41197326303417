import { Injectable, Provider } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    apiUrl = environment.apiUrl;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // req = req.clone({
        //     url: `${this.apiUrl}${req.url}`,
        //     withCredentials: true
        // });
        // return next.handle(req);
        // Get the auth token from local storage
        const authToken = localStorage.getItem('jwt');

        // Clone the request and add the new header
        // if (authToken) {
        //     const cloned = req.clone({
        //         headers: req.headers.set('Authorization', 'Bearer ' + authToken)
        //     });
        //     return next.handle(cloned);
        // } else {
        //     return next.handle(req);
        // }

        // Clone the request and add the new header
        let authReq = req;
        if (authToken) {
            authReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authToken)
            });
        }

        // Handle the request and the response
        return next.handle(authReq).pipe(
            map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse) {
                //     // You can process the response here if needed
                //     console.log('Response intercepted', event);
                // }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // Handle error responses
                if (error.status === 401) {
                    console.error('Errore 401: Non autorizzato - Token potrebbe essere scaduto o non valido');
                    // You might want to redirect to login page or refresh the token here
                }
                console.error(error)
                return throwError(() => error);
            })
        );

    }
}

export const authInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};