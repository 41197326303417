import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Kit } from 'src/app/Models/Kit';


@Component({
  selector: 'app-product-dialog-edit-articolo',
  templateUrl: './product-dialog-edit-articolo.component.html',
  styleUrls: ['./product-dialog-edit-articolo.component.scss']
})
export class ProductDialogEditArticoloComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProductDialogEditArticoloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Kit,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  onDelete(){
    this.data.disabilitato = true;
    this.dialogRef.close(this.data);
  }

}
