<div class="spinner" [style]="'display:' + loading + ';z-index:20;position: absolute; top:50%; left:50% '">
    <mat-spinner></mat-spinner>
</div>
<div mat-dialog-content>
   
    <div style="display:flex;flex-direction:row; width: 100%;">
        <div style="width: 60%;padding: 4px;">
            <h3>Seleziona Articolo <mat-slide-toggle *ngIf="data.hasUDC && data.livelloID != 3" [(ngModel)]="checkudc"
                    (change)="onChangeUDC()">Cerca per UDC</mat-slide-toggle></h3>
            <b class="articoloselezionato">{{articoloselezionato}}</b>
        </div>
        <div style="width: 40%;padding: 4px; margin-top: 10px;">
            <div style="display:flex;flex-direction:row; width: 100%;">
                <div style="width: 50%;padding: 4px;">
                    <mat-form-field appearance="fill">
                        <mat-label>Qta</mat-label>
                        <input matInput [(ngModel)]="data.Qta" [disabled]="qtadisabled" type="number" min="0" [max]="maxqta"
                            (change)="onChangeQta()">
                    </mat-form-field>
                    <br>
                    <b style="color:red">{{msg}}</b>
                    <br>
                </div>
                <div style="width: 50%;padding: 4px;">
                    <mat-form-field appearance="fill" *ngIf="isnotadisp">
                        <mat-label>Note</mat-label>
                        <input matInput [(ngModel)]="this.nota" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <app-grid [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef" [rowData]="rowArticoli"
        [columnTypes]="columnTypes" (onDblClick)="loadDataDettaglio($event)" (gridReady)="GridReady($event)" (onChangedCheckbox)="onChange($event)">
    </app-grid>
    
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" (click)="onSave()" cdkFocusInitial>Aggiungi</button>
</div>