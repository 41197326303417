import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, ColGroupDef, ColumnApi, RowDoubleClickedEvent } from 'ag-grid-community';
import { ProdottofinitoService } from 'src/app/services/prodottofinito.service';

@Component({
  selector: 'app-dialog-piano-consegne-insert-container',
  templateUrl: './dialog-piano-consegne-insert-container.component.html',
  styleUrls: ['./dialog-piano-consegne-insert-container.component.scss']
})
export class DialogPianoConsegneInsertContainerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogPianoConsegneInsertContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private prodottofinitoservice: ProdottofinitoService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }

  labelerror : string="";
  displayedColumns = "udc"
  notes: string[] = []; 
  concatenatedNotes: string = '';

  countUDC : number = 0;

  async addClipboardNote(): Promise<void> {
    try {
      const clipboardText = await navigator.clipboard.readText();
      if (clipboardText) {
        this.notes = [];
        this.notes = clipboardText.split("\r\n");
        this.notes.pop();
        this.countUDC = this.notes.length;
        this.concatenatedNotes = "";
        this.concatenatedNotes = this.notes.join();  
      }
    } catch (err) {
      console.error('Errore nel recupero degli appunti dalla clipboard:', err);
    }
  }

  Salva(){
    this.data.UDC = this.concatenatedNotes;
    // this.data.numpro = '110022';
    // this.data.codcliente = '00016';
    // this.data.codspedizione = '002';
    // this.data.ordoer = '123154534';

    this.prodottofinitoservice.InsCNT(this.data.numpro, this.data.codcliente, this.data.codspedizione, this.data.ordoer, this.data.UDC).subscribe({
      next: (response: any) => {
        if (response) {
          this.dialogRef.close();
        } else {
          this.labelerror = "Errore Generico"
          setTimeout(() => {
            this.labelerror = "";
          }, 2500);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err.error)
        this.labelerror = err.error
        setTimeout(() => {
          this.labelerror = "";
        }, 2500);
      }
    })

    //this.dialogRef.close(this.data);
  }

}
