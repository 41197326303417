import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Ordine } from 'src/app/Models/Ordine';
import { DocumentiService } from 'src/app/services/documenti.service';
import { GridDelButtonComponent } from '../grid-del-button/grid-del-button.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

export class Tab {
  id: number | null = 0;
  id2: number | null = 0;
  nome: string = "";
  numero: string ="";
}
@Component({
  selector: 'app-gruppo-ordini',
  templateUrl: './gruppo-ordini.component.html',
  styleUrls: ['./gruppo-ordini.component.scss']
})
export class GruppoOrdiniComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: Ordine[];
  label = "Lista"
  tabs: Tab[] = [];
  IdOrdine: number = 0;
  IdGruppodiCarico: number = 0;

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.reloadData()

  }

  refreshGrid() {
    this.reloadData()
  }
  reloadData() {
    this.loading = 'block';
    // this.http
    //   .get<Ingresso[]>("https://testapiall1.azurewebsites.net/api/documenti/geti/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
    this.documentiService.GetPrelievoSpedizioniHEAD(0).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  newTab() {
    let row = {
      id: 0,
      id2:0,
      nome: 'Nuovo Gruppo',
      numero: ''
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.IdOrdine = -1;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    //if(e.data.IdGruppodiCarico != 0){
    if (!this.tabs.some((x) => x.id == e.data.idOrdine) && !this.tabs.some((x) => x.id2 == e.data.idGruppodiCarico)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.idOrdine,
        id2: e.data.idGruppodiCarico,
        nome: e.data.cliente,
        numero: e.data.numeroOrdine
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.IdOrdine = e.data.idOrdine;
      this.IdGruppodiCarico = e.data.idGruppodiCarico;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.idOrdine) + 1);
  //}
  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  private gridApi!: GridApi<any>;
  newGruppo() {
    let selection = this.gridApi.getSelectedRows();
    if (selection.length > 1) {
      if (selection.every((e) => e.ragioneSociale == selection[0].ragioneSociale) && selection.every((e) => e.localita == selection[0].localita)) {

        selection = selection.map((x) => x['idOrdine'])

        this.documentiService.CreaGruppoOrdini(selection.join()).subscribe((res) => {
          if (res[0].err == 'ok') {
            // let row = {
            //   id: parseInt(res[0].msg),
            //   nome: 'Nuovo DDT',
            //   numero: '',
            //   isOds: false
            // }
            // this.tabs.push(row);
            //console.log(res[0].msg);
            //this.DocumentoID =  parseInt(res[0].msg);
            //this.selected.setValue(this.tabs.length);
            this.openSnackBar('Gruppo Creato.');
            this.refreshGrid()
          } else
            this.openSnackBar('Si è verificato un errore durante la creazione del Gruppo');
        })



      } else {
        this.openSnackBar('Ragione Sociale e Località non corrispondono');
      }
    } else {
      this.openSnackBar('Selezionare almeno 2 ordini');
    }


  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  groupInGrid : boolean= false;

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'idGruppodiCarico', headerName: "", width:120, filter: false,
      checkboxSelection: (e) => {
        if (e.data.idGruppodiCarico > 0){
          this.groupInGrid = true;
          return false;
        }
        else
          return true;
      },
      cellRenderer: 'delButtonGrid',
      cellRendererParams: {
        onClick: this.EliminaGruppo.bind(this),
      }
    },
    {
      field: 'numeroOrdine', headerName: "Numero Ordine", width: 200, filter: 'agTextColumnFilter', 
    },
    { field: 'data', headerName: "Data Inserimento", width: 200, type: 'datetimeColumn' },
    { field: 'intestatario', headerName: "Destinatario", width: 200 , filter: 'agTextColumnFilter'},
    { field: 'ragioneSociale', headerName: "Cliente", width: 200, filter: 'agTextColumnFilter' },
    { field: 'localita', headerName: "Località", width: 200, filter: 'agTextColumnFilter' },
  ];

  EliminaGruppo(x){
    let id = x.rowData.idGruppodiCarico;
    console.log(id)
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'Verrà eliminato il gruppo ' + x.rowData.numeroOrdine + ', Continuare?',
        BtnSi: 'Continua',
        BtnNo: 'Annulla'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.documentiService.Del_GruppodiCarico(id).subscribe((res)=>{
          if (res[0].err == 'ok') {
            this.openSnackBar('Gruppo Eliminato.');
            this.groupInGrid = false;
            this.refreshGrid();
          }else{
            this.openSnackBar('Si è verificato un errore durante la cancellazione del Gruppo');
          }
        })
      }
    });
  }

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('it-IT')
       //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
    datetimeColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('it-IT').replace(new RegExp('-', "g"), '/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T');
          const date = dateParts[0].split('-');
          const day = Number(date[2]);
          const month = Number(date[1]) - 1;
          const year = Number(date[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;
  public getRowStyle;
  public frameworkComponents;
  constructor(private http: HttpClient, private documentiService: DocumentiService, private _snackBar: MatSnackBar,public dialog: MatDialog) {
    // this.getRowStyle = (params) => {
    //   if (params.data.Confermato) {
    //       return { background: '#4caf50' };
    //   }else
    //   return false
    // };
    this.frameworkComponents = {
      delButtonGrid: GridDelButtonComponent,
    }
   }

  ngOnInit(): void {
  }

}
