import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { BtnRinominaRepartoComponent } from '../btn-rinomina-reparto/btn-rinomina-reparto.component';
import { DialogRinominaRepartoComponent } from '../dialog-rinomina-reparto/dialog-rinomina-reparto.component';
import { GruppoService } from 'src/app/services/gruppo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
export class Tab {
  id: number | null = 0;
  nome: string = "";
}
@Component({
  selector: 'app-gruppo-utenti',
  templateUrl: './gruppo-utenti.component.html',
  styleUrls: ['./gruppo-utenti.component.scss']
})
export class GruppoUtentiComponent implements OnInit {

  loading: string = 'none';
  selected = new FormControl(0);
  public gridColumnApi!: ColumnApi;
  label = "Lista";
  public rowData$!: any[];
  tabs: Tab[] = [];
  IdGruppo : number;
  public closedTabs: Array<any> = [];

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  public frameworkComponents;
  constructor(private http: HttpClient, public dialog: MatDialog, private loginService: LoginService, private grupposervice: GruppoService) {
    this.frameworkComponents = {
      BtnRinominaRepartoComponent,
    }
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData(0)
    }
  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  newTab(){
    let row = {
      id: 0,
      nome: 'Nuovo Reparto'
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.IdGruppo = -1;
    this.selected.setValue(this.tabs.length);
  }

  addTab(e: RowDoubleClickedEvent){
    if (!this.tabs.some((x) => x.id == +e)) {
      let obj = this.rowData$.find((value) => value.idGruppo == +e);
      let row = {
        id: obj.idGruppo,
        nome: obj.nome
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = obj.Articolo_Id || 0;
      // this.data.Descrizione = obj.Descrizione;
      this.IdGruppo = obj.idGruppo;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == +e) + 1);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.reloadData(0)
  }

  refreshGrid() {
    this.reloadData(0)
  }
  reloadData(id : number) {
    this.loading = 'block';
    this.Elenco();
  }

  Elenco(){
    this.loginService.GetReparto().subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });
  }


  

  newReparto(x = null){
    let id = 0;
    let nome = '';
    if(x){
      id= x.rowData.idGruppo;
      nome=x.rowData.nome
    }

    const dialogRef = this.dialog.open(DialogRinominaRepartoComponent, {
      width: '250px',
      data: {
        id,
        nome
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result);
        this.grupposervice.Updins_Gruppo(result.id, result.nome).subscribe((data)=>{
          if(!data.error){
            this.refreshGrid();
          }else{
            console.error(data.message)
          }
        })
      }
    });
  }

  // rinominaGruppo(x): void {
  //   let row = x.rowData;
  //   const dialogRef = this.dialog.open(DialogRinominaRepartoComponent, {
  //     width: '250px',
  //     data: {
  //       id: row.IdGruppo,
  //       Nome: row.Nome,
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       //console.log(result);
  //       this.grupposervice.Updins_Gruppo(result.id, result.Nome).subscribe((data)=>{
  //         if(data == 'ok'){
  //           this.refreshGrid();
  //         }
  //       })
  //     }
  //   });
  // }


  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'IdGruppo', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'nome', headerName: "Nome", width: 200,filter: 'agTextColumnFilter' },
    {
      field: 'idGruppo', headerName: "Rinomina", width: 200, filter: false,
      cellRenderer: 'BtnRinominaRepartoComponent',
      cellRendererParams: {
        onClick: this.newReparto.bind(this),
      }
    },
    //{ field: 'IdGruppo',headerName: "Rinomina", width: 200, cellRenderer: BtnRinominaRepartoComponent, filter: '' },
    
  ];


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };


  ngOnInit(): void {
    this.Elenco();
  }

}
