<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
</div>
<div class="card">
    <p class="title-page">Configurazione menu di accesso</p>
    <button class="button-add-row" (click)="onAbilita(true)">
        Abilita
    </button>
    <button class="button-delete-row" (click)="onAbilita(false)">
        Disabilita
    </button>
    <app-grid (onDblClick)="openGruppoDialog($event)" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [rowData]="rowData$" (gridReady)="onGridReady($event)"></app-grid>
</div>