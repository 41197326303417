import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  reparto: string,
  settore: string,
  scaffale: string,
  montante: string,
  lato: string,
  ripiano: string,
  cella: string,
}


@Component({
  selector: 'app-dialog-new-riga-magazzino',
  templateUrl: './dialog-new-riga-magazzino.component.html',
  styleUrls: ['./dialog-new-riga-magazzino.component.scss']
})
export class DialogNewRigaMagazzinoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogNewRigaMagazzinoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }

}
