
<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Q.tà</mat-label>
        <input matInput type="number" [(ngModel)]="data.articolo.qta" [disabled]="data.isUDC">
    </mat-form-field><br>
    <mat-form-field appearance="fill" >
        <mat-label>Note</mat-label>
        <input matInput [(ngModel)]="data.note" />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button class="button-annulla2" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
    <button class="button-delete-row" (click)="onElimina()" cdkFocusInitial>Elimina</button>
</div>