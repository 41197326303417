import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  Sigla: string;
  Descrizione: string;
  nazioni: any[];
  Articolo_Id: number;
}
@Component({
  selector: 'app-product-dialog-descrizione',
  templateUrl: './product-dialog-descrizione.component.html',
  styleUrls: ['./product-dialog-descrizione.component.scss']
})
export class ProductDialogDescrizioneComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProductDialogDescrizioneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }


}
