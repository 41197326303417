import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Articolo } from 'src/app/Models/Articolo';
import { DescrizioneArticolo } from 'src/app/Models/DescrizioneArticolo';
import { Kit } from 'src/app/Models/Kit';
import { Liste } from 'src/app/Models/Liste';
import { Log } from 'src/app/Models/Log';
import { VarianteArticolo } from 'src/app/Models/VarianteArticolo';
import { ArticoloService } from 'src/app/services/articolo.service';
import { CommonService } from 'src/app/services/common.service';
import { FamigliaService } from 'src/app/services/famiglia.service';
import { ProductDialogArticoloComponent } from '../product-dialog-articolo/product-dialog-articolo.component';
import { ProductDialogDescrizioneComponent } from '../product-dialog-descrizione/product-dialog-descrizione.component';
import { ProductDialogEditArticoloComponent } from '../product-dialog-edit-articolo/product-dialog-edit-articolo.component';
import { ProductDialogVarianteComponent } from '../product-dialog-variante/product-dialog-variante.component';
import { Tab } from '../products/products.component';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {

  formprodotto: FormGroup = new FormGroup({
    idCliente: new FormControl(''),
    articolo_Id: new FormControl(''),
    partNumber: new FormControl(''),
    descrizione: new FormControl(''),
    //Tipo: new FormControl(''),
    idCategoria: new FormControl(''),
    pzConfezione: new FormControl(''),
    id_Valuta: new FormControl(''),
    idCategoriaABC: new FormControl(''),
    id_Misura: new FormControl(''),
    idCriterio: new FormControl(''),
    lottoRiordino: new FormControl(''),
    pn: new FormControl(''),
    pl: new FormControl(''),
    scortamin: new FormControl(''),
    scortamax: new FormControl(''),
    note: new FormControl(''),
    altezza: new FormControl(''),
    larghezza: new FormControl(''),
    profondita: new FormControl(''),
    volume: new FormControl(''),
    // Colata: new FormControl(''),
    // Fase: new FormControl(''),
    // MES: new FormControl(''),
    ausiliario: new FormControl(''),
    lavaggio: new FormControl(''),
    idGruppo: new FormControl(''),
    idFamiglia: new FormControl(''),
    bloccoQualita: new FormControl('')
  });

  @Input() id: number = 0;

  @Input() tab: Tab = { id: 0, nome: "", partnumber : "" };

  @Output() onSearch = new EventEmitter<number>();
  @Output() onClose = new EventEmitter<number>();

  idInterno: number;

  public liste: Liste;

  public loading: string = 'none';

  setSearch(value: number) {
    this.onSearch.emit(value);
  }

  onChangeGruppo(id:number){
    if(id > 0){
      this.formprodotto.get('idFamiglia').enable()
      this.ElencoFamiglie(id)
    }
  }

  selected = new FormControl(0);
  emailFormControl = new FormControl('', [Validators.email]);

  rowDataVarianti: VarianteArticolo[];
  rowDataDescrizioni: DescrizioneArticolo[];

  constructor(private articoloService: ArticoloService,
     private _snackBar: MatSnackBar,
      public dialog: MatDialog,
      private commonService: CommonService,
      private famigliaService: FamigliaService,
      private http: HttpClient) {
    // this.form = new FormGroup({
    //   categoria: new FormControl(2)
    // })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  openVarianteDialog(varianteid: number = 0): void {
    if(this.idInterno >0){
    let variante = this.rowDataVarianti.find((e) => e.gridIndex == varianteid);
    const dialogRef = this.dialog.open(ProductDialogVarianteComponent, {
      width: '250px',
      data: {
        Articolo_Id: this.ArticoloID, IdVarianti: varianteid,
        name: variante?.nomeVariante || '', valore: variante?.valore || '',
        note: variante?.note || ''
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let variante: VarianteArticolo = {
          articolo_Id: result.Articolo_Id,
          idVarianti: result?.idVarianti || 0,
          nomeVariante: result.name,
          valore: result.valore.toString(),
          note: result.note,
          utente : '',
          gridIndex : result.gridIndex
        }
        this.articoloService.InsVariante(variante).subscribe({
          next: (response: any) => {
              if (!response.error) {
                //const token = response.token;
                this.articoloService.GetArticolo(this.idInterno).subscribe((res) => {
                  this.rowDataVarianti = res[0]?.variante || [];
                  this.rowDataDescrizioni = res[0]?.altredescrizioni || [];
                })
            } else {
              this.openSnackBar('Si è verificato un errore');
            }
          },
          error: (err) => {
            console.error(err.message)
            if(err.status == 500 || err.status == 400)
              this.openSnackBar(err.error.message != '' ? err.error.message : err.message);
            else
              this.openSnackBar('Si è verificato un errore');
  
          }
        }
          );
      }
    });
  }else
   this.openSnackBar('Salvare l\'Articolo prima di continuare');
  }

  onElimina(){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'L\'articolo sarà eliminato, Continuare?',
        BtnSi: 'Elimina',
        BtnNo: 'Annulla',
        BtnInvertiti: true
      },
    });
 
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = 'block';
        this.articoloService.DelArticolo(this.idInterno).subscribe((data) => {
          this.loading = 'none'
          if (data > 0) {
            this.onClose.emit(this.idInterno);   // da doc era closeTab
          } else {
            this.openSnackBar(data);
          }
        });
      }
    });
  };

  openDescrizioneDialog(sigla: string = ''): void {
    if(this.idInterno >0){
    let descrizione = this.rowDataDescrizioni.find((e) => e.gridIndex == sigla);
    const dialogRef = this.dialog.open(ProductDialogDescrizioneComponent, {
      width: '250px',
      data: {
        Articolo_Id: this.ArticoloID,
        Sigla: descrizione?.sigla || '',
        Descrizione: descrizione?.descrizione || '',
        nazioni: this.nazioni
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let descrizione: DescrizioneArticolo = {
          articolo_Id: result.Articolo_Id,
          sigla: result.Sigla,
          descrizione: result.Descrizione,
          utente: '',
          gridIndex : result.gridIndex
        }
        this.articoloService.InsDescrizione(descrizione).subscribe({
          next: (response: any) => {
              if (!response.error) {
                //const token = response.token;
                this.articoloService.GetArticolo(this.idInterno).subscribe((res) => {
                  this.rowDataVarianti = res[0]?.variante || [];
                  this.rowDataDescrizioni = res[0]?.altredescrizioni || [];
                })
            } else {
              this.openSnackBar('Si è verificato un errore');
            }
          },
          error: (err) => {
            console.error(err.message)
            if(err.status == 500 || err.status == 400)
              this.openSnackBar(err.error.message != '' ? err.error.message : err.message);
            else
              this.openSnackBar('Si è verificato un errore');
  
          }
        });
      }
    });
  }else
    this.openSnackBar('Salvare l\'Articolo prima di continuare');

  }

  RefreshVariantiDescrizioni(params : GridReadyEvent){
    this.gridColumnApi = params.columnApi;
    this.articoloService.GetArticolo(this.idInterno).subscribe((res) => {
      this.rowDataVarianti = res[0]?.variante || [];
      this.rowDataDescrizioni = res[0]?.altredescrizioni || [];
    });
  }

  ngOnInit(): void {
    this.idInterno=this.id
    this.getProdotto(this.idInterno);
    this.getListe();
  }

  ngAfterViewInit() {
  }

  getListe() {
    this.commonService.getListe()
      .subscribe((data) => (this.liste = data));
  }
  dataSource;
  dataSourceGiacenze;
  nazioni;
  ArticoloID;
  listaClienti;
  listaGruppi;
  listaFamiglie;

  prodottoFinito: boolean = false;
  getProdotto(id: number) {
    this.articoloService.GetArticolo(id).subscribe((res) => {
      // if(id!=-1){
      //   this.formprodotto.get('PartNumber').disable()
      //   this.formprodotto.get('Descrizione').disable()
      // }
      this.formprodotto = new FormGroup({
        articolo_Id: new FormControl(res[0]?.Articolo_Id || 0, Validators.required),
        partNumber: new FormControl(res[0]?.PartNumber || ''),
        descrizione: new FormControl(res[0]?.Descrizione || '', Validators.required),
        // Tipo: new FormControl(res[0]?.Tipo || '', Validators.required),
        idCategoria: new FormControl(res[0]?.IdCategoria || 1),
        pzConfezione: new FormControl(res[0]?.PzConfezione || 0),
        id_Valuta: new FormControl(res[0]?.id_Valuta || 0),
        idCategoriaABC: new FormControl(res[0]?.IdCategoriaABC || 1),
        id_Misura: new FormControl(res[0]?.id_Misura || 1, Validators.required),
        idCriterio: new FormControl(res[0]?.IdCriterio || 1),
        lottoRiordino: new FormControl(res[0]?.LottoRiordino || 0),
        pn: new FormControl(res[0]?.PN || 0),
        pl: new FormControl(res[0]?.PL || 0),
        scortamin: new FormControl(res[0]?.Scortamin || 0),
        scortamax: new FormControl(res[0]?.Scortamax || 0),
        note: new FormControl(res[0]?.Note || ''),
        altezza: new FormControl(res[0]?.Altezza || 0),
        larghezza: new FormControl(res[0]?.Larghezza || 0),
        profondita: new FormControl(res[0]?.Profondita || 0),
        volume: new FormControl(res[0]?.Volume || 0),
        // Colata: new FormControl(res[0]?.Colata || false), 
        // Fase: new FormControl(res[0]?.Fase || false),
        // MES: new FormControl(res[0]?.MES || false),
        ausiliario: new FormControl(res[0]?.Ausiliario || false),
        idCliente: new FormControl(res[0]?.idCliente || 0, Validators.required),
        idGruppo: new FormControl(res[0]?.IdGruppo || 0),
        idFamiglia: new FormControl(res[0]?.IdFamiglia || 0),
        lavaggio: new FormControl(res[0]?.Lavaggio || false),
        bloccoQualita: new FormControl(res[0]?.BloccoQualita || false),
      });

      this.prodottoFinito = res[0]?.ProdottoFinito || false;

      this.dataSource = res[0]?.log || [];
      this.dataSourceGiacenze = res[0]?.giacenze || [];
      this.rowDataVarianti = res[0]?.variante || [];
      this.rowDataDescrizioni = res[0]?.altredescrizioni || [];
      this.nazioni = res[0]?.nazioni || [];
      this.ArticoloID = res[0]?.Articolo_Id || [];
      this.listaClienti=res[0]?.enti || [];
      if(this.formprodotto.get('idGruppo').value == "0")
        this.formprodotto.get('idFamiglia').disable();
      else
        this.ElencoFamiglie(this.formprodotto.get('idGruppo').value)

      this.listaGruppi= res[0]?.gruppi;  

      

    }
    );
  }

  rowDataKit : Kit[] = [];

  openArticoloEditDialog(e : RowDoubleClickedEvent){
    if(this.idInterno >0){
    let descrizione = this.rowDataKit.find((value) => value.gridIndex == +e);

    const dialogRef = this.dialog.open(ProductDialogEditArticoloComponent, {
      data: descrizione
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        //console.log(result)
        let articolo: Kit = result;
        articolo.articolo_Id = this.idInterno;
        articolo.utente = '';
        this.articoloService.InsKit(articolo).subscribe({
          next: (response) => {
              if (!response.error) {
                //const token = response.token;
                this.articoloService.GetKit(this.idInterno).subscribe((data) => {
                  this.rowDataKit = data;
                });
            } else {
              this.openSnackBar('Si è verificato un errore');
            }
          },
          error: (err) => {
            console.error(err.message)
            if(err.status == 500 || err.status == 400)
              this.openSnackBar(err.error.message != '' ? err.error.message : err.message);
            else
              this.openSnackBar('Si è verificato un errore');
  
          }
        })
      }
    });
  }else
  this.openSnackBar('Salvare l\'Articolo prima di continuare');
  }

  RefreshKit(params : GridReadyEvent){

    this.gridColumnApi = params.columnApi;
    this.articoloService.GetKit(this.idInterno).subscribe((data) => {
      this.rowDataKit = data;
    });

    // this.http.get<Kit[]>("https://testapiall1.azurewebsites.net/api/articoli/GetKits/?id=0")
    //   .subscribe((data) => {
    //     this.rowDataKit = data;
    //     this.loading = 'none';
    //   });

  }

  public columnDefKit: (ColDef | ColGroupDef)[] = [
    // { field: 'Articolo_Id', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'descrizione', headerName: "Des. Articolo", width: 200 },
    { field: 'partNumber', headerName: "Part Number", width: 200 },
    { field: 'qta', headerName: "Qtà", width: 150, type: 'numberColumn' },
    { field: 'note', headerName: "Note", width: 200 },
    //{ field: 'Data', headerName: "Data", type: 'dateColumn' },
  ];

  ElencoFamiglie(id: number){
    this.famigliaService.GetFamiglia(id).subscribe((data) => {
      this.listaFamiglie = data;
    });
  }

  displayedColumns: string[] = ['id', 'tipo', 'utente', 'data'];
  displayedColumnsGiacenza: string[] = ['udc', 'locazione', 'qta'];


  onSubmit() {
    if (!this.formprodotto.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
    } else {
      this.loading = 'block';
      let req: Articolo = this.formprodotto.getRawValue();
      req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
      req.lottoRiordino = req?.lottoRiordino || 0;
      
      if (this.idInterno  > 0) 
      {
        req.articolo_Id = this.idInterno
      }

      let token = JSON.parse(localStorage.getItem('r'));

      req.utente = token.firstName + " " + token.lastName; //dal token prelevare il nome utente

      req.tipo = 0;
      req.colata = false;
      req.fase = false;
      req.mes = false;
      this.articoloService.InsArticolo(req)
      .subscribe({
        next: (response) => {
          if (response) {
            this.loading = 'none';
            if (req.articolo_Id == 0)
              this.openSnackBar('Prodotto salvato');
            else {
              this.openSnackBar('Prodotto modificato');
              this.getProdotto(req.articolo_Id);
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          this.loading = 'none';
          console.error(err)
            this.openSnackBar(err.message != "" ? err.message : 'Si è verificato un errore');
        }
      })
      


      // .subscribe((data) => {

      //   this.loading = 'none';
      //   if (req.articolo_Id == 0)
      //     this.openSnackBar('Prodotto salvato');
      //   else {
      //     this.openSnackBar('Prodotto modificato');
      //     this.getProdotto(req.articolo_Id);
      //   }

      // });
    }
  }

  onUndo() {
    if (this.idInterno > 0) {
      this.getProdotto(this.idInterno);
    } else {
      this.getProdotto(-1);
    }
  }

  // nVariante = 0;
  // varianti: any[] = []

  // newVariante() {
  //   this.formvariante['Variante' + this.nVariante] = new FormControl('');
  //   this.formvariante['Valore' + this.nVariante] = new FormControl(0);
  //   this.formvariante['NoteVariante' + this.nVariante] = new FormControl('');

  // }


  public columnDefsVarianti: (ColDef | ColGroupDef)[] = [
    // { field: 'IdVariantìi', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'nomeVariante', headerName: "Nome Variante", width: 320, filter: 'agTextColumnFilter' },
    { field: 'valore', type: 'numberColumn', width: 200, filter: 'agTextColumnFilter' },
    { field: 'note', width: 250, filter: 'agTextColumnFilter' },
  ];
  public columnDefsDescrizioni: (ColDef | ColGroupDef)[] = [
    { field: 'sigla', headerName: "Sigla", width: 320, filter: 'agTextColumnFilter' },
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
  ];


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;


  openArticoloDialog(){
    if(this.idInterno >0){
    const dialogRef = this.dialog.open(ProductDialogArticoloComponent, {
      width: "50vw",
      //height: window.innerHeight - 100 + "px",
      data: {
        DocumentoID: this.idInterno
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result)
        let articolo: Kit = result;
        articolo.articolo_Id = this.idInterno;
        this.articoloService.InsKit(articolo).subscribe({
          next: (response) => {
              if (!response.error) {
                //const token = response.token;
                this.articoloService.GetKit(this.idInterno).subscribe((data) => {
                  this.rowDataKit = data;
                });
            } else {
              this.openSnackBar('Si è verificato un errore');
            }
          },
          error: (err) => {
            console.error(err.message)
            if(err.status == 500 || err.status == 400)
              this.openSnackBar(err.error.message != '' ? err.error.message : err.message);
            else
              this.openSnackBar('Si è verificato un errore');
  
          }
        }
        )
      }
    });
  }else{
    this.openSnackBar('Salvare l\'Articolo prima di continuare');
  }
  }
  
  changeBloccoQualita(){
    let bqValue = this.formprodotto.get('bloccoQualita').value; //nuovo valore

    let req = {
      Val : bqValue,
      IdArticolo : this.idInterno
    }

    this.articoloService.UpdateBloccoQualita(req).subscribe({
      next: (response) => {
        if (!response.error) {
          this.openSnackBar('Blocco Qualità aggiornato');
        } else {
          this.openSnackBar('Si è verificato un errore');
        }
      },
      error: (err) => {
        console.error(err.message)
        if(err.status == 500 || err.status == 400)
          this.openSnackBar(err.error.message != '' ? err.error.message : err.message);
        else
          this.openSnackBar('Si è verificato un errore');

      }
    }
    )
  }


}
