<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
</div>
<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)"
    (selectedTabChange)="changeTab($event)">
    <mat-tab [label]=label>
        <div class="card">
            <mat-card-actions  align="start">
                <button class="button-tab-save" (click)="newTab()" title="Aggiungi">
                    <mat-icon style="font-size:20px">add</mat-icon>
                </button>
                <button class="button-tab-confirm" (click)="newDDT()" title="Crea DDT">
                    <mat-icon style="font-size:20px">folder</mat-icon>
                </button>
                <button class="button-tab-undo" (click)="refreshGrid()" title="Ricarica">
                    <mat-icon style="font-size:20px">refresh</mat-icon>
                </button>
                
            </mat-card-actions>
            <ag-grid-angular style="width: 100%; height: 80vh" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData$" [rowSelection]="'multiple'" [animateRows]="true"
                (gridReady)="onGridReady($event)" [columnTypes]="columnTypes" (rowDoubleClicked)="addTab($event)" [getRowStyle]="getRowStyle" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <!-- <app-grid [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowData]="rowData$" (gridReady)="onGridReady($event)" (onDblClick)="addTab($event)" [getRowStyle]="getRowStyle" style='width: 100%; height: 80vh' [frameworkComponents]="frameworkComponents"
            >
        </app-grid> -->
        </div>
    </mat-tab>
    <ng-container *ngFor="let tab of tabs; let index = index;">
        <mat-tab *ngIf="searchTab(tab.id!)">
            <ng-template mat-tab-label>
                {{tab.numero + ' ' + tab.nome}}
                <button mat-icon-button (click)="closeTab(tab.id!)">
                    <mat-icon>close</mat-icon>
                </button>
            </ng-template>
            <app-ods-edit [id]="IDODS" *ngIf="tab.isOds else emissioneddt"></app-ods-edit>
            <ng-template #emissioneddt>
                <app-uscita-edit [id]="DocumentoID" [fromDDT]="false" [livelloID]="livelloID" [numOrdine]="numOrdine"  (closeTab)="closeTab($event)"></app-uscita-edit>
            </ng-template>
        </mat-tab>
    </ng-container>
</mat-tab-group>