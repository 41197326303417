import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { IngressoArticolo } from 'src/app/Models/IngressoArticolo';
import { Liste } from 'src/app/Models/Liste';
import { TipoDocumento } from 'src/app/Models/TipoDocumento';
import { ArticoloService } from 'src/app/services/articolo.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomerService } from 'src/app/services/customer.service';
import { DocumentiService } from 'src/app/services/documenti.service';
import { DialogIngressoArticoloComponent } from '../dialog-ingresso-articolo/dialog-ingresso-articolo.component';
import { DialogIngressoCercaArticoloComponent } from '../dialog-ingresso-cerca-articolo/dialog-ingresso-cerca-articolo.component';
import { Tab } from '../ingresso/ingresso.component';
import { Prelievo } from 'src/app/Models/Prelievo';
import { PrelievoDettagli } from 'src/app/Models/PrelievoDettagli';
import { DialogUscitaArticoloComponent } from '../dialog-uscita-articolo/dialog-uscita-articolo.component';
import { DialogUscitaCercaArticoloComponent } from '../dialog-uscita-cerca-articolo/dialog-uscita-cerca-articolo.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OrdineDettagli } from 'src/app/Models/OrdineDettagli';
import { Ordine } from 'src/app/Models/Ordine';
import { DialogOrdineCercaArticoloComponent } from '../dialog-ordine-cerca-articolo/dialog-ordine-cerca-articolo.component';
import { DestinazioniClienti } from 'src/app/Models/DestinazioniClienti';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { GridWarnIconComponent } from '../../grid-warn-icon/grid-warn-icon.component';


@Component({
  selector: 'app-ordine-edit',
  templateUrl: './ordine-edit.component.html',
  styleUrls: ['./ordine-edit.component.scss']
})
export class OrdineEditComponent implements OnInit {

  formingresso: FormGroup = new FormGroup({
    note : new FormControl(''),
    idCliente: new FormControl(0),
    idIntestatario: new FormControl(0),
    fornitoreId: new FormControl(0),
    idSito: new FormControl(0),
    codice_Dest: new FormControl(0),
    urgenza: new FormControl(false),
    lavaggio: new FormControl(false),
    indirizzo: new FormControl(''),
    localita: new FormControl(''),
    provincia: new FormControl(''),
    nazione: new FormControl(''),
    cap: new FormControl(''),
    numeroOrdine: new FormControl(''),
    dataConsegna: new FormControl((new Date()).toISOString().substring(0,10)),
  });
  @Input() id: number = 0;
  @Input() livelloID: number = 0;
  @Output() closeTab = new EventEmitter<number>();

  @Input() tab: Tab = { id: 0, nome: "", numero: "" };

  public loading: string = 'none';
  selected = new FormControl(0);
  clienti : Cliente[] = [];
  
  idInterno : number;

  openSnackBar(message: string, time = 3000) {
    this._snackBar.open(message, 'Chiudi', {
      duration: time
    });
  }

  
  constructor(private _snackBar: MatSnackBar, 
    private documentiService: DocumentiService,
    private clientiService: CustomerService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private jwtHelper: JwtHelperService,
    private gridApi: GridApi) { 
    }

  hasUDC: boolean = false;

  fornitoreID;
  IDIntestatario;

  dynamicControls = [];
  FaseExtraActionsFromGet;
  Lavaggio = false;

  reloaded = false

  fromPianoConsegne : boolean = false;

  getOrdine(id: number) {
    this.formingresso.get('idIntestatario').disable()
    this.formingresso.get('fornitoreId').disable()
    this.documentiService.GetOrdine(id, 14).subscribe((res) => {
      this.formingresso = new FormGroup({

        //Data: new FormControl(new Date(res[0]?.Data).toLocaleDateString('af').replace(new RegExp('/', "g"), '-') || '', Validators.required),
        note: new FormControl(res[0]?.note || ''),
        idCliente: new FormControl(res[0]?.idCliente || 0, Validators.required),
        idIntestatario: new FormControl(res[0]?.idIntestatario || 0, Validators.required),
        fornitoreId: new FormControl(res[0]?.fornitoreId || 0, Validators.required),
        codice_Dest: new FormControl(res[0]?.codice_Dest || 0, Validators.required),
        urgenza: new FormControl(res[0]?.urgenza || false),
        lavaggio: new FormControl(res[0]?.lavaggio || false),
        indirizzo: new FormControl(res[0]?.indirizzo || '', Validators.required),
        localita: new FormControl(res[0]?.localita || '', Validators.required),
        provincia: new FormControl(res[0]?.provincia || '', Validators.required),
        nazione: new FormControl(res[0]?.nazione || '', Validators.required),
        cap: new FormControl(res[0]?.cap || '', Validators.required),
        numeroOrdine: new FormControl(res[0]?.numeroOrdine || '', Validators.required),
        dataConsegna: new FormControl(new Date(res[0]?.dataConsegna).toLocaleDateString('af').replace(new RegExp('/', "g"), '-')  || (new Date()).toISOString().substring(0,10)),
      });
      
      this.fromPianoConsegne = res[0].fromPianoConsegne;
      
      //res[0].FaseExtraActions = []
      // res[0].FaseExtraActions = [
      //   {
      //     Id: 1,
      //     IdFaseExtra: 1,
      //     Action: "SKIP",
      //     ActionName: "NoLavaggio",
      //     Type: "bool",
      //     Value: "false"
      //   }
      // ]

      //if (!this.reloaded) {
        this.FaseExtraActionsFromGet = res[0]?.faseExtraActions || [];

        let dC = [];
        let form = this.formingresso;
        if (res[0]?.faseExtraActions && res[0]?.faseExtraActions.length > 0) {
          res[0].faseExtraActions.forEach(function (value) {
            form.addControl('dynamic' + value.id, new FormControl(value.type == 'bool' ? (value.value == 'true' ? true : false) : value.value));
            dC.push(value)
          });
        }
        this.dynamicControls = dC;
      //}


      this.IDIntestatario = res[0]?.idIntestatario || 0;
      this.fornitoreID = res[0]?.fornitoreId || 0;

      if(id == -1)
      this.formingresso.get('dataConsegna').setValue( (new Date()).toISOString().substring(0,10))



      this.clienti = res[0].enti;
      this.rowArticoli = res[0].dettagliOrdine;
      this.listarticoli = res[0].dettagliOrdine;
      this.Lavaggio = false;
      
      if (this.listarticoli?.length > 0) {
        this.Lavaggio = this.listarticoli.some(oggetto => oggetto.lavaggio === true)
        if (this.listarticoli[0].numUDC != '') {
          // this.hasUDC = true;
          if(!this.fromPianoConsegne)
            this.columnDefsArticoli = this.columnDefsArticoliUDC
          else
          this.columnDefsArticoli = this.columnDefsPianoConsegne;
        } else
          this.columnDefsArticoli = this.columnDefsArticolinoUDC
      } else
        this.columnDefsArticoli = this.columnDefsArticolinoUDC

      this.formingresso.get('idIntestatario').disable()
      this.formingresso.get('fornitoreId').disable()

      if (res[0].confermato) {
        this.btnadd = false;
        this.btnsalva = false;
      }
      this.onChangeCliente(res[0].idCliente, res[0].idIntestatario)
    }
    );
  }

  destinazioni : DestinazioniClienti[]= [];
  destinatari;
  fornitori;

  

  btnadd = true;
  btnsalva= true;
  onChangeCliente(id:number, idint: number = 0){
    if(id != 0){
      // this.clientiService.GetDestinazioniClienti(id).subscribe((data)=>{
      //   this.destinazioni = data;
      // })
      this.formingresso.get('idIntestatario').enable()
      this.formingresso.get('fornitoreId').enable()
      this.clientiService.GetEnte(id).subscribe((data) => {
        this.destinatari = data[0]?.entidestinatari || [];
        this.fornitori = data[0]?.entifornitori || [];
        this.formingresso.get('fornitoreId').setValue(this.fornitoreID)
        if (idint != 0) {
          this.onChangeIntestatario(idint)
          this.formingresso.get('idIntestatario').setValue(this.IDIntestatario)
        }
      })
      //this.btnadd=true
    }
  }
  
  onChangeIntestatario(id:number){
    this.clientiService.GetEnte(id).subscribe((data) => {
      this.formingresso.get('indirizzo').setValue(data[0].indirizzo_Legale)
      this.formingresso.get('localita').setValue(data[0].cittaL)
      this.formingresso.get('provincia').setValue(data[0].provL)
      this.formingresso.get('cap').setValue(data[0].capL)
      this.formingresso.get('nazione').setValue('Italia');
      if(this.btnsalva)
        this.btnadd = true
    })
    
  }

  RefreshMissioni(params : GridReadyEvent){
    this.gridColumnApi = params.columnApi;
    this.rowArticoli= this.rowArticoli;
  }

  onSubmit(Confermato : boolean = false) {
    if (!this.formingresso.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
      return false;
    } else {
      this.loading = 'block';
      let req: Ordine = this.formingresso.value;
      req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
      req.idOrdine= this.idInterno == -1 ? 0 : this.idInterno;
      this.listarticoli.forEach(a => {
        a.disabilitato = a?.disabilitato || false;
        a.articolo_id = a?.articolo_id || 0;
      });
      req.dettagliOrdine = this.listarticoli;
      req.confermato = Confermato
      req.utente= '';
      req.data=new Date();
      req.lavaggio = this.Lavaggio;
      //req.disabilitato = false
      
      
      req.faseExtraActions = [];
      let form = this.formingresso;
      
      this.FaseExtraActionsFromGet.forEach(element => {
        req.faseExtraActions.push(
          {
            id: element.id,
            idFaseExtra: element.idFaseExtra,
            action: element.action,
            actionName: element.actionName,
            type: element.type,
            value: form.get('dynamic' + element.id).value ? form.get('dynamic' + element.id).value : ""
          }
        )
        
        delete form.value['dynamic' +element.id]
        //form.removeControl('dynamic' +element.Id)
      });
      this.formingresso = form;
      // console.log(req);
      this.documentiService.InsOrdine(req).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err == '0') {
          this.idInterno = +data[0].msg;
          this.reloaded = true;
          this.getOrdine(data[0].msg);
          if(Confermato)
            this.openSnackBar('Ordine Confermato');
          else
            this.openSnackBar('Ordine Salvato');
          return true
        } else {
          this.openSnackBar(data[0].msg);
          return false
        }
      });
      
      return true
    }
  }

  public listarticoli: OrdineDettagli[] = [];
  rowArticoli: OrdineDettagli[] = [];

  public columnDefsArticoli:(ColDef | ColGroupDef)[];
  public columnDefsArticolinoUDC: (ColDef | ColGroupDef)[] = [
    //{ field: 'gridIndex', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: "note", headerName : "Note",width:100, filter: false, cellRenderer: GridWarnIconComponent,},
    { field: 'descrizione', width: 200, filter: 'agNumberColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 200, filter: 'agNumberColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
  ];
  public columnDefsArticoliUDC: (ColDef | ColGroupDef)[] = [
    { field: "note", headerName : "Note",width:100, filter: false, cellRenderer: GridWarnIconComponent,},
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'numUDC', headerName: "UDC", width: 150, filter: 'agTextColumnFilter' },
  ];
  public columnDefsPianoConsegne: (ColDef | ColGroupDef)[] = [
    { field: "note", headerName : "Note",width:100, filter: false, cellRenderer: GridWarnIconComponent,},
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Cod. Art.", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qtà", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'numUDC', headerName: "UDC", width: 150, filter: 'agTextColumnFilter' },
    { field: 'pesoNetto', headerName: "Peso Netto", width: 150, filter: 'agNumberColumnFilter' },
    { field: 'pesoLordo', headerName: "Peso Lordo", width: 150, filter: 'agNumberColumnFilter' },
    { field: 'altezza', headerName: "Altezza", width: 150, filter: 'agNumberColumnFilter' },
    { field: 'larghezza', headerName: "Larghezza", width: 150, filter: 'agNumberColumnFilter' },
    { field: 'lunghezza', headerName: "Lunghezza", width: 150, filter: 'agNumberColumnFilter' },
  ];

 


  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
    }

  ngOnInit(): void {



    this.getOrdine(this.id)

    

    this.idInterno=this.id;
  }

  openDialog(e: RowDoubleClickedEvent){

    //if(this.btnsalva){
    let articolo = this.rowArticoli.find((val) => val.gridIndex == +e);
    const dialogRef = this.dialog.open(DialogUscitaArticoloComponent, {
      data: {
        isUDC: articolo.numUDC != '' ? true : false,
        articolo,
        note: articolo.note
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.articolo.utente = this.jwtHelper.decodeToken(localStorage.getItem('jwt')).name
        result.articolo.Note = result.note
        this.listarticoli = [result.articolo];
        this.onSubmit()
      }
    });
    //}

  }

  onDelete(){
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: {
        messaggio: 'Eliminare l\'ordine?',
        BtnSi: 'Elimina',
        BtnNo: 'Annulla',
        BtnInvertiti: true
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = 'block';
        this.documentiService.Del_Ordine(this.idInterno).subscribe((data) => {
          this.loading = 'none'
          if (data[0].err == '0') {
            this.closeTab.emit(this.idInterno);
          } else {
            this.openSnackBar(data[0].msg);
          }
        });
      }
    });

    
  }

  openDialogSearchArticolo() {
    if(this.formingresso.get('numeroOrdine').value != ''){
    let IdCliente = this.formingresso.get('idCliente').value;

    let hasUDC = false;
    let isUDC = false;
    if (this.listarticoli == null) {
      hasUDC = true;

    } else {
      if (this.listarticoli.length > 0) {
        if (this.listarticoli[0]?.numUDC != '') {
          isUDC = true;
        }
      }
      else
        hasUDC = true
    }


    const dialogRef = this.dialog.open(DialogOrdineCercaArticoloComponent, {
      width: '100vw',
      maxWidth: '100vw',
      data: {
        IdCliente,
        hasUDC,
        isUDC,
        livelloID: this.livelloID,
        fixedlavaggio: this.Lavaggio,
        hasProducts: this.listarticoli?.length > 0 ? true : false
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.listarticoli = result.articoli;
        this.Lavaggio = result.lavaggio
        //console.log(this.listarticoli);
        this.onSubmit()
      }
    });
  }else{
    this.openSnackBar('Compila i campi obbligatori prima di continuare');
  }
  }

  onClickConferma() {
    if (!this.formingresso.valid) {
      this.openSnackBar('Compila i campi obbligatori prima di continuare');
    } else {

      if (this.listarticoli?.length > 0) {

        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          data: {
            messaggio: 'Una volta confermato non sarà possibile modificare i dettagli dell\'ordine',
            BtnSi: 'Conferma',
            BtnNo: 'Annulla'
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.onSubmit(true)
          }
        });
      } else {
        this.openSnackBar('L\'ordine deve contenere almeno un\'articolo')
      }
    }
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onStampa(){
    if(this.idInterno > 0){
      this.loading = 'block';
      this.documentiService.StampaOrdine(this.idInterno).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err = '0') {
          var fileblob = this.b64toBlob(data[0].msg, 'application/pdf');
          var url = window.URL.createObjectURL(fileblob);

          window.open(url);
        } else {
          this.openSnackBar(data[0].msg, 5000);
        }
      });
    }else{
      this.openSnackBar("Salvare l'ordine prima di continuare");
    }
  }


  onStampaDDT() {
    if (this.idInterno > 0) {
      this.loading = 'block';
      this.documentiService.StampaOrdineDdt(this.idInterno).subscribe((data) => {
        this.loading = 'none'
        if (data[0].err = '0') {
          var fileblob = this.b64toBlob(data[0].msg, 'application/pdf');
          var url = window.URL.createObjectURL(fileblob);

          window.open(url);
        } else {
          this.openSnackBar(data[0].msg, 5000);
        }
      });
    } else {
      this.openSnackBar("Salvare l'ordine prima di continuare");
    }
  }

}
