<form [formGroup]="formingresso" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button>-->
            <button class="button-print-icon" type="button" (click)="onStampa()">
                <mat-icon style="font-size:20px">print</mat-icon></button>
            <button class="button-tab-delete" type="button" title="Elimina" (click)="onDelete()">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button> 
            <button class="button-confirm-row" type="button" (click)="onStampaDDT()">
                Stampa DDT</button>

        </mat-card-actions>
        <table>
            <tr>
                <td style="border-right: 1px solid #c0c0c0;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr><td style="text-align: center;" colspan="3"><p class="text-grigio">Dati Generali</p></td></tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Cliente</mat-label>
                                    <mat-select name="IdCliente" formControlName="idCliente" (valueChange)="onChangeCliente($event)">
                                        <!-- (valueChange)="onChangeCliente($event)" -->
                                        <mat-option *ngFor="let cliente of clienti" [value]="cliente.idCliente">
                                            {{cliente.ragione_Sociale}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Intestatario</mat-label>
                                    <mat-select formControlName="fornitoreId" name="FornitoreID">
                                        <!-- (valueChange)="onChangeIntestatario($event)" -->
                                        <mat-option *ngFor="let ente of fornitori" [value]="ente.idCliente">
                                            {{ente.ragione_Sociale}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <!-- <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Destinazione</mat-label>
                                    <mat-select formControlName="Codice_Dest">
                                        <mat-option *ngFor="let destinazione of destinazioni" [value]="destinazione.Codice_Dest">
                                            {{destinazione.Indirizzo}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td> -->
                            
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Numero Ordine</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="nordine" formControlName="numeroOrdine">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Data di consegna</mat-label>
                                    <input matInput type="date" name="DataConsegna" formControlName="dataConsegna">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-checkbox type="checkbox" name="Urgenza" color="primary" formControlName="urgenza">Urgente
                                </mat-checkbox>
                            </td>
                            
                                <!-- <mat-checkbox type="checkbox" name="Lavaggio" color="primary" formControlName="Lavaggio">No Lavaggio
                                </mat-checkbox> -->
                                <div *ngFor="let item of dynamicControls">
                                    <td>
                                        <ng-container [ngSwitch]="item.Type">
                                            <div *ngSwitchCase="'bool'">
                                                <mat-checkbox type="checkbox" [value]="item.Value" [formControlName]="'dynamic' + item.Id" color="primary">{{item.ActionName}}</mat-checkbox>
                                            </div>
                                            <div *ngSwitchCase="'string'">
                                                <mat-form-field class="full-width">
                                                    <mat-label>{{item.actionName}}</mat-label>
                                                    <input matInput [formControlName]="'dynamic' +item.id">
                                                </mat-form-field>
                                            </div>
                                            <div *ngSwitchDefault>
                                            </div>
                                        </ng-container>
                                    </td>
                              </div>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <mat-form-field class="full-width">
                                    <mat-label>Note</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <textarea matInput formControlName="note" rows="5"></textarea>
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>
                </td>
                <td style="display: table;align-items: start;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr><td style="text-align: center;" colspan="3"><p class="text-grigio">Destinatario</p></td></tr>
                        <tr>
                            <td colspan="2">
                                <mat-form-field class="full-width">
                                    <mat-label>Destinatario</mat-label>
                                    <mat-select name="IDIntestatario" formControlName="idIntestatario" (valueChange)="onChangeIntestatario($event)">
                                        <!-- (valueChange)="onChangeIntestatario($event)" -->
                                        <mat-option *ngFor="let ente of destinatari" [value]="ente.idCliente">
                                            {{ente.ragione_Sociale}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <mat-form-field class="full-width">
                                <mat-label>Indirizzo</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="Indirizzo" formControlName="indirizzo">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>CAP</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="cap" formControlName="cap">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Località</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="Localita" formControlName="localita">
                            </mat-form-field>
                        </td>
                    
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Provincia</mat-label>
                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                            <input matInput name="Provincia" formControlName="provincia">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Nazione</mat-label>
                            <input matInput name="Nazione" formControlName="nazione">
                        </mat-form-field>
                    </td>
                </tr>
                    </table>
                </td>
            </tr>
        
        </table>
        <div style="display:flex;flex-direction: row; justify-content: space-between;">
            <div>
                <button *ngIf="btnadd" class="button-add-row" type="button" (click)="openDialogSearchArticolo()">Aggiungi
                    Riga</button>
                
                 <!-- <button class="button-delete-row" type="button">Elimina
                    Riga</button> -->
            </div>
            <div>
                <button class="button-confirm-row " *ngIf="btnsalva && !fromPianoConsegne" type="button" title="Salva" (click)="onClickConferma()">
                    Conferma
                </button>
            </div>
        </div>
        <app-grid [columnDefs]="columnDefsArticoli"
            [defaultColDef]="defaultColDef" [rowData]="rowArticoli" (gridReady)="RefreshMissioni($event)" (onDblClick)="openDialog($event)"
            >
        </app-grid>
        

    </mat-card>
</form>