import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, IAggFunc, IAggFuncParams, RowClickedEvent, RowDoubleClickedEvent, RowGroupingDisplayType } from 'ag-grid-community';
import { Ingresso } from 'src/app/Models/Ingresso';
import { UDC } from 'src/app/Models/UDC';
import { DocumentiService } from 'src/app/services/documenti.service';
import { Tab } from '../documenti/ingresso/ingresso.component';
import { DialogDocumentoComponent } from '../dialog-documento/dialog-documento.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pn-edit',
  templateUrl: './pn-edit.component.html',
  styleUrls: ['./pn-edit.component.scss']
})
export class PnEditComponent implements OnInit {

  form: FormGroup = new FormGroup({
    idUDC: new FormControl(''),
    NomeUDC: new FormControl(''),
    data: new FormControl(''),
  })

  @Input() id: number = 0;
  @Input() ndoc: string = '';
  @Input() livelloID: number = -1;
  @Input() tab: Tab = { id: 0, nome: "", numero: '' };

  public loading: string = 'none';
  constructor(private _snackBar: MatSnackBar,
  private documentiService: DocumentiService,
  public dialog: MatDialog,) { }
  selected = new FormControl(0);
  public rowData$!: any[];
  public rowDataGia$!: any[];
  public rowDataMov$!: any[];
  idDoc: number = 0;
  isAbbinato: boolean= false;

  public columnDefsUDC: (ColDef | ColGroupDef)[] = [
    // { field: 'DocumentoID', headerName: "ID", width: 100, type: 'numberColumn'},
    { field: 'numDoc', headerName: "Numero Documento", width: 320, filter: 'agTextColumnFilter' },
    { field: 'dataDoc', headerName: "Data Documento", width: 320, type: 'dateColumn' },
    { field: 'causale', headerName: "Tipo Documento", width: 200, filter: 'agTextColumnFilter' },
    { field: 'ragioneSociale', headerName: "Ragione Sociale", width: 320, filter: 'agTextColumnFilter' },
    // { field: 'Localita', headerName: "Località", width: 200 },
    // { field: 'Indirizzo', headerName: "Indirizzo", width: 200 },
    // { field: 'Provincia', headerName: "Provincia", width: 200 },
    // { field: 'Nazione', headerName: "Nazione", width: 200 },
    // { field: 'cap', headerName: "CAP", width: 100 },
    // { field: 'DataConsegna', headerName: "Data Consegna", width: 200, type: 'dateColumn' },
    // { field: 'Peso', headerName: "Peso", width: 200, type: 'numberColumn' },
    // { field: 'Volume', headerName: "Volume", width: 200, type: 'numberColumn' },
    // { field: 'Email', headerName: "Email", width: 200 },
    // { field: 'Telefono', headerName: "Telefono", width: 200 },
  ];

  public columnDefsUDM: (ColDef | ColGroupDef)[] = [
    // { field: 'idUDC', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true},
    { field: 'nomeUDC', headerName: "Nome UDC", width: 200, filter: 'agTextColumnFilter' },
    { field: 'data', headerName: "Data", width: 150, type: 'dateColumn' },
  ];

  
  public columnDefsMov: (ColDef | ColGroupDef)[] = [
    { field: 'udc', headerName: "UDC", width: 200, filter: 'agTextColumnFilter' },
    { field: 'data', width: 150, type: 'dateColumn', filter: 'agTextColumnFilter'},
    { field: 'barcodelocazione', headerName: "Ubicazione", width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'numDoc', headerName: "Numero Documento", width: 200, filter: 'agTextColumnFilter' },
    { field: 'causale', headerName: "Causale", width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta',headerName: "Qta TOT", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'utente', headerName: "Utente", width: 320, filter: 'agTextColumnFilter'},
  ];


  public isGroupingEnabled = false; // Proprietà per il checkbox
  // { field: 'Id_giacenza', headerName: "ID", width: 100, type: 'numberColumn'},
  public columnDefsGia: (ColDef | ColGroupDef)[] = [
    { field: 'numUDC', headerName: 'UDC', width: 200, filter: 'agTextColumnFilter' },
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    {
      field: 'locazionesrg', headerName: "Ubicazione", width: 200, filter: 'agTextColumnFilter',
      rowGroup: this.isGroupingEnabled,  // Usa la proprietà per abilitare o disabilitare il raggruppamento
      hide: this.isGroupingEnabled       // Nascondi la colonna se raggruppato
    },
    { field: 'qta', headerName: "Qta TOT", type: 'numberColumn', filter: 'agNumberColumnFilter', aggFunc: this.isGroupingEnabled ? 'sum' : null, valueFormatter: this.isGroupingEnabled ? this.sumValueFormatter : null},
    { field: 'qtaDisponibile', headerName: "Qta Disponibile", type: 'numberColumn', filter: 'agNumberColumnFilter', aggFunc: this.isGroupingEnabled ? 'sum' : null , valueFormatter: this.isGroupingEnabled ? this.sumValueFormatter : null},
    { field: 'qtaImpegnata', headerName: "Qta Impegnata", type: 'numberColumn', filter: 'agNumberColumnFilter', aggFunc: this.isGroupingEnabled ? 'sum' : null, valueFormatter: this.isGroupingEnabled ? this.sumValueFormatter : null }
  ];

  
  public autoGroupColumnDef : ColDef = {
    headerName: 'Ubicazione',
    field: 'locazionesrg',
    width: 200,
    cellRendererParams: { suppressCount: true }  
  }

  onGroupingCheckboxChanged(event: any) {
    this.isGroupingEnabled = event.target.checked;
    this.updateColumnDefs();  // Aggiorna la definizione delle colonne
  }
  // Funzione per formattare il valore aggregato senza cambiare il titolo
  sumValueFormatter(params: any) {
    return params.value ? params.value : '';  // Ritorna solo il valore, senza aggiungere 'sum'
  }
  updateColumnDefs() {
    this.columnDefsGia = [
      { field: 'numUDC', headerName: 'UDC', width: 200, filter: 'agTextColumnFilter' },
      { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
      {
        field: 'locazionesrg', headerName: "Ubicazione", width: 200, filter: 'agTextColumnFilter',
        rowGroup: this.isGroupingEnabled,  // Usa la proprietà per abilitare o disabilitare il raggruppamento
        hide: this.isGroupingEnabled       // Nascondi la colonna se raggruppato
      },
      { field: 'qta', headerName: "Qta TOT", type: 'numberColumn', filter: 'agNumberColumnFilter', aggFunc: this.isGroupingEnabled ? 'sum' : null , valueFormatter: this.isGroupingEnabled ? this.sumValueFormatter : null},
      { field: 'qtaDisponibile', headerName: "Qta Disponibile", type: 'numberColumn', filter: 'agNumberColumnFilter', aggFunc: this.isGroupingEnabled ? 'sum' : null, valueFormatter: this.isGroupingEnabled ? this.sumValueFormatter : null},
      { field: 'qtaImpegnata', headerName: "Qta Impegnata", type: 'numberColumn', filter: 'agNumberColumnFilter', aggFunc: this.isGroupingEnabled ? 'sum' : null , valueFormatter: this.isGroupingEnabled ? this.sumValueFormatter : null}
    ];
  
  }

  // public groupDisplayType: RowGroupingDisplayType = 'groupRows';//'singleColumn'; // Inizialmente senza raggruppamento
  // public toggleGroup(event: any): void {
  //   this.gridApi.addAggFuncs({ sum: this.sumFunction });
  
  //   if (event.target.checked) {
  //     this.columnDefsGia = this.columnDefsGia.map(col => {
  //       if (col["field"] === 'locazionesrg') {
  //         return { ...col, rowGroup: true };
  //       }
  //       return col;
  //     });
  
  //     const ubicazioneCol = this.columnDefsGia.find(col => col["field"] === 'locazionesrg');
  //     if (ubicazioneCol) {
  //       this.columnDefsGia = this.columnDefsGia.filter(col => col["field"] !== 'locazionesrg');
  //       this.columnDefsGia.unshift(ubicazioneCol);
  //     }
  
  //     this.groupDisplayType = 'groupRows'; // Mostra i gruppi
  //   } else {
  //     this.columnDefsGia = this.columnDefsGia.map(col => {
  //       if (col["field"] === 'locazionesrg') {
  //         return { ...col, rowGroup: false };
  //       }
  //       return col;
  //     });
  
  //     const originalPositionCol = this.columnDefsGia.find(col => col["field"] === 'locazionesrg');
  //     if (originalPositionCol) {
  //       this.columnDefsGia = this.columnDefsGia.filter(col => col["field"] !== 'locazionesrg');
  //       this.columnDefsGia.splice(2, 0, originalPositionCol); // Inserisci al terzo posto
  //     }
  
  //     this.groupDisplayType = 'singleColumn'; // Disabilita il raggruppamento
  //   }
  //   this.gridApi.refreshCells({ force: true });
  // }

  // public aggFuncs: {
  //   [key: string]: IAggFunc;
  // } = {
  //   // this overrides the grids built in sum function
  //   sum: this.sumFunction
  // };

  // sumFunction(params: IAggFuncParams) {
  //   params.values.reduce((acc, value) => acc + (Number(value) || 0), 0)
  // }

  public columnDefs : (ColDef | ColGroupDef)[] = this.columnDefsUDC;
  idtipo:number=1;

  public label : string= 'UDC';

  onChangeTipo(e : number){
    this.idtipo=e;
    if(this.idtipo == 1){
      this.columnDefs = this.columnDefsUDC;
      this.label = 'UDC';
      this.reloadUDCData();
    }else if(this.idtipo == 0){
      this.label = 'UDM';
      this.columnDefs = this.columnDefsUDM;
      this.reloadUDMData();
    }
    this.idDoc = 0;
    (document.querySelector('#selectedRows') as any).innerHTML = ''
  }

  gridload(e: any){
    //console.log(e.tab.textLabel);
    if (this.isAbbinato && !this.isAdmin) {
      this.reloadMovimento();
      return
    }
    switch (e.tab.textLabel) {
      case 'Giacenze':
        this.reloadGiacenza();
        break;
      case 'Movimenti':
        this.reloadMovimento();
        break;
      case 'Documenti':
        this.reloadUDCData();
        break;
        default: break
    }


  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
  }

  isAdmin = false;

  ngOnInit(): void {
    if(this.ndoc != '-1'){
      this.isAbbinato= true;
    }
    this.idDoc=this.id;
    
    if(this.livelloID == 1)
      this.isAdmin=true;
  }

  // getDocumento(id: number) {
  //   this.documentiService.GetUDC(id).subscribe((res) => {
  //     this.form = new FormGroup({
  //       idUDC: new FormControl(res[0].idUDC),
  //       NomeUDC: new FormControl(res[0].NomeUDC, Validators.required),
  //       data: new FormControl(res[0].data, Validators.required),
  //     })
  //   })

  // }

  // onSubmit() {
  //   if (!this.form.valid) {
  //     this.openSnackBar('Compila i campi obbligatori prima di continuare');
  //   } else {
  //     this.loading = 'block';
  //     let req: UDC = this.form.value;
  //     //req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
  //     this.documentiService.InsUDC(req).subscribe(data => this.loading = 'none');
  //     this.openSnackBar(req.idUDC == 0 ? 'Documento salvato' : 'Documento modificato');
  //   }
  // }

  onSave() {
    this.loading = 'block';
    if (this.idDoc == 0) {
      this.documentiService.InsUDC(1).subscribe(data => this.loading = 'none');
    } else {
      this.documentiService.InsUDCDoc(this.idDoc, 0).subscribe(data => this.loading = 'none');
    }
    this.openSnackBar('UDC salvato');
  }

  OpenDoc(e: RowDoubleClickedEvent) {
    const dialogRef = this.dialog.open(DialogDocumentoComponent, {
      width: "80vw",
      height: window.innerHeight - 10 + "px",
      data: {
        id: e.data.DocumentoID,
        tipo: e.data.TipoDocID
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      
    })
    
  }
  
  

  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('it-IT')
       //return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };
  
  private gridApi!: GridApi<any>;
  public gridColumnApi!: ColumnApi;
  onGridReady(params: GridReadyEvent) {
    if (!this.gridApi) {
      this.gridColumnApi = params.columnApi;
      this.gridApi = params.api;
      if (this.isAbbinato) {
        if (this.livelloID == 1)
          this.reloadGiacenza()
        else {
          this.reloadMovimento()
        }
      } else {
        this.reloadUDCData();
      }
    }
    // if(!this.gridsLoaded)
    //   this.reloadGrids()

  }

  gridsLoaded: boolean=false;
  reloadGrids(){
    // this.reloadUDCData()
    // this.reloadGiacenza()
    // this.reloadMovimento()
    this.gridsLoaded = true;
  }

  reloadUDCData() {
    this.loading = 'block';
    this.documentiService.GetNoUDC(0,14,this.idDoc).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  reloadUDMData() {
    this.loading = 'block';
    this.documentiService.GetUDCnoUDM(14).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  reloadGiacenza() {
    this.loading = 'block';
    this.documentiService.GetGiacenzexArticoloId(this.idDoc).subscribe((data) => {
      this.rowDataGia$ = data
      this.loading = 'none';
    });

  }

  reloadMovimento() {
    this.loading = 'block';
    this.documentiService.GetMovimenti(0,this.idDoc).subscribe((data) => {
      this.rowDataMov$ = data
      this.loading = 'none';
    });

  }
}
