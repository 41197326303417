import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Giacenza } from 'src/app/Models/Giacenza';
import { ArticoloService } from 'src/app/services/articolo.service';
import { MagazziniTableComponent } from '../magazzini/magazzini-table/magazzini-table.component';

@Component({
  selector: 'app-dialog-selezione-articolo-inventario',
  templateUrl: './dialog-selezione-articolo-inventario.component.html',
  styleUrls: ['./dialog-selezione-articolo-inventario.component.scss']
})
export class DialogSelezioneArticoloInventarioComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSelezioneArticoloInventarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private articoliService: ArticoloService,
    public dialog: MatDialog,
  ) { }

  @ViewChild(MagazziniTableComponent) magazzinoTableComponent: MagazziniTableComponent;

  selectedLocation: any

  private gridApi!: GridApi<any>;
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.loadData()
  }

  rowArticoli: Giacenza[] = [];
  public columnDefsArticoli: (ColDef | ColGroupDef)[] = [
    {headerName:'', checkboxSelection:true, filter:false, width: 30 },
    { field: 'Partnumber', headerName: "Part Number", width: 150, filter: 'agTextColumnFilter' },
    { field: 'Descrizione', width: 200, filter: 'agTextColumnFilter' },
    // { field: 'NumUDC', headerName: "UDC", width: 150, filter: 'agTextColumnFilter' },
    { field: 'Qta', headerName: "Qtà Disponibile", type: 'numberColumn', width: 100, filter: 'agNumberColumnFilter' },
    // { field: 'QtaImpegnata', headerName: "Qtà Imp.", type: 'numberColumn', width: 100, filter: 'agNumberColumnFilter' },
    // { field: 'qtaEvasa', headerName: "Qtà Totale", type: 'numberColumn', width: 100, filter: 'agNumberColumnFilter' },
    { field: 'NumDoc', headerName: "N. Doc.", width: 150, filter: 'agTextColumnFilter' },
    { field: 'Kit', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },
  ];
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  loading : string = 'none';

  gridparams;

  GridReady(params: GridReadyEvent){
    this.gridApi = params.api;
    this.gridparams=params;
  }

  loadData() {
    this.loading = 'block';
    this.articoliService.ElencoArticolixCliente(this.data.IdCliente, false).subscribe((data) => {

      this.rowArticoli = data;
      // if (data.length > 0) {
      //   //se si aggiungono nuove colonne fisse da 10 passa a 11
      //   if (this.columnDefsArticoli.length < 10) {
      //     //this.columnDefsArticoli = this.columnDefsArticoli.splice(0,this.columnDefsArticoli.length -1)
      //     //this.columnDefsArticolinoUDC = this.columnDefsArticoli
      //     let keys = data.map((a) => Object.keys(a))[0].slice(15);
      //     //da [15] sono varianti
      //     //console.log(keys);
      //     let children = []

      //     for (var i = 0; i < keys.length; i++) {
      //       children.push({
      //         field: keys[i],
      //         width: 150,
      //         filter: 'agTextColumnFilter'
      //       })
      //     }
      //     this.columnDefsArticoli = [
      //       ...this.columnDefsArticoli.slice(0, -2),
      //       {
      //         headerName: 'Varianti',
      //         children
      //       },
      //       ...this.columnDefsArticoli.slice(-2)
      //     ];

      //     // this.columnDefsArticoli.push({
      //     //   headerName: 'Varianti',
      //     //   children
      //     // });
      //   }
      // }
      this.loading = 'none';
    });

  }

  onSave() {
    let ret;
    if (this.data.tipo == 2) {
      let selection = this.gridApi.getSelectedRows();
      ret = selection;
    }else{
      ret = this.magazzinoTableComponent.selectedLocation;
    }

    let result = [];
    for(var i = 0;i< ret.length;i++){
      result.push({
        InventarioMasterID: this.data.id,
        Articolo_ID: ret[i]?.articolo_id || 0,
        id_Locazione: ret[i]?.id_Locazione || 0,
        PartNumber: ret[i]?.Partnumber || '',
        Locazione: ''
      })
    }
  
    
    this.dialogRef.close(result)
  }

  
}
