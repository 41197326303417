import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Famiglia } from 'src/app/Models/Famiglia';

@Component({
  selector: 'app-dialog-famiglia',
  templateUrl: './dialog-famiglia.component.html',
  styleUrls: ['./dialog-famiglia.component.scss']
})
export class DialogFamigliaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogFamigliaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Famiglia,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }
}
