import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Cliente } from 'src/app/Models/Cliente';
import { Magazzino } from 'src/app/Models/Magazzino';
import { LocazioneMagazzino } from 'src/app/Models/LocazioneMagazzino';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatDialog } from '@angular/material/dialog';
import { Famiglia } from 'src/app/Models/Famiglia';
import { DialogFamigliaComponent } from '../dialog-famiglia/dialog-famiglia.component';
import { FamigliaService } from 'src/app/services/famiglia.service';
import { GruppoService } from 'src/app/services/gruppo.service';
import { Gruppo } from 'src/app/Models/Gruppo';




@Component({
  selector: 'app-famiglia',
  templateUrl: './famiglia.component.html',
  styleUrls: ['./famiglia.component.scss']
})
export class FamigliaComponent implements OnInit {

  loading: string = 'none';
  selected = new FormControl(0);
  public gridColumnApi!: ColumnApi;
  label = "Lista";
  public rowData$!: any[];

  constructor(private http: HttpClient, public dialog: MatDialog, private famigliaService: FamigliaService, private gruppoService: GruppoService) {
  }

  openFamigliaDialog(e : RowDoubleClickedEvent = undefined): void {
    let res = this.rowData$.find((value) => value.gridIndex == +e);
    const dialogRef = this.dialog.open(DialogFamigliaComponent, {
      width: '250px',
      data: {
        IdFamiglia: res?.idFamiglia || 0,
        IdGruppo: res?.idGruppo || 0,
        Descrizione: res?.descrizione || '',
        gridIndex: res?.gridIndex || 0,
        gruppi: this.listaGruppi
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let obj: Famiglia = {
          idGruppo: result.idGruppo,
          idFamiglia: result?.idFamiglia || 0,
          descrizione: result.descrizione,
          disabilitato: false,
          gridIndex : result.idGruppo,
          gruppi: []
        }
        this.famigliaService.InsFamiglia(obj).subscribe(() => this.refreshGrid());
      }
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.reloadData(0)

  }

  refreshGrid() {
    this.reloadData(0)
  }
  reloadData(id : number) {
    this.loading = 'block';
    // this.magazzinoService.GetLocazioni(id).subscribe((data) => {
    //   this.rowData$ = data
    //   this.loading = 'none';
    // });
    this.Elenco();
  }


  Elenco(){
    this.famigliaService.GetFamiglia(0).subscribe((data) => {
        this.rowData$ = data
        this.loading = 'none';
      });
  }

  listaGruppi: Gruppo[]= [];

  ElencoGruppi(){
    this.gruppoService.GetGruppo(0).subscribe((data) => {
        this.listaGruppi = data;
      });
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'IdFamiglia', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'descrizione', headerName: "Famiglia", width: 320, filter: 'agTextColumnFilter' },
  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };


  ngOnInit(): void {
    this.Elenco();
    this.ElencoGruppi();
  }

}
