<form [formGroup]="formingresso" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button> -->
            <button class="button-tab-delete" type="button" title="Elimina" *ngIf="!Accettato" (click)="onElimina()">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button>

        </mat-card-actions>
        <table>
            <tr>
                <td style="border-right: 1px solid #c0c0c0;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr><td style="text-align: center;" colspan="3"><p class="text-grigio">Dati Generali</p></td></tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Cliente</mat-label>
                                    <mat-select name="IdCliente" formControlName="idCliente"
                                        (valueChange)="onChangeCliente($event)">
                                        <mat-option *ngFor="let cliente of clienti" [value]="cliente.idCliente">
                                            {{cliente.ragione_Sociale}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Tipo Doc.</mat-label>
                                    <mat-select name="TipoDocID" formControlName="tipoDocID">
                                        <mat-option *ngFor="let tipodocumento of tipodoc"
                                            [value]="tipodocumento.idtipologiadocumento">
                                            {{tipodocumento.descrizione}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Causale Doc.</mat-label>
                                    <mat-select name="IdCausale" formControlName="idCausale">
                                        <mat-option *ngFor="let causale of causali" [value]="causale.idCausale">
                                            {{causale.descrizione}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Numero Doc.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="NumDoc" formControlName="numDoc">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Data Doc.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input type="date" matInput name="datadoc" formControlName="dataDoc">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Valore Doc.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput type="number" name="ValoreDoc" formControlName="valoreDoc">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Numero Rif</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="nrif" formControlName="nRif">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Data Rif.</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input type="text" matInput name="DataRif" formControlName="dataRif">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width ">
                                    <mat-label>Valuta</mat-label>
                                    <mat-select name="id_Valuta" formControlName="id_Valuta">
                                        <mat-option *ngFor="let valuta of liste.valuta" [value]="valuta.id">
                                            {{valuta.descrizione}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
            
                        </tr>
                        <tr>
                            <td>
                                <p>DDT Importato: <b>{{esportato}}</b></p>
                            </td>
                            <td>
                                <mat-checkbox type="checkbox" name="Bloccato" color="primary" formControlName="bloccato">Bloccato
                                </mat-checkbox>
                            </td>
                        </tr>
                    </table>
                </td>
                <td style="display: table;align-items: start;">
                    <table class="full-width class-test" cellspacing="0">
                        <tr><td style="text-align: center;" colspan="3"><p class="text-grigio">Intestatario</p></td></tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Intestatario</mat-label>
                                    <mat-select name="IdIntenstatario" formControlName="idIntenstatario"
                                        (valueChange)="onChangeIntestatario($event)">
                                        <mat-option *ngFor="let intestatario of intestatari" [value]="intestatario.idCliente">
                                            {{intestatario.ragione_Sociale}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Ragione Sociale</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="RagioneSociale" formControlName="ragioneSociale">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <mat-form-field class="full-width">
                                <mat-label>Indirizzo</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="Indirizzo" formControlName="indirizzo">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>CAP</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="cap" formControlName="cap">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Località</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <input matInput name="Localita" formControlName="localita">
                            </mat-form-field>
                        </td>
                    <td>
                        <mat-form-field class="full-width">
                            <mat-label>Provincia</mat-label>
                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                            <input matInput name="Provincia" formControlName="provincia">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                <td>
                    <mat-form-field class="full-width">
                        <mat-label>Nazione</mat-label>
                        <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                        <input matInput name="Nazione" formControlName="nazione">
                    </mat-form-field>
                </td>
            </tr>
                    </table>
                </td>
            </tr>
        </table>
        <div class="w3-container">
            <div class="w3-right">
                <button class="button-delete-row" *ngIf="Confermato && !Accettato" type="button" title="Accetta" (click)="onAccetta()">
                    Accetta
                </button>
            </div>
        </div>
        <mat-tab-group backgroundColor="primary" [selectedIndex]="selected.value"
            (selectedIndexChange)="selected.setValue($event)">
            <mat-tab label="Articoli">
                <div style="display:flex;flex-direction: row; justify-content: space-between;">
                    <div>
                        <button *ngIf="!Accettato" class="button-add-row" type="button" (click)="openArticoloSearchDialog()">Aggiungi
                            {{Confermato ? "Riscontro": 'Riga'}}</button>
                        <!-- <button class="button-delete-row" type="button">Elimina
                            Riga</button> -->
                        <!-- <button class="button-print" type="button" (click)="creaOrdine()">
                            Crea Ordine</button> -->
                    </div>
                    
                </div>
                
                <app-grid (gridReady)="RefreshArticoli($event)"
                            [rowData]="rowArticoli" [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef"
                            (onDblClick)="openArticoloDialog($event)"></app-grid>
            </mat-tab>
            <mat-tab label="UDC">
                <div style="display:flex;flex-direction: row; justify-content: space-between;">
                    
                    <div>
                        <button [disabled]="Confermato" class="button-add-row" type="button" (click)="onConfirm(0)">
                            Conferma senza UDC</button>
                        <button [disabled]="Confermato" class="button-confirm-row" type="button" (click)="onConfirm(1)">
                            Conferma con UDC</button>
                        <button class="button-print" type="button" (click)="printPDF()">
                            Stampa UDC</button>
                    </div>
                </div>
                
                <!-- (onDblClick)="openUDCDialog($event)" -->
                <app-grid (gridReady)="RefreshUDC($event)"
                            [rowData]="rowUDC" [columnDefs]="columnDefsUDC" [defaultColDef]="defaultColDef"
                            (onDblClick)="openUDCDialog($event)"></app-grid>
            </mat-tab>
            <mat-tab label="Info Spedizione">
                <table class="full-width" cellspacing="0">
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Colli</mat-label>
                                <input matInput type="number" formControlName="colli">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Peso (kg)</mat-label>
                                <input matInput type="number" formControlName="peso">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Volume (m^3)</mat-label>
                                <input matInput type="number" formControlName="volume">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Telefono</mat-label>
                                <input matInput formControlName="telefono">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Data Cons.</mat-label>
                                <input type="date" matInput [disabled]="true" formControlName="dataConsegna">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Contatto</mat-label>
                                <input matInput formControlName="contatto">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Vettore</mat-label>
                                <mat-select name="CodVettore" formControlName="codVettore">
                                    <mat-option *ngFor="let vettore of vettori" [value]="vettore.codVettore">
                                        {{vettore.ragione_Sociale}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Contratto Vettore</mat-label>
                                <mat-select formControlName="contrattoVettoreId">
                                    <!-- <mat-option *ngFor="let nazione of nazioni" [value]="nazione.sigla">
                                        {{nazione.descrizione}}
                                    </mat-option> -->
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Incasso C/Ass</mat-label>
                                <mat-select formControlName="pagamentoId">
                                    <mat-option *ngFor="let pagamento of liste.pagamenti" [value]="pagamento.id">
                                        {{pagamento.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Natura Merce</mat-label>
                                <input matInput formControlName="naturaMerce">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="full-width">
                                <mat-label>Rif. Vettore</mat-label>
                                <input matInput formControlName="rifVettore">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <mat-form-field class="full-width">
                                <mat-label>Note</mat-label>
                                <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                <textarea matInput formControlName="note" rows="5"></textarea>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>

            </mat-tab>
        </mat-tab-group>

    </mat-card>
</form>