
<div mat-dialog-content>
    <h1>Seleziona Articolo</h1>
    <app-grid (gridReady)="loadData()" [rowData]="rowArticoli"
            [columnDefs]="columnDefsArticoli" [defaultColDef]="defaultColDef" (onDblClick)="addArticolo($event)"></app-grid>

        <div style="display:flex;flex-direction: row; justify-content: space-around;" *ngIf="isProductSelected">
            <b style="line-height: 4;">{{product}}</b>
            <mat-form-field appearance="fill">
                <mat-label>Qta</mat-label>
                <input matInput [(ngModel)]="data.qta" type="number" required (input)="data.qta != 0 ? isQtyInsert = true: isQtyInsert = false">
            </mat-form-field>  
            
            <mat-form-field appearance="fill">
                <mat-label>Note</mat-label>
                <input matInput [(ngModel)]="data.note" (input)="data.qta != 0 ? isQtyInsert  = true: isQtyInsert = false">
            </mat-form-field>
        </div>
</div>
<div mat-dialog-actions>
    <button class="button-annulla" (click)="onNoClick()">Annulla</button>
    <button class="button-add-row" *ngIf="isQtyInsert" (click)="onSave()" cdkFocusInitial>Salva</button>
</div>