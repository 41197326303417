<ag-grid-angular [style]="style" class="ag-theme-alpine" (gridReady)="load($event)"  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
    [rowData]="rowData$" [rowSelection]="rowSelection" [animateRows]="true" 
    (cellClicked)="onCellClicked($event)" (rowDoubleClicked)="dblClick($event)" [columnTypes]="columnTypes"
    [enableRangeSelection]="true"
    [localeText]="localeText" [frameworkComponents]="frameworkComponents" [getRowStyle]="getRowStyle"
    [loadingCellRenderer]="loadingCellRenderer"
    [loadingCellRendererParams]="loadingCellRendererParams"
    (rowSelected)="onRowSelected($event)"
    [rowMultiSelectWithClick]="false"
    [suppressCellSelection]="true"
    [suppressRowClickSelection]="true"
    [statusBar]="statusBar"
    [groupSelectsFiltered]="groupSelectsFiltered"
    [masterDetail]="masterDetail"
    >
</ag-grid-angular>
<!-- [getContextMenuItems]="getContextMenuItems" -->