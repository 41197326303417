import { Directive, ViewContainerRef } from '@angular/core';
import { ProductEditComponent } from '../components/product-edit/product-edit.component';

@Directive({
  selector: '[appProductEditDirective]',
  //template: ProductEditComponent
})
export class ProductEditDirectiveDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
    viewContainerRef.createComponent(ProductEditComponent);
  }

}
