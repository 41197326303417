<div mat-dialog-content>
    <h2>{{data.label}}</h2>
    <!-- <mat-slide-toggle [(ngModel)]="data.abilitato">Abilita</mat-slide-toggle> -->
    <mat-form-field class="full-width">
        <mat-label>Livello</mat-label>
        <mat-select [(ngModel)]="data.livello">
            <mat-option *ngFor="let livello of data.livelli" [value]="livello.idLivello">
                {{livello.nome}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <b>{{descrizione}}</b>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
</div>
