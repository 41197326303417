<form [formGroup]="formcliente" (ngSubmit)="onSubmit()">
    <div class="spinner" [style]="'display:' + loading">
        <mat-spinner></mat-spinner>
    </div>
    <mat-card>
        <mat-card-actions align="start" *ngIf="!isEnte">
            <button class="button-tab-save" type="submit" title="Salva">
                <mat-icon style="font-size:20px">save</mat-icon>
            </button>
            <!-- <button class="button-tab-undo" type="button" (click)="onUndo()" title="Ripristina">
                <mat-icon style="font-size:20px">undo</mat-icon>
            </button> -->
            <button class="button-tab-delete" type="button" (click)="onElimina()" title="Elimina">
                <mat-icon style="font-size:20px">delete</mat-icon>
            </button>

        </mat-card-actions>
        <mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
            <mat-tab label="Generali">

                <table class="full-width">
                    <tr>
                        <td class="full-width"
                            style="display: table;align-items: start;border-right: 1px solid #c0c0c0;">
                            <table class="full-width">
                                <tr>
                                    <td style="text-align: center;">
                                        <p class="text-grigio">Generali</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">

                                            <mat-label>Ragione Sociale</mat-label>
                                            <!-- <input matInput [value]="data.Descrizione" #descrizione> -->
                                            <input matInput name="ragionesociale" formControlName="ragione_Sociale">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>P.IVA</mat-label>
                                            <input matInput name="piva" formControlName="piva">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Cod. Fiscale</mat-label>
                                            <input matInput name="cf" formControlName="cf">
                                        </mat-form-field>
                                    </td>

                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Sito</mat-label>
                                            <mat-select name="Sito" formControlName="idSito">
                                                <!-- <ng-template *ngFor="let sito of siti">
                                                    <mat-option *ngIf="sito.abilitato" [value]="sito.gridIndex">
                                                        {{sito.Nome}}
                                                    </mat-option>
                                                </ng-template> -->
                                                
                                                    <mat-option *ngFor="let sito of siti" [value]="sito.gridIndex">
                                                        {{sito.nome}}
                                                    </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr *ngIf="!isEnte">
                                    <td style="text-align: center;">
                                        <p class="text-grigio">Altro</p>
                                    </td>
                                </tr>
                                <tr *ngIf="!isEnte">
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Data Creazione</mat-label>
                                            <input matInput type="date" name="datacreazione"
                                                formControlName="data_Creazione">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr *ngIf="!isEnte">
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Lingua Documento</mat-label>
                                            <mat-select name="LinguaDoc" formControlName="linguaDOC">
                                                <mat-option *ngFor="let nazione of nazioni" [value]="nazione.sigla">
                                                    {{nazione.descrizione}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr *ngIf="!isEnte">
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Codice Vettore</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="codvettore" formControlName="codVettore">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr *ngIf="!isEnte">
                                    <td colspan="2">
                                        <mat-form-field class="full-width">
                                            <mat-label>Note</mat-label>
                                            <textarea matInput name="note" formControlName="note"></textarea>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td style="width: 50%;">
                            <table class="full-width" style="display: table;align-items: start;margin-left:20px ;">
                                <tr>
                                    <td style="text-align: center;">
                                        <p class="text-grigio">Recapiti</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Indirizzo Legale</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="indirizzolegale" formControlName="indirizzo_Legale">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Città Legale</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="cittalegale" formControlName="cittaL">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Provincia Legale</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="provincialegale" formControlName="provL">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Nazione</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="Nazione" formControlName="nazione">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>CAP Legale</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input type="number" matInput name="caplegale" formControlName="capl">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Telefono Legale</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="telefonolegale" formControlName="telefoniL">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Cellulare</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="cellulare" formControlName="cellulari">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Email Primaria</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="email1" formControlName="email1">
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field class="full-width">
                                            <mat-label>Email Secondaria</mat-label>
                                            <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                            <input matInput name="email2" formControlName="email2">
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td></td>
                    </tr>
                </table>

                <!-- <table class="full-width" cellspacing="0">
                    
                    
                    
                    
                </table> -->

            </mat-tab>
            <mat-tab label="Varianti" *ngIf="!isEnte">
                <mat-card>
                    <mat-card-actions>
                        <button mat-button type="button" color="primary" (click)="openVarianteDialog()">
                            Aggiungi Variante
                        </button>
                    </mat-card-actions>
                    <div class="card">
                        <app-grid (onDblClick)="openVarianteDialog($event)" [columnDefs]="columnDefsVarianti"
                            [defaultColDef]="defaultColDef" [rowData]="rowDataVarianti"
                            (gridReady)="RefreshVarianti($event)">
                        </app-grid>
                    </div>
                </mat-card>
            </mat-tab>
            <mat-tab label="Destinatari" *ngIf="!isEnte">
                <mat-card>
                    <mat-card-actions>
                        <button mat-button type="button" color="primary" (click)="openDestinazioneDialog()">
                            Aggiungi Destinatario
                        </button>
                    </mat-card-actions>
                    <div class="card">
                        <app-grid (onDblClick)="openDestinazioneDialog($event)" [columnDefs]="columnDefsDestinazioni"
                            [defaultColDef]="defaultColDef" [rowData]="rowDataDestinazioni"
                            (gridReady)="RefreshDestinazioni($event)">
                        </app-grid>
                    </div>
                </mat-card>
            </mat-tab>
            <mat-tab label="Fornitori" *ngIf="!isEnte">
                <mat-card>
                    <mat-card-actions>
                        <button mat-button type="button" color="primary" (click)="openFornitoreDialog()">
                            Aggiungi Fornitore
                        </button>
                    </mat-card-actions>
                    <div class="card">
                        <app-grid (onDblClick)="openFornitoreDialog($event)" [columnDefs]="columnDefsFornitori"
                            [defaultColDef]="defaultColDef" [rowData]="rowDataFornitori"
                            (gridReady)="RefreshFornitori($event)">
                        </app-grid>
                    </div>
                </mat-card>
            </mat-tab>
            <mat-tab label="Orari" *ngIf="!isEnte">
                <mat-card>
                    <table class="full-width">
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Orario</mat-label>
                                    <input matInput type="time" formControlName="orario">
                                </mat-form-field>
                            </td>
                        
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Giorni Consegna prima dell'orario</mat-label>
                                    <!-- <input matInput [value]="data.Descrizione" #descrizione> -->
                                    <input matInput type="number" name="ragionesociale" formControlName="gGprima">
                                </mat-form-field>
                            </td>
                        
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Giorni Consegna dopo l'orario</mat-label>
                                    <!-- <input matInput [value]="data.Descrizione" #descrizione> -->
                                    <input matInput type="number" name="ragionesociale" formControlName="gGdopo">
                                </mat-form-field>
                            </td>
                        
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Giorni Consegna Urgente</mat-label>
                                    <!-- <input matInput [value]="data.Descrizione" #descrizione> -->
                                    <input matInput type="number" name="ragionesociale" formControlName="ggUrgenza">
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</form>