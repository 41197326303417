import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cliente } from 'src/app/Models/Cliente';


@Component({
  selector: 'app-dialog-fornitori',
  templateUrl: './dialog-fornitori.component.html',
  styleUrls: ['./dialog-fornitori.component.scss']
})
export class DialogFornitoriComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogFornitoriComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Cliente,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.dialogRef.close(this.data.idCliente);
  }

  ngOnInit(): void {
  }

}
