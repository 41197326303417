import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { OrdineDettagli } from 'src/app/Models/OrdineDettagli';

@Component({
  selector: 'app-grid-add-button',
  templateUrl: './grid-add-button.component.html',
  styleUrls: ['./grid-add-button.component.scss']
})
export class GridAddButtonComponent {

  params;
  label: number;

  agInit(params): void {
    this.params = params;
    this.label = parseInt( this.getValueToDisplay(params).numDoc);
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
  getValueToDisplay(params: ICellRendererParams) {
    return params.data
  }

}
