import { HttpClient } from '@angular/common/http';
import { Component, OnInit, DoCheck, ViewChild, Input, EventEmitter, ContentChild, TemplateRef, ViewChildren, QueryList, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellClickedEvent, ColDef, GridReadyEvent, ColumnApi, ColGroupDef, RowDoubleClickedEvent,
} from 'ag-grid-community';

import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Utente } from 'src/app/Models/Utente';
import { MenuService } from 'src/app/services/menu.service';

export class Tab {
  id: number | null = 0;
  nome: string = "";
}
@Component({
  selector: 'app-utenti',
  templateUrl: './utenti.component.html',
  styleUrls: ['./utenti.component.scss']
})
export class UtentiComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: Utente[];
  label = "Lista"
  tabs: Tab[] = [];
  UtenteID: number = 0;

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.reloadData()

  }

  refreshGrid() {
    this.reloadData()
  }
  reloadData() {
    this.loading = 'block';
    // this.http
    //   .get<Ingresso[]>("https://testapiall1.azurewebsites.net/api/documenti/geti/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
    this.menuService.getUtenti().subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo Utente'
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.UtenteID = -1;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {

    if (!this.tabs.some((x) => x.id == +e)) {
      let obj = this.rowData$.find((value) => value.idUtente == +e);
      let row = {
        id: obj.idUtente,
        nome: obj.nome + ' ' + obj.cognome
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = obj.Articolo_Id || 0;
      // this.data.Descrizione = obj.Descrizione;
      this.UtenteID = obj.idUtente;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == +e) + 1);

  }

  closeTab(index: any) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'nome', headerName: "Nome", width: 200 , filter: 'agTextColumnFilter'},
    { field: 'cognome', headerName: "Cognome", width: 200 , filter: 'agTextColumnFilter'},
    { field: 'altriFlagUtente', headerName: "User", width: 200, filter: 'agTextColumnFilter' },
    { field: 'gruppo', headerName: "Gruppo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'azienda', headerName: "Azienda", width: 200, filter: 'agTextColumnFilter' },

  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;
  public getRowStyle;

  constructor(private http: HttpClient, private menuService: MenuService) { 
  }

  ngOnInit(): void {
  }

}
