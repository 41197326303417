<div mat-dialog-content>
    <div mat-dialog-content>

        <table class="full-width">
            <tr>
                <td class="full-width" style="display: table;align-items: start;border-right: 1px solid #c0c0c0;">
                    <table class="full-width">
                        <tr>
                            <td style="text-align: center;">
                                <p class="text-grigio">Generali</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">

                                    <mat-label>Ragione Sociale</mat-label>
                                    <!-- <input matInput [value]="data.Descrizione" #descrizione> -->
                                    <input matInput name="ragionesociale" [(ngModel)]="data.ragione_Sociale">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>P.IVA</mat-label>
                                    <input matInput name="piva" [(ngModel)]="data.piva">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Cod. Fiscale</mat-label>
                                    <input matInput name="cf" [(ngModel)]="data.cf">
                                </mat-form-field>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <mat-checkbox type="checkbox" color="primary"
                                [(ngModel)]="data.destinatario">Destinatario</mat-checkbox>
                            </td>
                            <td>
                                <mat-checkbox type="checkbox" color="primary"
                                [(ngModel)]="data.fornitore">Fornitore</mat-checkbox>
                            </td>

                        </tr>
                    </table>
                </td>
                <td style="width: 50%;">
                    <table class="full-width" style="display: table;align-items: start;margin-left:20px ;">
                        <tr>
                            <td style="text-align: center;">
                                <p class="text-grigio">Recapiti</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Indirizzo Legale</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="indirizzolegale" [(ngModel)]="data.indirizzo_Legale">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Città Legale</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="cittalegale" [(ngModel)]="data.cittaL">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Provincia Legale</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="provincialegale" [(ngModel)]="data.provL">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Nazione</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="Nazione" [(ngModel)]="data.nazione">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>CAP Legale</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input type="number" matInput name="caplegale" [(ngModel)]="data.capl">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Telefono Legale</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="telefonolegale" [(ngModel)]="data.telefoniL">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Cellulare</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="cellulare" [(ngModel)]="data.cellulari">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Email Primaria</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="email1" [(ngModel)]="data.email1">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="full-width">
                                    <mat-label>Email Secondaria</mat-label>
                                    <!-- <input type="number" matInput [value]="data.Tipo" #tipo> -->
                                    <input matInput name="email2" [(ngModel)]="data.email2">
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                </td>
                <td></td>
            </tr>
        </table>

        <!-- <table class="full-width" cellspacing="0">
            
            
            
            
        </table> -->


    </div>
    <div mat-dialog-actions>
        <button class="button-annulla" (click)="onNoClick()">Annulla</button>
        <button class="button-add-row" [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
        <button class="button-delete" (click)="onDelete()" *ngIf="data.idCliente > 0">Elimina</button>
    </div>
</div>