import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { Giacenza } from 'src/app/Models/Giacenza';
import { ArticoloService } from 'src/app/services/articolo.service';
import { DocumentiService } from 'src/app/services/documenti.service';

export interface DialogData{
  articoli: Giacenza[],
  IdCliente: number,
  Qta:number,
  hasUDC:boolean,
  isUDC: boolean,
  livelloID: number,
  fixedlavaggio: boolean,
  hasProducts: boolean
}

@Component({
  selector: 'app-dialog-ordine-cerca-articolo',
  templateUrl: './dialog-ordine-cerca-articolo.component.html',
  styleUrls: ['./dialog-ordine-cerca-articolo.component.scss']
})
export class DialogOrdineCercaArticoloComponent implements OnInit {

   constructor(
    public dialogRef: MatDialogRef<DialogOrdineCercaArticoloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private articoliService: ArticoloService,
    private documentiService: DocumentiService,
    public dialog: MatDialog,
    private jwtHelper : JwtHelperService
  ) { 

    
  }

  
  gridOptions: GridOptions;
  msg:string = '';
  articoloselezionato:string = '';
  maxqta:string = 'none';
  nota: string;
  isnotadisp: boolean;

  checkudc :boolean = this.data.isUDC;

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.loadData()
  }

  loading : string = 'none';

  rowArticoli: Giacenza[] = [];
  
  public columnDefsArticoli:(ColDef | ColGroupDef)[];
  public columnDefsArticolinoUDC: (ColDef | ColGroupDef)[] = [
    //{ field: 'gridIndex', headerName: "Cod. Articolo", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'Kit', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },
    { field: 'Lavaggio', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },
    { field: 'Partnumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'Descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'Qta', headerName: "Qtà Disponibile", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'QtaImpegnata', headerName: "Qtà Imp.", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
    { field: 'qtaEvasa', headerName: "Qtà Totale", type: 'numberColumn', width: 150, filter: 'agNumberColumnFilter' },
  ];

  public columnDefsArticoliUDC: (ColDef | ColGroupDef)[] = [
    {headerName:'', checkboxSelection: true},
    { field: 'Lavaggio', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },
    { field: 'Partnumber', headerName: "Part Number", width: 150, filter: 'agTextColumnFilter' },
    { field: 'Descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'NumUDC', headerName: "UDC", width: 150, filter: 'agTextColumnFilter' },
    { field: 'Qta', headerName: "Qtà Disponibile", type: 'numberColumn', width: 100, filter: 'agNumberColumnFilter' },
    // { field 'QtaImpegnata', headerName: "Qtà Imp.", type: 'numberColumn', width: 100, filter: 'agNumberColumnFilter' },
    // { field: 'qtaEvasa', headerName: "Qtà Totale", type: 'numberColumn', width: 100, filter: 'agNumberColumnFilter' },
    { field: 'Ndoc', headerName: "N. Doc.", width: 150, filter: 'agTextColumnFilter' },
    { field: 'Kit', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    } },
  ];




  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public gridColumnApi!: ColumnApi;

  qtadisabled: boolean = false;

  ret: any;
  multipleSelection : boolean = false;
  rowSelection: any[] = [];
  private gridApi!: GridApi<any>;

  isLavaggio = '0';

  onChange(e: any){
    this.rowSelection = this.gridApi.getSelectedRows();
    
    if(this.rowSelection.length > 0){
      this.multipleSelection = true;
    } else {
      this.multipleSelection = false;
    }
    this.ret = {articoli: this.rowSelection};

    if(this.rowSelection.length > 0){
      this.isLavaggio = this.rowSelection[0].Lavaggio ? '1' : '2'; // SI - NO

      if (!this.data.hasProducts) {
        this.columnDefsArticoli = [{
          headerName: '', checkboxSelection: (param) => {
            if (this.isLavaggio == '1') {
              if (param.data.Lavaggio)
                return true
              else
                return false
            } else if (this.isLavaggio == '2') {
              if (param.data.Lavaggio)
                return false
              else
                return true
            } else
              return true
          }
        }, ...this.columnDefsArticoli.slice(1)]
      }
          
    }else if(this.rowSelection.length == 0){
      this.columnDefsArticoli = [{ headerName: '', checkboxSelection: true} , ...this.columnDefsArticoli.slice(1)];
    }
  }

  loadData(udc : boolean = false) {
    this.loading = 'block';
    if(this.data.livelloID == 3){
      this.data.isUDC = true;
      this.checkudc = true;
    }
    this.qtadisabled = this.data.isUDC;
    this.articoliService.ElencoArticolixCliente(this.data.IdCliente, this.data.isUDC).subscribe((data) => {
      
      this.rowArticoli = data;
      if(this.data.isUDC){
        this.columnDefsArticoli = this.columnDefsArticoliUDC;
        this.data.Qta = 0;
      }else{
        this.multipleSelection=false;
        this.columnDefsArticoli = this.columnDefsArticolinoUDC;
      }
      if(data.length > 0){
        // for(var i = 0; i< data.length; i++){
        //   data[i].Lavaggio = i % 2 == 0 ? true : false;
        // }

        data.forEach((x) => x.Lavaggio === null || x.Lavaggio === undefined? x.Lavaggio = false : x.Lavaggio)
        
        //se si aggiungono nuove colonne fisse da 11 passa a 12
        if (this.columnDefsArticoli.length < 11 && (udc || this.data.isUDC)) {
          //this.columnDefsArticoli = this.columnDefsArticoli.splice(0,this.columnDefsArticoli.length -1)
          //this.columnDefsArticolinoUDC = this.columnDefsArticoli
          let keys = data.map((a) => Object.keys(a))[0].slice(15);
          //da [15] sono varianti
          //console.log(keys);
          let children = []

          for (var i = 0; i < keys.length; i++) {
            children.push({
              field: keys[i],
              width:150,
              filter: 'agTextColumnFilter'
            })
          }
          this.columnDefsArticoli = [
            ...this.columnDefsArticoli.slice(0, -2),
            {
              headerName: 'Varianti',
              children
            },
            ...this.columnDefsArticoli.slice(-2)
          ]; 

          //this.data.fixedlavaggio // true = si - false = no
          if (this.data.hasProducts) {
            this.columnDefsArticoli = [{
              headerName: '', checkboxSelection: (param) => {
                if (param.data.Lavaggio) {
                  if (this.data.fixedlavaggio)
                    return true
                  else
                    return false
                } else {
                  if (!this.data.fixedlavaggio)
                    return true
                  else
                    return false
                }
              }
            }, ...this.columnDefsArticoli.slice(1)]
          }

          // this.columnDefsArticoli.push({
          //   headerName: 'Varianti',
          //   children
          // });
        }
      }
        this.loading = 'none';
    });

  }

  onChangeUDC(){
    this.data.isUDC = this.checkudc

      if(!this.checkudc)
        this.isnotadisp= false;
      
      this.loadData(this.checkudc)
    }

  onChangeQta() {
    if (!this.isKit) {
      if (this.checkudc && this.data.Qta < parseInt(this.maxqta))
        this.isnotadisp = true;
      else
        this.isnotadisp = false;
    }
  }

  isKit : boolean= false;
  
  loadDataDettaglio(idarticolo: number = 0) {
    let articolo: any = this.rowArticoli.find((e) => e.gridIndex == idarticolo);
    if (this.data.hasProducts) {
      if (articolo.Lavaggio === this.data.fixedlavaggio) {
        this.isnotadisp = false;
        this.ret = { articoli: [articolo] }

        this.isKit = +articolo.Kit == 0 ? true : false;

        this.articoloselezionato = 'Part Number: ' + articolo.Partnumber + ' Articolo: ' + articolo.Descrizione
        if (this.checkudc) {
          let diff = (articolo.Qta - articolo.QtaImpegnata);
          if (diff > 0) {
            this.data.Qta = diff;
            this.maxqta = (articolo.Qta - articolo.QtaImpegnata).toString();
          } else {
            this.data.Qta = 0;
            this.maxqta = 'none';
          }
        }
      }


    } else {
      this.isnotadisp = false;
      this.ret = { articoli: [articolo] }

      this.isKit = +articolo.Kit == 0 ? true : false;

      this.articoloselezionato = 'Part Number: ' + articolo.Partnumber + ' Articolo: ' + articolo.Descrizione
      if (this.checkudc) {
        let diff = (articolo.Qta - articolo.QtaImpegnata);
        if (diff > 0) {
          this.data.Qta = diff;
          this.maxqta = (articolo.Qta - articolo.QtaImpegnata).toString();
        } else {
          this.data.Qta = 0;
          this.maxqta = 'none';
        }
      }
    }
    //console.log(this.ret)
//    this.dialogRef.close(idarticolo)
  }

  gridparams;

  GridReady(params: GridReadyEvent){
    this.gridApi = params.api;
    this.gridparams=params;
    
  }

  onSave() {
    if (!this.multipleSelection) {
      if (this.data.Qta > 0) {
        if (this.data.Qta <= this.ret.articoli[0].Qta || !this.isKit) {
          this.ret.articoli[0].qta = this.data.Qta;
          this.ret.articoli[0].utente = this.jwtHelper.decodeToken(localStorage.getItem('jwt')).name;
          this.ret.articoli[0].stato = '';
          this.ret.articoli[0].rifdoc = '';
          this.ret.articoli[0].disabilitato = false;
          this.ret.articoli[0].locazionedst = '';
          this.ret.articoli[0].gridIndex = 0;
          this.ret.articoli[0].Note = this.nota || '';
          this.ret.lavaggio = this.ret.articoli[0].Lavaggio
          if (!this.data.isUDC) {
            this.ret.articoli[0].NumUDC = '';
          }
          //console.log(this.ret)
          this.dialogRef.close(this.ret)
        } else {
          this.msg = 'La Quantità inserita è maggiore di quella disponibile (' + this.ret.articoli[0].Qta + ')';
          setTimeout(() => {
            this.msg = ''
          }, 2000);
        }
      } else {
        this.msg = 'Inserisci una Quantità prima di continuare';
        setTimeout(() => {
          this.msg = ''
        }, 2000);
      }
    } else {
      if (!(this.rowSelection.every((a) => a.Lavaggio == true) || this.rowSelection.every((a) => a.Lavaggio == false))) {
      //if (!this.data.lavaggio && !(this.rowSelection.every((a) => a.Lavaggio == true) || this.rowSelection.every((a) => a.Lavaggio == false))) {
        this.msg = 'Selezionare solo Part Number in Lavaggio o viceversa';
        setTimeout(() => {
          this.msg = ''
        }, 4000);
      } else {
        let articoliret = [];

        for (var i = 0; i < this.ret.articoli.length; i++) {
          articoliret.push(this.ret.articoli[i])
          articoliret[i].utente = this.jwtHelper.decodeToken(localStorage.getItem('jwt')).name;
          articoliret[i].stato = '';
          articoliret[i].rifdoc = '';
          articoliret[i].disabilitato = false;
          articoliret[i].locazionedst = '';
          articoliret[i].gridIndex = 0;
          articoliret[i].Note = this.nota || '';
        }
        this.dialogRef.close({ articoli: articoliret, lavaggio: this.rowSelection.some(oggetto => oggetto.Lavaggio === true)})
      }
    }
  }

}
