import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Prelievo } from 'src/app/Models/Prelievo';
import { DocumentiService } from 'src/app/services/documenti.service';
import { GridUrgenzaIconComponent } from '../../grid-urgenza-icon/grid-urgenza-icon.component';
import { GridWarnIconComponent } from '../../grid-warn-icon/grid-warn-icon.component';
import { GridLavaggioIconComponent } from '../../grid-lavaggio-icon/grid-lavaggio-icon.component';

export class Tab {
  id: number | null = 0;
  nome: string = "";
  numero: string ="";
}
@Component({
  selector: 'app-missioni',
  templateUrl: './missioni.component.html',
  styleUrls: ['./missioni.component.scss']
})
export class MissioniComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: Prelievo[];
  label = "Lista"
  tabs: Tab[] = [];
  IdPrelievo: number = 0;

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.reloadData()

  }

  refreshGrid() {
    this.reloadData()
  }
  reloadData() {
    this.loading = 'block';
  
    this.documentiService.GetPrelievi(0, 14).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });

  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuova Missione',
      numero: ''
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.IdPrelievo = -1;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    if (!this.tabs.some((x) => x.id == e.data.idPrelievo)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.idPrelievo,
        nome: e.data.ragioneSociale,
        numero: e.data.numeroOrdine
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.IdPrelievo = e.data.idPrelievo;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.idPrelievo) + 1);

  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'urgenza', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    }, filter: false, cellRenderer: GridUrgenzaIconComponent },
    { field: 'lavaggio', valueFormatter: (param)=>{
      return param.value ? 'Si' : 'No'
    }, filter: true, cellRenderer: GridLavaggioIconComponent },
    { field: "note", headerName : "Note",width:100, filter: false, cellRenderer: GridWarnIconComponent,},
    { field: 'numeroOrdine', headerName: "Numero Ordine", width: 150, filter: 'agTextColumnFilter'},
    { field: 'data', headerName: "Data Inserimento", width: 150, type: 'dateColumn' },
    { field: 'dataConsegna', headerName: "Data Consegna", width: 150, type: 'dateColumn' },
    { field: 'ragioneSociale', headerName: "Cliente", width: 200, filter: 'agTextColumnFilter' },
    { field: 'intestatario', headerName: "Destinatario", width: 200, filter: 'agTextColumnFilter' },
    // { field: 'Stato', headerName: "Stato", width: 320 },

  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };


  public gridColumnApi!: ColumnApi;
  public getRowStyle;
  constructor(private http: HttpClient, private documentiService: DocumentiService) {
    this.getRowStyle = (params) => {
      switch(params.data.idstato){
        case '1':
          return { background: '#cbcbcb' };
        case '2':
          return { background: '#4caf50' };
        default:
          return false
      }
  };
   }

  ngOnInit(): void {
  }


}
