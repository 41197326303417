import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowClickedEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { Accettazione } from 'src/app/Models/Accettazione';
import { UDC } from 'src/app/Models/UDC';
import { DocumentiService } from 'src/app/services/documenti.service';
import { Tab } from '../ingresso/ingresso.component';

@Component({
  selector: 'app-udc-abbinamento-edit',
  templateUrl: './udc-abbinamento-edit.component.html',
  styleUrls: ['./udc-abbinamento-edit.component.scss']
})
export class UdcAbbinamentoEditComponent implements OnInit {
  form: FormGroup = new FormGroup({
    numUDC: new FormControl(''),
    partNumber: new FormControl(''),
    qtaMiss: new FormControl(''),
    forza: new FormControl(false),
  })

  @Input() id: number = 0;

  @Input() tab: Tab = { id: 0, nome: "", numero:'' };

  public loading: string = 'none';
  constructor(private _snackBar: MatSnackBar,
  private documentiService: DocumentiService,) { }
  selected = new FormControl(0);
  canForza=false;

  ngOnInit(): void {
  }

  // getDocumento(id: number) {
  //   this.documentiService.GetUDC(id).subscribe((res) => {
  //     this.form = new FormGroup({
  //       idUDC: new FormControl(res[0].idUDC),
  //       NomeUDC: new FormControl(res[0].NomeUDC, Validators.required),
  //       data: new FormControl(res[0].data, Validators.required),
  //     })
  //   })

  // }

  onUndo() {
    //   if (this.id > 0) {
    //     this.getCliente(this.id);
    //   } else {
    //     this.getCliente(-1);
    //   }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  onSubmit() {
      if (!this.form.valid) {
        this.openSnackBar('Compila i campi obbligatori prima di continuare');
      } else {
        this.loading = 'block';
        let req: Accettazione = this.form.value;
        req.utente= '';
        req.idSito = 14; //PER ORA CABLATO MA IN FUTURO VA MESSO ID UTENTE
        this.documentiService.InsAccettazione(req).subscribe((data) => {
          this.loading = 'none';
          switch (data[0].err) {
            case '1':
              this.openSnackBar(data[0].msg);
              break;
            case '2':
              this.openSnackBar(data[0].msg);
              this.canForza = true;
              break;
            case '0':
              this.openSnackBar('Salvataggio avvenuto con successo');
              break;
          }
         
          console.log(data);
        });
        //this.openSnackBar(req.idUDC == 0 ? 'Documento salvato' : 'Documento modificato');
      }
    }

}
