import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Magazzino } from 'src/app/Models/Magazzino';
import { MagazzinoService } from 'src/app/services/magazzino.service';
import { DialogNewMagazzinoComponent } from '../dialog-new-magazzino/dialog-new-magazzino.component';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { DialogNewRigaMagazzinoComponent } from '../dialog-new-riga-magazzino/dialog-new-riga-magazzino.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


interface FoodNode {
  name: string;
  magazzini?: FoodNode[];
}

/*
[
  {
    name: 'Magazzino 1',
    children: [{name: 'Scaffale 1'}, {name: 'Scaffale 2'}, {name: 'Scaffale 3'}],
  },
  {
    name: 'Magazzino 2',
    children: [
      {
        name: 'Scaffale 1',
        children: [{name: 'Cella 1'}, {name: 'Cella 2'}],
      },
      {
        name: 'Scaffale 2',
        children: [{name: 'Cella 1'}, {name: 'Cella 2'}],
      },
    ],
  },
]; */

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id:number;
}

@Component({
  selector: 'app-magazzini-table',
  templateUrl: './magazzini-table.component.html',
  styleUrls: ['./magazzini-table.component.scss']
})
export class MagazziniTableComponent implements OnInit {

  constructor(private magazzinoService : MagazzinoService,public dialog: MatDialog,private _snackBar: MatSnackBar) { }

  loading: string = 'none';
  public rowData$!: any[];

  @Input() pageMagazzini: boolean;
  @Input() selectedLocation: any;
  // @Output() : EventEmitter<any> = new EventEmitter();
  // @ViewChild(MagazziniTableComponent) magazzinoComponent: MagazziniTableComponent;


  // onSalvaClick() {
  //   this.selectedLocation.emit(this.gridApi.getSelectedRows());
  // }

  private _transformer = (node: Magazzino, level: number) => {
    return {
      expandable: !!node.magazzini && node.magazzini.length > 0,
      name: node.descrizione,
      level: level,
      id: node.id_Magazzino
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.magazzini,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  openMagazzinoDialog(magazzinoID: number = 0): void {
    
    const dialogRef = this.dialog.open(DialogNewMagazzinoComponent, {
      width: '250px',
      data: {
        
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  openRigaMagazzinoDialog(e : RowDoubleClickedEvent = undefined): void {
    
    const dialogRef = this.dialog.open(DialogNewRigaMagazzinoComponent, {
      width: '300px',
      //height: '500px',
      data: {
        
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  public gridColumnApi!: ColumnApi;
  private gridApi!: GridApi<any>;

  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.reloadData(0)

  }

  refreshGrid() {
    this.reloadData(0)
  }
  reloadData(id : number) {
    this.loading = 'block';
    this.magazzinoService.GetLocazioni(id).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
    });
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'id_Locazione', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'reparto', headerName: "Reparto", width: 100, filter: 'agTextColumnFilter' },
    { field: 'settore', headerName: "Settore", width: 100, filter: 'agTextColumnFilter' },
    { field: 'scaffale', headerName: "Scaffale", width: 100, filter: 'agTextColumnFilter' },
    { field: 'montante', width: 100, filter: 'agTextColumnFilter' },
    { field: 'ripiano', width: 100, filter: 'agTextColumnFilter' },
  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
        return new Date(params.value).toISOString().replace(/T.*/, '').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  listMagazzini: Magazzino[] = [];

  ElencoMagazzini() {
    this.magazzinoService.GetMagazzino(0).subscribe((data) => this.dataSource.data = data);
  }

  ngOnInit(): void {
    this.ElencoMagazzini();
    //this.selectedLocation = 'asdasdasdasdasdasd';
  }

  onSelected(){
    this.selectedLocation = this.gridApi.getSelectedRows()
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      duration: 2000
    });
  }

  stampaLocazioni() {
    let selection = this.gridApi.getSelectedRows();
    if(selection.length > 0){
      selection = selection.map((x) => x['id_Locazione']);
      let j = selection.join();
      //console.log(j)
      this.magazzinoService.StampaMagazzini(j).subscribe((data)=>{
        if(data[0].err == '0' ){
          this.printPDF(data[0].msg);
        }else{
          this.openSnackBar(data[0].msg);
        }

      })
      
    }else{
      this.openSnackBar('Selezionare almeno una locazione prima di continuare');
    }
  }

  printPDF(pdf : any){
    var fileblob = this.b64toBlob(pdf, 'application/pdf');
    var url = window.URL.createObjectURL(fileblob); 

    window.open(url);

  }

  b64toBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

}
