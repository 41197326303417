import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  @Input() message: string;
  @Input() action: string;

  @Output()
  open = new EventEmitter<any>();

  constructor(private _snackBar: MatSnackBar) { }
  ngOnInit(): void {
  }

  openSnackBar() {
    this._snackBar.open('messaggio', 'azione');
  }

}
