<div mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Nome Variante</mat-label>
        <input matInput [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Valore</mat-label>
        <input matInput type="number" [(ngModel)]="data.valore">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Note</mat-label>
        <textarea matInput type="number" [(ngModel)]="data.note"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Annulla</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Salva</button>
</div>