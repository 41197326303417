import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-utente-sito',
  templateUrl: './dialog-utente-sito.component.html',
  styleUrls: ['./dialog-utente-sito.component.scss']
})
export class DialogUtenteSitoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogUtenteSitoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  isAbilitato: boolean = this.data.isAbilitato;



  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
  }

}
