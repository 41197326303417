import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { Menu } from '../Models/Menu';
import { CategoriaABC } from '../Models/CategoriaABC';
import { RepartoMenu } from '../Models/RepartoMenu';
import { Utente } from '../Models/Utente';
import { Sito } from '../Models/Sito';
import { ImpostazioniUtente } from '../Models/ImpostazioniUtente';
import { Cliente } from '../Models/Cliente';
import { environment } from 'src/environments/environment';

const httpOptionsssss = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': environment.apiUrl,
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  })
};


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private apiUrl = 'http://localhost:3000'; //METTI L'URL QUI DELLA TUA CHIAMATA GET
  private apiUrl2 = environment.apiUrl; //METTI L'URL QUI DELLA TUA CHIAMATA GET
  private apiUrlx = "https://testapiall1.azurewebsites.net/api/articoli/Articoli_Liste/";
  constructor(private http: HttpClient) { 
//    httpOptions.headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
  }

  GetEnte(id: number): Observable<Cliente> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl2 + `${"/enti/get/?id=" + id}`;
    return this.http.get<Cliente>(url, httpOptions);
  }

  getMenu(): Observable<Menu[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Menu[]>(this.apiUrl2 + "/menu/getmenu?id=1", httpOptions);
  }

  getMenuReparti(id : number = 1): Observable<RepartoMenu[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<RepartoMenu[]>(this.apiUrl2 + "/utenti/GetMenuxReparti/?id=" + id, httpOptions);
  }

  getUtenti(id : number = 0): Observable<Utente[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Utente[]>(this.apiUrl2 + "/utenti/GetUtenti/?id=" + id, httpOptions);
  }

  GetSitiUtente(id : number = 0): Observable<Sito[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Sito[]>(this.apiUrl2 + "/utenti/Gettbsitiutente/?id=" + id, httpOptions);
  }

  GetSito(id : number = 0): Observable<Sito[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Sito[]>(this.apiUrl2 + "/utenti/GetSito", httpOptions);
  }

  GetUtentexCliente(id : number = 0): Observable<Utente[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Utente[]>(this.apiUrl2 + "/utenti/GetUtentexCliente?id=" + id, httpOptions);
  }

  InsSito(sito: Sito): Observable<string> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl2 + "/utenti/UpdIns_Sito";
    return this.http.put<string>(url, sito, httpOptions);
  }

  changePassword(utente: ImpostazioniUtente): Observable<string> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl2 + "/utenti/Change_Password";
    return this.http.put<string>(url, utente, httpOptions);
  }

  InsUtente(utente: Utente): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl2 + "/Users/Register ";
    return this.http.post<any>(url, utente, httpOptions);
  }

  UpdUtente(utente: Utente): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = this.apiUrl2 + "/utenti/Updins_Utenti";
    return this.http.put<any>(url, utente, httpOptions);
  }


  toggleSitodaUtente(idsito: number, idutente:number, def: boolean) : Observable<string>{
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = `${this.apiUrl2 + "/utenti/UpdIns_Sito?id=" + idutente + "&id2=" + idsito + "&id3=" + def }`;
    return this.http.put<string>(url, httpOptions);    
  }

  toggleUtentexCliente(idutente: number, IdCliente:number) : Observable<any>{
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = `${this.apiUrl2 + "/utenti/UpdIns_UtentexCliente?id=" + idutente + "&id2=" + IdCliente }`;
    return this.http.put<any>(url, httpOptions);    
  }

  updateGruppiMenu(menu: RepartoMenu): Observable<string> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = `${this.apiUrl2 + "/utenti/UpdateGruppiMenu"}`;
    return this.http.put<string>(url, menu, httpOptions);
  }

  getPreferiti(): Observable<Menu[]> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get<Menu[]>(this.apiUrl2 + "/menu/getfavoriti?id=1", httpOptions);
  }

  deleteMenu(menu: Menu): Observable<Menu> {
    const url = `${this.apiUrl2 + "/menu"}/${menu.id}`;
    return this.http.delete<Menu>(url);
  }

  updateMenuReminder(menu: Menu): Observable<Menu> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = `${this.apiUrl2 + "/menu"}/${menu.id}`;
    return this.http.put<Menu>(url, menu, httpOptions);
  }

  addMenu(menu: Menu): Observable<Menu> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.post<Menu>(this.apiUrl + "/menu", menu, httpOptions);
  }

  togglePreferiti(menu: Menu): Observable<Menu> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = `${this.apiUrl2 + "/menu/updateFavoriti/?id=1&IdMenu="}${menu.id}`;
    return this.http.post<Menu>(url, menu, httpOptions);
  }

  GetDescrizioneVoceMenu(id: number): Observable<any> {
    const token = localStorage.getItem('jwt');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': environment.apiUrl,
        'Authorization': 'Bearer ' + token
      })
    };
    const url = `${this.apiUrl2 + "/menu/GetDescrizioneVoceMenu?idmenu=" + id}`;
    return this.http.get<Menu>(url, httpOptions);
  }

}
