import { Component, Directive, OnInit, ViewContainerRef } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Router } from '@angular/router';
import { LicenseManager } from 'ag-grid-enterprise'
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthServiceService } from './services/auth-service.service';
import { User } from './Models/user.model';

LicenseManager.setLicenseKey("CompanyName=AllinONE SRL,LicensedApplication=Allinone,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-035429,SupportServicesEnd=1_December_2023_[v2]_MTcwMTM4ODgwMDAwMA==fe560556d173d19757c8bc74206f9b1f")


export interface ExampleTab {
  label: string;
  template: string;
}

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  asyncTabs: Observable<ExampleTab[]>;
  title = 'sidenav';

  isSideNavCollapsed = false;
  screenWidth = 0;

  onToggleSideNav(data: SideNavToggle): void {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }


  

  constructor(public viewContainerRef: ViewContainerRef,
    private router: Router, private jwtHelper: JwtHelperService, private authService: AuthServiceService) {
    this.asyncTabs = new Observable((observer: Observer<ExampleTab[]>) => {
      //setTimeout(() => {
      observer.next([
        {
          label: 'First',
          template: `
              <app-tab-item></app-tab-item>
            ` },
        { label: 'Second', template: 'routerlink 2' },
        { label: 'Third', template: 'Content 3' },
      ]);
      //  }, 1000);
    });
  }
  ngOnInit(): void {
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "\o/";
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
    });

    if(localStorage.getItem('jwt')){
      const r = JSON.parse(localStorage.getItem('r'));
      const token =localStorage.getItem('jwt')
      // const decodedToken = this.jwtHelper.decodeToken(token);
      //this.authService.createUser(decodedToken.Idsito,decodedToken.Role, decodedToken.userid, decodedToken.name, decodedToken._exp, token);
      this.authService.createUserAlt(r.id,r.roleName,r.idutente,r.firstName,r.lastName,r.username,r.expiresIn,token)
    }
    
  }

}
