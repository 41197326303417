<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
</div>
<mat-tab-group [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
    <mat-tab [label]=label>
        <div class="card">
            <mat-card-actions align="start">
                <button class="button-tab-save" (click)="openGruppoDialog()" title="Aggiungi">
                    <mat-icon style="font-size:20px">add</mat-icon>
                </button>
                <button class="button-tab-undo" (click)="refreshGrid()" title="Ricarica">
                    <mat-icon style="font-size:20px">refresh</mat-icon>
                </button>
            </mat-card-actions>
                    <ag-grid-angular style="width: 100%; height: 80vh" class="ag-theme-alpine"
                        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData$"
                        [rowSelection]="'multiple'" [animateRows]="true" (gridReady)="onGridReady($event)"
                        [columnTypes]="columnTypes" (rowDoubleClicked)="
                        openGruppoDialog($event)">
                    </ag-grid-angular>

                    <!-- <app-grid (onDblClick)="openGruppoDialog($event)" [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowData]="rowData$" (gridReady)="onGridReady($event)"
            ></app-grid> -->
        </div>
    </mat-tab>
</mat-tab-group>