import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { UDC } from 'src/app/Models/UDC';
import { DocumentiService } from 'src/app/services/documenti.service';

export class Tab {
  id: number | null = 0;
  nome: string = "";
}

@Component({
  selector: 'app-udc-abbinamento',
  templateUrl: './udc-abbinamento.component.html',
  styleUrls: ['./udc-abbinamento.component.scss']
})
export class UdcAbbinamentoComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: UDC[];
  label = "Lista"
  tabs: Tab[] = [];
  idUDC: number = 0;

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;

    this.reloadData()

  }

  refreshGrid() {
    this.reloadData()
  }

  reloadData() {
    this.loading = 'block';
    this.documentiService.GetUDCAccettazione(14).subscribe((data) => {
      this.rowData$ = data;
      this.loading = 'none';
    });

  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo Abbinamento'
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.idUDC = 0;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    if (!this.tabs.some((x) => x.id == e.data.numDoc)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.numDoc,
        nome: e.data.partNumber
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.idUDC = e.data.numDoc;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.numDoc) + 1);

  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }
//"Qta":5.00000,"QtaMiss":2.00000,"QtaDiff":0.00000,"utente":"","Controllo":""}
  public columnDefs: (ColDef | ColGroupDef)[] = [
    { field: 'numUDC', headerName: "Numero UDC", width: 200, checkboxSelection: true , filter: 'agTextColumnFilter' },
    { field: 'numDoc', headerName: "Numero Documento", width: 200, type: 'numberColumn', filter: 'agTextColumnFilter'},
    { field: 'ragioneSociale', headerName: "Ragione Sociale", width: 200, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 320, filter: 'agTextColumnFilter' },
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta', headerName: "Qta", width: 200, type:'numberColumn', filter: 'agNumberColumnFilter' },

  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      //valueFormatter: (params) =>{return ''},
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true,
    
  };

  public gridColumnApi!: ColumnApi;

  constructor(private http: HttpClient, private documentiService: DocumentiService) {
    
   }

  ngOnInit(): void {
  }

}
