import { ILoadingCellRendererAngularComp } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ILoadingCellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-loading-cell',
  templateUrl: './grid-loading-cell.component.html',
  styleUrls: ['./grid-loading-cell.component.scss']
})
export class GridLoadingCellComponent implements ILoadingCellRendererAngularComp {
    public params!: ILoadingCellRendererParams & { loadingMessage: string };
  

    agInit(
      params: ILoadingCellRendererParams & { loadingMessage: string }
    ): void {
      this.params = params;
    }

}
