import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { ColDef, ColGroupDef, ColumnApi, GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community';
import { UDC } from 'src/app/Models/UDC';
import { DocumentiService } from 'src/app/services/documenti.service';
import { GruppoService } from 'src/app/services/gruppo.service';

export class Tab {
  id: number | null = 0;
  nome: string = "";
}

@Component({
  selector: 'app-pn',
  templateUrl: './pn.component.html',
  styleUrls: ['./pn.component.scss']
})
export class PnComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: UDC[];
  label = "Lista"
  tabs: Tab[] = [];
  Articolo_Id: number = 0;
  ndoc: string='';

  livelloID:number=-1;  

  idtipo:number=1;
  onChangeTipo(e : number){
    this.idtipo=e;
    this.reloadData(this.idtipo)
  }

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.reloadData(this.idtipo)

  }

  refreshGrid() {
    this.reloadData(this.idtipo)
  }
  reloadData(id : number = 1) {
    this.loading = 'block';
    // this.http
    //   .get<Ingresso[]>("https://testapiall1.azurewebsites.net/api/documenti/geti/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
    this.documentiService.GetPN(id, 0, 14).subscribe((data) => {
      this.rowData$ = data;
      this.loading = 'none';
    });

  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo PN'
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.Articolo_Id = 0;
    this.ndoc='-1';
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    // if(this.livelloID ==1){
    if (!this.tabs.some((x) => x.id == e.data.articolo_Id)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.articolo_Id,
        nome: e.data.partNumber
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.Articolo_Id = e.data.articolo_Id;
      this.ndoc= e.data.NumDoc;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.articolo_Id) + 1);
  // }
  }

  onPrint(){
    window.open();
  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'idUDC', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true },
    { field: 'ragioneSociale', headerName: "Ragione Sociale", width: 200, checkboxSelection: true, filter: 'agTextColumnFilter' },
    { field: 'partNumber', headerName: "Part Number", width: 200, filter: 'agTextColumnFilter' },
    { field: 'descrizione', width: 200, filter: 'agTextColumnFilter' },
    { field: 'qta',headerName: "Qta TOT", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'qtaDisponibile',headerName: "Qta Disponibile", type:'numberColumn', filter: 'agNumberColumnFilter'},
    { field: 'qtaImpegnata',headerName: "Qta Impegnata", type:'numberColumn', filter: 'agNumberColumnFilter'},

  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        // return new Date().toLocaleDateString('it-IT')
       return new Date(params.value).toISOString().replace(/T.*/,'').split('-').reverse().join('/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      //valueFormatter: (params) =>{return ''},
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    // enable floating filters by default
    floatingFilter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
  };

  public gridColumnApi!: ColumnApi;
  public getRowStyle;
  constructor(private http: HttpClient, private documentiService: DocumentiService, private gruppoService: GruppoService, private router: Router ) {
    this.getRowStyle = (params) => {
      if (params.data.Qta>0) {
          return { background: '#4caf50' };
      }else
      return false
  };
   }

  ngOnInit(): void {
    this.gruppoService.Sel_IdLivello(this.router.url.substring(6)).subscribe((data)=>{
      this.livelloID=data;
    })
  }

}
