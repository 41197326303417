<form #loginForm="ngForm" (ngSubmit)="reset(loginForm)">
    <div class="main">
        <div class="login">
        <div style="text-align: center;">
            
            <img src="../../../assets/images/stikstok.png" width="200" />
        </div>
        <div class="login-form">
            <h1>Reset Password</h1>
            <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input name="Password" type="password" [(ngModel)]="credentials.password" matInput required>
            </mat-form-field>   
            <mat-form-field appearance="fill">
                <mat-label>Conferma Password</mat-label>
                <input matInput type="password" name="Passward" [(ngModel)]="credentials.confirmPassword" required>
            </mat-form-field>
            <!-- <div *ngIf="invalidLogin">Username o Password Errata</div> -->
            <button type="submin" *ngIf="hidebtn">Conferma</button>
        </div>
        <div style="text-align: right;">
            <img src="../../../assets/images/logo.png" class="logo"/>   
        </div>
    </div>
    </div>
    </form>