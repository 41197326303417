import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ColDef, ColGroupDef, ColumnApi, GridApi, GridReadyEvent, RowDoubleClickedEvent, StatusPanelDef } from 'ag-grid-community';

import { Ingresso } from 'src/app/Models/Ingresso';
import { DocumentiService } from 'src/app/services/documenti.service';

export class Tab {
  id: number | null = 0;
  nome: string = "";
  numero: string ="";
}

@Component({
  selector: 'app-ingresso',
  templateUrl: './ingresso.component.html',
  styleUrls: ['./ingresso.component.scss']
})
export class IngressoComponent implements OnInit {

  @ViewChild(MatTabGroup, { read: MatTabGroup })
  public tabGroup: MatTabGroup | undefined;
  @ViewChildren(MatTab, { read: MatTab })
  public tabNodes: QueryList<MatTab> | undefined;
  public closedTabs: Array<any> = [];
  loading: string = 'none';
  selected = new FormControl(0);
  public rowData$!: Ingresso[];
  label = "Lista"
  tabs: Tab[] = [];
  DocumentoID: number = 0;

  private gridApi!: GridApi;

  changeTab(tab: MatTabChangeEvent) {
    if (tab.index === 0) {
      this.reloadData()
    }
  }
  onGridReady(params: GridReadyEvent) {
    this.gridColumnApi = params.columnApi;
    this.gridApi = params.api;
    this.reloadData()

  }

  refreshGrid() {
    this.reloadData()
  }
  reloadData() {
    this.loading = 'block';
    // this.http
    //   .get<Ingresso[]>("https://testapiall1.azurewebsites.net/api/documenti/geti/?id=0")
    //   .subscribe((data) => {
    //     this.rowData$ = data
    //     this.loading = 'none';
    //   });
    this.documentiService.GetDocumento(14, 0).subscribe((data) => {
      this.rowData$ = data
      this.loading = 'none';
      
    });

  }

  newTab() {
    let row = {
      id: 0,
      nome: 'Nuovo Ingresso',
      numero: ''
    }
    this.tabs.push(row);
    // this.data.Articolo_Id = e.data.Articolo_Id || 0;
    // this.data.Descrizione = e.data.Descrizione;

    this.DocumentoID = -1;
    this.selected.setValue(this.tabs.length);

  }

  addTab(e: RowDoubleClickedEvent) {
    if (!this.tabs.some((x) => x.id == e.data.documentoID)) {
      //this.closedTabs.splice(this.closedTabs.indexOf(e.data.Articolo_Id), 1);
      let row = {
        id: e.data.documentoID,
        nome: e.data.ragioneSociale,
        numero: e.data.numDoc
      }
      this.tabs.push(row);
      // this.data.Articolo_Id = e.data.Articolo_Id || 0;
      // this.data.Descrizione = e.data.Descrizione;
      this.DocumentoID = e.data.documentoID;
    }

    this.selected.setValue(this.tabs.findIndex((x) => x.id == e.data.documentoID) + 1);

  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(
      (document.getElementById('filter-text-box') as HTMLInputElement).value
    );
  }

  closeTab(index: number) {
    //event.stopPropagation();
    this.tabs.splice(this.tabs.findIndex(i => i.id == index), 1);
    this.closedTabs.push(index);
    this.tabGroup!.selectedIndex = 0;
  }

  searchTab(id: number): boolean {
    if (this.tabs.find(i => i.id == id))
      return true
    else
      return false
  }

  public columnDefs: (ColDef | ColGroupDef)[] = [
    // { field: 'DocumentoID', headerName: "ID", width: 100, type: 'numberColumn', checkboxSelection: true ,filter: 'agNumberColumnFilter'},
    { field: 'numDoc', headerName: "Numero Documento", width: 200, filter: 'agTextColumnFilter' },
    // { field: 'PartNumbers', headerName: "Part Number", width: 200 },
    
    { field: 'dataRif', headerName: "Data Riferimento", width: 200, type: 'datetimeColumn'},
    { field: 'dataDoc', headerName: "Data Documento", width: 200, type: 'dateColumn' },
    { field: 'ragioneSociale', headerName: "Ragione Sociale", width: 200, filter: 'agTextColumnFilter' },
    { field: 'localita', headerName: "Località", width: 200, filter: 'agTextColumnFilter' },
    { field: 'indirizzo', headerName: "Indirizzo", width: 200, filter: 'agTextColumnFilter' },
    { field: 'provincia', headerName: "Provincia", width: 200, filter: 'agTextColumnFilter' },
    { field: 'nazione', headerName: "Nazione", width: 200, filter: 'agTextColumnFilter' },

  ];
  public columnTypes: { [key: string]: ColDef } = {

    dimensionColumn: { width: 100, columnGroupShow: 'open', filter: false },
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    dateColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString('it-IT')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T')[0].split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    },
    datetimeColumn: {
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleString('it-IT').replace(new RegExp('-', "g"), '/')
      },
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('T');
          const date = dateParts[0].split('-');
          const day = Number(date[2]);
          const month = Number(date[1]) - 1;
          const year = Number(date[0]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      }
    }
  }
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableRowGroup: true,
    resizable: true,
    width: 100,
    // enable floating filters by default
    floatingFilter: true
  };

  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
      //{ statusPanel: 'agTotalRowCountComponent', align: 'left' },
      // { statusPanel: 'agFilteredRowCountComponent' },
      // { statusPanel: 'agSelectedRowCountComponent' },
      // { statusPanel: 'agAggregationComponent' },
    ],
  };


  public gridColumnApi!: ColumnApi;
  public getRowStyle;
  constructor(private http: HttpClient, private documentiService: DocumentiService) { 

    this.getRowStyle = (params) => {
      if (params.data.accettato) {
        return { background: '#4caf50' };
      }  else if(params.data.confermato){
        return { background: '#EDB100' };
      }else
        return false
    };
  }

  ngOnInit(): void {
  }

}
