<div class="spinner" [style]="'display:' + loading">
    <mat-spinner></mat-spinner>
</div>
<button class="button-add-row" (click)="confermaPrenotazione()"> Conferma Prenotazione</button>
<button class="button-print" (click)="onBtExport()"> Esporta</button>
<ag-grid-angular style="width: 100%; height: 85vh;" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
    [groupDefaultExpanded]="groupDefaultExpanded" [masterDetail]="true"
    [detailCellRendererParams]="detailCellRendererParams" [detailRowAutoHeight]="true" class="ag-theme-alpine"
    (gridReady)="onGridReady($event)" [undoRedoCellEditing]="true" [columnTypes]="columnTypes"
    [detailRowAutoHeight]="true" [gridOptions]="gridOptions" (firstDataRendered)="onFirstDataRendered($event)"
    [frameworkComponents]="frameworkComponents" [rowData]="rowData" [getRowStyle]="getRowStyle" [excelStyles]="excelStyles"
    [defaultExcelExportParams]="defaultExcelExportParams" [defaultCsvExportParams]="defaultCsvExportParams"></ag-grid-angular>